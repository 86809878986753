import React from 'react';
import { Box } from '@mui/material';
import { UseQueryResult } from 'react-query';

import ALLOK from '../../../../img/GreenBeacon.png';
import ERROR from '../../../../img/RedBeacon.png';
import WARNING from '../../../../img/YellowBeacon.png';
import UNKNOWN from '../../../../img/DarkGrayBeacon.png';
import Title from './Title';
import { useMainDashboardQuery } from '../../../../api/reactQuery/queries';
import { HealthCheckResponse } from '../../../../api/MainDashboard/MainDashboardRequests';

const SystemHealth = () => {
  const systemHealthQuery = useMainDashboardQuery('getHealthCheck') as UseQueryResult<HealthCheckResponse>;

  const showHealthIcon = () => {
    switch (systemHealthQuery?.data?.health) {
      case 'UNKNOWN':
        return UNKNOWN;
      case 'HEALTHY':
        return ALLOK;
      case 'UNHEALTHY':
        return ERROR;
      case 'STRESSED':
        return WARNING;
      default:
        return UNKNOWN;
    }
  };

  return (
    <>
      <Title>System Health</Title>
      <Box display="flex" margin="auto" alignItems="center" justifyContent="center">
        <Box
          component="img"
          src={showHealthIcon()}
          alt="HealthCheck"
          width="115px"
        />
      </Box>
    </>
  );
};

export default SystemHealth;
