import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  CardContent,
  Divider
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { UseQueryResult } from 'react-query';

import Title from './Title';
import { useMainDashboardQuery } from '../../../../api/reactQuery/queries';
import { BasicChartResponse } from '../../../../api/MainDashboard/MainDashboardRequests';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const CertExpiry = () => {
  const theme = useTheme();
  const certsExpireQuery = useMainDashboardQuery('getCertsExp') as UseQueryResult<BasicChartResponse>;

  const [chartInstance, setChartInstance] = useState({
    labels: [] as string[],
    datasets: [{}] as any[]
  });

  const updateChartData = useCallback((data: number[], labels: string[]) => {
    setChartInstance((prevState) => ({
      ...prevState,
      labels,
      datasets: [
        {
          label: 'Number of Certs Expiring',
          backgroundColor: ['#E25046', '#F9B928', '#1E589F'],
          data,
          barThickness: 53,
          maxBarThickness: 50,
          barPercentage: 0.5,
          categoryPercentage: 0.5
        }
      ]
    }));
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false as const,
    plugins: {
      legend: { display: false }
    },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.primary,
      footerFontColor: theme.palette.text.primary
    },
    layout: { padding: 0 },
    scales: {
      x: {
        ticks: {
          color: theme.palette.text.primary
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          color: theme.palette.text.primary,
          beginAtZero: true,
          min: 0,
          maxTicksLimit: 10,
          precision: 0
        },
        grid: {
          borderDash: [2],
          borderDashOffset: 2,
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    }
  };

  useEffect(() => {
    if (certsExpireQuery.isSuccess) {
      updateChartData(certsExpireQuery.data.data, certsExpireQuery.data.labels);
    }
  },
  [
    certsExpireQuery.data?.data,
    certsExpireQuery.data?.labels,
    certsExpireQuery.isSuccess,
    updateChartData
  ]);

  return (
    <Box>
      <Title>Certificates Expiring Soon</Title>
      <Divider />
      <CardContent>
        <Box height="334px" position="relative">
          <Bar data={chartInstance} options={options} />
        </Box>
      </CardContent>
    </Box>
  );
};

export default CertExpiry;
