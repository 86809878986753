import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';

import { basicErrorHandler, receiveErrors } from '../reactQuery/queryErrorHandling';
import { useLoggedInUserQuery } from '../Session/SessionQueries';
import {
  INVALID_SESSION,
  UNEXPECTED_ERROR,
  SYSLOG_LIST_QUERY,
  SYSLOG_UNENCRYPTED_ALERT_QUERY,
  TLSAUTH,
  TLSUNAUTH,
  TlsProtocols
} from '../../util/constants';
import { getCredList, getSyslogList, getSyslogUnencryptedAlert } from './LoggingRequests';
import { selectGlobalAlertOpen } from '../../redux/slices/globalAlertSlice';

export const useCredList = (protocol?: TlsProtocols) => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  return useQuery(
    protocol ? `${protocol}` : 'NO_PROTOCOL_DEFINED_CRED_LIST',
    () => {
      if (!protocol) {
        return [];
      }
      const response = getCredList(protocol)
        .then((res) => res.data);
      return response;
    },
    {
      enabled: (protocol === TLSAUTH || protocol === TLSUNAUTH) && Boolean(loggedInUserData?.user),
      retry: (failureCount, error: AxiosError) => {
        if (error.response?.data.errors?.some((entry: any) => entry.type !== INVALID_SESSION)) {
          return failureCount < 3;
        }
        return false;
      },
      onError: (error) => {
        if (error.response?.status === 400) {
          receiveErrors({ error: UNEXPECTED_ERROR }, dispatch);
        } else {
          basicErrorHandler(error, dispatch);
        }
      }
    }
  );
};

// TODO: tighten, after Syslog files converted to TS
export const useSyslogList = (loggerAdd: any[], loggerRemove: any[]) => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  return useQuery(
    SYSLOG_LIST_QUERY,
    () => {
      const response = getSyslogList()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: loggerAdd.length === 0
        && loggerRemove.length === 0
        && Boolean(loggedInUserData?.user),
      refetchInterval: 150000,
      retry: (failureCount, error: AxiosError) => {
        if (error.response?.data.errors?.some((entry: any) => entry.type !== INVALID_SESSION)) {
          return failureCount < 3;
        }
        return false;
      },
      onError: (error) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};

export const useSyslogUnencryptedAlert = () => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const alertOpen = useSelector(selectGlobalAlertOpen);

  const shouldBeEnabled = () => {
    if (loggedInUserData?.user?.privileges?.LOGGERS_READ) {
      return alertOpen && Boolean(loggedInUserData?.user);
    }
    return false;
  };

  return useQuery(
    SYSLOG_UNENCRYPTED_ALERT_QUERY,
    () => {
      const response = getSyslogUnencryptedAlert()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: shouldBeEnabled(),
      refetchInterval: 15000,
      retry: (failureCount, error: AxiosError) => {
        if (error.response?.data.errors?.some((entry: any) => entry.type !== INVALID_SESSION)) {
          return failureCount < 3;
        }
        return false;
      },
      onError: (error) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};
