import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { submitSetDeployDialogFlag } from './DeploymentRequests';
import { UNEXPECTED_ERROR, GET_DEPLOYMENT_STATUS_QUERY, GET_DEPLOY_DIALOG_FLAG_QUERY } from '../../util/constants';
import { basicErrorHandler, receiveErrors } from '../reactQuery/queryErrorHandling';

const useSetDeployDialogFlagMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    submitSetDeployDialogFlag,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_DEPLOYMENT_STATUS_QUERY);
        queryClient.invalidateQueries(GET_DEPLOY_DIALOG_FLAG_QUERY);
      },
      onError: (error: AxiosError) => {
        if (error.response?.data.errors) {
          basicErrorHandler(error, dispatch);
        } else {
          const errorData = {
            error: UNEXPECTED_ERROR
          };
          receiveErrors(errorData, dispatch);
        }
      }
    }
  );
};

export default useSetDeployDialogFlagMutation;
