import React, { useCallback, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { UseQueryResult } from 'react-query';

import Title from './Title';
import EnhancedTableHead from './EnhancedTableHead';
import { useMainDashboardQuery } from '../../../../api/reactQuery/queries';
import { BasicChartResponse } from '../../../../api/MainDashboard/MainDashboardRequests';

ChartJS.register(ArcElement, Tooltip, Legend);

interface TableRowShape {
  label: string;
  total: number;
  percent: number;
}

const CertsByProfile = () => {
  const [profileTable, setProfileTable] = React.useState<TableRowShape[]>([]);
  const [top3, setTop3] = React.useState<JSX.Element[]>([]);
  const certsByProfileQuery = useMainDashboardQuery('getEEProfileStats') as UseQueryResult<BasicChartResponse>;
  const theme = useTheme();
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof TableRowShape>('percent');

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [expanded, setExpanded] = React.useState(false);
  const [chartInstance, setChartInstance] = useState({
    datasets: [{}] as any[],
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    },
    labels: [] as string[]
  });

  const doughnutColorLoop = useCallback((freshData) => {
    // Doughnut colors
    const colors = [
      '#1E589F',
      '#E25046',
      '#53983C',
      '#F9B928',
      '#7FDBFF',
      '#B10DC9',
      '#FFDC00',
      '#001f3f',
      '#39CCCC',
      '#01FF70',
      '#85144b',
      '#F012BE',
      '#3D9970',
      '#111111'
    ];
    // new proposed colors
    // const colors = [
    //   '#140a2e',
    //   '#291a66',
    //   '#492ea3',
    //   '#6945de',
    //   '#9b85e8',
    //   '#d5d7f7',
    //   '#0f124a',
    //   '#212466',
    //   '#292e85',
    //   '#37409b',
    //   '#1e589f',
    //   '#008d7d',
    //   '#00B400'
    // ];
    const bgcolors = [];
    for (let i = 0; i < freshData.length; i += 1) bgcolors.push(colors[i % colors.length]);
    return bgcolors;
  }, []);

  const descendingComparator = (a: TableRowShape, b: TableRowShape, key: keyof TableRowShape) => {
    if (b[key] < a[key]) {
      return -1;
    }
    if (b[key] > a[key]) {
      return 1;
    }
    // if numbers compared were equal, fallback to sort by label
    if (b.label > a.label) {
      return 1;
    }
    return -1;
  };

  const stableSort = (
    array: TableRowShape[],
    comparator: (a: TableRowShape, b: TableRowShape) => number
  ) => {
    const stabilizedThis = array.map((el, index) => [el, index]) as TableRowShape[][];
    stabilizedThis.sort((a, b) => comparator(a[0], b[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = useCallback((sortOrder: 'asc' | 'desc', orderByKey: keyof TableRowShape) => (
    sortOrder === 'desc'
      ? (a: TableRowShape, b: TableRowShape) => descendingComparator(a, b, orderByKey)
      : (a: TableRowShape, b: TableRowShape) => -descendingComparator(a, b, orderByKey)
  ), []);

  const setTopThree = useCallback(
    (list: TableRowShape[]) => {
      const topThreeVal = stableSort(list, getComparator('desc', 'percent'))
        .slice(0, 3)
        .map((row) => (
          <TableRow hover={true} key={`${row.label}_top3`}>
            <TableCell align="left">{row.label}</TableCell>
            <TableCell align="right">{row.total}</TableCell>
            <TableCell align="right">{`${row.percent}%`}</TableCell>
          </TableRow>
        ));
      setTop3(topThreeVal);
    },
    [getComparator]
  );

  const constructTableRows = (data: number[], labels: string[]) => {
    const list: TableRowShape[] = [];
    const sum = data.reduce((a, b) => a + b, 0);
    labels.forEach((entry, i) => {
      list.push({
        label: entry,
        total: data[i],
        percent: Math.round((data[i] * 100) / sum)
      });
    });
    return list;
  };

  const handlePageChange = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (_event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as keyof TableRowShape);
  };

  const getEmptyRows = (rows: TableRowShape[]) => (
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
  );

  const options = {
    animation: false as const,
    cutout: 65,
    layout: { padding: 0 },
    plugins: {
      legend: {
        display: false
      }
    },
    maintainAspectRatio: false,
    responsive: true
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const setProfileChartData = useCallback((data: number[], labels: string[]) => {
    const freshData = data;
    const tableRows = constructTableRows(
      data,
      labels
    );
    setProfileTable(tableRows);
    setTopThree(tableRows);
    setChartInstance((prevState) => ({
      ...prevState,
      datasets: [
        {
          data: freshData,
          borderWidth: 1,
          backgroundColor: doughnutColorLoop(freshData),
          borderColor: '#EBEBF2'
        }
      ],
      labels
    }));
  }, [doughnutColorLoop, setTopThree]);

  useEffect(() => {
    if (certsByProfileQuery.isSuccess) {
      setProfileChartData(certsByProfileQuery.data.data, certsByProfileQuery.data.labels);
    }
  }, [certsByProfileQuery.isSuccess,
    certsByProfileQuery.data?.data,
    certsByProfileQuery.data?.labels,
    setProfileChartData
  ]);

  return (
    <Box sx={{ height: '50%' }}>
      <Title>Certificates By End Entity Profile</Title>
      <Divider />
      <CardContent>
        <Box position="relative" height="181px">
          <Doughnut data={chartInstance} options={options} />
        </Box>
        <Box height="81px" display="flex" justifyContent="center" mt={2}>
          <Table size="small">
            <TableBody>{top3 || null}</TableBody>
          </Table>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          size="large"
          sx={{
            ml: 'auto',
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: () => theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest
            })
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
        <CardContent>
          <TableContainer sx={{ overflow: 'hidden' }}>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                sortDirection={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(profileTable, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.label}>
                      <TableCell align="left">{row.label}</TableCell>
                      <TableCell align="right">{row.total}</TableCell>
                      <TableCell align="right">
                        {row.percent}
                        %
                      </TableCell>
                    </TableRow>
                  ))}
                {getEmptyRows(profileTable) > 0 && (
                  <TableRow
                    style={{
                      height: 33 * getEmptyRows(profileTable)
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={profileTable.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </CardContent>
      </Collapse>
    </Box>
  );
};

export default CertsByProfile;
