import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  CHANGE_PHONE,
  DISABLE_ROOTCA_TOTP,
  DISABLE_TOTP,
  ENABLE_ROOTCA_TOTP,
  NEW_TOTP,
  Setup2faActionsType,
  SETUP_TOTP,
  START_ROOTCA_TOTP,
  STORED_TOTP,
  TotpVerifyModes,
  UNEXPECTED_ERROR
} from '../../../../../util/constants';
import BruteForceBackdrop from '../../../layout/BruteForceBackdrop';
import { useVerifyTOTPMutation, useVerifyTOTPActionMutation } from '../../../../../api/reactQuery/mutations';
import { receiveErrors } from '../../../../../api/reactQuery/queryErrorHandling';
import { selectBruteForceBackdropOpen } from '../../../../../redux/slices/bruteForceBackdropSlice';
import { TokenFormType, TOTPSetPhoneFormType } from '../../../../../types/formTypes';
import { CombinedFields } from '../../../../../api/TwoFA/TwoFARequests';

interface TOTPVerifyProps {
  labelObj: TOTPSetPhoneFormType,
  handleClose: (reason: string) => void,
  actionType: Setup2faActionsType,
  nextStep: (step: number) => void,
  verifyMode: TotpVerifyModes,
  setVerifyMode: React.Dispatch<React.SetStateAction<TotpVerifyModes>>
}

const TOTPVerify = ({
  labelObj,
  handleClose,
  actionType,
  nextStep,
  verifyMode,
  setVerifyMode
}: TOTPVerifyProps) => {
  const dispatch = useDispatch();
  const {
    mutate: verifyTOTPMutate,
    isSuccess: isVerifyTOTPMutationSuccess
  } = useVerifyTOTPMutation();
  const { mutate: verifyTOTPActionMutate } = useVerifyTOTPActionMutation();
  const backdropOpen = useSelector(selectBruteForceBackdropOpen);

  const TOTPVerifyFormSchema = yup.object().shape({
    token: yup
      .string()
      .min(6, 'Invalid Token')
      .max(6, 'Invalid Token')
      .matches(/^[0-9][0-9][0-9][0-9][0-9][0-9]$/, 'Invalid Token')
      .required('Required')
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError
  } = useForm<TokenFormType>({
    mode: 'onChange',
    resolver: yupResolver(TOTPVerifyFormSchema)
  });

  const onSubmit = (data: TokenFormType) => {
    const combinedFields: CombinedFields = { ...data, ...labelObj, actionType };

    if (
      (actionType === SETUP_TOTP
        || actionType === CHANGE_PHONE)
      && verifyMode === NEW_TOTP
    ) {
      const verifyTOTPObject = {
        combinedFields,
        setError
      };
      verifyTOTPMutate(verifyTOTPObject);
    } else if (actionType === DISABLE_TOTP
      || (actionType === CHANGE_PHONE
      && verifyMode === STORED_TOTP)
    || actionType === ENABLE_ROOTCA_TOTP
    || actionType === DISABLE_ROOTCA_TOTP
    || actionType === START_ROOTCA_TOTP
    ) {
      if (
        actionType === ENABLE_ROOTCA_TOTP
      || actionType === DISABLE_ROOTCA_TOTP
      || actionType === START_ROOTCA_TOTP
      || actionType === DISABLE_TOTP
      ) {
        combinedFields.action = actionType;
      }
      const verifyTOTPActionObject = {
        combinedFields,
        setError,
        handleClose,
        nextStep,
        setVerifyMode
      };
      verifyTOTPActionMutate(verifyTOTPActionObject);
    } else {
      receiveErrors({ error: UNEXPECTED_ERROR }, dispatch);
    }
  };

  return (
    <>
      <BruteForceBackdrop />
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        width="100%"
      >
        {!isVerifyTOTPMutationSuccess ? (
          <div>
            <DialogContent>
              <Typography variant="body2">
                Enter the 6-digit token displayed in the app
              </Typography>
              <Box width={400} height={100}>
                <TextField
                  autoFocus={true}
                  margin="normal"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('token', {
                    required: true
                  })}
                  label="Enter Token"
                  id="token"
                  size="small"
                  inputProps={{ maxLength: 6 }}
                  autoComplete="off"
                  error={!!errors.token}
                  helperText={errors.token?.message}
                  variant="standard"
                />
              </Box>
            </DialogContent>
          </div>
        ) : (
          <div>
            <DialogContent>
              <Box width={400}>
                <Typography variant="body2" gutterBottom={true}>
                  Done!
                  <br />
                  <br />
                  Two-Factor authentication has been set up for your account.
                </Typography>
              </Box>
            </DialogContent>
          </div>
        )}

        <DialogActions>
          <Button
            onClick={() => handleClose('button')}
            variant={!isVerifyTOTPMutationSuccess ? 'text' : 'contained'}
            color="primary"
          >
            {!isVerifyTOTPMutationSuccess ? 'Cancel' : 'Done'}
          </Button>
          {!isVerifyTOTPMutationSuccess && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={backdropOpen || !isValid}
            >
              Verify
            </Button>
          )}
        </DialogActions>
      </Box>
    </>
  );
};

export default TOTPVerify;
