import React from 'react';
import {
  Box,
  Button
} from '@mui/material';
import download from 'downloadjs';
import { useDispatch } from 'react-redux';

import { receiveErrors } from '../../../../api/reactQuery/queryErrorHandling';

const CertificatePracticeStatement = () => {
  const dispatch = useDispatch();
  return (
    <Box sx={{ '& > *': { m: 1 } }}>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          const res = await fetch('/api/downloadCPS');
          const contentType = res.headers.get('Content-Type');
          if (contentType && /application\/json/.test(contentType)) {
            const json = await res.json();
            if (json?.error) {
              receiveErrors(json, dispatch);
            }
          } else {
            const blob = await res.blob();
            download(blob, 'CPS_Template_Packet-2021-06-08.zip');
          }
        }}
      >
        Download CPS
      </Button>
    </Box>
  );
};

export default CertificatePracticeStatement;
