import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  ListItemSecondaryAction,
  TextField,
  useMediaQuery,
  useTheme,
  Button
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector, useDispatch } from 'react-redux';

import { useRouteMatch } from 'react-router-dom';
import {
  IP_EDIT_CASE,
  stopUpdateIp,
  clearHelperTextError,
  updateIp,
  PENDING_IP_SUFFIX,
  PendingIpShape,
  selectIpInUpdateMode,
  selectIpHelperText
} from '../../redux/slices/sourceIpSlice';
import inputValidation from './InputValidation';
import { MORE_INFO_ROUTE } from '../../util/routeConstants';

interface ActiveEditItemProps {
  description: string,
  fullList: PendingIpShape[],
  isPendingAdd?: boolean,
  text: string
}

const ActiveEditItem = ({
  description,
  fullList,
  isPendingAdd = false,
  text
}: ActiveEditItemProps) => {
  const dispatch = useDispatch();
  const forceMobileLayout = useRouteMatch({
    path: MORE_INFO_ROUTE,
    sensitive: false
  });
  const ipInUpdateMode = useSelector(selectIpInUpdateMode);
  const helperTextValidationError = useSelector(selectIpHelperText);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [validationPassed, setValidationPassed] = useState(false);
  const [updatedIp, setUpdatedIp] = useState(text);
  const theme = useTheme();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down('sm')) || forceMobileLayout;
  const maxListLength = 100;
  const helperTextValidationErrorForUpdate = helperTextValidationError.targetTextField
    === IP_EDIT_CASE.UPDATE;

  const editFieldChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setUpdatedField: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setValidationPassed(false);
    if (helperTextValidationErrorForUpdate) {
      dispatch(clearHelperTextError());
    }
    const inputData = {
      text: setUpdatedField === setUpdatedIp ? event.target.value : text,
      description: setUpdatedField === setUpdatedDescription ? event.target.value : description
    };
    const listToValidateAgainst = fullList.filter(
      (editedIpEntry) => {
        // legacy database entries wont have keys, so use text as subsitute
        const tempKey = editedIpEntry.key || editedIpEntry.text;
        return (tempKey.split(PENDING_IP_SUFFIX)[0]
        !== ipInUpdateMode.key?.split(PENDING_IP_SUFFIX)[0]);
      }
    );

    const targetTextField = IP_EDIT_CASE.UPDATE;
    if (
      inputValidation(
        inputData,
        listToValidateAgainst,
        maxListLength,
        targetTextField,
        dispatch
      )) {
      setUpdatedField(event.target.value);
      setValidationPassed(true);
    }
  };

  const cancelUpdate = () => {
    setUpdatedIp(text);
    setUpdatedDescription(description);
    dispatch(stopUpdateIp());
  };

  const submitUpdatedFields = () => {
    const inputData = {
      text: updatedIp,
      description: updatedDescription
    };
    dispatch(updateIp({ existingEntry: ipInUpdateMode, newEntry: inputData }));
    cancelUpdate();
  };

  const desktopViewButtons = () => (
    <ListItemSecondaryAction>
      <IconButton
        onClick={submitUpdatedFields}
        size="large"
        color="primary"
        disabled={!validationPassed}
        aria-label="Apply Update"
      >
        <CheckIcon />
      </IconButton>
      <IconButton aria-label="Cancel Update" onClick={cancelUpdate} size="large">
        <ClearIcon color="primary" />
      </IconButton>
    </ListItemSecondaryAction>
  );

  const mobileViewButtons = () => (
    <Grid container={true} sx={{ justifyContent: 'flex-end', mt: 1 }}>
      <Button
        sx={{ mr: 2 }}
        disabled={!validationPassed}
        onClick={submitUpdatedFields}
        startIcon={<CheckIcon />}
        variant="outlined"
        color="primary"
        aria-label="Apply Update"
      >
        Update
      </Button>
      <Button
        onClick={cancelUpdate}
        startIcon={<ClearIcon />}
        variant="outlined"
        color="primary"
        aria-label="Cancel Update"
      >
        Cancel
      </Button>

    </Grid>
  );
  return (
    <>
      <Grid item={true} xs={12} sm={forceMobileLayout ? 12 : 3}>
        <TextField
          fullWidth={true}
          label="IP"
          defaultValue={text}
          onChange={(event) => { editFieldChange(event, setUpdatedIp); }}
          error={helperTextValidationErrorForUpdate}
          helperText={helperTextValidationError?.message}
          variant="standard"
          sx={{ color: isPendingAdd ? 'green' : '', pr: isMobileLayout ? 0 : 2 }}
        />
      </Grid>
      <Grid item={true} xs={12} sm={forceMobileLayout ? 12 : 7}>
        <TextField
          label="Description"
          defaultValue={description}
          onChange={(event) => { editFieldChange(event, setUpdatedDescription); }}
          inputProps={{ maxLength: 255 }}
          fullWidth={true}
          variant="standard"
          sx={{ color: isPendingAdd ? 'green' : '' }}
        />
      </Grid>
      {isMobileLayout
        ? mobileViewButtons()
        : desktopViewButtons()}

    </>
  );
};

export default ActiveEditItem;
