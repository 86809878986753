import { QueryClient, QueryCache, MutationCache } from 'react-query';
import { INVALID_SESSION, LOGGED_IN_USER_QUERY } from '../../util/constants';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      error.response?.data?.errors?.forEach((entry: any) => {
        if (entry.type === INVALID_SESSION) {
          queryClient.invalidateQueries(LOGGED_IN_USER_QUERY);
        }
      });
    }
  }),
  mutationCache: new MutationCache({
    onError: (error: any) => {
      error.response?.data?.errors?.forEach((entry: any) => {
        if (entry.type === INVALID_SESSION) {
          queryClient.invalidateQueries(LOGGED_IN_USER_QUERY);
        }
      });
    }
  })
});

export default queryClient;
