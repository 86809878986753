import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';

interface BruteForceBackdropState {
  backdropOpen: boolean,
  backdropCountdown: number,
  isFailedPasswordAttempt: boolean
}

const initialState: BruteForceBackdropState = {
  backdropOpen: false,
  backdropCountdown: 0,
  isFailedPasswordAttempt: true
};

export const bruteForceBackdropSlice = createSlice({
  name: 'bruteForceBackdrop',
  initialState,
  reducers: {
    clearBruteForceBackdrop: () => initialState,
    setBruteForceBackdrop: (state, action: PayloadAction<BruteForceBackdropState>) => {
      state.backdropOpen = action.payload.backdropOpen;
      state.backdropCountdown = action.payload.backdropCountdown;
      state.isFailedPasswordAttempt = action.payload.isFailedPasswordAttempt;
    }
  }
});

export const {
  clearBruteForceBackdrop,
  setBruteForceBackdrop
} = bruteForceBackdropSlice.actions;

export const selectBruteForceBackdropOpen = (
  state: RootState
) => state.bruteForceBackdrop.backdropOpen;
export const selectBruteForceBackdropCountdown = (
  state: RootState
) => state.bruteForceBackdrop.backdropCountdown;
export const selectBruteForceBackdropFailedPwAttempt = (
  state: RootState
) => state.bruteForceBackdrop.isFailedPasswordAttempt;

export default bruteForceBackdropSlice.reducer;
