const faq = `


## Access Control

**Q: Are all authentication credentials encrypted during transmission and protected in a one-way hash in storage?**

>A: Yes, passwords are hashed using bcrypt and stored in a secure database.

**Q: Are controls in place to lock laptops, desktops and servers when unattended?**

>A: All employees who work on the EJBCA SaaS are required to abide by the Acceptable Use Policy which describes how to securely use company assets. Additionally, employee workstations have to VPN to a bastion host prior to accessing the production environment. As such, the security of the employee's workstation is deemed low risk.

**Q: Are employees assigned job roles and granted privileges in accordance with their role? Is a least privilege model used?**

>A: EJBCA SaaS team members are limited to only a few team members. Additionally, the team members are restricted to the least privilege required to complete their job responsibilities.

**Q: Does EJBCA SaaS enforce single sign-on (SSO)?**

>A: SSO is not currently supported on EJBCA SaaS. Customers need to authenticate through our application portal to access their PKI environment. However, our team has SSO on our development roadmap and will be released in the near future.

**Q: Does the application avoid the use of shared or service accounts?**

>A: Each customer who subscribes to EJBCA SaaS connects their environment to a unique AWS account. As such, shared accounts are not used. Customers are responsible for restricting shared accounts in their environments.

**Q: Does the application enforce session timeouts?**

>A: All connections through the web UI including (administrators and RA users) have enforced session timeouts.

**Q: Does the application enforce maximum login attempts?**

>A: Yes, users have 10 attempts within 30 mins to authenticate to their account. 

**Q: Does your organization ensure application and platform components are granted the minimum level of access required to the customer's data?**

>A: Yes, PrimeKey manages applications and platform components based on the minimum level of access required for an employee to efficiently do their job function.

**Q: Does your organization have a formal Access Control policy?**

>A: The EJBCA SaaS team has an access control policy in place which describes how to manage and secure access to EJBCA SaaS.

**Q: How are access privileges assigned?**

>A: On the EJBCA SaaS side, only a few members of the team have access to customer data. Each member is vetted and trained at least annually. The customer is responsible for provisioning privileged access to their users.

**Q: How is a user's access to information modified based on their role?**

>A: EJBCA SaaS uses an RBAC model in which each user is assigned a permission role. Based on the role, the user can only access certain aspects or perform certain actions in the application.

**Q: What is the process for provisioning, removing, and updating accounts?**

>A: The customer is provisioned an account once they sign a contract with AWS and create a profile in the portal. The account is removed when a user requests a removal, ends the AWS contract, or does not meet their financial obligation. The customer is ultimately responsible for provisioning, removing, and updating accounts for their users.

**Q: Can any cloud hosting party access customer data?**

>A: Third parties cannot access customer data.

----------------

## Human Resources
  
**Q: Are background checks performed on employees?**

>A: PrimeKey conducts background checks on employees before being hired on a full-time basis.

**Q: Are employees required to attend information security awareness training?**

>A: Employees are required to attend information security awareness training annually. The training materials include the proper handling of customer personal information. The material for the training is updated at least on an annual basis.

**Q: Do job descriptions include security roles and responsibilities?**

>A: Job descriptions including security roles and responsibilities that are communicated to the new employee by the human resource manager.

**Q: Does PrimeKey have a documented employee termination/change position policy?**

>A: Yes, we have an Onboarding and Termination policy that facilitates onboarding, offboarding, and changing of positions in the company. Included in the policy are instructions on how to return company assets.

**Q: What documents are employees required to sign before working with EJBCA SaaS?**

>A: When employees or contractors are onboarded, they sign a Code of Conduct agreement and a Non-disclosure agreement.

**Q: Does PrimeKey impose disciplinary measures for violations of company policies, including information security policies?**

>A: Yes, PrimeKey imposes disciplinary measures up to and including termination for violation of company policies.

----------------

## Information Security
  
**Q: Has your executive management defined information security goals?**

>A: Information Security goals are written in our Objectives Quality, InfoSec, Environment document. Information security goals are set around incidents and risk remediation. These goals are reviewed and approved by the CEO Magnus Svenningson.

**Q: When was the last time your information security program was reviewed and/or certified by an independent third party?**

>A: PrimeKey became ISO 27001, 14001, and 9001 certified in Q4 2021. To see our current certificates, refer to https://www.iafcertsearch.org.

**Q: Does PrimeKey have a documented data classification policy?**

>A: PrimeKey has an information data classification policy in place. The information data classification policy instructs how to apply the proper protection over customer data.

**Q: Does PrimeKey have a formal, documented asset management program?**

>A: All assets that process and store customer information reside in the Amazon Web Services (AWS) environment. A list of all critical assets is tracked through the Management Console. As the assets are not assigned to a single device, but a host of devices managed by Amazon, tracking the treatment, handling, disposal, destruction, and/or reuse of a single physical asset is not applicable.

**Q: Does PrimeKey have an acceptable use policy?**

>A: Yes, we have an Acceptable Use Policy that governs how employees access company assets.

**Q: Does PrimeKey have a formal Information Security policy?**

>A: PrimeKey uses our Information Security Policy to define the appropriate method of accessing customer data. Employees do not store or transport customer data. The Information Security Policy is reviewed at least annually.

**Q: Does PrimeKey have an Information Security oversight function?**

>A: PrimeKey has a security oversight function that comprises the GM of the US, Harry Haramis, the CTO, Tomas Gustavsson, and the CEO, Magnus Svenningson. Each individual sets information security goals and is committed to information security.

**Q: How does PrimeKey make its policies available to all employees?**

>A: A repository of all polices for the those who work on EJBCA SaaS resides in the corporate intranet.

----------------

## Communications and Operations Management
  
**Q: Are directory browsing and traversal capabilities disabled by default on your application platform?**

>A: Yes, all directory browsing and traversal capabilities are disabled by default in EJBCA SaaS.

**Q: Are employees permitted to install unauthorized or unapproved applications on corporate workstations?**

>A: All employees who work on the EJBCA SaaS are required to abide by the Acceptable Use Policy which prohibits the use harmful applications and software. Additionally, employee workstations have to full tunnel VPN to a bastion host prior to accessing production.

**Q: Do you allow any unsecured protocol into or out of your network? If so, please explain.**

>A: The only ports open in the environment are:  
>- 80 -- for CRLs, OCSP, and SCEP  
>- 443 (TLS) -- for portal traffic, EJBCA web traffic, RestAPI, WebServices, EST, CMP, and ACME  
>
>SCEP traffic across port 80 is securely encapsulated in a session which follows the RFC 5280 standard. OCSP and CRLs are signed by a CA and by nature are not supposed to require a certificate to check the status of another certificate. 

**Q: Do you have a removable media policy in place?**

>A: All employees who work on the EJBCA SaaS are required to abide by the Acceptable Use Policy which prohibits the malicious use of removable media. Additionally, employee workstations have to full tunnel VPN to a bastion host prior to accessing production.

**Q: Do you have a wireless network policy?**

>A: All employees who work on the EJBCA SaaS are required to abide by the Acceptable Use Policy which describes how to securely use the wireless network in the office. Additionally, employee workstations have to full tunnel VPN to a bastion host prior to accessing production.

**Q: Does PrimeKey allow for open file sharing services?**

>A: PrimeKey uses integral Wiki pages for sharing files between employees. An employee must authenticate before accessing the pages.

**Q: For locations that process or store customer data, is the database tier accessible from the internet or located in a DMZ or "untrusted" network?**

>A: The database tier is not accessible from the internet, DMZ, or "untrusted" network as they are on their own dedicated AWS RDS instance in a private subnet which can only be accessed through the customer portal.

**Q: How do you protect email containing sensitive information?**

>A: Employees are directed to avoid sending sensitive customer information through email. Additionally, we use a secure TLS port for mail transmission.

**Q: How does PrimeKey harden network devices?**

>A: Devices are hardened to only provide enough access for the device to perform its task. For devices facing the internet, only port 80 and 443 are open. SCEP traffic across port 80 is securely encapsulated in a session which follows the RFC 5280 standard. OCSP and CRLs are signed by a CA and by nature are not supposed to require a certificate to check the status of another certificate.

**Q: How is system documentation stored and secured?**

>A: AWS support documentation is found on their website. Operation documentation for EJBCA SaaS is stored in our intranet and can only be accessed by employees of PrimeKey.

**Q: What environments does PrimeKey use to support EJBCA SaaS?**

>A: PrimeKey uses three environments to manage the EJBCA SaaS:  
>- Dev -- Local instance of the code  
>- Staging -- AWS instance that runs in parallel to Prod  
>- Prod -- AWS instance that hosts all customer PKI infrastructure  

**Q: Does EJBCA SaaS place limits on resource intensive operations?**

>A: EJBCA SaaS is hosted on AWS which is designed to scale easily. Customers will be constrained by the size of the environment they have chosen.

**Q: How do I know my environment will be available whenever I need it? What is the SLA?**

>A: The EJBCA SaaS Service Level Agreement is dependent on the size of your environment. For XS, the SLA is 99%. For S and M instances, the SLA is 99.95%. For L and XL instances, the SLA is 99.99%.

**Q: How are firewall rules created? How are they reviewed? And are firewalls set to “default deny = true"?**

>A: Rules are created through Ansible/Kubernetes manifest files and the "default deny" setting is set to true by default. The rules are reviewed on a periodic basis.

**Q: How are system resources, including network and storage resources, monitored?**

>A: EJBCA SaaS uses AWS CloudTrail and Fluentd to collect logs from EJBCA and AWS. The logs are then fed into AWS CloudWatch for aggregation and monitoring.

**Q: How are incidents detected in EJBCA SaaS?**

>A: AWS CloudWatch is used to detect any incidents in EJBCA SaaS. AWS CloudWatch is monitored by our EJBCA SaaS Operations team.

**Q: What if I want my data removed from EJBCA SaaS?**

>A: Your satisfaction is our greatest concern. If you want your data removed we can remove it from our product. However, we require the minimum amount of customer data to have the product function properly. If customer data is removed, the product will not function properly.

**Q: How does EJBCA SaaS handle emergency changes?**

>A: Emergency changes are handled through a hot fix workflow design in which changes are tested with required/functional validation and then quickly pushed to production but retroactively tested and approved in the staging environment.

**Q: Does EJBCA SaaS have a formal, structured change management process?**

>A: Yes, we use GitHub flow to develop, test, approve, and then push changes to production.

----------------

## Compliance
  
**Q: Do you conduct risk assessments of your third parties?**

>A: We perform a risk analysis prior to onboarding any product or vendor on EJBCA SaaS.

**Q: Do you have an internal audit function?**

>A: We have a dedicated internal audit function that is based out of Sweden that periodically audits our environment. We also have an audit analyst who helps support internal audits in the US.

**Q: Does your organization have a Chief Compliance Officer and/or a Compliance group?**

>A: The point of contact for compliance for EJBCA SaaS is Spencer Finnegan, Product Security analyst.

**Q: What controls are in place to ensure compliance with data protection and privacy regulations?**

>A: PrimeKey has established many controls to ensure compliance with data protection and privacy, a few include:  
>- End to end encryption of customer data  
>- Each customer is on a dedicated RDS instance on a private subnet  
>- 2FA is required for all PrimeKey employees who access production
>
> You can access our entire SaaS privacy policy at https://www.primekey.com/wp-content/uploads/2022/01/privacy-policy-primekey-ejbca-saas-2020-10-01-2.pdf

**Q: What regulations are applicable to your organization (e.g., HIPAA, PCI, GLBA, etc.)?**

>A:
>
>**-SOC 2**
>
>Our internal audit function has applied SOC 2 criteria to the EJBCA SaaS control environment but we are not officially compliant. However, becoming SOC 2 compliant is on our near future roadmap.
>
>EJBCA is hosted on AWS which is SOC 2 compliant.
>
>**-ISO 27001**
>
>PrimeKey is ISO 27001 certified. EJBCA is hosted on AWS which is also ISO 27001 certified.
>
>**-ISO 27017**
>
>Our internal audit function has applied ISO 27017 criteria to the EJBCA SaaS control environment but we are not officially certified.
>
>EJBCA is hosted on AWS which is ISO 27017 certified.
>
>**-ISO 14001**
>
>PrimeKey is ISO 14001 certified. ISO 14001 is also known as the standard that covers environmental management systems.
>
>**-ISO 9001**
>
>PrimeKey is ISO 9001 certified. ISO 9001 is also known as the standard that covers quality management systems.
>
>**-Common Criteria**
>
>PrimeKey's EJBCA is Common Criteria certified.
>
>**-HIPAA**
>
>EJBCA SaaS does not store, transmit, or process PHI (Protected Health Information). As such, HIPPA does not apply to EJBCA SaaS.
>
>EJBCA is also hosted on AWS which is HIPAA compliant.
>
>**-PCI DSS**
>
>Usage of the EJBCA SaaS is fed from our application to AWS. AWS handles the billing for the usage of our product. EJBCA SaaS does not store credit card data.
>
>EJBCA SaaS is built on AWS which is PCI DSS compliant.
>
>**-FedRamp**
>
>Our internal audit function has applied FedRamp criteria to the EJBCA SaaS control environment but we are not officially certified. However, becoming FedRamp certified is on our roadmap.
>
>EJBCA is hosted on AWS which is FedRamp certified.
>
>**-CSA Star**
>
>PrimeKey is in the process of becoming STAR Level 1 compliant.
>
>EJBCA is also hosted on AWS which is CSA Star certified.
>


----------------

## Risk Assessment

**Q: Does PrimeKey implement a formal risk assessment program?**

>A: A formal comprehensive risk assessment is conducted on an annual basis. The risk assessment identifies internal and external risks related to the confidentiality, integrity, and availability of the customer data.

**Q: Do you have a process in place to research/scan and then implement security best practices**

>A: Yes, our EJBCA SaaS Operations team does research on a periodic basis to identify industry best practices to implement into our product.

----------------

## Business Continuity and Disaster Recovery
  
**Q: Does your organization have a formal incident response policy?**

>A: PrimeKey has an incident response playbook that each team member has access to.

**Q: How often are business risk assessments performed and what scenarios are covered in your risk assessment (i.e. a pandemic event)?**

>A: Risk assessments are performed annually. Below are a few of the scenarios:  
> - Data stored in a server that is not available  
> - Unauthorized access to sensitive information  
> - Data stored in the mail server is not available  

**Q: If you outsource to any third parties, how do you assess business continuity management for your suppliers?**

>A: As we rely on AWS for our EJBCA SaaS platform, we have carefully assessed the business continuity management of AWS. As AWS is both SOC 2 certified and ISO 27001 certified, we feel comfortable with their business continuity management.

**Q: Are there alternative office space/locations available for employees to work in? If not, what is your plan for continuing operations?**

>A: We have five offices around the world. We have an office in California, Washington DC, Sweden, Australia and Germany. As the EJBCA SaaS environment can be accessed via the internet, employees aren't constrained to work from a PrimeKey office.

**Q: Is there an alternative data center site available for continued data center operations?**

>A: Depending on the size of your environment, the number of regions and availability zones will vary. The larger size environments will offer more redundancy and availability. Refer to details found on the AWS listing for specific details.

**Q: What policies or programs do you have in place in order to help prevent the spread of infectious diseases such as influenza or COVID 19 in the workplace?**

>A: Employees who work on EJBCA SaaS are not required to be in the office in order to work on the product. As such, employees are encouraged to work from home if they have any symptoms of infectious diseases.

----------------

## Product Specific

**Q: Can I create publicly trusted certificates in EJBCA SaaS?**

>A: The EJBCA SaaS was not designed for creating publicly trusted certificates. EJBCA SaaS specializes in creating internally trusted certificates.

**Q: Should I choose AWS Cloud HSM or AWS KMS?**

>A: AWS Cloud HSM is the best way to protect your keys in your security environment, but comes at a steeper cost. The Cloud HSM is FIPS 140-2 Level 3 certified. AWS KMS, on the other hand, is FIPS 140-2 Level 2 certified. The main difference between level 2 and level 3 is that additional requirements are added to level 3 for physical tamper-resistance and identity-based authentication.

**Q: Should I use an on-premise PKI or a SaaS based PKI?**

>A: When determining if you want to store your data in the cloud, ensure you evaluate the feasibility with regard to skills, cost, and available time to determine if you can realistically provide a secure and compliant solution. PrimeKey abides by criteria from SOC 2, ISO 27001, 27017, and CSA Star and provides a world class PKI product and environment in the cloud.

**Q: What kind of certificates can be created in EJBCA SaaS?**

>A: EJBCA SaaS supports both X.509 v3 certificates and Card Verifiable Certificates (CVC BSI TR-03110). The certificates are compliant with standards such as RFC 5280, CA/Browser Forum, eIDAS, ICAO 9303, EAC 2.10, and ISO 18013 Amendment 2 eDL.

**Q: What kinds of data are collected and stored in EJBCA SaaS?**

>A: EJBCA SaaS collects and stores enrollment data, customer usernames/passwords, certificates, digital signatures, and private keys.

**Q: Will I have access to EJBCA source code?**

>A: No, customers will not have access to the source code to make modifications. However, the application is extremely customizable and can be configured to fit most PKI environments.

**Q: Does EJBCA SaaS have any compliance certifications?**

>A: EJBCA SaaS was designed and built with ISO 27001 and SOC 2 criteria in mind. We are currently ISO 27001, 14001, and 9001 certified. We also have started the process of becoming SOC 2 complaint. Additionally, EJBCA is Common Criteria EAL 4+ compliant.

**Q: Is any software development for EJBCA SaaS performed outside the US?**

>A: PrimeKey is based in Sweden and the development of EJBCA is based there. As such, development of EJBCA will take place in Sweden but any other development for EJBCA SaaS will take place in the United States.

**Q: How does PrimeKey communicate with customers?**

>A: Customers can contact us by calling, sending an email, or submitting a ticket online.

**Q: How is one customer's information kept separate from another customer's information?**

>A: Customer's environments exist on their own dedicated AWS RDS instance in a private subnet. There is no crossover of VPCs between customers. 

**Q: Can I migrate existing keys from my PKI environment into EJBCA SaaS?**

>A: No, only new keys are allowed into EJBCA SaaS. We do not migrate existing keys into the product. However, if you choose to leave EJBCA SaaS, your keys can be provided to you.

**Q: How can I contact support if I have an issue?**

>A: If you have an issue you can contact support by emailing support@c2company.com or contacting us by going to https://www.primekey.com/contact. To make it easy, we have added the ability to contact support in the EJBCA SaaS portal.

**Q: EJBCA SaaS really is not for me, do you have any other products?**

>A: Yes, PrimeKey offers many different products including EJBCA Enterprise and SignServer across many different mediums including our cloud options to deploy within your own VPC, on-prem appliances, virtual appliances, and source code licenses. We have done business across many industries and with many different use cases. Please contact us by emailing us at sales@primekey.com or by going to our website.

**Q: If I need additional help with EJBCA SaaS or choose a different PrimeKey product, can PrimeKey help me?**

>A: Yes, we have a professional services team that are experts in PKI and some of the best in the industry. If you are interested, contact us by emailing us at sales@primekey.com

**Q: Can I export my log data to my own syslog server/service?**
 
>A: Yes, syslog data can be pushed to external servers/services. You have the option of using TCP – unencrypted, TLS – unauthenticated, or TLS – authenticated for root CA and/or issuing CA logs. We do warn that the use of TCP – unencrypted will send logs in plaintext and should only be used for testing purposes.

----------------

## Bonus Offering

**Q: Can I use my existing CP/CPS? Or can PrimeKey help me make one?**

>A: Customers can use an existing CP/CPS. We have also created generic CP/CPS templates to help customers create a CP and a CPS to be successful in their PKI environment. In order to get the CP/CPS templates, you must subscribe to EJBCA SaaS.

----------------

## Product Security 

**Q: Does EJBCA SaaS enforce two-factor authentication (2FA)?**

>A: 2FA is required for all engineers who have access to customer environments. 2FA is optional for customers.

**Q: Are penetration tests performed on EJBCA SaaS? And if so, how often?**

>A: Yes, penetration tests are performed on EJBCA SaaS using Qualys. The scans are performed by the EJBCA SaaS operations team on a periodic basis.

**Q: Does EJBCA SaaS use the latest and secure versions of all dependencies and development frameworks?**

>A: Yes, PrimeKey continuously monitors and updates the versions of software used in EJBCA SaaS.

**Q: How does EJBCA protect the data in transit and at rest?**

>A: The data in EJBCA SaaS is encrypted at rest and in transit, using several strong encryption protocols, and technologies that include Transport Layer Security/Secure Sockets Layer (TLS/SSL), Internet Protocol Security (IPSec), and Advanced Encryption Standard (AES).

**Q: Is data masked in development environments? Is production data/customer data used in testing?**

>A: Customer personal information is not used in development or testing. Customer data is strictly kept in the production environment.

**Q: Do we perform vulnerability tests on AWS or any other third parties?**

>A: We do not perform vulnerability tests on AWS as they are ISO 27001 and SOC 2 certified and have extremely strict SLAs.

**Q: Do cookies include any authentication credentials or PII?**

>A: The only cookie we use in EJBCA SaaS is a session cookie to maintain session state. The cookie does not hold any sensitive customer PII.

**Q: Do authentication controls fail securely with no information being provided?**

>A: Yes, when a customer attempts to login with incorrect credentials, by default, authentication will fail and will not provide any information as to what failed.

**Q: Are Admin accounts held to the same password policy that users/customers are? Or is it a higher standard?**

>A: Administrators (Admins) are held to the same strict password policy we have created. Additionally, Admins are required to use two-factor authentication (2FA) in order to access customer's environments. Customers, on the other hand, are not required to use 2FA.

**Q: What is the process for backing up customer data?**

>A: Customer data is set to be backed up on a daily basis to a secure RDS database.

**Q: How long is customer data retained and archived?**

>A: EJBCA SaaS follows the retention standards set by AWS. For active customers, data is backed up daily and retained. If a customer fails to pay their bill or cancels their subscription, their account and data will be deleted in 90 days. For active customers, we archive data for a maximum of one year.

**Q: Are audit logs protected from being modified?**

>A: Yes, audit logs are immutable objects that are read only and can't be edited. Additionally, EJBCA SaaS logs are protected through the use of signed audit logs and database tampered detection and resistance.

**Q: What is contained in audit logs?**

>A: At a high level, the audit logs contain logs of the AWS infrastructure and the EJBCA infrastructure. The EJBCA logs also contain what transpires within EJBCA SaaS.

**Q: What are the password requirements in EJBCA SaaS?**

>A: Our password requirements follow the suggestions of Open Web Application Security Project (OWASP). Admins additionally must use 2FA to access a customer's environment.

**Q: Do you allow any unsecured port to be open in the environment?**

>A: No, the ports open in the environment are the essential ports to keep the product functioning. The ports that are open are secured and protected.

**Q: Does PrimeKey use a secure application platform build and security hardening process?**

>A: When assets are created in the EJBCA SaaS environment, a team of engineers hardens each device by disabling all but the essential features to keep the device secure while functioning effectively.

**Q: What security and vulnerabilities are considered during the development process?**

>A: PrimeKey has a secure system development lifecycle processes to design, develop, and test software in a manner free of critical and high risk vulnerabilities. All common OWASP and high risk application vulnerabilities including XSS, CSRF, SQLi, and the OWASP Top 10 are considered in the development process.

**Q: How does the EJBCA SaaS Operations team manage their keys and passwords?**

>A: The keys used by the Operations teams reside in AWS KMS in which they are managed and maintained securely. Passwords for EJBCA are managed and stored in AWS Secret Manager.

**Q: Are patches scanned for vulnerabilities prior to being added to EJBCA SaaS?**

>A: Yes, EJBCA SaaS is built on Kubernetes containers. The EJBCA container and its patches are scanned prior to being deployed.

**Q: Does EJBCA SaaS have DDOS protection?**

>A: Yes, PrimeKey uses AWS Shield to protect EJBCA SaaS from DDOS attacks.

----------------

## Tools

**Q: Does PrimeKey use a formal SCM (Source Code Management) system?**

>A: PrimeKey uses GitHub as a formal SCM for EJBCA SaaS.

**Q: Does PrimeKey use a firewall to protect the EJBCA SaaS?**

>A: PrimeKey primarily protects EJBCA SaaS using IP table filters and restrictive ACLs.

**Q: Does PrimeKey use IDS/IPS to protect EJBCA SaaS?**

>A: PrimeKey uses Host IDS on AMIs and CloudWatch to protect EJBCA SaaS.

**Q: What tool is used for IAM (Identity Access Management)?**

>A: To manage Identity Access Management we leverage AWS IAM. We also leverage the default roles in the application. Our operation staff have also adopted the rule of least privilege in which they are only given access to perform the function of their job.

**Q: Does PrimeKey run vulnerability tests over the EJBCA SaaS environment?**

>A: Yes, PrimeKey leverages a combination of Qualys tools for vulnerability scanning.

**Q: What tool(s) are used to collect and log data in EJBCA SaaS?**

>A: We use one main tool to collect logs in EJBCA SaaS. All PKI operations are logged at a granular level for all applications, services, and infrastructure components and pushed to AWS CloudWatch.



----------------

## Physical and Environmental Security

**Q: How has PrimeKey taken into consideration concerns surrounding physical and environmental security?**

>A: We have carefully chosen Amazon (AWS) to host EJBCA SaaS. AWS is certified with SOC 2, CSA Star, and ISO 27001 certifications which demonstrates to us that they adequately cover the physical and environmental security aspects with regard to infrastructure and customer data. On an annual basis, our team evaluates any physical and environmental concerns that AWS is responsible for.


----------------

## Retention

**Q: How long is customer data retained and archived?**

>A: EJBCA SaaS follows the retention standards set by AWS. For active customers, data is backed up daily and retained. If a customer fails to pay their bill or cancels their subscription, their account and data will be deleted in 90 days. For active customers, PrimeKey archives data for a maximum of one year.

**Q: How long is log data retained?**

>A: Log data is stored for up to a year in AWS.`;

export default faq;
