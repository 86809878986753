import React from 'react';
import { AlertColor, Chip } from '@mui/material';

interface UserStatusTagProps {
  label: string;
  tagSize?: 'small' | 'large';
  variant?: 'filled' | 'outlined';
  color?: AlertColor;
}

const smallTagStyle = {
  borderRadius: '4px',
  fontSize: '.66rem',
  height: '20px',
  marginLeft: '16px',
  marginBottom: '4px'
} as const;

const largeTagStyle = {
  borderRadius: '4px',
  height: '26px',
  fontSize: '.75rem',
  marginLeft: '16px',
  marginBottom: '6px'
} as const;

const UserStatusTag = (
  {
    label,
    tagSize = 'small',
    variant = 'filled',
    color = 'default' as AlertColor
  }: UserStatusTagProps
) => <Chip label={label} color={color} variant={variant} sx={tagSize === 'small' ? smallTagStyle : largeTagStyle} />;

export default UserStatusTag;
