import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material';

import type { RootState } from '../store';
import { GLOBAL_ALERT_KEYS } from '../../util/constants';

export interface SingleAlertDetails {
  alertMessage: string;
  alertType: AlertColor;
  key: typeof GLOBAL_ALERT_KEYS[keyof typeof GLOBAL_ALERT_KEYS];
}

interface GlobalAlertState {
  [key : string]: SingleAlertDetails
}

const initialState: GlobalAlertState = {};

export const globalAlertSlice = createSlice({
  name: 'globalAlerts',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<SingleAlertDetails>) => {
      state[action.payload.key] = action.payload;
    },
    removeAlert: (state, action: PayloadAction<{ keyToRemove: string }>) => {
      delete state[action.payload.keyToRemove];
    }
  }
});

export const {
  setAlert,
  removeAlert
} = globalAlertSlice.actions;

export const selectGlobalAlerts = (state: RootState) => state.globalAlerts;
export const selectGlobalAlertOpen = (
  state: RootState
) => Object.keys(state.globalAlerts).length > 0;

export default globalAlertSlice.reducer;
