import React, { useEffect, useState, useCallback } from 'react';
import { Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';

import SyslogTableContent from './SyslogTableContent';
import { useSyslogList, useLoggedInUserQuery } from '../../../../../api/reactQuery/queries';
import { useSubmitLoggersMutation } from '../../../../../api/reactQuery/mutations';
import ChangesPreview from './ChangesPreview';
import AddSyslogConfigForm from './AddSyslogConfigForm';
import { TlsProtocols } from '../../../../../util/constants';
import { SyslogListResponse } from '../../../../../api/Logging/LoggingRequests';
import { SubmitLoggersFormType, SyslogFormType } from '../../../../../types/formTypes';

interface SyslogLayoutProps {
  setUnsavedSyslogChanges: React.Dispatch<React.SetStateAction<boolean>>,
  unsavedSyslogChanges: boolean
}

const SyslogLayout = ({
  setUnsavedSyslogChanges,
  unsavedSyslogChanges
}: SyslogLayoutProps) => {
  const [loggerRemove, setLoggerRemove] = useState<SyslogFormType[]>([]);
  const [loggerAdd, setLoggerAdd] = useState<SyslogFormType[]>([]);
  const [initialDisplayed, setInitialDisplayed] = useState<SyslogListResponse[]>([]);
  const [displayedSyslog, setDisplayedSyslog] = useState<SyslogListResponse[]>([]);
  const [openPreviewChangesDialog, setOpenPreviewChangesDialog] = useState(
    false
  );
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const {
    data: syslogListData,
    isSuccess: isSyslogListSuccess
  } = useSyslogList(loggerAdd, loggerRemove);
  const { mutate: submitLoggersMutate } = useSubmitLoggersMutation();
  const userPrivs = loggedInUserData?.user?.privileges;

  const { handleSubmit } = useForm<SubmitLoggersFormType>();

  const tableHeaders = [
    { key: 'appType', label: 'Application' },
    { key: 'ipdns', label: 'IP / FQDN' },
    { key: 'loggerPodLabel', label: 'Name' },
    { key: 'protocol', label: 'Protocol' },
    { key: 'credentials', label: 'Credentials' },
    { key: 'port', label: 'Port' }
  ];

  const protocolToText = (protocol: TlsProtocols) => {
    if (protocol === '') {
      return '';
    }
    const textToDisplay = {
      TLSUNAUTH: 'TLS - Unauthenticated',
      TLSAUTH: 'TLS - Authenticated',
      TCP: 'TCP - Unencrypted'
    };
    return textToDisplay[protocol];
  };

  const resetInitialandDisplayed = useCallback(() => {
    if (isSyslogListSuccess) {
      setDisplayedSyslog(syslogListData);
      setInitialDisplayed(syslogListData);
    }
  }, [syslogListData, isSyslogListSuccess]);

  const addedAndRemoved = useCallback(() => {
    const addToDatabase = displayedSyslog.filter(
      (newEntry) => !initialDisplayed
        .map((savedEntry) => savedEntry.loggerPodLabel)
        .includes(newEntry.loggerPodLabel)
    );

    setLoggerAdd(addToDatabase);

    setLoggerRemove(
      initialDisplayed.filter(
        (savedEntry) => !displayedSyslog
          .map((removedEntry) => removedEntry.loggerPodLabel)
          .includes(savedEntry.loggerPodLabel)
      )
    );
  }, [displayedSyslog, initialDisplayed]);

  const openPreviewChangesOnclick = () => {
    addedAndRemoved();
    setOpenPreviewChangesDialog(true);
  };

  const onSubmit = () => {
    const data: SubmitLoggersFormType = {};
    if (loggerRemove.length > 0) {
      data.loggerRemove = loggerRemove.map((removedEntries) => ({
        loggerPodLabel: removedEntries.loggerPodLabel
      }));
    }
    if (loggerAdd.length > 0) {
      data.loggerAdd = loggerAdd;
    }
    const submitLoggersObject = {
      resetInitialandDisplayed,
      data
    };
    submitLoggersMutate(submitLoggersObject);
  };

  const onClickRemoveEntry = (entries: SyslogListResponse) => {
    setDisplayedSyslog(displayedSyslog.filter((entry) => entries !== entry));
  };

  useEffect(() => {
    addedAndRemoved();
  }, [displayedSyslog, addedAndRemoved]);

  useEffect(() => {
    resetInitialandDisplayed();
  }, [syslogListData, isSyslogListSuccess, resetInitialandDisplayed]);

  useEffect(() => {
    if (loggerAdd.length !== 0 || loggerRemove.length !== 0) {
      setUnsavedSyslogChanges(true);
    } else {
      setUnsavedSyslogChanges(false);
    }
  }, [loggerRemove, loggerAdd, setUnsavedSyslogChanges]);

  return (
    <>
      <Prompt
        when={unsavedSyslogChanges}
        message="You have unsaved changes. Leaving now will discard these changes. "
      />
      <ChangesPreview
        openPreviewChangesDialog={openPreviewChangesDialog}
        setOpenPreviewChangesDialog={setOpenPreviewChangesDialog}
        loggerAdd={loggerAdd}
        loggerRemove={loggerRemove}
        tableHeaders={tableHeaders}
        protocolToText={protocolToText}
        setLoggerRemove={setLoggerRemove}
        setLoggerAdd={setLoggerAdd}
        submitDisplayedSyslog={onSubmit}
        setDisplayedSyslog={setDisplayedSyslog}
        initialDisplayed={initialDisplayed}
      />

      {userPrivs?.LOGGERS_WRITE && (
      <Grid container={true} item={true} direction="column">
        <AddSyslogConfigForm
          displayedSyslog={displayedSyslog}
          setDisplayedSyslog={setDisplayedSyslog}
        />
      </Grid>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} item={true}>
          <SyslogTableContent
            isLoading={false}
            onClickRemoveEntry={onClickRemoveEntry}
            displayedSyslog={displayedSyslog}
            tableHeaders={tableHeaders}
            protocolToText={protocolToText}
          />
        </Grid>
        {userPrivs?.LOGGERS_WRITE && (
        <Grid
          container={true}
          item={true}
          justifyContent="flex-end"
          spacing={2}
          mt={2}
        >
          <Grid item={true}>
            <Button
              variant="outlined"
              color="primary"
              onClick={openPreviewChangesOnclick}
              disabled={loggerAdd.length === 0 && loggerRemove.length === 0}
            >
              Preview Changes
            </Button>
          </Grid>
          <Grid item={true}>
            <Grid item={true}>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={loggerAdd.length === 0 && loggerRemove.length === 0}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
        )}
      </form>
    </>
  );
};

export default SyslogLayout;
