import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  TextField
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { useSubmitFeedbackMutation } from '../../api/reactQuery/mutations';
import { FEEDBACK_PAGE_OPTIONS } from '../../util/constants';
import { FeedbackFormType } from '../../types/formTypes';

interface RequestSupportDialogProps {
  handleDialogClose(): void,
  isOpen: boolean
}

const RequestSupportDialog: React.FC<RequestSupportDialogProps> = ({
  handleDialogClose,
  isOpen
}) => {
  const successMessage = 'Request submitted successfully.';
  const { mutate: submitFeedbackMutate } = useSubmitFeedbackMutation(successMessage);

  const dialogNarrative1 = `The total certificate count for your current deployment size
    has been exceeded.  Certificate issuance will not be interrupted in your EJBCA deployment.
    Please upgrade the size of your deployment within your AWS Marketplace subscription options.
    After the resize request is submitted, the EJBCA SaaS deployment will experience a brief outage
    while the infrastructure is upgraded.  If you would like information on how to do this please `;

  const helpLink = 'https://doc.primekey.com/ejbca-saas/ejbca-saas-configuration-guide/changing-saas-subscription-deployment-size';

  const dialogNarrative2 = `Our sales and operations teams have been notified automatically.  If you would
    like to reach out to PrimeKey directly, you may use the form below, or email `;

  const salesEmailLink = 'sales@primekey.com';

  // For form validation
  const requestSupportSchema = yup.object().shape({
    userFeedbackText: yup
      .string()
      .max(5000, 'Enter at most 5000 characters.')
      .required('Please provide feedback.')
      .matches(
        /^[0-9A-Za-z ,\-_().?'"!; @\n]*$/,
        'Invalid characters. Allowed: (0-9, A-Z , a-z, -, _, (, }, @, ., ?, !, ;. comma, space)'
      )
  });

  const {
    formState: { errors, isValid },
    setError,
    handleSubmit,
    register,
    reset
  } = useForm<FeedbackFormType>({
    mode: 'onChange',
    resolver: yupResolver(requestSupportSchema)
  });

  const resetAndClose = () => {
    handleDialogClose();
    reset({
      userFeedbackText: ''
    });
  };

  const onSubmit = (data: FeedbackFormType) => {
    const enhancedData = {
      data: { ...data, feedbackCategory: 'Support' as typeof FEEDBACK_PAGE_OPTIONS[number] },
      setError,
      resetAndClose
    };
    submitFeedbackMutate(enhancedData);
  };

  return (
    <Dialog open={isOpen} fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogTitle sx={{ pl: 0 }}>
            Limit Exceeded
          </DialogTitle>
          <DialogContentText component="div">
            <p>
              {dialogNarrative1}
              <Link href={helpLink}>
                click here.
              </Link>
            </p>
            {dialogNarrative2}
            <Link href={`mailto:${salesEmailLink}`} target="_blank">
              {salesEmailLink}
            </Link>
          </DialogContentText>
          <TextField
            multiline={true}
            rows={3}
            variant="filled"
            margin="normal"
            fullWidth={true}
            id="userFeedbackText"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('userFeedbackText')}
            autoFocus={true}
            error={!!errors.userFeedbackText}
            helperText={
              errors.userFeedbackText && errors.userFeedbackText.message
            }
          />
          <Grid container={true} direction="row" spacing={2} alignItems="flex-end" justifyContent="flex-end">
            <Grid item={true} display="grid" justifyContent="flex-end">
              <Button onClick={resetAndClose} color="primary" variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item={true} alignItems="end">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                endIcon={<SendIcon />}
                disabled={!isValid}
              >
                Request Support
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default RequestSupportDialog;
