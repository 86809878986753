import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DeployedDialogText from '../DeployedDialogText';
import EXTERNAL_LINKS from '../../../util/externalLinks';

const supportData = [
  {
    accordionTitle: 'Superadmin Credentials',
    accordionDetails: (
      <div>
        <DeployedDialogText />
      </div>
    )
  },
  {
    accordionTitle: 'Documentation',
    accordionDetails: (
      <div>
        <Typography gutterBottom={true}>
          <Link href={EXTERNAL_LINKS.EJBCA_SAAS_DOCS} target="_blank">
            EJBCA SaaS Documentation
          </Link>
        </Typography>
        <Typography gutterBottom={true}>
          <Link href={EXTERNAL_LINKS.EJBCA_DOCS} target="_blank">
            EJBCA Documentation
          </Link>
        </Typography>
      </div>
    )
  },
  {
    accordionTitle: 'Contact Support',
    accordionDetails: (
      <div>
        <Typography gutterBottom={true}>
          <Link href={EXTERNAL_LINKS.PRIMEKEY_SUPPORT_PORTAL} target="_blank">
            Support Portal
          </Link>
          <br />
          To request a PrimeKey Support Account, please use the Email Support
          link below.
        </Typography>

        <Typography gutterBottom={true}>
          <Link href={EXTERNAL_LINKS.SUPPORT_EMAIL} target="_blank">
            Email Support
          </Link>
        </Typography>
      </div>
    )
  }
];

const Support = () => (
  <main>
    <Typography component="h1" variant="h4" gutterBottom={true}>
      Support
    </Typography>
    <Typography variant="subtitle1">
      Let us help you succeed. Check out our documentation or contact our
      Support Team.
    </Typography>
    <br />
    {supportData.map((supportDatum) => (
      <Accordion key={supportDatum.accordionTitle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{supportDatum.accordionTitle}</Typography>
        </AccordionSummary>

        <AccordionDetails>{supportDatum.accordionDetails}</AccordionDetails>
      </Accordion>
    ))}
  </main>
);

export default Support;
