import React from 'react';
import {
  Box,
  Link,
  Typography
} from '@mui/material';

import StaticPageLayoutContainer from './StaticPageLayoutContainer';
import spidey from '../img/spidey.jpeg';
import EXTERNAL_LINKS from '../util/externalLinks';

const AlreadyLoggedIn = () => (
  <StaticPageLayoutContainer>
    <>
      <Typography variant="h5" align="center" gutterBottom={true}>
        Oops - multiple sessions
      </Typography>
      <Box m="auto">
        <Box
          component="img"
          src={spidey}
          alt="spidey"
          width="398px"
        />
      </Box>
      <Box maxWidth="sm">
        <Typography variant="subtitle1" gutterBottom={true} align="center">
          You again!  It looks like you&apos;re currently logged in, in another tab.
          Please logout of any active sessions, and then try your activation link again.
          You can also try to open your link in an incognito or private tab.
          If that doesn&apos;t solve your problem, please click
          <Link href={EXTERNAL_LINKS.SUPPORT_EMAIL} target="_blank">
            {' here '}
          </Link>
          to contact support.
          <br />
        </Typography>
      </Box>
    </>
  </StaticPageLayoutContainer>
);

export default AlreadyLoggedIn;
