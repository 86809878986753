import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { Link as routerLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  closeErrorDialog,
  selectErrorDialogOpen,
  selectErrorErrorMessage,
  selectErrorErrorType
} from '../../redux/slices/errorDialogSlice';
import {
  UNEXPECTED_ERROR,
  CUSTID_PRODCODE_ALREADY_REGISTERED,
  ENTITLEMENTS_NOT_AVAILABLE,
  UNAUTHORIZED
} from '../../util/constants';
import SignupLink from '../SignupLink';
import EXTERNAL_LINKS from '../../util/externalLinks';
import { LOGIN_ROUTE } from '../../util/routeConstants';

interface DialogLocalState {
  title: string;
  content: JSX.Element,
  actions: JSX.Element,
  closeable: boolean
}

const SessionErrorDialog = () => {
  const dispatch = useDispatch();
  const reduxErrorType = useSelector(selectErrorErrorType);
  const openDialog = useSelector(selectErrorDialogOpen);
  const reduxErrorMessage = useSelector(selectErrorErrorMessage);
  const [dialog, setDialog] = useState<DialogLocalState>({
    title: '',
    content: <></>,
    actions: <></>,
    closeable: true
  });

  const handleDialogClose = useCallback((_e, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(closeErrorDialog());
    }
  }, [dispatch]);

  const handleCloseButton = useCallback(() => {
    handleDialogClose({}, 'button');
  }, [handleDialogClose]);

  const dialogOptions = useCallback(() => {
    switch (reduxErrorType) {
      case ENTITLEMENTS_NOT_AVAILABLE: return {
        title: 'Entitlements Not Available',
        content: (
          <div>
            <Typography gutterBottom={true}>
              {'No entitlements were found.  Most likely, your session timed out. Please return to the EJBCA SaaS product listing at '}
              <SignupLink linkText="AWS Marketplace" linkVariant="body1" />
              {' and click the "Click here to setup your account" link in order to proceed.'}
            </Typography>
          </div>
        ),
        actions: (
          <Button
            autoFocus={true}
            color="primary"
            href={process.env.REACT_APP_MARKET_SIGNUP_URL || EXTERNAL_LINKS.AWS_SIGNUP_URL}
          >
            Setup
          </Button>
        ),
        closeable: true
      };
      case CUSTID_PRODCODE_ALREADY_REGISTERED: return {
        title: 'AWS Account Already Registered',
        content: (
          <div>
            <Typography>
              {`Your AWS customer identifier is already registered for this product
              code. Please `}
              <Link to={LOGIN_ROUTE} component={routerLink}>
                log in
              </Link>
              {' with your existing account, or contact '}
              <Link href={EXTERNAL_LINKS.SUPPORT_EMAIL} target="_blank">
                Support
              </Link>
              .
            </Typography>
          </div>
        ),
        actions: (
          <Button autoFocus={true} onClick={handleCloseButton} color="primary">
            Close
          </Button>
        ),
        closeable: true
      };
      case UNAUTHORIZED: return {
        title: 'Unauthorized action',
        content: (
          <div>
            <Typography>
              {`Your account does not have the privileges necessary to perform the action
              you were attempting. Your company's account manager may be able to help, or
              you can contact `}
              <Link href={EXTERNAL_LINKS.SUPPORT_EMAIL} target="_blank">
                Support
              </Link>
              .
            </Typography>
          </div>
        ),
        actions: (
          <Button autoFocus={true} onClick={handleCloseButton} color="primary">
            Close
          </Button>
        ),
        closeable: true
      };

      case UNEXPECTED_ERROR:
      default: return {
        title: 'Sorry! Try again later',
        content: (
          <div>
            <Typography gutterBottom={true}>
              Something went wrong. Please try again later or contact
              {' '}
              <Link href={EXTERNAL_LINKS.SUPPORT_EMAIL} target="_blank">
                Support
              </Link>
              .
            </Typography>
            { reduxErrorMessage
          && (
          <Typography>
            {`Error Message: ${reduxErrorMessage}`}
          </Typography>
          )}
          </div>
        ),
        actions: (
          <Button autoFocus={true} onClick={handleCloseButton} color="primary">
            Close
          </Button>
        ),
        closeable: true
      };
    }
  }, [handleCloseButton, reduxErrorMessage, reduxErrorType]);

  useEffect(() => {
    if (openDialog) {
      setDialog(dialogOptions());
    }
  }, [dialogOptions, openDialog, reduxErrorType]);

  return (
    <Dialog
      disableEscapeKeyDown={true}
      onClose={handleDialogClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography sx={{ typography: 'h6' }}>
          <ErrorIcon sx={{ mr: 1, verticalAlign: 'sub' }} />
          {dialog.title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ p: 2 }}>{dialog.content}</DialogContent>
      <DialogActions sx={{ m: 0, p: 1 }}>{dialog.actions}</DialogActions>
    </Dialog>
  );
};

export default SessionErrorDialog;
