import React, { useEffect } from 'react';
import {
  TextField,
  Grid,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGetSingleUserQuery } from '../../../../api/reactQuery/queries';

import PrivilegeForm from './PrivilegeForm';
import { useAddUserMutation, useUpdateUserMutation } from '../../../../api/reactQuery/mutations';
import CreateUserButtons from './UserFormButtons/CreateUserButtons';
import EditModeButtons from './UserFormButtons/EditModeButtons';
import { UserFormType } from '../../../../types/formTypes';
import { CREATE_USER_ROUTE } from '../../../../util/routeConstants';
import { preppedPrivileges, reformatPrivilegesForViewing } from './conversionHelpers';
import { AddUserMutateObject } from '../../../../api/UserManagement/UserManagementRequests';
import TwoFaDetails from './TwoFaDetails';

interface UserFormProps {
  defaultValues: UserFormType,
  isInEditMode?: boolean,
  isUserActive?: boolean
  setIsInEditMode?: (flag: boolean) => void,
}

const UserForm = (
  {
    defaultValues,
    isInEditMode = false,
    isUserActive = false,
    setIsInEditMode = () => {}
  }: UserFormProps
) => {
  const { id } = useParams<Record<string, string>>();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { mutate: addUserMutate } = useAddUserMutation();
  const { mutate: updateUserMutate } = useUpdateUserMutation();
  const { data: singleUserQueryData } = useGetSingleUserQuery(id);
  const originatingFromCreate = pathname === CREATE_USER_ROUTE;

  const customInactiveUserStyle = {
    '& .MuiInput-root.Mui-disabled:before': { borderBottomStyle: 'none' }
  } as const;

  const customCreateUserStyle = {
    '& .MuiInput-root': { color: 'text.primary' },
    '& .MuiInputLabel-root': { color: 'text.primary' },
  } as const;

  const customDisabledStyle = {
    '& .MuiInput-input.Mui-disabled': { color: 'text.primary', WebkitTextFillColor: theme.palette.text.primary },
    '& .MuiInput-root.Mui-disabled:before': { borderBottomStyle: 'none' },
  } as const;

  const privilegeOptions = ['None', 'Read', 'Write'];

  const CreateUserSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, 'Enter at least 2 characters for your first name')
      .max(255, 'Enter between 2 and 255 characters for your first name')
      .required('Required'),
    lastName: yup
      .string()
      .min(2, 'Enter at least 2 characters for your last name')
      .max(255, 'Enter between 2 and 255 characters for your last name')
      .required('Required'),
    email: yup
      .string()
      .required('Email is required')
      .email('Please enter a valid email'),
    manageUsersPrivileges: yup
      .string()
      .required('Privileges are required')
      .oneOf(privilegeOptions, 'Please select a valid privilege'),
    sourceIPPrivileges: yup
      .string()
      .required('Privileges are required')
      .oneOf(privilegeOptions, 'Please select a valid privilege'),
    rootCAPrivileges: yup
      .string()
      .required('Privileges are required')
      .oneOf(privilegeOptions, 'Please select a valid privilege'),
    loggersPrivileges: yup
      .string()
      .required('Privileges are required')
      .oneOf(privilegeOptions, 'Please select a valid privilege')
  });

  const {
    handleSubmit,
    formState: {
      errors,
      dirtyFields,
      isValid,
      isDirty
    },
    register,
    reset,
    control,
    setError
  } = useForm<UserFormType>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(CreateUserSchema)
  });

  const addUser = (data: UserFormType) => {
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      privileges: preppedPrivileges(data)
    };
    const addUserMutateObject: AddUserMutateObject = {
      userData,
      setError
    };
    addUserMutate(addUserMutateObject);
  };

  const editedSubmit = (data: UserFormType) => {
    // dirtyFields is an object with field name: true, make an array of just the names
    const changedFields = Object.keys(dirtyFields) as Array<keyof UserFormType>;
    let userDataToUpdate = {};
    // find non privilege fields that need to be updated, and add the value of the input
    changedFields.forEach((entry) => {
      if (!entry.includes('Privileges')) {
        userDataToUpdate = { ...userDataToUpdate, [entry]: data[entry] };
      }
    });
    // get the privilege fields that have been changed
    // reformat for backend and add to updated object
    const checkForPrivileges = (entry: string) => entry.includes('Privileges');
    if (changedFields.some(checkForPrivileges)) {
      const privileges = preppedPrivileges(data);
      userDataToUpdate = { ...userDataToUpdate, privileges };
    }

    const userData = {
      ...singleUserQueryData,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      privileges: reformatPrivilegesForViewing(data)
    };

    const updateUserMutateObject = {
      id,
      userDataToUpdate,
      setError,
      dirtyFields,
      setIsInEditMode,
      userData
    };
    updateUserMutate(updateUserMutateObject);
  };

  const onSubmit = (data: UserFormType) => {
    if (originatingFromCreate) {
      addUser(data);
    } else if (isInEditMode) {
      editedSubmit(data);
    }
  };
  useEffect(() => {
    if (!isInEditMode) {
      reset(defaultValues);
    }
  }, [defaultValues, isInEditMode, reset]);

  return (
    <Box sx={{ width: '100%', px: 6 }} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container={true} item={true} columnSpacing={8} rowSpacing={1} direction="row" alignItems="flex-start" justifyContent="space-evenly" pb={4}>
        <Grid container={true} item={true} direction="column" xs={12} md={6}>
          <Grid item={true}>
            <TextField
              fullWidth={true}
              autoFocus={true}
              margin="normal"
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('firstName', {
                required: true
              })}
              id="firstName"
              label="First Name"
              autoComplete="off"
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              disabled={!isInEditMode && !originatingFromCreate}
              sx={isUserActive
                ? customDisabledStyle
                : originatingFromCreate
                  ? customCreateUserStyle
                  : customInactiveUserStyle}
            />
          </Grid>
          <Grid item={true}>
            <TextField
              fullWidth={true}
              margin="normal"
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('lastName', {
                required: true
              })}
              id="lastName"
              label="Last Name"
              autoComplete="off"
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              disabled={!isInEditMode && !originatingFromCreate}
              sx={isUserActive
                ? customDisabledStyle
                : originatingFromCreate
                  ? customCreateUserStyle
                  : customInactiveUserStyle}
            />
          </Grid>
          <Grid item={true}>
            <TextField
              fullWidth={true}
              margin="normal"
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('email', {
                required: true
              })}
              id="email"
              label="Email Address"
              autoComplete="off"
              error={!!errors.email}
              helperText={errors.email?.message}
              disabled={!isInEditMode && !originatingFromCreate}
              sx={isUserActive
                ? customDisabledStyle
                : originatingFromCreate
                  ? customCreateUserStyle
                  : customInactiveUserStyle}
            />
          </Grid>
          {!originatingFromCreate
           && (
           <Grid item={true} mt={2}>
             <TwoFaDetails
               isTotpEnabled={singleUserQueryData?.isTotpEnabled}
               isUserActive={isUserActive}
             />
           </Grid>
           )}

        </Grid>
        <Grid container={true} item={true} direction="column" xs={12} md={6}>
          <PrivilegeForm
            control={control}
            errors={errors}
            isInEditMode={isInEditMode}
            isUserActive={isUserActive}
          />
        </Grid>
      </Grid>
      {pathname === CREATE_USER_ROUTE
        ? (
          <CreateUserButtons
            isValid={isValid}
          />
        )
        : (
          <>
            {isInEditMode && (
              <EditModeButtons
                setIsInEditMode={setIsInEditMode}
                reset={reset}
                isValid={isValid}
                isDirty={isDirty}
              />
            )}
          </>
        )}
    </Box>
  );
};

export default UserForm;
