import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import {
  submitLogin,
  deleteSession,
} from './SessionRequests';
import { UNEXPECTED_ERROR, LOGGED_IN_USER_QUERY } from '../../util/constants';
import { fieldValidationErrorHandler, receiveErrors } from '../reactQuery/queryErrorHandling';
import { selectTheme } from '../../redux/slices/userPreferencesSlice';

export const useLogoutUserMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);

  return useMutation(
    deleteSession,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(LOGGED_IN_USER_QUERY);
        dispatch({ type: 'RESET_STATE', theme });
      },
      onError: () => {
        receiveErrors({ error: UNEXPECTED_ERROR }, dispatch);
      }
    }
  );
};

export const useLoginUserMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    submitLogin,
    {
      onSuccess: (response, loginUserObject) => {
        if ('totpEnabled' in response.data
          && response.data.totpEnabled
          && !loginUserObject.openTOTP
          && loginUserObject.setOpenTOTP
        ) {
          loginUserObject.setOpenTOTP(true);
        } else {
          queryClient.invalidateQueries(LOGGED_IN_USER_QUERY);
        }
      },
      onError: (error: AxiosError, loginUserObject) => {
        fieldValidationErrorHandler(error, dispatch, loginUserObject.setError);
      }
    }
  );
};
