import React from 'react';
import Button from '@mui/material/Button';
import download from 'downloadjs';
import { useDispatch } from 'react-redux';

import { receiveErrors } from '../../../../api/reactQuery/queryErrorHandling';

const CertificatePolicy = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          const res = await fetch('/api/downloadCP');
          const contentType = res.headers.get('Content-Type');
          if (contentType && /application\/json/.test(contentType)) {
            const json = await res.json();
            if (json?.error) {
              receiveErrors(json, dispatch);
            }
          } else {
            const blob = await res.blob();
            download(blob, 'CP_Template_Packet-2021-06-08.zip');
          }
        }}
        sx={{ m: 1 }}
      >
        Download CP
      </Button>
    </div>
  );
};

export default CertificatePolicy;
