import { UseFormSetError } from 'react-hook-form';

import { displayErrorDialog } from '../../redux/slices/errorDialogSlice';
import { setBruteForceBackdrop } from '../../redux/slices/bruteForceBackdropSlice';
import { setSnackbar } from '../../redux/slices/globalSnackbarSlice';
import { setLoginAlert } from '../../redux/slices/loginAlertSlice';
import {
  BRUTE_FORCE,
  UNEXPECTED_ERROR,
  INVALID_SESSION,
  CUSTID_PRODCODE_ALREADY_REGISTERED,
  UNAUTHORIZED,
  ENTITLEMENTS_NOT_AVAILABLE,
  LOGGED_IN_USER_QUERY
} from '../../util/constants';
import queryClient from './QueryClient';
import { AppDispatch } from '../../redux/store';

export const receiveErrors = (data: any, dispatch: AppDispatch) => {
  if (data?.error === INVALID_SESSION) {
    dispatch(setLoginAlert({
      loginAlertmessage: 'Your session has expired, please log in again.',
      isLoginAlertOpen: true,
      loginAlertType: 'warning'
    }));
    // this below line is only needed for cp and cps download.  Any other time INVALID_SESSION
    // happens it is from a query or mutation.  QueryClient.ts, forces a refetch
    // of LOGGED_IN_USER_QUERY whenever an Invalid session error is thrown
    queryClient.refetchQueries(LOGGED_IN_USER_QUERY);
  }
  if (data?.error === UNEXPECTED_ERROR
    || data?.error === CUSTID_PRODCODE_ALREADY_REGISTERED
    || data?.error === UNAUTHORIZED
    || data?.error === ENTITLEMENTS_NOT_AVAILABLE
  ) {
    dispatch(displayErrorDialog({
      openDialog: true,
      errorType: data.error,
      errorMessage: data.errorMessage || ''
    }));
  }
};

const errorDataObject = (type: string) => ({
  error: type
});

export const basicErrorHandler = (error: any, dispatch: AppDispatch) => {
  if (error.response?.data?.errors) {
    error.response.data.errors.forEach((entry: any) => {
      receiveErrors(errorDataObject(entry.type), dispatch);
    });
  } else {
    receiveErrors(error.response?.data, dispatch);
  }
};

export const fieldValidationErrorHandler = (
  error: any,
  dispatch: AppDispatch,
  setError: UseFormSetError<any>
) => {
  if (error.response?.data?.error) {
    receiveErrors(error.response.data, dispatch);
  } else {
    error?.response?.data?.errors.forEach((entry: any) => {
      if (entry.type === UNAUTHORIZED) {
        receiveErrors(errorDataObject(entry.type), dispatch);
      } else if (entry.param === BRUTE_FORCE) {
        dispatch(
          setBruteForceBackdrop({
            backdropOpen: true,
            backdropCountdown: entry.waitTime,
            isFailedPasswordAttempt: entry.isFailedPasswordAttempt
          })
        );
      } else {
        setError(entry.param, { type: 'manual', message: entry.msg });
      }
    });
  }
};

export const errorsSnackbarHandler = (err: any, dispatch: AppDispatch) => {
  err?.response?.data.errors.forEach((entry: any) => {
    if (entry.type) {
      receiveErrors(errorDataObject(entry.type), dispatch);
    } else {
      dispatch(setSnackbar({
        snackbarType: entry.severity,
        snackbarMessage: entry.msg
      }));
    }
  });
};
