import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer
} from '@reduxjs/toolkit';

import bruteForceBackdrop from './slices/bruteForceBackdropSlice';
import errorDialog from './slices/errorDialogSlice';
import globalAlerts from './slices/globalAlertSlice';
import globalSnackbar from './slices/globalSnackbarSlice';
import loginAlert from './slices/loginAlertSlice';
import navDrawer from './slices/navDrawerSlice';
import sourceIP from './slices/sourceIpSlice';
import userPreferences from './slices/userPreferencesSlice';

const combinedReducer = combineReducers({
  bruteForceBackdrop,
  errorDialog,
  globalAlerts,
  globalSnackbar,
  loginAlert,
  navDrawer,
  sourceIP,
  userPreferences
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'RESET_STATE') {
    // eslint-disable-next-line no-param-reassign
    state = {
      userPreferences: { theme: action.theme }
    } as RootState;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer
});

export type RootState = ReturnType<typeof combinedReducer>;

export type AppDispatch = typeof store.dispatch;

export default store;
