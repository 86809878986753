import React, { useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { TOTPSetPhoneFormType } from '../../../../../types/formTypes';
import { PhoneTypes } from '../../../../../util/constants';

interface TOTPSetPhoneProps {
  handleClose: (reason: string) => void,
  nextStep: (step: number) => void,
  setLabel: (label: TOTPSetPhoneFormType) => void,
}

const TOTPSetPhone = ({
  handleClose,
  nextStep,
  setLabel
}: TOTPSetPhoneProps) => {
  const [value, setValue] = useState<PhoneTypes>('iphone');

  const TOTPSetPhoneSchema = yup.object().shape({
    label: yup
      .string()
      .max(50, 'Enter at most 50 characters')
      .matches(
        /^[0-9A-Za-z ,\-_().]*$/,
        'Invalid characters. Allowed: (0-9, A-Z , a-z, -, _, (, ), comma, space)'
      )
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register
  } = useForm<TOTPSetPhoneFormType>({
    mode: 'onChange',
    resolver: yupResolver(TOTPSetPhoneSchema)
  });

  const onSubmit = (data: TOTPSetPhoneFormType) => {
    const enrichedData = data;
    enrichedData.phoneType = value as PhoneTypes;
    setLabel(enrichedData);
    nextStep(3);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as PhoneTypes);
  };

  return (
    <Box component="form" width="100%" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <DialogContent>

          <Typography variant="body2">
            Add a Label to identify your phone (e.g. My iPhone)
          </Typography>
          <Box width={400} height={240}>
            <TextField
              autoFocus={true}
              margin="normal"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('label', {
                required: true
              })}
              label="Label"
              id="label"
              fullWidth={true}
              type="label"
              inputProps={{ maxLength: 50 }}
              autoComplete="off"
              error={!!errors.label}
              helperText={errors.label?.message}
              variant="standard"
            />
            <Box mt={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend"><Typography variant="body2">Type of Phone</Typography></FormLabel>
                <RadioGroup aria-label="phoneType" name="phoneType" value={value} onChange={handleChange}>
                  <FormControlLabel value="iphone" control={<Radio color="primary" />} label={<Typography variant="body2">iPhone</Typography>} />
                  <FormControlLabel value="android" control={<Radio color="primary" />} label={<Typography variant="body2">Android</Typography>} />
                  <FormControlLabel value="other" control={<Radio color="primary" />} label={<Typography variant="body2">Other</Typography>} />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
      </div>
      <DialogActions>
        <Button onClick={() => handleClose('button')} variant="text" color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid}
        >
          Next
        </Button>
      </DialogActions>
    </Box>
  );
};

export default TOTPSetPhone;
