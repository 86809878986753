import React from 'react';
import {
  Button,
  Box
} from '@mui/material';
import {
  Edit as EditIcon,
} from '@mui/icons-material';

interface EditModeButtonProps {
  setIsInEditMode: (flag: boolean) => void,
}

const YourInfoNormalModeButtons : React.FC<EditModeButtonProps> = (
  {
    setIsInEditMode = () => {},
  }
) => {
  const onEditModeClick = () => {
    setIsInEditMode(true);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        onClick={onEditModeClick}
        startIcon={<EditIcon />}
        variant="outlined"
      >
        Edit
      </Button>

    </Box>
  );
};

export default YourInfoNormalModeButtons;
