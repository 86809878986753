import React from 'react';
import {
  Link,
  TypographyProps
} from '@mui/material';

import EXTERNAL_LINKS from '../util/externalLinks';

interface SignupLinkProps {
  linkText?: string;
  linkVariant?: TypographyProps['variant'];
  marketUrl?: string;
}

const SignupLink = (
  {
    linkText,
    linkVariant,
    marketUrl
  }: SignupLinkProps
) => (
  <Link
    href={marketUrl}
    variant={linkVariant}
  >
    {linkText}
  </Link>
);

SignupLink.defaultProps = {
  linkText: "Don't have an account? Sign Up.",
  linkVariant: 'body2',
  marketUrl: process.env.REACT_APP_MARKET_SIGNUP_URL || EXTERNAL_LINKS.AWS_SIGNUP_URL
};

export default SignupLink;
