/* eslint-disable max-len */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ComplianceLayout from '../components/portal/pages/compliance/ComplianceLayout';
import Dashboard from '../components/portal/pages/dashboard/Dashboard';
import EjbcaLinks from '../components/portal/pages/EjbcaLinks';
import LoggingTabs from '../components/portal/pages/logging/LoggingTabs';
import Profile from '../components/portal/pages/profile/Profile';
import RootCA from '../components/portal/pages/RootCA';
import SourceIP from '../components/portal/pages/SourceIP';
import Support from '../components/portal/pages/Support';
import CreateUser from '../components/portal/pages/userManagement/CreateUser';
import AllUsers from '../components/portal/pages/userManagement/AllUsers';
import UserDetail from '../components/portal/pages/userManagement/UserDetail';
import NotFound from '../components/NotFound';

import ProtectedRoute from './ProtectedRoute';
import {
  CREATE_USER_ROUTE,
  DASHBOARD_ROUTE,
  LINKS_ROUTE,
  USER_MANAGEMENT_ROUTE,
  ROOT_CA_ROUTE,
  SOURCE_IP_ROUTE,
  PROFILE_ROUTE,
  LOGGING_ROUTE,
  SUPPORT_ROUTE,
  COMPLIANCE_ROUTE
} from '../util/routeConstants';
import {
  MANAGEUSERS_READ,
  MANAGEUSERS_WRITE,
  SOURCEIP_READ,
  ROOTCA_READ,
  LOGGERS_READ
} from '../util/constants';

const PortalRoutes = () => (
  <Switch>
    <ProtectedRoute path={DASHBOARD_ROUTE} component={Dashboard} />
    <ProtectedRoute path={LINKS_ROUTE} component={EjbcaLinks} />
    <ProtectedRoute path={ROOT_CA_ROUTE} component={RootCA} requiredPrivileges={[ROOTCA_READ]} />
    <ProtectedRoute path={SOURCE_IP_ROUTE} component={SourceIP} requiredPrivileges={[SOURCEIP_READ]} />
    <ProtectedRoute path={`${COMPLIANCE_ROUTE}/:id`} component={ComplianceLayout} />
    <ProtectedRoute path={CREATE_USER_ROUTE} exact={true} component={CreateUser} requiredPrivileges={[MANAGEUSERS_WRITE]} />
    <ProtectedRoute path={USER_MANAGEMENT_ROUTE} exact={true} component={AllUsers} requiredPrivileges={[MANAGEUSERS_READ]} />
    <ProtectedRoute path={`${USER_MANAGEMENT_ROUTE}/:id`} component={UserDetail} requiredPrivileges={[MANAGEUSERS_READ]} />
    <ProtectedRoute path={PROFILE_ROUTE} component={Profile} />
    <ProtectedRoute path={SUPPORT_ROUTE} component={Support} />
    <ProtectedRoute path={LOGGING_ROUTE} component={LoggingTabs} requiredPrivileges={[LOGGERS_READ]} />
    <Route component={NotFound} />
  </Switch>
);

export default PortalRoutes;
