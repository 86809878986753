import React, { useState } from 'react';
import {
  Paper,
  Tab,
  Tabs,
  Typography
} from '@mui/material';

import SyslogLayout from './SyslogTab/SyslogLayout';
import CredentialsTab from './CredentialsTab/Credentials';
import AcctDeployingBackdrop from '../../layout/AcctDeployingBackdrop';

const LoggingTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [unsavedSyslogChanges, setUnsavedSyslogChanges] = useState(false);
  const handleTabChange = (newTabValue: number) => {
    if (selectedTab === 1 && unsavedSyslogChanges) {
      const windowConfirm = window.confirm(
        'You have unsaved changes. Leaving now will discard these changes.'
      );
      if (windowConfirm === false) {
        return;
      }
    }
    setSelectedTab(newTabValue);
  };

  return (
    <main>
      <AcctDeployingBackdrop />
      <Typography component="h1" variant="h4" gutterBottom={true}>
        Logging
      </Typography>
      <Typography variant="subtitle1">
        External logging can be configured to send all EJBCA Root CA and Issuing
        CA logs to remote hosts. Currently only Syslog targets are supported via
        TCP or TLS. Authenticated or unauthenticated options may be used. If
        using TLS, configure key pairs and/or certificates used for connecting
        to external hosts first, then select those when configuring the syslog
        targets. Please review the Syslog Configuration Guide for specific
        certificate requirements.
      </Typography>
      <br />
      <Paper sx={{ mt: 4, width: '100%', p: 4 }}>
        <Tabs
          value={selectedTab}
          onChange={(_e, newTabValue) => handleTabChange(newTabValue)}
          centered={true}
          textColor="primary"
          indicatorColor="primary"
          sx={{ mb: 2 }}
        >
          <Tab label="Credentials" />
          <Tab label="Syslog" />
        </Tabs>

        {selectedTab === 0 && <CredentialsTab />}
        {selectedTab === 1 && (
          <SyslogLayout
            setUnsavedSyslogChanges={setUnsavedSyslogChanges}
            unsavedSyslogChanges={unsavedSyslogChanges}
          />
        )}
      </Paper>
    </main>
  );
};

export default LoggingTabs;
