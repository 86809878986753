import React, { useCallback, useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';

import { useSubmitFeedbackMutation } from '../../api/reactQuery/mutations';
import { FeedbackFormType } from '../../types/formTypes';
import { FEEDBACK_PAGE_OPTIONS } from '../../util/constants';

interface FeedbackDialogProps {
  pageName: string,
  handleDialogClose: () => void,
  openDialog: boolean
}

const FeedbackDialog = (
  {
    pageName,
    handleDialogClose,
    openDialog
  }: FeedbackDialogProps
) => {
  const { mutate: submitFeedbackMutate } = useSubmitFeedbackMutation();
  const theme = useTheme();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));

  // Ties route to feedback category page title and removes faq/cp/cps from compliance
  // *ADD NEW PAGE HERE*
  const routedPageToCategoryTitle = useCallback(() => {
    const splitURL = pageName.split('/');
    const routeToTitle = {
      dashboard: 'Dashboard',
      ejbca: 'EJBCA Links',
      sourceIp: 'Source IPs',
      rootCa: 'Root CA',
      compliance: 'Compliance',
      support: 'Support',
      profile: 'Profile',
      logging: 'Logging',
      users: 'User Management'
    };
    const pageCalculated = splitURL[2] as keyof typeof routeToTitle;
    return routeToTitle[pageCalculated] as typeof FEEDBACK_PAGE_OPTIONS[number];
  }, [pageName]);

  // For form validation
  const feedbackSchema = yup.object().shape({
    userFeedbackText: yup
      .string()
      .max(5000, 'Enter at most 5000 characters.')
      .required('Please provide feedback.')
      .matches(
        /^[0-9A-Za-z ,\-_().?'"!; @\n]*$/,
        'Invalid characters. Allowed: (0-9, A-Z , a-z, -, _, (, }, @, ., ?, !, ;. comma, space)'
      ),
    feedbackCategory: yup.string().oneOf([...FEEDBACK_PAGE_OPTIONS])
  });

  const {
    control,
    formState: { errors, isValid },
    setError,
    handleSubmit,
    register,
    reset
  } = useForm<FeedbackFormType>({
    defaultValues: {
      feedbackCategory: routedPageToCategoryTitle() ?? '',
      userFeedbackText: ''
    },
    mode: 'onChange',
    resolver: yupResolver(feedbackSchema)
  });

  const resetAndClose = () => {
    handleDialogClose();
    reset({
      feedbackCategory: routedPageToCategoryTitle() ?? '',
      userFeedbackText: ''
    });
  };

  const onSubmit = (data: FeedbackFormType) => {
    const feedbackObject = {
      data,
      setError,
      resetAndClose
    };
    submitFeedbackMutate(feedbackObject);
  };

  useEffect(() => {
    if (openDialog) {
      reset({
        feedbackCategory: routedPageToCategoryTitle() ?? '',
        userFeedbackText: ''
      });
    }
  }, [openDialog, reset, routedPageToCategoryTitle]);

  return (
    <div>
      <Dialog open={openDialog} fullWidth={true}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Let us know how we can improve EJBCA SaaS!
            </DialogContentText>
            <TextField
              multiline={true}
              maxRows={10}
              minRows={3}
              variant="filled"
              margin="normal"
              fullWidth={true}
              id="userFeedbackText"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('userFeedbackText')}
              autoFocus={true}
              error={!!errors.userFeedbackText}
              helperText={
                errors.userFeedbackText && errors.userFeedbackText.message
              }
              sx={{ '& .MuiFilledInput-root': { pt: 1 } }}
            />

            <Box sx={{
              display: 'flex',
              flexDirection: isMobileLayout ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end'
            }}
            >
              <Controller
                control={control}
                name="feedbackCategory"
                render={({ field }) => (
                  <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    error={!!errors.feedbackCategory}
                    label="Feedback Category"
                    select={true}
                    sx={{ minWidth: isMobileLayout ? '100%' : 175 }}
                  >
                    {FEEDBACK_PAGE_OPTIONS.map((page) => (
                      <MenuItem key={page} value={page}>
                        {page}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Box sx={{ display: 'flex', mt: isMobileLayout ? 1 : 0 }}>
                <Button
                  onClick={resetAndClose}
                  color="primary"
                  variant="outlined"
                  sx={{ mr: 2 }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={!isValid}
                  color="primary"
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
};

export default FeedbackDialog;
