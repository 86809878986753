import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import ReactMarkdown from 'markdown-to-jsx';

interface MarkdownProps {
  children: string
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h5',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link },
    li: {
      component: (
        ({
          children
        }: MarkdownProps) => (
          <Box component="li" mt={1}>
            <Typography component="span">
              {children}
            </Typography>
          </Box>
        )
      ),
    },
  },
};

const Markdown = (
  {
    children
  }: MarkdownProps
) => (
  <ReactMarkdown
    options={options}
  >
    {children}
  </ReactMarkdown>
);

export default Markdown;
