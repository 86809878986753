import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { AlertColor } from '@mui/material';
import { AxiosError } from 'axios';

import { submitSourceIp } from './SourceIpRequests';
import { UNEXPECTED_ERROR, SOURCE_IP_QUERY } from '../../util/constants';
import { ErrorResponse } from '../reactQuery/ErrorResponse';
import { setSnackbar } from '../../redux/slices/globalSnackbarSlice';
import { clearSourceIpState } from '../../redux/slices/sourceIpSlice';
import { receiveErrors } from '../reactQuery/queryErrorHandling';

const useSubmitSourceIpMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const whichSnackbarToDisplay = (status: 'CHANGE_IN_PROGRESS' | 'SUBMIT_CHANGE') => {
    if (status === 'CHANGE_IN_PROGRESS') {
      return {
        snackbarMessage: 'Change in progress.  Please try again later',
        snackbarType: 'warning' as AlertColor
      };
    }
    return {
      snackbarMessage: 'Submitted Source IP change request.',
      snackbarType: 'info' as AlertColor
    };
  };

  return useMutation(
    submitSourceIp,
    {
      onSuccess: (response) => {
        const statusFlag = response.data.reason
          ? response.data.reason : response.data.requestType;
        const snackbarContent = whichSnackbarToDisplay(statusFlag);
        if (response.data.changed) {
          dispatch(clearSourceIpState());
        }
        dispatch(setSnackbar({
          snackbarType: snackbarContent.snackbarType,
          snackbarMessage: snackbarContent.snackbarMessage
        }));
        queryClient.invalidateQueries(SOURCE_IP_QUERY);
      },
      onError: (error: AxiosError) => {
        error?.response?.data.errors?.forEach((entry: ErrorResponse) => {
          const enrichedError = {
            error: entry.type || UNEXPECTED_ERROR,
            errorMessage: entry.msg
          };
          receiveErrors(enrichedError, dispatch);
        });
      }
    }
  );
};

export default useSubmitSourceIpMutation;
