import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';

import { useLoggedInUserQuery } from '../api/reactQuery/queries';
import { MORE_INFO_ROUTE, PORTAL_ROUTE } from '../util/routeConstants';

interface AuthRouteProps extends RouteComponentProps {
  component: any;
  path: string;
}

const AuthRoute = (
  {
    component: Component,
    path
  }: AuthRouteProps
) => {
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const isUserLoggedIn = Boolean(loggedInUserData?.user?.email);
  const areOptionsDefined = loggedInUserData?.user?.optionsDefined;

  return (
    <Route
      path={path}
      render={() => (isUserLoggedIn && areOptionsDefined
        ? <Redirect to={PORTAL_ROUTE} />
        : isUserLoggedIn && !areOptionsDefined
          ? <Redirect to={MORE_INFO_ROUTE} />
          : <Component />
      )}
    />
  );
};

export default withRouter(AuthRoute);
