import React from 'react';
import {
  Box,
  Typography
} from '@mui/material';

interface TitleProps {
  children: string | JSX.Element
}

const Title = (props: TitleProps) => {
  const { children } = props;
  return (
    <Typography component="div" color="textPrimary" gutterBottom={true}>
      <Box fontWeight="fontWeightMedium">{children}</Box>
    </Typography>
  );
};

export default Title;
