import axios, { AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import * as forms from '../../types/formTypes';

export interface ForgotPasswordResponse {
  status: string,
  Message: string
}

export interface SetPasswordResponse {
  Message: string
}

export interface ForgotPasswordPayload {
  data: forms.ForgotPasswordFormType,
  setError: UseFormSetError<forms.ForgotPasswordFormType>,
  setMessageFromServer: React.Dispatch<React.SetStateAction<string>>
}

export interface SetPasswordPayload {
  data: forms.SetPasswordFormType,
  setError: UseFormSetError<forms.SetPasswordFormType>,
  token: string
}

// Passwords
export const submitForgotPassword = (
  forgotPasswordObject: ForgotPasswordPayload
): Promise<AxiosResponse<ForgotPasswordResponse>> => axios.post('/api/post/forgotPassword', forgotPasswordObject.data);
export const submitResetPassword = (
  resetPasswordObject: SetPasswordPayload
): Promise<AxiosResponse<SetPasswordResponse>> => axios.post(`/api/post/resetPass/${resetPasswordObject.token}`, resetPasswordObject.data);
