import axios, { AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import { TokenFormType, TOTPReauthFormType } from '../../types/formTypes';
import { PhoneTypes, Setup2faActionsType, TotpVerifyModes } from '../../util/constants';

export interface CombinedFields {
  label: string;
  phoneType: PhoneTypes;
  actionType: Setup2faActionsType;
  token: string;
  action?: Setup2faActionsType;
}

interface ReauthPayload {
  actionType: Setup2faActionsType;
  password: TOTPReauthFormType;
  nextStep: (step: number) => void;
  setError: UseFormSetError<TOTPReauthFormType>;
}

interface VerifyTOTPPayload {
  combinedFields: CombinedFields;
  setError: UseFormSetError<TokenFormType>;
}

interface VerifyTOTPActionPayload {
  combinedFields: CombinedFields;
  handleClose: (reason: string) => void;
  nextStep: (step: number) => void;
  setError: UseFormSetError<TokenFormType>;
  setVerifyMode: React.Dispatch<React.SetStateAction<TotpVerifyModes>>
}

interface TOTPStateQueryResponse {
  totpEnabled: boolean;
  totpEnabledDate?: string;
  totpLabel?: string;
  totpPhoneType?: string;
  totpRootCAEnabled?: boolean;
  totpRootCAEnabledDate?: string;
}

interface QRCodeResponse {
  otpauth: string;
  secret: string;
}

interface TOTPReathResponse {
  Message: string;
}

interface VerifyTOTPResponse {
  Message: string;
}

interface VerifyTOTPActionResponse {
  Message: string;
  initAction?: string;
}

export const getTOTPState = (): Promise<AxiosResponse<TOTPStateQueryResponse>> => axios.get('/api/getTOTPState');

export const submitRequestForQrCode = (): Promise<AxiosResponse<QRCodeResponse>> => axios.post('/api/post/genTOTP');

export const submitTOTPReauth = (
  submitReauthObject: ReauthPayload
): Promise<AxiosResponse<TOTPReathResponse>> => axios.post('/api/post/reauth', submitReauthObject.password);

export const submitVerifyTOTP = (
  submitVerifyTOTPObject: VerifyTOTPPayload
): Promise<AxiosResponse<VerifyTOTPResponse>> => axios.post('/api/post/verifyTOTP', submitVerifyTOTPObject.combinedFields);

export const submitVerifyTOTPAction = (
  submitVerifyTOTPActionObject: VerifyTOTPActionPayload
): Promise<AxiosResponse<VerifyTOTPActionResponse>> => axios.post('/api/post/verifyTOTPAction', submitVerifyTOTPActionObject.combinedFields);
