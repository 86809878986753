import React, { useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { zxcvbn, ZxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';

interface PasswordStrengthMeterProps {
  passwordString: string;
  setPasswordScoreInParent: (score: number) => number;
}

const PasswordStrengthMeter = ({
  passwordString,
  setPasswordScoreInParent
}: PasswordStrengthMeterProps) => {
  const [passwordScore, setPasswordScore] = useState<number>(0);
  const [passwordWarning, setPasswordWarning] = useState<string>('');

  const passwordOptions = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
  };
  ZxcvbnOptions.setOptions(passwordOptions);

  const normalise = (value: number) => {
    if (value < 2) { // weak scores
      if (passwordString.length < 25) {
        return passwordString.length * 2; // grow meter in 1st quarter even while weak
      }
      return 49; // pw is long, but score is still weak, stay in 1st half
    }
    return (value * 25);
  };

  const determineLabelAndColor = (score: number, target: string) => {
    switch (score) {
      case 0:
      case 1:
        return target === 'label' ? 'Weak' : '#ff1744';
      case 2:
        return target === 'label' ? 'Fair' : '#f57f17';
      case 3:
        return target === 'label' ? 'Good' : '#d4e157';
      case 4:
        return target === 'label' ? 'Strong' : '#00c853';
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const getScore = async () => {
      const zResult = await zxcvbn(passwordString);
      setPasswordScore(zResult.score);
      setPasswordScoreInParent(zResult.score);
      let message = zResult.feedback.warning;
      if (!message && passwordString.length > 0 && passwordString.length < 8) {
        message = 'Passwords must contain at least 8 characters';
      }
      setPasswordWarning(message);
    };
    getScore();
  }, [passwordScore, passwordString, setPasswordScoreInParent]);

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={normalise(passwordScore)}
        sx={{
          '& .MuiLinearProgress-bar': {
            backgroundColor: determineLabelAndColor(passwordScore, 'color')
          }
        }}
      />

      <Box textAlign="center">
        {passwordString.length > 0 && determineLabelAndColor(passwordScore, 'label')}
        {passwordWarning.length > 0 && ` - ${passwordWarning}`}
      </Box>
    </>
  );
};

export default PasswordStrengthMeter;
