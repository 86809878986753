import React from 'react';
import { Typography } from '@mui/material';
import TwoFAAccordionContent from './accordions/TwoFAAccordionContent';
import PrivilegeAccordion from './accordions/PrivilegeAccordionContent';
import YourInfoAccordionContent from './accordions/YourInfoAccordionContent';

const Profile = () => (
  <>
    <Typography component="h1" variant="h4" gutterBottom={true}>
      Profile
    </Typography>
    <Typography variant="subtitle1">
      Manage your account information, privacy, and security.
    </Typography>
    <br />
    <YourInfoAccordionContent />
    <TwoFAAccordionContent />
    <PrivilegeAccordion />
  </>
);

export default Profile;
