import React from 'react';
import { CloudUpload, Search } from '@mui/icons-material';
import { Box } from '@mui/material';
import { AlertType, DropzoneArea } from '@shotskydiver/material-ui-dropzone';
import { Control, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { setSnackbar } from '../../../../../redux/slices/globalSnackbarSlice';
import { CredentialDialogFormType } from '../../../../../types/formTypes';

interface ControlledDropzoneProps {
  control: Control<CredentialDialogFormType, any>,
  displayText: string,
  errors: any,
  name: 'caFile' | 'clientCert' | 'clientKey'
}

const ControlledDropzone = ({
  control,
  displayText,
  errors,
  name
}: ControlledDropzoneProps) => {
  const dispatch = useDispatch();
  const dropzoneAddDisplaySnackbar = (message: string, variant: AlertType) => {
    if (variant !== 'success') {
      dispatch(setSnackbar({
        snackbarType: variant,
        snackbarMessage: message
      }));
    }
  };

  return (
    <>
      <Controller
        rules={{ required: true }}
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <DropzoneArea
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            acceptedFiles={[
              'application/x-pem-file',
              'application/x-x509-ca-cert',
              'application/x-x509-user-cert'
            ]}
            // class .dropzoneErrorStyle defined in theme.js
            dropzoneClass={errors[name] ? 'dropzoneErrorStyle' : ''}
            dropzoneText={displayText}
            filesLimit={1}
            Icon={field.value?.length ? CloudUpload : Search}
            onAlert={dropzoneAddDisplaySnackbar}
            previewGridProps={{
              container: { spacing: 1, direction: 'row', justifyContent: 'center' }
            }}
            previewChipProps={{
              color: errors[name] ? 'error' : 'primary',
              style: {
                maxWidth: displayText.length > 20 ? 550 : 150
              }
            }}
            previewText="Selected file:"
            showAlerts={false}
            showPreviewsInDropzone={true}
            useChipsForPreview={true}
          />
        )}
      />
      {errors[name]?.message && (
        <Box
          color="error.main"
          display="flex"
          justifyContent="center"
          mt={1}
          textAlign="center"
          width="100%"
        >
          {errors[name].message}
        </Box>
      )}
    </>
  );
};

ControlledDropzone.displayText = 'ControlledDropzone';

export default ControlledDropzone;
