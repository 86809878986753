import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useParams } from 'react-router-dom';

import { useDeleteUserMutation } from '../../api/reactQuery/mutations';
import { useGetSingleUserQuery } from '../../api/reactQuery/queries';

interface DeleteUserDialogProps {
  openDeleteUserDialog: boolean,
  setOpenDeleteUserDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteUserDialog = (
  {
    openDeleteUserDialog,
    setOpenDeleteUserDialog
  }: DeleteUserDialogProps
) => {
  const { id } = useParams<Record<string, string>>();
  const { data: singleUserQueryData } = useGetSingleUserQuery(id);
  const { mutate: deleteUserMutate } = useDeleteUserMutation();

  const handleDeleteDialogClose = () => {
    setOpenDeleteUserDialog(false);
  };

  const onConfirmDeleteUserClick = () => {
    const deleteUserMutateObject = singleUserQueryData;
    if (deleteUserMutateObject) {
      deleteUserMutate(deleteUserMutateObject);
    }
    handleDeleteDialogClose();
  };

  return (
    <Dialog open={openDeleteUserDialog}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography sx={{ typography: 'h6' }}>
          <ErrorIcon sx={{ mr: 1, verticalAlign: 'sub' }} />
          {`Delete ${singleUserQueryData?.firstName} ${singleUserQueryData?.lastName}?`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ p: 2 }}>
        <DialogContentText>
          { `Deleting users cannot be undone.  Are you sure you want to delete
          ${singleUserQueryData?.firstName} ${singleUserQueryData?.lastName}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 1 }}>
        <Button autoFocus={true} onClick={handleDeleteDialogClose} color="primary">
          No, go back
        </Button>
        <Button onClick={onConfirmDeleteUserClick} color="primary">
          Yes, delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;
