import React from 'react';
import { List, Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { UseQueryResult } from 'react-query';
import IpListItems from './IpListItems';
import { useLoggedInUserQuery } from '../../api/reactQuery/queries';
import { selectPendingIps } from '../../redux/slices/sourceIpSlice';
import { SourceIpQueryResponse } from '../../api/SourceIp/SourceIpRequests';

interface DisplayedIpListProps {
  sourceIpQueryResult?: UseQueryResult<SourceIpQueryResponse>
}

const DisplayedIpList = ({
  sourceIpQueryResult
}: DisplayedIpListProps) => {
  const pendingIps = useSelector(selectPendingIps);
  const loggedInUserOptionsDefined = useLoggedInUserQuery().data?.user?.optionsDefined;

  // optionsDefined is used to check if a user has already filled out the moreinfo page,
  // there is no sourceIpQueryResult if they have not yet,
  // if there is also no pendingIPs we do not want to display anything,
  // only display a list on moreinfo page if there are pendingIPs
  const listContent = () => {
    if (!loggedInUserOptionsDefined && !pendingIps.length) {
      return undefined;
    }

    if (sourceIpQueryResult?.isLoading) {
      return <Typography>Loading...</Typography>;
    }
    if (sourceIpQueryResult?.isSuccess || !loggedInUserOptionsDefined) {
      let fullList = pendingIps;
      if (loggedInUserOptionsDefined && sourceIpQueryResult?.data) {
        const combinedIpsArray = [
          ...JSON.parse(sourceIpQueryResult.data?.ipAddresses),
          ...pendingIps
        ];
        const sortedIps = combinedIpsArray.sort((a, b) => ((a.text > b.text) ? 1 : -1));
        fullList = sortedIps;
      }
      return (fullList.length > 0 ? (
        <Box>
          <List>
            {fullList.map((ip) => (
              <IpListItems
                fullList={fullList}
                text={ip.text}
                description={ip.description}
                propKey={ip.key || ip.text}
                key={ip.key || ip.text}
                status={ip.status}
              />
            ))}
          </List>
        </Box>
      ) : (
        <div>
          <Typography>Add an IP</Typography>
        </div>
      ));
    }
    return undefined;
  };
  return (
    <>
      {listContent()}
    </>
  );
};

export default DisplayedIpList;
