import React from 'react';
import {
  Box,
  Container
} from '@mui/material';

import PKLogo from './PKLogo';
import Copyright from './portal/layout/Copyright';

interface StaticPageLayoutContainerProps {
  children: React.ReactNode;
}

const StaticPageLayoutContainer = ({
  children
}: StaticPageLayoutContainerProps) => (
  <Container
    maxWidth="xs"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100vh',
      pb: '10px',
      pt: 8
    }}
  >
    <Box mt={4} display="flex" flexDirection="column" alignItems="center">
      <Box mb={4}>
        <PKLogo />
      </Box>
      {children}
    </Box>
    <Box pt={4} pb="10px">
      <Copyright />
    </Box>
  </Container>
);

export default StaticPageLayoutContainer;
