const cp = `
# CP/CPS Template Packets

## *What is a Certificate Policy?*

### CP (Certificate Policy)

-   A document that defines the rights, duties, and obligations of each party involved in the PKI
-   Overarching requirement document for the PKI environment

## *What do I have to work with?*

Below are the pieces to building a CP with their respective descriptions:

- CP Template (word) - provides the CP skeleton with highlighted sections that need to be customized by you.
- CP Working Doc (excel) - provides a working area for you to customize the highlighted sections and examples from publicly available CPs.'

The CP templates were created based on NIST standards that falls in line with RFC 3647.

## *What do I do with these documents?*
The highlighted sections of the CP are the most customizable portions of the documents. After the highlighted sections are customized, you will need to review the document as a whole to make sure the other sections fall in line with your unique standards/operations. The non-highlighted sections are NIST and best practice statements that might need to be modified for your environment. These documents were created for your internal use only, they are not to be distributed or sold externally. Failure to abide by this may result in litigation.

## *Where can I get additional help?*
If you would like additional professional help with creating or customizing your CP/CPS, please contact [sales@primekey.com](mailto:sales@primekey.com?subject=PrimeKey%20SaaS%20Customer%20-%20Professional%20Services%20Assistance)

You may download a template for a Certificate Policy here:
`;

export default cp;
