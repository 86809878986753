import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import logo from '../img/logo.png';
import logoBlack from '../img/logoBlack.png';
import { selectTheme } from '../redux/slices/userPreferencesSlice';

const PKLogo = () => {
  const themePref = useSelector(selectTheme);
  const whichLogo = themePref === 'light' ? logoBlack : logo;

  return (
    <Box
      component="img"
      src={whichLogo}
      alt="Logo"
      width="224px"
    />
  );
};

export default PKLogo;
