import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { useLoggedInUserQuery } from '../Session/SessionQueries';
import { getTOTPState } from './TwoFARequests';
import { INVALID_SESSION, GET_TOTP_STATE_QUERY } from '../../util/constants';
import { basicErrorHandler } from '../reactQuery/queryErrorHandling';

const useGetTOTPStateQuery = () => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();

  return useQuery(
    GET_TOTP_STATE_QUERY,
    () => {
      const response = getTOTPState()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: Boolean(loggedInUserData?.user),
      retry: (failureCount, error: AxiosError) => {
        if (error.response?.data.errors?.some((entry: any) => entry.type === INVALID_SESSION)) {
          return false;
        }
        return failureCount < 3;
      },
      onError: (error) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};

export default useGetTOTPStateQuery;
