import React from 'react';
import { Typography, Box } from '@mui/material';
import { GppBad as GppBadIcon, GppGood as GppGoodIcon } from '@mui/icons-material';

interface TwoFaDetailsProps {
  isTotpEnabled?: boolean;
  isUserActive?: boolean
}

const TwoFaDetails = ({
  isTotpEnabled = false,
  isUserActive = false
}: TwoFaDetailsProps) => {
  const colorAndStatusToDisplay = () => {
    let text = 'Not Enabled';
    let icon = <GppBadIcon fontSize="small" color={isUserActive ? 'warning' : 'disabled'} sx={{ mr: 0.5 }} />;
    if (isTotpEnabled) {
      text = 'Enabled';
      icon = <GppGoodIcon fontSize="small" color={isUserActive ? 'success' : 'disabled'} sx={{ mr: 0.5 }} />;
    }
    return (
      <>
        {icon}
        <Typography color="inherit">
          {text}
        </Typography>
      </>
    );
  };
  return (
    <>
      <Box display="flex" color={isUserActive ? 'text.primary' : 'text.disabled'}>
        <Typography color="inherit">
          Two-Factor Authentication:
        </Typography>

        <Box ml={1} display="flex" color="inherit">
          {colorAndStatusToDisplay()}
        </Box>
      </Box>
    </>

  );
};

export default TwoFaDetails;
