import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { useLoggedInUserQuery } from '../Session/SessionQueries';
import { useLogoutUserMutation } from '../Session/SessionMutations';
import {
  INVALID_SESSION,
  UNEXPECTED_ERROR,
  GET_DEPLOYMENT_STATUS_QUERY,
  GET_DEPLOY_DIALOG_FLAG_QUERY
} from '../../util/constants';
import { getDeploymentState, getDeploymentStatus, getDeployDialogFlag } from './DeploymentRequests';
import { basicErrorHandler, receiveErrors } from '../reactQuery/queryErrorHandling';

export const useDeploymentState = () => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const { mutate: logoutUserMutate } = useLogoutUserMutation();
  const [refetchTime, setRefetchTime] = useState(10000);

  return useQuery(
    'deploymentState',
    () => {
      const response = getDeploymentState()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: Boolean(loggedInUserData?.user),
      // Only possible errors are Invalid session or forbidden, which should force a logout,
      // which should not allow a retry
      retry: false,
      refetchInterval: refetchTime,
      // Interval runs every 10 seconds when the deployment is in progress, but switches to every
      // 5 minutes when a deployment is not happeneing.
      onSuccess: (data) => {
        if (data.entState === 30) {
          setRefetchTime(10000);
        } else {
          setRefetchTime(300000);
        }
      },
      onError: () => {
        const errorData = {
          // Only possible errors are Invalid session or forbidden, which should force a logout,
          // using Invalid session for both possibilities.
          error: INVALID_SESSION
        };
        logoutUserMutate();
        receiveErrors(errorData, dispatch);
      }
    }
  );
};

export const useGetDeploymentStatus = () => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  return useQuery(
    GET_DEPLOYMENT_STATUS_QUERY,
    () => {
      const response = getDeploymentStatus()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: Boolean(loggedInUserData?.user),
      refetchInterval: (response) => {
        if (response?.status === 100) {
          return false;
        }
        return 5000;
      },
      onError: (error: AxiosError) => {
        if (error.response?.data.errors) {
          basicErrorHandler(error, dispatch);
        } else {
          receiveErrors({ error: UNEXPECTED_ERROR }, dispatch);
        }
      }

    }
  );
};

export const useGetDeployDialogFlag = () => {
  const dispatch = useDispatch();
  const { data: deploymentStatusData } = useGetDeploymentStatus();

  return useQuery(
    GET_DEPLOY_DIALOG_FLAG_QUERY,
    () => {
      const response = getDeployDialogFlag()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: !!deploymentStatusData,
      onError: (error: AxiosError) => {
        if (error.response?.data.errors) {
          basicErrorHandler(error, dispatch);
        } else {
          receiveErrors({ error: UNEXPECTED_ERROR }, dispatch);
        }
      }
    }
  );
};
