import React from 'react';
import { Alert, Collapse } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  selectGlobalAlertOpen,
  selectGlobalAlerts
} from '../../../redux/slices/globalAlertSlice';

const GlobalAlert = () => {
  const alertOpen = useSelector(selectGlobalAlertOpen);
  const alerts = useSelector(selectGlobalAlerts);

  const alertKeyList = Object.keys(alerts);

  return (
    <>
      {alertKeyList.map((key) => (
        <Collapse in={alertOpen} key={key}>
          <Alert
            severity={alerts[key].alertType}
            sx={{ mb: 2 }}
            variant="filled"
          >
            {alerts[key].alertMessage}
          </Alert>
        </Collapse>
      ))}
    </>
  );
};

export default GlobalAlert;
