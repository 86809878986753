import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import queryClient from '../reactQuery/QueryClient';
import { useLoggedInUserQuery } from '../Session/SessionQueries';
import {
  getAllUsers,
  getSingleUser,
  getPrivilegeCategories,
  getIsTokenValid,
  getSelf,
  UserResponse
} from './UserManagementRequests';
import { basicErrorHandler, errorsSnackbarHandler } from '../reactQuery/queryErrorHandling';
import { TOKEN_ERROR_ROUTE } from '../../util/routeConstants';
import {
  GET_ALL_USERS_QUERY,
  GET_IS_TOKEN_VALID_QUERY,
  GET_SELF_QUERY,
  GET_SINGLE_USER_QUERY
} from '../../util/constants';

export const useGetAllUsersQuery = () => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  return useQuery(
    GET_ALL_USERS_QUERY,
    () => {
      const response = getAllUsers()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: Boolean(loggedInUserData?.user),
      onError: (error: AxiosError) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};

export const useGetSingleUserQuery = (id: string) => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  return useQuery(
    `${GET_SINGLE_USER_QUERY}: ${id}`,
    () => {
      const response = getSingleUser(id)
        .then((res) => res.data);
      return response;
    },
    {
      // userData already exists from getAllUsersQuery.  Use that query to populate user detail page
      // so no need for Loading...
      initialData: () => queryClient.getQueryData<UserResponse[]>(GET_ALL_USERS_QUERY)?.find(
        (person) => person.id.toString() === id
      ),
      enabled: Boolean(loggedInUserData?.user) && Boolean(id),
      onError: (error) => {
        errorsSnackbarHandler(error, dispatch);
      }
    }
  );
};

export const useGetPrivilegeCategoriesQuery = () => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  return useQuery(
    'getPrivilegeCategories',
    () => {
      const response = getPrivilegeCategories()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: Boolean(loggedInUserData?.user),
      onError: (error: AxiosError) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};

export const useGetIsTokenValidQuery = () => {
  const { token } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const history = useHistory();

  return useQuery(
    GET_IS_TOKEN_VALID_QUERY,
    () => {
      const response = getIsTokenValid(token)
        .then((res) => res.data);
      return response;
    },
    {
      enabled: false,
      retry: false,
      onError: (error: AxiosError) => {
        error.response?.data.errors?.forEach((entry: any) => {
          if (entry.tokenError) {
            history.push(TOKEN_ERROR_ROUTE, token);
          }
        });
        basicErrorHandler(error, dispatch);
      }
    }
  );
};

export const useGetSelfQuery = () => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  return useQuery(
    GET_SELF_QUERY,
    () => {
      const response = getSelf()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: Boolean(loggedInUserData?.user),
      onError: (error: AxiosError) => {
        errorsSnackbarHandler(error, dispatch);
      }
    }
  );
};
