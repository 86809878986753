import axios, { AxiosResponse } from 'axios';

interface StateResponse {
  customerIdentifier: string;
  productCode: string;
  dimension: string;
  entState: number; // just state in the db??
  domainName: string;
  expiration: string; // date?
  value: number; // what's this for?
}

interface StatusResponse {
  status: number;
}

interface GetFlagReponse {
  flag: boolean;
}

interface SetFlagPayload {
  value: boolean;
}

interface SetFlagResponse {
  flag: boolean;
}

export const getDeploymentState = (): Promise<AxiosResponse<StateResponse>> => axios.get('/api/deploymentState');
export const getDeploymentStatus = (): Promise<AxiosResponse<StatusResponse>> => axios.get('/api/status');
export const getDeployDialogFlag = (): Promise<AxiosResponse<GetFlagReponse>> => axios.get('/api/getDeployDialogFlag');
export const submitSetDeployDialogFlag = (
  deployDialogFlag: SetFlagPayload
): Promise<AxiosResponse<SetFlagResponse>> => axios.post('/api/post/setDeployDialogFlag', deployDialogFlag);
