export const COUNTRY_LIST = [
  { key: 'US', value: 'United States' },
  { key: 'AF', value: 'Afghanistan' },
  { key: 'AX', value: 'Åland Islands' },
  { key: 'AL', value: 'Albania' },
  { key: 'DZ', value: 'Algeria' },
  { key: 'AS', value: 'American Samoa' },
  { key: 'AD', value: 'Andorra' },
  { key: 'AO', value: 'Angola' },
  { key: 'AI', value: 'Anguilla' },
  { key: 'AQ', value: 'Antarctica' },
  { key: 'AG', value: 'Antigua and Barbuda' },
  { key: 'AR', value: 'Argentina' },
  { key: 'AM', value: 'Armenia' },
  { key: 'AW', value: 'Aruba' },
  { key: 'AU', value: 'Australia' },
  { key: 'AT', value: 'Austria' },
  { key: 'AZ', value: 'Azerbaijan' },
  { key: 'BS', value: 'Bahamas' },
  { key: 'BH', value: 'Bahrain' },
  { key: 'BD', value: 'Bangladesh' },
  { key: 'BB', value: 'Barbados' },
  { key: 'BY', value: 'Belarus' },
  { key: 'BE', value: 'Belgium' },
  { key: 'BZ', value: 'Belize' },
  { key: 'BJ', value: 'Benin' },
  { key: 'BM', value: 'Bermuda' },
  { key: 'BT', value: 'Bhutan' },
  { key: 'BO', value: 'Bolivia, Plurinational State of' },
  { key: 'BQ', value: 'Bonaire, Sint Eustatius and Saba' },
  { key: 'BA', value: 'Bosnia and Herzegovina' },
  { key: 'BW', value: 'Botswana' },
  { key: 'BV', value: 'Bouvet Island' },
  { key: 'BR', value: 'Brazil' },
  { key: 'IO', value: 'British Indian Ocean Territory' },
  { key: 'BN', value: 'Brunei Darussalam' },
  { key: 'BG', value: 'Bulgaria' },
  { key: 'BF', value: 'Burkina Faso' },
  { key: 'BI', value: 'Burundi' },
  { key: 'KH', value: 'Cambodia' },
  { key: 'CM', value: 'Cameroon' },
  { key: 'CA', value: 'Canada' },
  { key: 'CV', value: 'Cape Verde' },
  { key: 'KY', value: 'Cayman Islands' },
  { key: 'CF', value: 'Central African Republic' },
  { key: 'TD', value: 'Chad' },
  { key: 'CL', value: 'Chile' },
  { key: 'CN', value: 'China' },
  { key: 'CX', value: 'Christmas Island' },
  { key: 'CC', value: 'Cocos (Keeling) Islands' },
  { key: 'CO', value: 'Colombia' },
  { key: 'KM', value: 'Comoros' },
  { key: 'CG', value: 'Congo' },
  { key: 'CD', value: 'Congo, the Democratic Republic of the' },
  { key: 'CK', value: 'Cook Islands' },
  { key: 'CR', value: 'Costa Rica' },
  { key: 'CI', value: "Côte d'Ivoire" },
  { key: 'HR', value: 'Croatia' },
  { key: 'CU', value: 'Cuba' },
  { key: 'CW', value: 'Curaçao' },
  { key: 'CY', value: 'Cyprus' },
  { key: 'CZ', value: 'Czech Republic' },
  { key: 'DK', value: 'Denmark' },
  { key: 'DJ', value: 'Djibouti' },
  { key: 'DM', value: 'Dominica' },
  { key: 'DO', value: 'Dominican Republic' },
  { key: 'EC', value: 'Ecuador' },
  { key: 'EG', value: 'Egypt' },
  { key: 'SV', value: 'El Salvador' },
  { key: 'GQ', value: 'Equatorial Guinea' },
  { key: 'ER', value: 'Eritrea' },
  { key: 'EE', value: 'Estonia' },
  { key: 'ET', value: 'Ethiopia' },
  { key: 'FK', value: 'Falkland Islands (Malvinas)' },
  { key: 'FO', value: 'Faroe Islands' },
  { key: 'FJ', value: 'Fiji' },
  { key: 'FI', value: 'Finland' },
  { key: 'FR', value: 'France' },
  { key: 'GF', value: 'French Guiana' },
  { key: 'PF', value: 'French Polynesia' },
  { key: 'TF', value: 'French Southern Territories' },
  { key: 'GA', value: 'Gabon' },
  { key: 'GM', value: 'Gambia' },
  { key: 'GE', value: 'Georgia' },
  { key: 'DE', value: 'Germany' },
  { key: 'GH', value: 'Ghana' },
  { key: 'GI', value: 'Gibraltar' },
  { key: 'GR', value: 'Greece' },
  { key: 'GL', value: 'Greenland' },
  { key: 'GD', value: 'Grenada' },
  { key: 'GP', value: 'Guadeloupe' },
  { key: 'GU', value: 'Guam' },
  { key: 'GT', value: 'Guatemala' },
  { key: 'GG', value: 'Guernsey' },
  { key: 'GN', value: 'Guinea' },
  { key: 'GW', value: 'Guinea-Bissau' },
  { key: 'GY', value: 'Guyana' },
  { key: 'HT', value: 'Haiti' },
  { key: 'HM', value: 'Heard Island and McDonald Islands' },
  { key: 'VA', value: 'Holy See (Vatican City State)' },
  { key: 'HN', value: 'Honduras' },
  { key: 'HK', value: 'Hong Kong' },
  { key: 'HU', value: 'Hungary' },
  { key: 'IS', value: 'Iceland' },
  { key: 'IN', value: 'India' },
  { key: 'ID', value: 'Indonesia' },
  { key: 'IR', value: 'Iran, Islamic Republic of' },
  { key: 'IQ', value: 'Iraq' },
  { key: 'IE', value: 'Ireland' },
  { key: 'IM', value: 'Isle of Man' },
  { key: 'IL', value: 'Israel' },
  { key: 'IT', value: 'Italy' },
  { key: 'JM', value: 'Jamaica' },
  { key: 'JP', value: 'Japan' },
  { key: 'JE', value: 'Jersey' },
  { key: 'JO', value: 'Jordan' },
  { key: 'KZ', value: 'Kazakhstan' },
  { key: 'KE', value: 'Kenya' },
  { key: 'KI', value: 'Kiribati' },
  { key: 'KP', value: "Korea, Democratic People's Republic of" },
  { key: 'KR', value: 'Korea, Republic of' },
  { key: 'KW', value: 'Kuwait' },
  { key: 'KG', value: 'Kyrgyzstan' },
  { key: 'LA', value: "Lao People's Democratic Republic" },
  { key: 'LV', value: 'Latvia' },
  { key: 'LB', value: 'Lebanon' },
  { key: 'LS', value: 'Lesotho' },
  { key: 'LR', value: 'Liberia' },
  { key: 'LY', value: 'Libya' },
  { key: 'LI', value: 'Liechtenstein' },
  { key: 'LT', value: 'Lithuania' },
  { key: 'LU', value: 'Luxembourg' },
  { key: 'MO', value: 'Macao' },
  { key: 'MK', value: 'Macedonia, the former Yugoslav Republic of' },
  { key: 'MG', value: 'Madagascar' },
  { key: 'MW', value: 'Malawi' },
  { key: 'MY', value: 'Malaysia' },
  { key: 'MV', value: 'Maldives' },
  { key: 'ML', value: 'Mali' },
  { key: 'MT', value: 'Malta' },
  { key: 'MH', value: 'Marshall Islands' },
  { key: 'MQ', value: 'Martinique' },
  { key: 'MR', value: 'Mauritania' },
  { key: 'MU', value: 'Mauritius' },
  { key: 'YT', value: 'Mayotte' },
  { key: 'MX', value: 'Mexico' },
  { key: 'FM', value: 'Micronesia, Federated States of' },
  { key: 'MD', value: 'Moldova, Republic of' },
  { key: 'MC', value: 'Monaco' },
  { key: 'MN', value: 'Mongolia' },
  { key: 'ME', value: 'Montenegro' },
  { key: 'MS', value: 'Montserrat' },
  { key: 'MA', value: 'Morocco' },
  { key: 'MZ', value: 'Mozambique' },
  { key: 'MM', value: 'Myanmar' },
  { key: 'NA', value: 'Namibia' },
  { key: 'NR', value: 'Nauru' },
  { key: 'NP', value: 'Nepal' },
  { key: 'NL', value: 'Netherlands' },
  { key: 'NC', value: 'New Caledonia' },
  { key: 'NZ', value: 'New Zealand' },
  { key: 'NI', value: 'Nicaragua' },
  { key: 'NE', value: 'Niger' },
  { key: 'NG', value: 'Nigeria' },
  { key: 'NU', value: 'Niue' },
  { key: 'NF', value: 'Norfolk Island' },
  { key: 'MP', value: 'Northern Mariana Islands' },
  { key: 'NO', value: 'Norway' },
  { key: 'OM', value: 'Oman' },
  { key: 'PK', value: 'Pakistan' },
  { key: 'PW', value: 'Palau' },
  { key: 'PS', value: 'Palestinian Territory, Occupied' },
  { key: 'PA', value: 'Panama' },
  { key: 'PG', value: 'Papua New Guinea' },
  { key: 'PY', value: 'Paraguay' },
  { key: 'PE', value: 'Peru' },
  { key: 'PH', value: 'Philippines' },
  { key: 'PN', value: 'Pitcairn' },
  { key: 'PL', value: 'Poland' },
  { key: 'PT', value: 'Portugal' },
  { key: 'PR', value: 'Puerto Rico' },
  { key: 'QA', value: 'Qatar' },
  { key: 'RE', value: 'Réunion' },
  { key: 'RO', value: 'Romania' },
  { key: 'RU', value: 'Russian Federation' },
  { key: 'RW', value: 'Rwanda' },
  { key: 'BL', value: 'Saint Barthélemy' },
  { key: 'SH', value: 'Saint Helena, Ascension and Tristan da Cunha' },
  { key: 'KN', value: 'Saint Kitts and Nevis' },
  { key: 'LC', value: 'Saint Lucia' },
  { key: 'MF', value: 'Saint Martin (French part)' },
  { key: 'PM', value: 'Saint Pierre and Miquelon' },
  { key: 'VC', value: 'Saint Vincent and the Grenadines' },
  { key: 'WS', value: 'Samoa' },
  { key: 'SM', value: 'San Marino' },
  { key: 'ST', value: 'Sao Tome and Principe' },
  { key: 'SA', value: 'Saudi Arabia' },
  { key: 'SN', value: 'Senegal' },
  { key: 'RS', value: 'Serbia' },
  { key: 'SC', value: 'Seychelles' },
  { key: 'SL', value: 'Sierra Leone' },
  { key: 'SG', value: 'Singapore' },
  { key: 'SX', value: 'Sint Maarten (Dutch part)' },
  { key: 'SK', value: 'Slovakia' },
  { key: 'SI', value: 'Slovenia' },
  { key: 'SB', value: 'Solomon Islands' },
  { key: 'SO', value: 'Somalia' },
  { key: 'ZA', value: 'South Africa' },
  { key: 'GS', value: 'South Georgia and the South Sandwich Islands' },
  { key: 'SS', value: 'South Sudan' },
  { key: 'ES', value: 'Spain' },
  { key: 'LK', value: 'Sri Lanka' },
  { key: 'SD', value: 'Sudan' },
  { key: 'SR', value: 'Suriname' },
  { key: 'SJ', value: 'Svalbard and Jan Mayen' },
  { key: 'SZ', value: 'Swaziland' },
  { key: 'SE', value: 'Sweden' },
  { key: 'CH', value: 'Switzerland' },
  { key: 'SY', value: 'Syrian Arab Republic' },
  { key: 'TW', value: 'Taiwan, Province of China' },
  { key: 'TJ', value: 'Tajikistan' },
  { key: 'TZ', value: 'Tanzania, United Republic of' },
  { key: 'TH', value: 'Thailand' },
  { key: 'TL', value: 'Timor-Leste' },
  { key: 'TG', value: 'Togo' },
  { key: 'TK', value: 'Tokelau' },
  { key: 'TO', value: 'Tonga' },
  { key: 'TT', value: 'Trinidad and Tobago' },
  { key: 'TN', value: 'Tunisia' },
  { key: 'TR', value: 'Turkey' },
  { key: 'TM', value: 'Turkmenistan' },
  { key: 'TC', value: 'Turks and Caicos Islands' },
  { key: 'TV', value: 'Tuvalu' },
  { key: 'UG', value: 'Uganda' },
  { key: 'UA', value: 'Ukraine' },
  { key: 'AE', value: 'United Arab Emirates' },
  { key: 'GB', value: 'United Kingdom' },
  { key: 'UM', value: 'United States Minor Outlying Islands' },
  { key: 'UY', value: 'Uruguay' },
  { key: 'UZ', value: 'Uzbekistan' },
  { key: 'VU', value: 'Vanuatu' },
  { key: 'VE', value: 'Venezuela, Bolivarian Republic of' },
  { key: 'VN', value: 'Viet Nam' },
  { key: 'VG', value: 'Virgin Islands, British' },
  { key: 'VI', value: 'Virgin Islands, U.S.' },
  { key: 'WF', value: 'Wallis and Futuna' },
  { key: 'EH', value: 'Western Sahara' },
  { key: 'YE', value: 'Yemen' },
  { key: 'ZM', value: 'Zambia' },
  { key: 'ZW', value: 'Zimbabwe' }
] as const;

export const MOREINFO_COUNTRY_LIST = [
  { key: '', value: 'None' },
  { key: 'US', value: 'US (United States)' },
  { key: 'AF', value: 'AF (Afghanistan)' },
  { key: 'AX', value: 'AX (Åland Islands)' },
  { key: 'AL', value: 'AL (Albania))' },
  { key: 'DZ', value: 'DZ (Algeria)' },
  { key: 'AS', value: 'AS (American Samoa)' },
  { key: 'AD', value: 'AD (Andorra)' },
  { key: 'AO', value: 'AO (Angola)' },
  { key: 'AI', value: 'AI (Anguilla)' },
  { key: 'AQ', value: 'AQ (Antarctica)' },
  { key: 'AG', value: 'AG (Antigua and Barbuda)' },
  { key: 'AR', value: 'AR (Argentina)' },
  { key: 'AM', value: 'AM (Armenia)' },
  { key: 'AW', value: 'AW (Aruba)' },
  { key: 'AU', value: 'AU (Australia)' },
  { key: 'AT', value: 'AT (Austria)' },
  { key: 'AZ', value: 'AZ (Azerbaijan)' },
  { key: 'BS', value: 'BS (Bahamas)' },
  { key: 'BH', value: 'BH (Bahrain)' },
  { key: 'BD', value: 'BD (Bangladesh)' },
  { key: 'BB', value: 'BB (Barbados)' },
  { key: 'BY', value: 'BY (Belarus)' },
  { key: 'BE', value: 'BE (Belgium)' },
  { key: 'BZ', value: 'BZ (Belize)' },
  { key: 'BJ', value: 'BJ (Benin)' },
  { key: 'BM', value: 'BM (Bermuda)' },
  { key: 'BT', value: 'BT (Bhutan)' },
  { key: 'BO', value: 'BO (Bolivia, Plurinational State of)' },
  { key: 'BQ', value: 'BQ (Bonaire, Sint Eustatius and Saba)' },
  { key: 'BA', value: 'BA (Bosnia and Herzegovina)' },
  { key: 'BW', value: 'BW (Botswana)' },
  { key: 'BV', value: 'BV (Bouvet Island)' },
  { key: 'BR', value: 'BR (Brazil)' },
  { key: 'IO', value: 'IO (British Indian Ocean Territory)' },
  { key: 'BN', value: 'BN (Brunei Darussalam)' },
  { key: 'BG', value: 'BG (Bulgaria)' },
  { key: 'BF', value: 'BF (Burkina Faso)' },
  { key: 'BI', value: 'BI (Burundi)' },
  { key: 'KH', value: 'KH (Cambodia)' },
  { key: 'CM', value: 'CM (Cameroon)' },
  { key: 'CA', value: 'CA (Canada)' },
  { key: 'CV', value: 'CV (Cape Verde)' },
  { key: 'KY', value: 'KY (Cayman Islands)' },
  { key: 'CF', value: 'CF (Central African Republic)' },
  { key: 'TD', value: 'TD (Chad)' },
  { key: 'CL', value: 'CL (Chile)' },
  { key: 'CN', value: 'CN (China)' },
  { key: 'CX', value: 'CX (Christmas Island)' },
  { key: 'CC', value: 'CC (Cocos (Keeling) Islands)' },
  { key: 'CO', value: 'CO (Colombia)' },
  { key: 'KM', value: 'KM (Comoros)' },
  { key: 'CG', value: 'CG (Congo)' },
  { key: 'CD', value: 'CD (Congo, the Democratic Republic of the)' },
  { key: 'CK', value: 'CK (Cook Islands)' },
  { key: 'CR', value: 'CR (Costa Rica)' },
  { key: 'CI', value: "CI (Côte d'Ivoire)" },
  { key: 'HR', value: 'HR (Croatia)' },
  { key: 'CU', value: 'CU (Cuba)' },
  { key: 'CW', value: 'CW (Curaçao)' },
  { key: 'CY', value: 'CY (Cyprus)' },
  { key: 'CZ', value: 'CZ (Czech Republic)' },
  { key: 'DK', value: 'DK (Denmark)' },
  { key: 'DJ', value: 'DJ (Djibouti)' },
  { key: 'DM', value: 'DM (Dominica)' },
  { key: 'DO', value: 'DO (Dominican Republic)' },
  { key: 'EC', value: 'EC (Ecuador)' },
  { key: 'EG', value: 'EG (Egypt)' },
  { key: 'SV', value: 'SV (El Salvador)' },
  { key: 'GQ', value: 'GW (Equatorial Guinea)' },
  { key: 'ER', value: 'ER (Eritrea)' },
  { key: 'EE', value: 'EE (Estonia)' },
  { key: 'ET', value: 'ET (Ethiopia)' },
  { key: 'FK', value: 'FK (Falkland Islands (Malvinas))' },
  { key: 'FO', value: 'FO (Faroe Islands)' },
  { key: 'FJ', value: 'FI (Fiji)' },
  { key: 'FI', value: 'FI (Finland)' },
  { key: 'FR', value: 'FR (France)' },
  { key: 'GF', value: 'GF (French Guiana)' },
  { key: 'PF', value: 'PF (French Polynesia)' },
  { key: 'TF', value: 'TF (French Southern Territories)' },
  { key: 'GA', value: 'GA (Gabon)' },
  { key: 'GM', value: 'GM (Gambia)' },
  { key: 'GE', value: 'GE (Georgia)' },
  { key: 'DE', value: 'DE (Germany)' },
  { key: 'GH', value: 'GH (Ghana)' },
  { key: 'GI', value: 'GI (Gibraltar)' },
  { key: 'GR', value: 'GR (Greece)' },
  { key: 'GL', value: 'GL (Greenland)' },
  { key: 'GD', value: 'GD (Grenada)' },
  { key: 'GP', value: 'GP (Guadeloupe)' },
  { key: 'GU', value: 'GU (Guam)' },
  { key: 'GT', value: 'GT (Guatemala)' },
  { key: 'GG', value: 'GG (Guernsey)' },
  { key: 'GN', value: 'GN (Guinea)' },
  { key: 'GW', value: 'GW (Guinea-Bissau)' },
  { key: 'GY', value: 'GY (Guyana)' },
  { key: 'HT', value: 'HT (Haiti)' },
  { key: 'HM', value: 'HM (Heard Island and McDonald Islands)' },
  { key: 'VA', value: 'VA (Holy See (Vatican City State))' },
  { key: 'HN', value: 'HN (Honduras)' },
  { key: 'HK', value: 'HK (Hong Kong)' },
  { key: 'HU', value: 'HU (Hungary)' },
  { key: 'IS', value: 'IS (Iceland)' },
  { key: 'IN', value: 'IN (India)' },
  { key: 'ID', value: 'ID (Indonesia)' },
  { key: 'IR', value: 'IR (Iran, Islamic Republic of)' },
  { key: 'IQ', value: 'IQ (Iraq)' },
  { key: 'IE', value: 'IE (Ireland)' },
  { key: 'IM', value: 'IM (Isle of Man)' },
  { key: 'IL', value: 'IL (Israel)' },
  { key: 'IT', value: 'IT (Italy)' },
  { key: 'JM', value: 'JM (Jamaica)' },
  { key: 'JP', value: 'JP (Japan)' },
  { key: 'JE', value: 'JE (Jersey)' },
  { key: 'JO', value: 'JO (Jordan)' },
  { key: 'KZ', value: 'KZ (Kazakhstan)' },
  { key: 'KE', value: 'KE (Kenya)' },
  { key: 'KI', value: 'KI (Kiribati)' },
  { key: 'KP', value: "KP (Korea, Democratic People's Republic of)" },
  { key: 'KR', value: 'KR (Korea, Republic of)' },
  { key: 'KW', value: 'KW (Kuwait)' },
  { key: 'KG', value: 'KG (Kyrgyzstan)' },
  { key: 'LA', value: "LA (Lao People's Democratic Republic)" },
  { key: 'LV', value: 'LV (Latvia)' },
  { key: 'LB', value: 'LB (Lebanon)' },
  { key: 'LS', value: 'LS (Lesotho)' },
  { key: 'LR', value: 'LR (Liberia)' },
  { key: 'LY', value: 'LY (Libya)' },
  { key: 'LI', value: 'LI (Liechtenstein)' },
  { key: 'LT', value: 'LT (Lithuania)' },
  { key: 'LU', value: 'LU (Luxembourg)' },
  { key: 'MO', value: 'MO (Macao)' },
  { key: 'MK', value: 'MK (Macedonia, the former Yugoslav Republic of)' },
  { key: 'MG', value: 'MG (Madagascar)' },
  { key: 'MW', value: 'MW (Malawi)' },
  { key: 'MY', value: 'MY (Malaysia)' },
  { key: 'MV', value: 'MV (Maldives)' },
  { key: 'ML', value: 'ML (Mali)' },
  { key: 'MT', value: 'MT (Malta)' },
  { key: 'MH', value: 'MH (Marshall Islands)' },
  { key: 'MQ', value: 'MQ (Martinique)' },
  { key: 'MR', value: 'MR (Mauritania)' },
  { key: 'MU', value: 'MU (Mauritius)' },
  { key: 'YT', value: 'YT (Mayotte)' },
  { key: 'MX', value: 'MX (Mexico)' },
  { key: 'FM', value: 'FM (Micronesia, Federated States of)' },
  { key: 'MD', value: 'MD (Moldova, Republic of)' },
  { key: 'MC', value: 'MC (Monaco)' },
  { key: 'MN', value: 'MN (Mongolia)' },
  { key: 'ME', value: 'ME (Montenegro)' },
  { key: 'MS', value: 'MS (Montserrat)' },
  { key: 'MA', value: 'MA (Morocco)' },
  { key: 'MZ', value: 'MZ (Mozambique)' },
  { key: 'MM', value: 'MM (Myanmar)' },
  { key: 'NA', value: 'NA (Namibia)' },
  { key: 'NR', value: 'NR (Nauru)' },
  { key: 'NP', value: 'NP (Nepal)' },
  { key: 'NL', value: 'NL (Netherlands)' },
  { key: 'NC', value: 'NC (New Caledonia)' },
  { key: 'NZ', value: 'NZ (New Zealand)' },
  { key: 'NI', value: 'NI (Nicaragua)' },
  { key: 'NE', value: 'NE (Niger)' },
  { key: 'NG', value: 'NG (Nigeria)' },
  { key: 'NU', value: 'NU (Niue)' },
  { key: 'NF', value: 'NF (Norfolk Island)' },
  { key: 'MP', value: 'MP (Northern Mariana Islands)' },
  { key: 'NO', value: 'NO (Norway)' },
  { key: 'OM', value: 'OM (Oman)' },
  { key: 'PK', value: 'PK (Pakistan)' },
  { key: 'PW', value: 'PW (Palau)' },
  { key: 'PS', value: 'PS (Palestinian Territory, Occupied)' },
  { key: 'PA', value: 'PA (Panama)' },
  { key: 'PG', value: 'PG (Papua New Guinea)' },
  { key: 'PY', value: 'PY (Paraguay)' },
  { key: 'PE', value: 'PE (Peru)' },
  { key: 'PH', value: 'PH (Philippines)' },
  { key: 'PN', value: 'PN (Pitcairn)' },
  { key: 'PL', value: 'PL (Poland)' },
  { key: 'PT', value: 'PT (Portugal)' },
  { key: 'PR', value: 'PR (Puerto Rico)' },
  { key: 'QA', value: 'QA (Qatar)' },
  { key: 'RE', value: 'RE (Réunion)' },
  { key: 'RO', value: 'RO (Romania)' },
  { key: 'RU', value: 'RU (Russian Federation)' },
  { key: 'RW', value: 'RW (Rwanda)' },
  { key: 'BL', value: 'BL (Saint Barthélemy)' },
  { key: 'SH', value: 'SH (Saint Helena, Ascension and Tristan da Cunha)' },
  { key: 'KN', value: 'KN (Saint Kitts and Nevis)' },
  { key: 'LC', value: 'LC (Saint Lucia)' },
  { key: 'MF', value: 'MF (Saint Martin (French part))' },
  { key: 'PM', value: 'PM (Saint Pierre and Miquelon)' },
  { key: 'VC', value: 'VC (Saint Vincent and the Grenadines)' },
  { key: 'WS', value: 'WS (Samoa)' },
  { key: 'SM', value: 'SM (San Marino)' },
  { key: 'ST', value: 'ST (Sao Tome and Principe)' },
  { key: 'SA', value: 'SA (Saudi Arabia)' },
  { key: 'SN', value: 'SN (Senegal)' },
  { key: 'RS', value: 'RS (Serbia)' },
  { key: 'SC', value: 'SC (Seychelles)' },
  { key: 'SL', value: 'SL (Sierra Leone)' },
  { key: 'SG', value: 'SG (Singapore)' },
  { key: 'SX', value: 'SX (Sint Maarten (Dutch part))' },
  { key: 'SK', value: 'SK (Slovakia)' },
  { key: 'SI', value: 'SI (Slovenia)' },
  { key: 'SB', value: 'SB (Solomon Islands)' },
  { key: 'SO', value: 'SO (Somalia)' },
  { key: 'ZA', value: 'ZA (South Africa)' },
  { key: 'GS', value: 'GS (South Georgia and the South Sandwich Islands)' },
  { key: 'SS', value: 'SS (South Sudan)' },
  { key: 'ES', value: 'ES (Spain)' },
  { key: 'LK', value: 'LK (Sri Lanka)' },
  { key: 'SD', value: 'SD (Sudan)' },
  { key: 'SR', value: 'SR (Suriname)' },
  { key: 'SJ', value: 'SJ (Svalbard and Jan Mayen)' },
  { key: 'SZ', value: 'SZ (Swaziland)' },
  { key: 'SE', value: 'SE (Sweden)' },
  { key: 'CH', value: 'CH (Switzerland)' },
  { key: 'SY', value: 'SY (Syrian Arab Republic)' },
  { key: 'TW', value: 'TW (Taiwan, Province of China)' },
  { key: 'TJ', value: 'TJ (Tajikistan)' },
  { key: 'TZ', value: 'TZ (Tanzania, United Republic of)' },
  { key: 'TH', value: 'TH (Thailand)' },
  { key: 'TL', value: 'TL (Timor-Leste)' },
  { key: 'TG', value: 'TG (Togo)' },
  { key: 'TK', value: 'TK (Tokelau)' },
  { key: 'TO', value: 'TO (Tonga)' },
  { key: 'TT', value: 'TT (Trinidad and Tobago)' },
  { key: 'TN', value: 'TN (Tunisia)' },
  { key: 'TR', value: 'TR (Turkey)' },
  { key: 'TM', value: 'TM (Turkmenistan)' },
  { key: 'TC', value: 'TC (Turks and Caicos Islands)' },
  { key: 'TV', value: 'TV (Tuvalu)' },
  { key: 'UG', value: 'UG (Uganda)' },
  { key: 'UA', value: 'UA (Ukraine)' },
  { key: 'AE', value: 'AE (United Arab Emirates)' },
  { key: 'GB', value: 'GB (United Kingdom)' },
  { key: 'UM', value: 'UM (United States Minor Outlying Islands)' },
  { key: 'UY', value: 'UY (Uruguay)' },
  { key: 'UZ', value: 'UZ (Uzbekistan)' },
  { key: 'VU', value: 'VU (Vanuatu)' },
  { key: 'VE', value: 'VE (Venezuela, Bolivarian Republic of)' },
  { key: 'VN', value: 'VN (Viet Nam)' },
  { key: 'VG', value: 'VG (Virgin Islands, British)' },
  { key: 'VI', value: 'VI (Virgin Islands, U.S.)' },
  { key: 'WF', value: 'SF (Wallis and Futuna)' },
  { key: 'EH', value: 'EH (Western Sahara)' },
  { key: 'YE', value: 'YE (Yemen)' },
  { key: 'ZM', value: 'ZM (Zambia)' },
  { key: 'ZW', value: 'ZW (Zimbabwe' }
] as const;

// had to remove const assertion due to known yup issue with using oneof/notoneof
// https://github.com/jquense/yup/issues/1230
export const PASSWORD_10K_MOST_COMMON = [
  'password',
  '12345678',
  'baseball',
  'football',
  'jennifer',
  'superman',
  'trustno1',
  'michelle',
  'sunshine',
  '123456789',
  'starwars',
  'computer',
  'corvette',
  'princess',
  'iloveyou',
  'maverick',
  'samantha',
  'steelers',
  'whatever',
  'hardcore',
  'internet',
  'mercedes',
  'bigdaddy',
  'midnight',
  '11111111',
  'marlboro',
  'victoria',
  'butthead',
  'startrek',
  'liverpoo',
  'danielle',
  'redskins',
  'mountain',
  'shithead',
  'xxxxxxxx',
  '88888888',
  'nicholas',
  'metallic',
  'qwertyui',
  'dolphins',
  'cocacola',
  'rush2112',
  'jonathan',
  'scorpion',
  'asdfasdf',
  'godzilla',
  'williams',
  'lifehack',
  'platinum',
  'garfield',
  '69696969',
  'jordan23',
  'bullshit',
  'airborne',
  'elephant',
  'explorer',
  'christin',
  'december',
  'benjamin',
  'dickhead',
  'brooklyn',
  'redwings',
  'michigan',
  '87654321',
  'guinness',
  'einstein',
  'snowball',
  'alexande',
  'passw0rd',
  'lasvegas',
  'slipknot',
  'kimberly',
  '1q2w3e4r',
  'carolina',
  'colorado',
  'creative',
  'bollocks',
  'darkness',
  'asdfghjk',
  'poohbear',
  'nintendo',
  'november',
  'password1',
  'lacrosse',
  'paradise',
  'maryjane',
  'spitfire',
  'anderson',
  'cherokee',
  'drowssap',
  'marshall',
  '1qaz2wsx',
  'caroline',
  'franklin',
  'snickers',
  'courtney',
  'westside',
  'patricia',
  'semperfi',
  'freeuser',
  'babygirl',
  'champion',
  'softball',
  'security',
  'wildcats',
  'veronica',
  'abcd1234',
  'wolverin',
  'remember',
  'freepass',
  'pearljam',
  'mistress',
  'peekaboo',
  'budlight',
  'electric',
  'stargate',
  'brittany',
  'swimming',
  'scotland',
  'swordfis',
  'blink182',
  'virginia',
  'passport',
  'aaaaaaaa',
  'rolltide',
  'bulldogs',
  'liverpool',
  'chevelle',
  'mitchell',
  'spiderma',
  'patriots',
  'cardinal',
  'kawasaki',
  'ncc1701d',
  'airplane',
  'scarface',
  'elizabet',
  'wolfpack',
  'lawrence',
  'american',
  'stingray',
  'simpsons',
  'srinivas',
  'panthers',
  'pussycat',
  'loverboy',
  'tarheels',
  'wolfgang',
  'testtest',
  'michael1',
  'pakistan',
  'infinity',
  'letmein1',
  'hercules',
  'billybob',
  'pavilion',
  'changeme',
  'darkside',
  'zeppelin',
  'darkstar',
  'charlie1',
  'wrangler',
  'qwerty12',
  'bobafett',
  'business',
  'sterling',
  'babydoll',
  'cheyenne',
  'longhorn',
  'presario',
  'mustang1',
  '21122112',
  'q1w2e3r4',
  '12341234',
  'devildog',
  'bluebird',
  'metallica',
  'access14',
  'enterpri',
  'blizzard',
  'asdf1234',
  'harrison',
  'thailand',
  '1234567890',
  'cadillac',
  'hellfire',
  'lonewolf',
  '12121212',
  'fireball',
  'precious',
  'engineer',
  'basketba',
  'valentin',
  'wetpussy',
  'morpheus',
  'hotstuff',
  'fuck_inside',
  'goldberg',
  'wrinkle1',
  'consumer',
  'serenity',
  '99999999',
  'bigboobs',
  'chocolat',
  'christia',
  'birthday',
  'stephani',
  '1234qwer',
  '98765432',
  '77777777',
  'highland',
  'seminole',
  'airforce',
  'hamilton',
  'buckeyes',
  'abcdefgh',
  'goldfish',
  'deftones',
  'icecream',
  'pleasure',
  'juventus',
  'ncc1701e',
  '51505150',
  'cavalier',
  'aardvark',
  'babylon5',
  'savannah',
  'yankees1',
  'fredfred',
  'concrete',
  'shamrock',
  'atlantis',
  'wordpass',
  'predator',
  'marathon',
  'montreal',
  'kathleen',
  'jessica1',
  'diamonds',
  'stallion',
  'letmein2',
  'clitoris',
  'sundance',
  'renegade',
  'hollywoo',
  'hello123',
  'sweetpea',
  'stocking',
  'campbell',
  'christop',
  'rockstar',
  'geronimo',
  'chandler',
  'lovelove',
  'greenday',
  '987654321',
  'creampie',
  'trombone',
  '55555555',
  'mongoose',
  'tottenha',
  'butterfl',
  'clifford',
  'fuckyou2',
  'infantry',
  'skywalke',
  'raistlin',
  'vanhalen',
  'sherlock',
  'dietcoke',
  'ultimate',
  'superfly',
  'freedom1',
  'drpepper',
  'lesbians',
  'musicman',
  'warcraft',
  'microsoft',
  'morrison',
  'isabelle',
  'thuglife',
  'stonecol',
  'logitech',
  'florence',
  '1passwor',
  'bluemoon',
  '22222222',
  'stardust',
  'margaret',
  '66666666',
  'charlott',
  'waterloo',
  '11223344',
  'standard',
  'alexandr',
  'hannibal',
  'frontier',
  'welcome1',
  'spanking',
  'japanese',
  'kristina',
  'deepthroat',
  'bonehead',
  'showtime',
  'squirrel',
  'mustangs',
  'septembe',
  'leonardo',
  'makaveli',
  'vacation',
  'passwor1',
  'columbia',
  'napoleon',
  'motorola',
  'william1',
  'matthew1',
  'robinson',
  'penguins',
  '8j4ye3uz',
  'californ',
  'qwertyuiop',
  'portland',
  'asdfghjkl',
  'overlord',
  'stranger',
  'socrates',
  'spiderman',
  '13131313',
  'national',
  'intrepid',
  'megadeth',
  'bigballs',
  'chargers',
  'discover',
  'isabella',
  'megapass',
  'grateful',
  'mushroom',
  'cristina',
  'hongkong',
  'basketball',
  'satan666',
  'kingkong',
  'penelope',
  'thompson',
  'anything',
  'knickers',
  'playtime',
  'lightnin',
  'slapshot',
  'titleist',
  'werewolf',
  'fernando',
  'blackcat',
  'tacobell',
  'kittycat',
  'thunder1',
  'thankyou',
  'scoobydo',
  'coltrane',
  'lonestar',
  'heather1',
  'beefcake',
  'zzzzzzzz',
  'personal',
  'anthony1',
  'fuckface',
  'lowrider',
  'punkrock',
  'dodgeram',
  'dingdong',
  'qqqqqqqq',
  'johnjohn',
  'asshole1',
  'crusader',
  'syracuse',
  'meridian',
  'turkey50',
  'keyboard',
  'ilovesex',
  'blackman',
  'richmond',
  'sandiego',
  'cooldude',
  'mariners',
  'caliente',
  'fletcher',
  'porsche9',
  'kangaroo',
  'springer',
  'goodtime',
  'chelsea1',
  'freckles',
  'nebraska',
  'webmaster',
  'blueeyes',
  'director',
  'monopoly',
  'blackjac',
  'southern',
  'peterpan',
  'fuckyou1',
  'a1b2c3d4',
  'sentinel',
  'richard1',
  '1234abcd',
  'guardian',
  'candyman',
  'mandingo',
  'munchkin',
  'billyboy',
  'rootbeer',
  'assassin',
  'frederic',
  'giovanni',
  'scarlett',
  'achilles',
  'warriors',
  'plymouth',
  'cameltoe',
  'fuckfuck',
  'sithlord',
  'backdoor',
  'chevrole',
  'lorraine',
  'cosworth',
  'eternity',
  'verbatim',
  'chocolate',
  'deadhead',
  'pineappl',
  'rosemary',
  'porkchop',
  'blackdog',
  'alexander',
  'valhalla',
  'santiago',
  'portugal',
  '1qazxsw2',
  'stripper',
  'sebastia',
  'hurrican',
  '1x2zkg8w',
  'atlantic',
  'hyperion',
  '44444444',
  'skittles',
  'hastings',
  'gangbang',
  'sailboat',
  'immortal',
  'maryland',
  'columbus',
  'beautiful',
  'swordfish',
  'ncc1701a',
  'spartans',
  'threesom',
  'dilligaf',
  'pinkfloy',
  'catalina',
  'formula1',
  'scooter1',
  'colombia',
  'lancelot',
  'angelica',
  'rockhard',
  'poontang',
  'starship',
  'starbuck',
  'catherin',
  'kentucky',
  '33333333',
  '12344321',
  'sapphire',
  'raiders1',
  'excalibu',
  'imperial',
  'phillips',
  'golfball',
  'front242',
  'macdaddy',
  'qwer1234',
  'cowboys1',
  'dannyboy',
  'martinez',
  'aquarius',
  'pppppppp',
  'clarence',
  'eatpussy',
  'beatrice',
  'phillies',
  'research',
  'gggggggg',
  'doughboy',
  'lollipop',
  'qazwsxed',
  'crazybab',
  'brothers',
  'butthole',
  'rightnow',
  'greatone',
  'gateway1',
  'wildfire',
  'jackson1',
  '0.0.0.000',
  'snuggles',
  'phoenix1',
  'technics',
  'gesperrt',
  'brucelee',
  'woofwoof',
  'theodore',
  'richards',
  'punisher',
  'username',
  'bunghole',
  'elizabeth',
  'lifetime',
  'masterbate',
  'diamond1',
  'abnormal',
  'davidson',
  'starfish',
  'penetration',
  'michaela',
  'caligula',
  'railroad',
  'bradford',
  'military',
  'bearbear',
  'patrick1',
  'christine',
  'swinging',
  'labrador',
  'justdoit',
  'meatball',
  'saturday',
  'defender',
  'piercing',
  'microsof',
  'mechanic',
  'robotech',
  'universe',
  'newpass6',
  'hellyeah',
  'zaq12wsx',
  'spectrum',
  'jjjjjjjj',
  'oklahoma',
  'mmmmmmmm',
  'blueblue',
  'wolverine',
  'sniffing',
  'keystone',
  'bbbbbbbb',
  'handsome',
  'tttttttt',
  'ssssssss',
  'somethin',
  'melissa1',
  'marcius2',
  'godsmack',
  'rangers1',
  'deeznuts',
  'kingston',
  'yosemite',
  'tommyboy',
  'masterbating',
  'marianne',
  'happyday',
  'manchest',
  'unbelievable',
  'aberdeen',
  'nathalie',
  'intercourse',
  'supersta',
  'bcfields',
  'hardrock',
  'children',
  'commando',
  'sinclair',
  'squerting',
  'jeanette',
  'meathead',
  'gandalf1',
  'magnolia',
  'kenworth',
  'redalert',
  'homemade',
  'webmaste',
  'insertion',
  'temptress',
  'gretchen',
  'celebrity',
  'ragnarok',
  'trinidad',
  'kingfish',
  'blackhaw',
  'thursday',
  'meatloaf',
  'interacial',
  'streaming',
  'pertinant',
  'pool6123',
  'animated',
  'gordon24',
  'fantasies',
  'touching',
  'homepage',
  'ejaculation',
  'whocares',
  'jamesbon',
  'amsterda',
  'february',
  'luckydog',
  'businessbabe',
  'brandon1',
  'experience',
  'software',
  'thirteen',
  'rasputin',
  'greenbay',
  'pa55word',
  'contortionist',
  'sneakers',
  'sonyfuck',
  'test1234',
  'roadkill',
  'cheerleaers',
  'madeline',
  'christian',
  'brighton',
  'housewifes',
  'emmanuel',
  'bigmoney',
  'seductive',
  'sexygirl',
  'canadian',
  'gangbanged',
  'crawford',
  'hotpussy',
  'implants',
  'intruder',
  'andyod22',
  'barcelon',
  'chainsaw',
  'chickens',
  'downtown',
  'magicman',
  'clevelan',
  'designer',
  'budweise',
  'experienced',
  'pitchers',
  'passwords',
  'jeremiah',
  'alliance',
  'halflife',
  'saratoga',
  'positive',
  'transexual',
  'close-up',
  'sunnyday',
  'starfire',
  'pictuers',
  'testing1',
  'tiberius',
  'lisalisa',
  'golfgolf',
  'flounder',
  'majestic',
  'trailers',
  'mikemike',
  'whitesox',
  'angelina',
  'goodluck',
  'charlton',
  'fingerig',
  'gallaries',
  'lockerroom',
  'treasure',
  'absolutely',
  'homepage-',
  'beerbeer',
  'testerer',
  'fordf150',
  'pa55w0rd',
  'kamikaze',
  'japanees',
  'masterbaiting',
  'callaway',
  'panasoni',
  'housewife',
  '18436572',
  'sullivan',
  'terrapin',
  'masturbation',
  'hardcock',
  'freeporn',
  'pornographic',
  'traveler',
  'moneyman',
  'shopping',
  'thumbnils',
  'amateurs',
  'apollo13',
  'goldwing',
  'doghouse',
  'pounding',
  'truelove',
  'underdog',
  'wrestlin',
  'sherwood',
  'johannes',
  'balloons',
  'happy123',
  'flamingo',
  'paintbal',
  'llllllll',
  'twilight',
  'christie',
  'bullseye',
  'knickerless',
  'binladen',
  'peterson',
  'thanatos',
  'albatros',
  'getsdown',
  'nwo4life',
  'underwear',
  'dddddddd',
  'deeznutz',
  'enterprise',
  'misfit99',
  'solution',
  'meredith',
  'barefoot',
  '50spanks',
  'scandinavian',
  'original',
  'shannon1',
  'techniques',
  'chemical',
  'salvador',
  'manchester',
  'buckshot',
  'thegreat',
  'goldstar',
  'triangle',
  'kristine',
  'snowboar',
  'penetrating',
  'roadking',
  'rockford',
  'chicago1',
  'ferrari1',
  'galeries',
  'godfathe',
  'gargoyle',
  'gangster',
  'pussyman',
  'pooppoop',
  'newcastl',
  'mortgage',
  'snoopdog',
  'assholes',
  'property',
  'broadway',
  'butterfly',
  'earthlink',
  'westwood',
  'blackbir',
  'slippery',
  'pianoman',
  'tomorrow',
  'roadrunn',
  'attitude',
  'seahawks',
  'tunafish',
  'cinnamon',
  'northern',
  '23232323',
  'zerocool',
  'limewire',
  'films+pic+galeries',
  'francois',
  'fuckthis',
  'girfriend',
  'uncencored',
  'chrisbln',
  'netscape',
  'hhhhhhhh',
  'knockers',
  'tazmania',
  'pharmacy',
  'arsenal1',
  'anaconda',
  'australi',
  'gotohell',
  'bulldog1',
  'monalisa',
  'whiteout',
  'james007',
  'bitchass',
  'southpar',
  'lionking',
  'megatron',
  'hawaiian',
  'gymnastic',
  'panther1',
  'wp2003wp',
  'passwort',
  'friendly',
  'oooooooo',
  'bullfrog',
  'holyshit',
  'jasmine1',
  'sergeant',
  'babyblue',
  'pass1234',
  'poseidon',
  'confused',
  'hollywood',
  'insertions',
  'juliette',
  'hayabusa',
  'hawkeyes',
  'geoffrey',
  'chuckles',
  'hounddog',
  'philippe',
  'thunderb',
  'marino13',
  'handyman',
  'cerberus',
  'gamecock',
  'magician',
  'preacher',
  'chrysler',
  'contains',
  'hedgehog',
  'hoosiers',
  'dutchess',
  'wareagle',
  'ihateyou',
  'sunflowe',
  'senators',
  'terminal',
  'laurence',
  'maradona',
  'america1',
  'chicken1',
  'passpass',
  'r2d2c3po',
  'myxworld',
  'missouri',
  'wishbone',
  'infiniti',
  'wonderboy',
  'stanford',
  'smeghead',
  'titanium',
  'charlene',
  'fishing1',
  'fullmoon',
  'absolute',
  'seinfeld',
  'pingpong',
  'matthews',
  'recovery',
  'babyface',
  'gladiato',
  'paranoid',
  'packers1',
  'longjohn',
  'clarinet',
  'mortimer',
  'modelsne',
  'vladimir',
  'together',
  'avalanch',
  '55bgates',
  'cccccccc',
  'paradigm',
  'operator',
  'valencia',
  'cocksuck',
  'creature',
  'borussia',
  'browning',
  'heritage',
  'millions',
  'starcraf',
  'spaceman',
  'chester1',
  'rrrrrrrr',
  'sandwich',
  'magazine',
  'buttfuck',
  'yeahbaby',
  '11235813',
  'bangbang',
  'charles1',
  'ffffffff',
  'doberman',
  'overkill',
  'claymore',
  'brewster',
  'electron',
  'eastside',
  'minimoni',
  'wildbill',
  'wildcard',
  'yyyyyyyy',
  'sweetnes',
  'skywalker',
  'alphabet',
  'babybaby',
  'graphics',
  'florida1',
  'flexible',
  'fuckinside',
  'ursitesux',
  'christma',
  'wwwwwwww',
  'just4fun',
  'rebecca1',
  'adrienne',
  '19691969',
  'silverad',
  'rhiannon',
  '10101010',
  'ashleigh',
  'qwerasdf',
  'presiden',
  'newyork1',
  'brigitte',
  'buddyboy',
  'heineken',
  'millwall',
  'beautifu',
  'sinister',
  'smashing',
  'teddybea',
  'ticklish',
  'lipstick',
  'reynolds',
  'applepie',
  'digital1',
  'dinosaur',
  'icehouse',
  'insanity',
  'bluefish',
  'strength',
  'sentnece',
  'temppass',
  'medicine',
  'hahahaha',
  'casanova',
  'fountain',
  'dolphin1',
  'porsche1',
  'vampires',
  'highheel',
  'kkkkkkkk',
  'illinois',
  '21212121',
  'stonecold',
  'testpass',
  'jiggaman',
  'federico',
  'scorpio1',
  'rt6ytere',
  'madison1',
  'coolness',
  'christina',
  'coldbeer',
  'brittney',
  'washingt',
  'stephanie',
  'shepherd',
  'tiffany1',
  'mephisto',
  'dragonba',
  'nygiants',
  'password2',
  'corleone',
  'kittykat',
  'vikings1',
  'splinter',
  'pipeline',
  'meowmeow',
  'chestnut',
  'longdong',
  'quant4307s',
  'eastwood',
  'moonligh',
  'illusion',
  'jayhawks',
  'swingers',
  'stefanie',
  'jefferso',
  'michael2',
  'fastball',
  'scrabble',
  'dirtbike',
  'customer',
  'nemrac58',
  'bobdylan',
  'hopeless',
  'kcj9wx5n',
  'killbill',
  'volkswag',
  'windmill',
  'iloveyou1',
  'starligh',
  'soulmate',
  'mcdonald',
  'rochelle',
  'oblivion',
  'valkyrie',
  'concorde',
  'costello',
  'delaware',
  'nocturne',
  'herewego',
  'earnhard',
  'eeeeeeee',
  'mobydick',
  'reddevil',
  'reckless',
  'radiohea',
  'coolcool',
  'classics',
  'choochoo',
  'wireless',
  'bigblock',
  'summer99',
  'sexysexy',
  'platypus',
  'telephon',
  '12qwaszx',
  'fishhead',
  'paramedi',
  'lonesome',
  'katherin',
  'moonbeam',
  'monster1',
  'monkeybo',
  'windsurf',
  '31415926',
  'sebastian',
  'smoothie',
  'snowflak',
  'playstat',
  'playboy1',
  'roadster',
  'hardware',
  'captain1',
  'undertak',
  'uuuuuuuu',
  'criminal',
  '1a2b3c4d',
  'thedoors',
  'annabell',
  'catwoman',
  'faithful',
  'farscape',
  'genesis1',
  'pumpkins',
  'training',
  'islander',
  'jamesbond',
  '19841984',
  'shitface',
  'maxwell1',
  'armstron',
  'alejandr',
  'augustus',
  'care1839',
  'fantasia',
  'freefall',
  'sandrine',
  'qwerqwer',
  'crystal1',
  'nineinch',
  'broncos1',
  'winston1',
  'warrior1',
  'iiiiiiii',
  'iloveyou2',
  'straight',
  'specialk',
  'tinkerbe',
  'jellybea',
  'cbr900rr',
  'gabriell',
  'gertrude',
  'glennwei',
  'sausages',
  'vanguard',
  'trinitro',
  'eldorado',
  'whiskers',
  'wildwood',
  'istheman',
  'interest',
  '25802580',
  'woodland',
  'strawber',
  'amsterdam',
  'catherine',
  'football1',
  'vancouve',
  'vauxhall',
  'acidburn',
  'myspace1',
  'buttercu',
  'minemine',
  'bigpoppa',
  'blackout',
  'blowfish',
  'talisman',
  'sundevil',
  'shanghai',
  'spencer1',
  'slowhand',
  'jonathon',
  'michaels',
  'resident',
  'redbaron',
  'andromed',
  'harddick',
  '5wr2i7h8',
  'charlotte',
  'fredrick',
  'francesc',
  'ferguson',
  'fairlane',
  'dogpound',
  'pornporn',
  'clippers',
  'daylight',
  'nnnnnnnn',
  'budapest',
  'whistler',
  'whatwhat',
  'wanderer',
  'idontkno',
  'thisisit',
  'robotics',
  'gonzalez',
  'drummer1',
  'private1',
  'cornwall',
  'christopher',
  'corvet07',
  'iverson3',
  'bluesman',
  'terminat',
  'johnson1',
  'bastards',
  'fuckoff1',
  'doomsday',
  'pornking',
  'bookworm',
  'highbury',
  'mischief',
  'ministry',
  'bigbooty',
  'yogibear',
  'september',
  'lkjhgfds',
  '123123123',
  'carpedie',
  'foxylady',
  'gatorade',
  'valdepen',
  'deadpool',
  'hotmail1',
  'kordell1',
  'vvvvvvvv',
  'jackson5',
  'bergkamp',
  'zanzibar',
  'services',
  'sheridan',
  'checkers',
  'luv2epus',
  'rainbow6',
  'qwerty123',
  'commande',
  'nightwin',
  'hotmail0',
  'enternow',
  'viewsoni',
  'berkeley',
  'woodstoc',
  'starstar',
  'patience',
  'hawaii50',
  'gorgeous',
  'challeng',
  'callisto',
  'firewall',
  'firefire',
  'passmast',
  'transfer',
  'clarissa',
  'moonshin',
  'jakejake',
  'bluejays',
  'southpark',
  'tomahawk',
  'leedsutd',
  'jermaine',
  'jeepster',
  'josephin',
  'matthias',
  'marriage',
  'antelope',
  'cabernet',
  'cheshire',
  'california',
  'fuckhead',
  'dominion',
  'trucking',
  'nostromo',
  'honolulu',
  'dynamite',
  'mollydog',
  'windows1',
  'washburn',
  'vincent1',
  'irishman',
  'bearcats',
  'sylveste',
  'marijuan',
  'reddwarf',
  '12312312',
  'hardball',
  'goldfing',
  'chambers',
  'fandango',
  'festival',
  'scrapper',
  'cromwell',
  'entrance',
  'klondike',
  'mohammed',
  'insomnia',
  '24682468',
  '24242424',
  'billbill',
  'blessing',
  'solitude',
  'pimpdadd',
  'johndeer',
  'babylove',
  'barbados',
  'carpente',
  'fishbone',
  'fireblad',
  'scissors',
  'screamer',
  'obsidian',
  'progress',
  'tottenham',
  'comanche',
  'monsters',
  'veronika',
  '20202020',
  'blueball',
  'yankees2',
  'wrestler',
  'sealteam',
  'sidekick',
  'smackdow',
  'sporting',
  'remingto',
  'arkansas',
  'andersen',
  'barcelona',
  'baltimor',
  'fortress',
  'fishfish',
  'firefigh',
  'rsalinas',
  'dontknow',
  'universa',
  'heinrich',
  'enforcer',
  'katherine',
  'waterboy',
  '23skidoo',
  'zildjian',
  'stoppedby',
  'sexybabe',
  'speakers',
  'polopolo',
  'perfect1',
  'thrasher',
  'lakeside',
  'masamune',
  'cherries',
  'chipmunk',
  'cezer121',
  'carnival',
  'fearless',
  'funstuff',
  'salasana',
  'pantera1',
  'qwert123',
  'creation',
  'nascar24',
  'estrella',
  'erection',
  'ericsson',
  'internal',
  '1michael',
  '19781978',
  '25252525',
  'sheepdog',
  'snowbird',
  'toriamos',
  'tennesse',
  'mazdarx7',
  'revolver',
  'babycake',
  'hallowee',
  'cannabis',
  'dolemite',
  'dodgers1',
  'painting',
  'coventry',
  'christmas',
  'cocksucker',
  'hotgirls',
  'eggplant',
  'mustang6',
  'monkey12',
  'wapapapa',
  'volleyba',
  'birthday4',
  'stephen1',
  'suburban',
  'soccer10',
  'something',
  'starcraft',
  'soccer12',
  'plastics',
  'penthous',
  'peterbil',
  'lakewood',
  'reginald',
  'goodgirl',
  'gotyoass',
  'capricor',
  'getmoney',
  'godfather',
  'gilligan',
  'dudedude',
  'pasadena',
  'opendoor',
  'magellan',
  'printing',
  'pressure',
  'killkill',
  'whiteboy',
  'voyager1',
  'jackjack',
  'success1',
  'spongebo',
  'phialpha',
  'password9',
  'tickling',
  'lexingky',
  'redheads',
  'apple123',
  'backbone',
  'aviation',
  'green123',
  'carlitos',
  'cartman1',
  'camaross',
  'favorite6',
  'ginscoot',
  'sabrina1',
  'devil666',
  'doughnut',
  'paintball',
  'rainbow1',
  'umbrella',
  'abc12345',
  'complete',
  'deerhunt',
  'darklord',
  'holidays',
  'hetfield',
  'hillbill',
  'hugetits',
  'evolutio',
  'whiplash',
  'wg8e3wjf',
  'istanbul',
  'bluebell',
  'wrestling',
  'superior',
  'suckdick',
  'stephane',
  'playball',
  'marcello',
  'marjorie',
  'rockwell',
  'baritone',
  'gladiator',
  'cricket1',
  'clemente',
  'exchange',
  'kisskiss',
  'kristian',
  'montecar',
  'mississi',
  'washington',
  '20012001',
  'bigdick1',
  'penguin1',
  'pathfind',
  'testibil',
  'lightning',
  'lighting',
  'republic',
  'anthony7',
  'goldeney',
  'cameron1',
  'freefree',
  'screwyou',
  'passthie',
  'postov1000',
  'puppydog',
  'a1234567',
  'cleopatr',
  'contract',
  'buffalo1',
  'bordeaux',
  'sunlight',
  'sprinter',
  'peaches1',
  'pinetree',
  'theforce',
  'jupiter1',
  'mckenzie',
  'annmarie',
  'austin31',
  '78945612',
  'calimero',
  'chevrolet',
  'favorite',
  'fellatio',
  'f00tball',
  'francine',
  'gateway2',
  'gamecube',
  'giovanna',
  'scheisse',
  'offshore',
  'macaroni',
  'pringles',
  'trouble1',
  'coolhand',
  'colonial',
  'darthvad',
  'cygnusx1',
  'natalie1',
  'eighteen',
  'elcamino',
  'blueberr',
  'yamahar1',
  'stafford',
  'snowboard',
  'speedway',
  'playboy2',
  'toonarmy',
  'mariposa',
  'baberuth',
  'gonzales',
  'chiquita',
  'charisma',
  'capslock',
  'cashmone',
  'gizmodo1',
  'dragonfl',
  'rachelle',
  'tropical',
  'crescent',
  'nathanie',
  'espresso',
  'kikimora',
  '20002000',
  'birthday1',
  'beatles1',
  'bigdicks',
  'beethove',
  'blacklab',
  'woodwork',
  'survivor',
  'pinnacle',
  'lemonade',
  'lalakers',
  'lebowski',
  'lalalala',
  'mercury1',
  'rocknrol',
  'riversid',
  '11112222',
  'alleycat',
  'ambrosia',
  'australia',
  'hattrick',
  'cassandr',
  'charlie123',
  'fighting',
  'gabriela',
  'outoutout',
  'pussy123',
  'randolph',
  'coldplay',
  'novifarm',
  'notredam',
  'honeybee',
  'wednesda',
  'waterfal',
  'billabon',
  'zachary1',
  '01234567',
  'superstar',
  'stiletto',
  'sigmachi',
  'somerset',
  'smithers',
  'playmate',
  'pinkfloyd',
  'laetitia',
  'revoluti',
  'archange',
  'handball',
  'chewbacc',
  'fullback',
  'dominiqu',
  'mandrake',
  'vagabond',
  'csfbr5yy',
  'deadspin',
  'ncc74656',
  'houston1',
  'hurricane',
  'horseman',
  'virginie',
  'idontknow',
  '151nxjmt',
  'bendover',
  'surprise',
  'supernov',
  'phantom1',
  'playoffs',
  'johngalt',
  'maserati',
  'riffraff',
  'architec',
  'cambridg',
  'foreplay',
  'sanity72',
  'salesman',
  'dreaming',
  'palmtree',
  'luckyone',
  'treefrog',
  'usmarine',
  'darkange',
  'cyclones',
  'bubba123',
  'building',
  'eclipse1',
  'kayleigh',
  'mustang2',
  'bigtruck',
  'yeahyeah',
  'stickman',
  'skipper1',
  'singapor',
  'southpaw',
  'slamdunk',
  'therock1',
  'tiger123',
  'mccarthy',
  '13576479',
  'greywolf',
  'candyass',
  'catfight',
  'frankie1',
  'qazwsxedc',
  'pregnant',
  'death666',
  'negative',
  'hooligan',
  'everlast',
  'mulligan',
  'motocros',
  'waterman',
  'inspiron',
  'bigblack',
  'zaq1xsw2',
  'yy5rbfsc',
  'takehana',
  'skydiver',
  'special1',
  'slimshad',
  'sopranos',
  'patches1',
  'thething',
  'mash4077',
  'matchbox',
  '14789632',
  'amethyst',
  'baseball1',
  'greenman',
  'goofball',
  'castillo',
  'capitals',
  'favorite2',
  'forsaken',
  'feelgood',
  'gfxqx686',
  'dilbert1',
  'dukeduke',
  'downhill',
  'longhair',
  'lockdown',
  'mamacita',
  'rainyday',
  'pumpkin1',
  'prospect',
  'rainbows',
  'trinity1',
  'trooper1',
  'citation',
  'bukowski',
  'bubbles1',
  'humphrey',
  'kcchiefs',
  'morticia',
  'montrose',
  '154ugeiu',
  'year2005',
  'wonderfu',
  'tampabay',
  'slapnuts',
  'spartan1',
  'sprocket',
  'sometime',
  'stanley1',
  'thinking',
  'lavalamp',
  'laserjet',
  'jediknig',
  'mazda626',
  'alexandra',
  'hairball',
  'graduate',
  'cartoons',
  'cashflow',
  'outsider',
  'mallrats',
  'primetime21',
  'valleywa',
  'abcdefg1',
  'natedogg',
  'nineball',
  'normandy',
  'nicetits',
  'buddy123',
  'highlife',
  'earthlin',
  'eatmenow',
  'kirkland',
  'money123',
  'warhamme',
  'instinct',
  'jackass1',
  '20spanks',
  'blackjack',
  '085tzzqi',
  '383pdjvl',
  'sparhawk',
  'pavement',
  'johnston',
  'material',
  'melanie1',
  'redlight',
  'aolsucks',
  'alexalex',
  'b929ezzh',
  'goodyear',
  'griffith',
  '863abgsg',
  'carebear',
  'checkmat',
  'forgetit',
  'rushmore',
  'question',
  'ptfe3xxp',
  'prophecy',
  'aircraft',
  'access99',
  'cocktail',
  'civilwar',
  'cleveland',
  'claudia1',
  'dapzu455',
  'daisydog',
  'eldiablo',
  'kingrich',
  'mudvayne',
  'vipergts',
  'italiano',
  'innocent',
  'yqlgr667',
  'zxcvbnm1',
  'suckcock',
  'stephens',
  '380zliki',
  'sexylady',
  'sixtynin',
  'sleeping',
  'sparkles',
  'letsdoit',
  'landmark',
  'marauder',
  'register',
  'basebal1',
  'azertyui',
  'hawkwind',
  'capetown',
  'flathead',
  'fisherma',
  'flipmode',
  'gabriel1',
  'dreamcas',
  'dirtydog',
  'dickdick',
  'destiny1',
  'trumpet1',
  'aaaaaaa1',
  'conquest',
  'creepers',
  'constant',
  'cornhole',
  'nirvana1',
  'elisabet',
  'musician',
  'milamber',
  'isacs155',
  '1million',
  '1letmein',
  'stonewal',
  'sexsexsex',
  'sonysony',
  'smirnoff',
  'pentagon',
  'paulpaul',
  'lighthou',
  'letmein22',
  'letmesee',
  'merchant',
  'redstorm',
  '14141414',
  'allison1',
  'basement',
  'hartford',
  'hardwood',
  'fatluvr69',
  'fidelity',
  'feathers',
  'gogators',
  'general1',
  'dragon69',
  'dragonball',
  'papillon',
  'optimist',
  'longshot',
  'undertow',
  'copenhag',
  'delldell',
  'culinary',
  'ibilltes',
  'hihje863',
  'envelope',
  'express1',
  'mustang5',
  'wellingt',
  'waterski',
  'infinite',
  'iloveyou!',
  '063dyjuy',
  'survival',
  'stockton',
  'softtail',
  'slimed123',
  'pizzaman',
  'pathetic',
  'tigercat',
  'jennings',
  'rootedit',
  'riverrat',
  'atreides',
  'happines',
  'chadwick',
  'ffvdj474',
  'foreskin',
  'gameover',
  'scoobydoo',
  'saxophon',
  'macintos',
  'lollypop',
  'qwertzui',
  'adelaide',
  'acapulco',
  'cybersex',
  'davecole',
  'davedave',
  'nineteen',
  'highlander',
  'kristin1',
  'knuckles',
  'katarina',
  'montana1',
  'wingchun',
  'watching',
  'illmatic',
  'bigpenis',
  'blue1234',
  'xxxxxxx1',
  'svetlana',
  '368ejhih',
  'playstation',
  'pescator',
  'jo9k2jw2',
  'jupiter2',
  'jurassic',
  'marines1',
  '14725836',
  '12345679',
  'alessand',
  'angelika',
  'alpha123',
  'barefeet',
  'badabing',
  'gsxr1000',
  'gregory1',
  '766rglqy',
  '69camaro',
  'calendar',
  'fishcake',
  'giuseppe',
  'gnasher23',
  'fuzzball',
  'save13tx',
  'russell1',
  'dripping',
  'dragon12',
  'dragster',
  'mainland',
  'poophead',
  'porn4life',
  'producer',
  'rapunzel',
  'velocity',
  'vanessa1',
  'trueblue',
  'vampire1',
  'navyseal',
  'nightowl',
  'nonenone',
  'nightmar',
  'bulletin',
  'hillside',
  'hzze929b',
  'hellohel',
  'edgewise',
  'embalmer',
  'excalibur',
  'mounta1n',
  'muffdive',
  'vivitron',
  'winfield',
  'wednesday',
  '17171717',
  '17011701',
  'tangerin',
  'stewart1',
  'summer69',
  'sweetness',
  'surveyor',
  'stirling',
  'ssptx452',
  'thriller',
  'master12',
  'anastasi',
  'almighty',
  'argentin',
  'flanders',
  'flyers88',
  'firehawk',
  'flashman',
  'godspeed',
  'giveitup',
  'funtimes',
  'frenchie',
  'disaster',
  'lovelife',
  'qcmfd454',
  'undertaker',
  '911turbo',
  'cristian',
  'daughter',
  'notebook',
  'borabora',
  'brisbane',
  'mohammad',
  'bettyboo',
  'blackice',
  'yvtte545',
  'tailgate',
  'shitshit',
  'sooners1',
  'smartass',
  'pennywis',
  'thetruth',
  'reindeer',
  'allstate',
  'greatest',
  'caldwell',
  'fussball',
  'geneviev',
  'samadams',
  'dipstick',
  'losangel',
  'loverman',
  'pussy4me',
  'university',
  'troubles',
  'churchil',
  'crazyman',
  'cutiepie',
  'bullwink',
  'bulldawg',
  'horsemen',
  'escalade',
  'minnesot',
  'moonlight',
  'mwq6qlzo',
  'verygood',
  'bellagio',
  'sickness',
  'skeeter1',
  'phaedrus',
  'thumper1',
  'tmjxn151',
  'thematri',
  'letmeinn',
  'jeffjeff',
  'johnmish',
  '11001001',
  'allnight',
  'amatuers',
  'attorney',
  'happyman',
  'graywolf',
  '474jdvff',
  '551scasi',
  'fishtank',
  'freewill',
  'glendale',
  'frogfrog',
  'gerhardt',
  'scirocco',
  'devilman',
  'pallmall',
  'lunchbox',
  'manhatta',
  'mandarin',
  'pxx3eftp',
  'president',
  'chris123',
  'daedalus',
  'natasha1',
  'nancy123',
  'nevermin',
  'newcastle',
  'edmonton',
  'monterey',
  'violator',
  'wildstar',
  'winter99',
  'iqzzt580',
  '19741974',
  '1q2w3e4r5t',
  'bigbucks',
  'blackcoc',
  'yesterda',
  'skinhead',
  'shadow12',
  'snapshot',
  'soccer11',
  'pleasant',
  'pimpdaddy',
  'lionhear',
  'littlema',
  'lincoln1',
  'laughing',
  'redshift',
  '12locked',
  'arizona1',
  'alfarome',
  'hawthorn',
  'goodfell',
  '554uzpad',
  'flipflop',
  'garrison',
  'rustydog',
  'sandberg',
  'samsung1',
  'dreamer1',
  'detectiv',
  'dominick',
  'paladin1',
  'papabear',
  'panasonic',
  'nyyankee',
  'pussyeat',
  'princeto',
  'climbing',
  'dad2ownu',
  'daredevi',
  'necklace',
  'huskers1',
  'hornyman',
  'england1',
  'motherfucker',
  'ilovegod',
  '201jedlz',
  'wrinkle5',
  'zoomzoom',
  '09876543',
  'starlite',
  'peternorth',
  'jeepjeep',
  'joystick',
  'junkmail',
  'jojojojo',
  'rockrock',
  'rasta220',
  'andyandy',
  'auckland',
  'gooseman',
  'happydog',
  'charlie2',
  'cardinals',
  'fortune12',
  'generals',
  'division',
  'ozlq6qwm',
  'macgyver',
  'mallorca',
  'prelude1',
  'trousers',
  'aerosmit',
  'clueless',
  'delpiero',
  'nounours',
  'buckaroo',
  'honeydew',
  'hooters1',
  'hugohugo',
  'evangeli'];
