import axios, { AxiosResponse } from 'axios';

import { BaseIpShape } from '../../types/formTypes';

export interface SourceIpQueryResponse {
  ipAddresses: string;
  changed: boolean;
  description?: string;
  reason?: 'CHANGE_IN_PROGRESS' | 'SUBMIT_CHANGE';
  requestType: 'CHANGE_IN_PROGRESS' | 'SUBMIT_CHANGE';
}

export interface SubmitSourceIpPayload {
  ipAddresses: BaseIpShape[];
}

export const getCidr = (): Promise<AxiosResponse<SourceIpQueryResponse>> => axios.get('/api/getCidr');
export const submitSourceIp = (
  submitSourceIpObject: SubmitSourceIpPayload
): Promise<AxiosResponse<SourceIpQueryResponse>> => axios.post('/api/post/submitCIDR', submitSourceIpObject);
