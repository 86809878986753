import axios, { AxiosResponse } from 'axios';

interface RootCaQueryResponse {
  status: 0 | 10 | 20 | 30;
  autoShutdownDate?: string;
}

interface RootCaReturnStatus {
  status: 0 | 10 | 20 | 30,
  initAction: string,
  autoShutdownDate: string
}

interface StopRootCaMutationResponse {
  reason?: string, // TODO - more specific for these?
  initAction?: string
}
interface StartRootCaMutationResponse extends StopRootCaMutationResponse {
  retStatus: 200,
  retObj: RootCaReturnStatus
}

export const submitStartRootCa = (): Promise<AxiosResponse<StartRootCaMutationResponse>> => axios.post('/api/post/startRootCA');
export const submitStopRootCa = (): Promise<AxiosResponse<StopRootCaMutationResponse>> => axios.post('/api/post/stopRootCA');
export const submitRefreshRootCaStatus = (): Promise<AxiosResponse<RootCaQueryResponse>> => axios.post('/api/post/refreshRootCAStatus');
