import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

import { useLoggedInUserQuery } from '../../../../../api/reactQuery/queries';
import { SyslogListResponse } from '../../../../../api/Logging/LoggingRequests';
import { TlsProtocols } from '../../../../../util/constants';

const disabledTextColor = {
  color: 'text.disabled'
};

export interface TableHeadersObject {
  key: string;
  label: string
}

interface SyslogTableContentProps {
  onClickRemoveEntry: (entries: SyslogListResponse) => void,
  displayedSyslog: SyslogListResponse[],
  isLoading: boolean,
  tableHeaders: TableHeadersObject[],
  protocolToText: (protocol: TlsProtocols) => string
}

const SyslogTableContent = ({
  onClickRemoveEntry,
  displayedSyslog,
  isLoading,
  tableHeaders,
  protocolToText
}: SyslogTableContentProps) => {
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const userPrivs = loggedInUserData?.user?.privileges;

  return (
    <>
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((headerEntry) => (
                  <TableCell key={headerEntry.key}>
                    {headerEntry.label}
                  </TableCell>
                ))}
                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedSyslog.map((entries) => (
                <TableRow key={`${entries.ipdns}-${entries.loggerPodLabel}`}>
                  <TableCell sx={entries.pending ? disabledTextColor : null}>
                    {entries.appType}
                  </TableCell>
                  <TableCell sx={entries.pending ? disabledTextColor : null}>
                    {entries.ipdns}
                  </TableCell>
                  <TableCell sx={entries.pending ? disabledTextColor : null}>
                    {entries.loggerPodLabel}
                  </TableCell>
                  <TableCell sx={entries.pending ? disabledTextColor : null}>
                    {protocolToText(entries.protocol)}
                  </TableCell>
                  <TableCell sx={entries.pending ? disabledTextColor : null}>
                    {entries.credentialName ? entries.credentialName : 'N/A'}
                  </TableCell>
                  <TableCell sx={entries.pending ? disabledTextColor : null}>
                    {entries.port}
                  </TableCell>
                  <TableCell align="right" sx={entries.pending ? disabledTextColor : null}>
                    {!userPrivs?.LOGGERS_WRITE || entries.pending ? (
                      <Tooltip
                        title={
                          entries.pendingAction === 'ADD'
                            ? 'Add in progress'
                            : entries.pendingAction === 'REMOVE' // TODO: check this string
                              ? 'Removal in progress'
                              : 'Insufficient privileges'
                        }
                      >
                        <span>
                          <IconButton disabled={true} size="large">
                            <RemoveCircleOutlineOutlinedIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <IconButton
                        onClick={() => {
                          onClickRemoveEntry(entries);
                        }}
                        size="large"
                      >
                        <RemoveCircleOutlineOutlinedIcon color="primary" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default SyslogTableContent;
