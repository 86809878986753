import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import { useDeploymentState } from '../../../api/reactQuery/queries';

const AcctDeployingBackdrop = () => {
  const deploymentState = useDeploymentState();
  const [displayBackdrop, setDisplayBackdrop] = useState(false);

  useEffect(() => {
    if (!deploymentState.isSuccess) {
      return;
    }
    if (deploymentState?.data.entState === 30) {
      setDisplayBackdrop(true);
    } else {
      setDisplayBackdrop(false);
    }
  }, [deploymentState.isSuccess, deploymentState.data]);

  return (
    <Backdrop open={displayBackdrop} sx={{ zIndex: 1 }}>
      <Grid container={true} alignItems="center" justifyContent="center">
        <Grid item={true}>
          <CircularProgress />
        </Grid>
        <Grid item={true}>
          <Typography variant="h5">
            &nbsp; Provisioning in progress. Please check back later.
          </Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default AcctDeployingBackdrop;
