import React from 'react';
import { useQueryClient } from 'react-query';
import { useTheme } from '@mui/material/styles';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import {
  Box,
  Button,
  useMediaQuery,
  IconButton
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import FeedbackDialog from '../../Dialogs/FeedbackDialog';
import Copyright from './Copyright';
import { SESSION_EXTEND_QUERY } from '../../../util/constants';

const PortalFooter = () => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const [openDialog, setDialogOpen] = React.useState(false);

  const handleDialogClose = () => {
    queryClient.invalidateQueries(SESSION_EXTEND_QUERY);
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    queryClient.invalidateQueries(SESSION_EXTEND_QUERY);
    setDialogOpen(true);
  };

  const { pathname } = useLocation();

  return (
    <>
      <FeedbackDialog
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        pageName={pathname}
      />
      <Box display="flex" justifyContent="space-between" p="0 16px 10px">
        {/* used as spacer */}
        <Box width={128} />
        <Box>
          <Copyright />
        </Box>
        <Box display="flex" width={128} justifyContent="flex-end">
          {matchesSmall
            ? (
              <Button
                type="button"
                variant="outlined"
                color="primary"
                endIcon={<FeedbackOutlinedIcon />}
                onClick={handleDialogOpen}
              >
                Feedback
              </Button>
            )
            : (
              <IconButton color="primary" onClick={handleDialogOpen}>
                <FeedbackOutlinedIcon />
              </IconButton>
            )}

        </Box>
      </Box>
    </>
  );
};
export default PortalFooter;
