import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useEjbcaUrl from '../../../util/useEjbcaUrl';
import CopyToClipboard from '../../../util/CopyToClipboard';
import EXTERNAL_LINKS from '../../../util/externalLinks';

const EjbcaLinks = () => {
  const issuingCAClusterArray = [
    {
      title: 'EJBCA Admin Web',

      url: useEjbcaUrl('https', 'issuing', '/ejbca/adminweb')
    },
    {
      title: 'EJBCA RA Web',

      url: useEjbcaUrl('https', 'issuing', '/ejbca/ra')
    },
    {
      title: 'EJBCA Public Web',
      url: useEjbcaUrl('https', 'issuing', '/ejbca')
    }
  ];

  const ejbcaRootCAArray = [
    {
      title: 'EJBCA Root CA Admin Web',
      url: useEjbcaUrl('https', 'root', '/ejbca/adminweb')
    }
  ];

  const crlsArray = [
    {
      title: 'Issuing CA',
      url: useEjbcaUrl('http', 'issuing', '/ejbca/publicweb/crls')
    },
    {
      title: 'Root CA',
      url: useEjbcaUrl('http', 'root', '/ejbca/publicweb/crls')
    }
  ];
  const ocspArray = [
    {
      title: 'Issuing CA',
      url: useEjbcaUrl('http', 'issuing', '/ejbca/publicweb/status/ocsp')
    },
    {
      title: 'Root CA',
      url: useEjbcaUrl('http', 'root', '/ejbca/publicweb/status/ocsp')
    }
  ];

  const ejbcaLinksAccordData = [
    {
      accordionTitle: 'EJBCA Issuing CA Cluster',
      accordionDetails: (
        <div>
          <Typography>
            These links direct users to various web interfaces for EJBCA: Admin
            Web for EJBCA CA administrative functions, RA Web for Registration
            Authority functions and Public Web.
          </Typography>
          <br />
          {issuingCAClusterArray.map((issuingCACluster) => (
            <div key={issuingCACluster.title}>
              <Typography>
                {issuingCACluster.title}
                :
                <Link
                  color="primary"
                  href={issuingCACluster.url}
                  target="_blank"
                >
                  {` ${issuingCACluster.url} `}
                </Link>
                <CopyToClipboard copyText={issuingCACluster.url} />
              </Typography>
            </div>
          ))}
        </div>
      ),
      id: 1
    },
    {
      accordionTitle: 'EJBCA Root CA',
      accordionDetails: (
        <div>
          <Typography variant="subtitle1">
            When running, the EJBCA Root CA link provides access the Root CA
            that can be used to sign the Issuing CA created by administrators.
          </Typography>
          <br />
          {ejbcaRootCAArray.map((ejbcaRootCA) => (
            <div key={ejbcaRootCA.title}>
              <Typography>
                {ejbcaRootCA.title}
                :
                <Link color="primary" href={ejbcaRootCA.url} target="_blank">
                  {` ${ejbcaRootCA.url} `}
                </Link>
                <CopyToClipboard copyText={ejbcaRootCA.url} />
              </Typography>
            </div>
          ))}
        </div>
      ),
      id: 2
    },
    {
      accordionTitle: (
        <div>
          <Typography variant="h5">CRLs and OCSP</Typography>
        </div>
      ),
      accordionDetails: (
        <div>
          <Typography variant="subtitle1">
            The CRLs and OCSP links show what the certificate revocation access
            URLs are.
          </Typography>
          <br />
          <Typography variant="subtitle1">CRL </Typography>
          <Typography variant="subtitle2" sx={{ ml: 1 }}>
            The CRL URL is for obtaining the Issuing CA and Root CA CDPs once
            the CAs are created. Click the respective link to get the cached URL
            for your CRL Distribution Point (CDP) after a certificate authority
            is created. Once a CA is created, a hash of the DN or Subject Key
            Identifier will be generated and shown in the provided link for use
            in the CDP field in Cerfificate Profiles.
          </Typography>
          <br />

          {crlsArray.map((crls) => (
            <div key={crls.title}>
              <Typography sx={{ ml: 1 }}>
                {crls.title}
                :
                <Link color="primary" href={crls.url} target="_blank">
                  {` ${crls.url} `}
                </Link>
                <CopyToClipboard copyText={crls.url} />
              </Typography>
            </div>
          ))}

          <br />
          <Typography variant="subtitle1">
            OCSP
            <br />
          </Typography>
          <Typography variant="subtitle2" sx={{ ml: 1 }}>
            The OCSP URL is the same for all CAs and should use be used in the
            AIA field in the Certificate Profiles.
          </Typography>
          <br />
          {ocspArray.map((ocsp) => (
            <div key={ocsp.title}>
              <Typography sx={{ ml: 1 }}>
                {ocsp.title}
                :
                <Link color="primary" href={ocsp.url} target="_blank">
                  {` ${ocsp.url} `}
                </Link>
                <CopyToClipboard copyText={ocsp.url} />
              </Typography>
            </div>
          ))}
        </div>
      ),
      id: 3
    }
  ];

  return (
    <main>
      <Typography component="h1" variant="h4" gutterBottom={true}>
        EJBCA Links
      </Typography>

      <Typography sx={{ mb: 4 }} variant="subtitle1">
        {
          'Below, you will find links to the EJBCA application resources, including the Issuing CA cluster, the Root CA, and the CRL and OCSP URLs for the Issuing CA and Root CA nodes. For more information on configuring EJBCA, please see the '
        }
        <Link href={EXTERNAL_LINKS.EJBCA_DOCS} target="_blank">
          EJBCA Documentation
        </Link>
        {' or the '}
        <Link
          href={EXTERNAL_LINKS.EJBCA_CONFIG_GUIDE}
          target="_blank"
        >
          EJBCA SaaS Configuration Guide on configuring EJBCA
        </Link>
        .
      </Typography>

      {ejbcaLinksAccordData.map((ejbcaLinksAccordDatum) => (
        <Accordion key={ejbcaLinksAccordDatum.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">
              {ejbcaLinksAccordDatum.accordionTitle}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            {ejbcaLinksAccordDatum.accordionDetails}
          </AccordionDetails>
        </Accordion>
      ))}
    </main>
  );
};

export default EjbcaLinks;
