import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  deleteUser,
  submitAddUser,
  changeUserStatus,
  updateUserDetails,
  resendConfirmationEmail,
  updateSelfDetails
} from './UserManagementRequests';
import { errorsSnackbarHandler, fieldValidationErrorHandler } from '../reactQuery/queryErrorHandling';
import { ErrorResponse } from '../reactQuery/ErrorResponse';
import { setSnackbar } from '../../redux/slices/globalSnackbarSlice';
import {
  ACTIVATE_USER,
  GET_ALL_USERS_QUERY,
  GET_SELF_QUERY,
  GET_SINGLE_USER_QUERY
} from '../../util/constants';
import { USER_MANAGEMENT_ROUTE } from '../../util/routeConstants';
import { useLogoutUserMutation } from '../Session/SessionMutations';

export const useAddUserMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(
    submitAddUser,
    {
      onSuccess: (response, addUserMutateObject) => {
        queryClient.invalidateQueries(GET_ALL_USERS_QUERY);
        response.data.success.forEach((entry: ErrorResponse) => {
          const { firstName, lastName } = addUserMutateObject.userData;
          dispatch(
            setSnackbar({
              snackbarType: entry.severity ? entry.severity : 'error',
              snackbarMessage:
                `User ${firstName} ${lastName} has been added. Confirmation email sent.`
            })
          );
        });
        history.push(USER_MANAGEMENT_ROUTE);
      },
      onError: (error, addUserMutateObject) => {
        fieldValidationErrorHandler(error, dispatch, addUserMutateObject.setError);
      }
    }
  );
};

export const useUpdateUserMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    updateUserDetails,
    {
      onSuccess: (response, updateUserMutateObject) => {
        queryClient.setQueryData(`${GET_SINGLE_USER_QUERY}: ${updateUserMutateObject.id}`, updateUserMutateObject.userData);
        queryClient.invalidateQueries(`${GET_SINGLE_USER_QUERY}: ${updateUserMutateObject.id}`);
        updateUserMutateObject.setIsInEditMode(false);
        response.data.success.forEach((entry: ErrorResponse) => {
          dispatch(
            setSnackbar({
              snackbarType: entry.severity ? entry.severity : 'error',
              snackbarMessage: updateUserMutateObject.dirtyFields.email
                ? 'User details updated.  New confirmation email sent.'
                : entry.msg
            })
          );
        });
      },
      onError: (error, updateUserMutateObject) => {
        fieldValidationErrorHandler(error, dispatch, updateUserMutateObject.setError);
      }
    }
  );
};

export const useDeleteUserMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(
    deleteUser,
    {
      onSuccess: (response, deleteUserMutateObject) => {
        queryClient.invalidateQueries('getUsersQuery');
        response.data.success.forEach((entry: ErrorResponse) => {
          const { firstName, lastName } = deleteUserMutateObject;
          dispatch(
            setSnackbar({
              snackbarType: entry.severity ? entry.severity : 'error',
              snackbarMessage: `User ${firstName} ${lastName} has been deleted.`
            })
          );
        });
        history.push(USER_MANAGEMENT_ROUTE);
      },
      onError: (error: AxiosError) => {
        errorsSnackbarHandler(error, dispatch);
      }
    }
  );
};

export const useChangeUserStatusMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    changeUserStatus,
    {
      onSuccess: (response, changeUserStatusObject) => {
        queryClient.invalidateQueries(`${GET_SINGLE_USER_QUERY}: ${changeUserStatusObject.singleUserQueryData.id}`);
        response.data.success.forEach((entry: ErrorResponse) => {
          const { firstName, lastName } = changeUserStatusObject.singleUserQueryData;
          const { action } = changeUserStatusObject;
          dispatch(
            setSnackbar({
              snackbarType: entry.severity ? entry.severity : 'error',
              snackbarMessage:
                `User ${firstName} ${lastName} has been ${action === ACTIVATE_USER ? 'reactivated' : 'deactivated'}.`
            })
          );
        });
      },
      onError: (error: AxiosError) => {
        errorsSnackbarHandler(error, dispatch);
      }
    }
  );
};

export const useResendConfirmationEmailMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    resendConfirmationEmail,
    {
      onSuccess: (response, confirmationEmailObject) => {
        queryClient.invalidateQueries(`${GET_SINGLE_USER_QUERY}: ${confirmationEmailObject.singleUserQueryData.id}`);
        response.data.success.forEach((entry: ErrorResponse) => {
          dispatch(
            setSnackbar({
              snackbarType: entry.severity ? entry.severity : 'error',
              snackbarMessage: `Confirmation email resent to ${confirmationEmailObject.singleUserQueryData.email}.`
            })
          );
        });
      },
      onError: (error: AxiosError) => {
        errorsSnackbarHandler(error, dispatch);
      }
    }
  );
};

// Changes to self
export const useUpdateSelfMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate: logoutUserMutate } = useLogoutUserMutation();

  return useMutation(
    updateSelfDetails,
    {
      onSuccess: (response, updateSelfMutateObject) => {
        if (updateSelfMutateObject.selfDataToUpdate.email) {
          logoutUserMutate();
        } else {
          queryClient.invalidateQueries(GET_SELF_QUERY);
          updateSelfMutateObject.setIsInEditMode(false);
          response.data.success.forEach((entry: ErrorResponse) => {
            dispatch(
              setSnackbar({
                snackbarType: entry.severity ? entry.severity : 'error',
                snackbarMessage: 'Your info has been updated'
              })
            );
          });
        }
      },
      onError: (error, updateUserMutateObject) => {
        updateUserMutateObject.setOpenUpdateEmailSelfDialog(false);
        fieldValidationErrorHandler(error, dispatch, updateUserMutateObject.setError);
      }
    }
  );
};
