import React from 'react';
import {
  Box,
  Link,
  Typography
} from '@mui/material';
import { Link as routerLink } from 'react-router-dom';

import StaticPageLayoutContainer from './StaticPageLayoutContainer';
import kitten from '../img/kitten.gif';
import EXTERNAL_LINKS from '../util/externalLinks';

const NotFound = () => (
  <StaticPageLayoutContainer>
    <>
      <Typography variant="h5" align="center" gutterBottom={true}>
        404
      </Typography>
      <Box m="auto">
        <Box
          component="img"
          src={kitten}
          alt="kitten"
          width="398px"
        />
      </Box>
      <Box maxWidth="sm">
        <Typography variant="subtitle1" gutterBottom={true} align="center">
          This little kitty has looked everywhere for the page you are looking for.
          Still, sometimes glitches happen and even little kittens don&apos;t get their way.
          Click
          <Link to="/" component={routerLink}>
            {' here '}
          </Link>
          to go home. Or click
          <Link href={EXTERNAL_LINKS.SUPPORT_EMAIL} target="_blank">
            {' here '}
          </Link>
          to contact support.
          <br />
        </Typography>
      </Box>
    </>
  </StaticPageLayoutContainer>
);

export default NotFound;
