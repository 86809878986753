import axios, { AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import { MoreInfoFormType, RegistrationFormType } from '../../types/formTypes';
import { LoggedInUserResponse } from '../Session/SessionRequests';

export interface MoreInfoPayload {
  enrichedData: MoreInfoFormType;
  setError: UseFormSetError<MoreInfoFormType>;
}

export interface RegisterUserPayload {
  data: RegistrationFormType;
  setError: UseFormSetError<RegistrationFormType>;
}

export interface MoreInfoResponse {
  user: LoggedInUserResponse;
}

export interface RegisterUserResponse {
  Message: string;
}

export const submitRegisterUser = (
  registerUserObject: RegisterUserPayload
): Promise<AxiosResponse<RegisterUserResponse>> => axios.post('api/post/register', registerUserObject.data);

export const submitMoreInfo = (
  moreInfoObject: MoreInfoPayload
): Promise<AxiosResponse<LoggedInUserResponse>> => axios.post('api/options', moreInfoObject.enrichedData);
