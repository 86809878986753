import React from 'react';
import { Link, Typography } from '@mui/material';

import EXTERNAL_LINKS from '../../../util/externalLinks';

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center" noWrap={true}>
    {'Copyright © '}
    <Link color="inherit" href={EXTERNAL_LINKS.PRIMEKEY_HOME} target="_blank">
      PrimeKey
    </Link>
    {` ${new Date().getFullYear()}`}
    <br />
    <Link
      href={EXTERNAL_LINKS.PRIMEKEY_GTC}
      color="inherit"
      target="_blank"
      rel="noopener"
    >
      Terms and Conditions
    </Link>
  </Typography>
);

export default Copyright;
