import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

interface DeleteUserDialogProps {
  openUpdateSelfEmailDialog: boolean,
  setOpenUpdateSelfEmailDialog: React.Dispatch<React.SetStateAction<boolean>>,
}

const UpdateSelfEmailDialog: React.FC<DeleteUserDialogProps> = ({
  openUpdateSelfEmailDialog: openUpdateEmailSelfDialog,
  setOpenUpdateSelfEmailDialog: setOpenUpdateEmailSelfDialog,
}) => {
  const handleDialogClose = () => {
    setOpenUpdateEmailSelfDialog(false);
  };

  return (
    <Dialog open={openUpdateEmailSelfDialog}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography sx={{ typography: 'h6' }}>
          <ErrorIcon sx={{ mr: 1, verticalAlign: 'sub' }} />
          Update Email?
        </Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ p: 2 }}>
        <DialogContentText>
          Updating your email will force a logout and resend a confirmation email.
          Would you still like to update your email?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 1 }}>
        <Button autoFocus={true} onClick={handleDialogClose} color="primary">
          No, go back
        </Button>
        <Button type="submit" form="update-self-form" color="primary">
          Yes, update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateSelfEmailDialog;
