// Feedback Constants
// If new pages are created, add them here and in Feedback Dialog
export const FEEDBACK_PAGE_OPTIONS = [
  'Dashboard',
  'EJBCA Links',
  'Source IPs',
  'Root CA',
  'Compliance',
  'Support',
  'Logging',
  'User Management',
  'Profile',
  'EJBCA Product Feedback',
  'General Feedback'
] as const;

// 2FA Setup Paths
export const SETUP_TOTP = 'SETUP_TOTP' as const;
export const CHANGE_PHONE = 'CHANGE_PHONE' as const;
export const ENABLE_ROOTCA_TOTP = 'ENABLE_ROOTCA_TOTP' as const;
export const DISABLE_ROOTCA_TOTP = 'DISABLE_ROOTCA_TOTP' as const;
export const REMOVE_TOTP = 'REMOVE_TOTP' as const;
export const START_ROOTCA_TOTP = 'START_ROOTCA_TOTP' as const;
export const DISABLE_TOTP = 'DISABLE_TOTP' as const;

export type Setup2faActionsType =
  typeof SETUP_TOTP |
  typeof CHANGE_PHONE |
  typeof ENABLE_ROOTCA_TOTP |
  typeof DISABLE_ROOTCA_TOTP |
  typeof REMOVE_TOTP |
  typeof START_ROOTCA_TOTP |
  typeof DISABLE_TOTP;

// Tells us if the shared secret is stored in the session (new setup)
// or stored in the db (existing config)
export const NEW_TOTP = 'NEW_TOTP' as const;
export const STORED_TOTP = 'STORED_TOTP' as const;

export type TotpVerifyModes =
  typeof NEW_TOTP |
  typeof STORED_TOTP;

export type PhoneTypes = 'android' | 'iphone' | 'other';

// Logging Protocols
export const TCP = 'TCP' as const;
export const TLSAUTH = 'TLSAUTH' as const;
export const TLSUNAUTH = 'TLSUNAUTH' as const;

export type TlsProtocols = typeof TLSAUTH | typeof TLSUNAUTH | typeof TCP | '';

// For Syslog Form
export const LOGGING_APP_TYPES = ['Root', 'Issuing', 'Both'] as const;

// Status messages from api
export const REMOVED_TOTP = 'REMOVED_TOTP' as const;
export const ENABLED_ROOTCA_TOTP = 'ENABLED_ROOTCA_TOTP' as const;
export const DISABLED_ROOTCA_TOTP = 'DISABLED_ROOTCA_TOTP' as const;
export const TOKEN_MATCH_SUCCESS = 'TOKEN_MATCH_SUCCESS' as const; // Token validated status
export const ROOTCA_STARTING = 10 as const;
export const REAUTH_SUCCESS = 'REAUTH_SUCCESS' as const;
export const ROOTCA_TOTP_ENABLED = 'ROOTCA_TOTP_ENABLED' as const;
export const ROOTCA_STARTUP_INITIATED = 'ROOTCA_STARTUP_INITIATED' as const;
export const SUBMIT_CHANGE = 'SUBMIT_CHANGE' as const;
export const CHANGE_IN_PROGRESS = 'CHANGE_IN_PROGRESS' as const;
export const ACCT_DEPLOYING = 'ACCT_DEPLOYING' as const;

// General error handling
export const SESSION_TIMED_OUT = 'SESSION_TIMED_OUT' as const;
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR' as const;
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR' as const;
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS' as const;
export const INVALID_SESSION = 'INVALID_SESSION' as const;
export const CUSTID_PRODCODE_ALREADY_REGISTERED = 'CUSTID_PRODCODE_ALREADY_REGISTERED' as const;
export const ENTITLEMENTS_NOT_AVAILABLE = 'ENTITLEMENTS_NOT_AVAILABLE' as const;
export const UNAUTHORIZED = 'UNAUTHORIZED' as const;
export const BRUTE_FORCE = 'bruteForce' as const;

// Privileges
export const MANAGEUSERS_WRITE = 'MANAGEUSERS_WRITE' as const;
export const MANAGEUSERS_READ = 'MANAGEUSERS_READ' as const;
export const SOURCEIP_WRITE = 'SOURCEIP_WRITE' as const;
export const SOURCEIP_READ = 'SOURCEIP_READ' as const;
export const ROOTCA_WRITE = 'ROOTCA_WRITE' as const;
export const ROOTCA_READ = 'ROOTCA_READ' as const;
export const LOGGERS_WRITE = 'LOGGERS_WRITE' as const;
export const LOGGERS_READ = 'LOGGERS_READ' as const;

export type PrivilegesType =
  typeof MANAGEUSERS_WRITE |
  typeof MANAGEUSERS_READ |
  typeof SOURCEIP_WRITE |
  typeof SOURCEIP_READ |
  typeof ROOTCA_WRITE |
  typeof ROOTCA_READ |
  typeof LOGGERS_WRITE |
  typeof LOGGERS_READ;

// React Query Names
export const LOGGED_IN_USER_QUERY = 'LOGGED_IN_USER_QUERY' as const;
export const GET_DEPLOYMENT_STATUS_QUERY = 'GET_DEPLOYMENT_STATUS_QUERY' as const;
export const GET_DEPLOY_DIALOG_FLAG_QUERY = 'GET_DEPLOY_DIALOG_FLAG_QUERY' as const;
export const SYSLOG_LIST_QUERY = 'SYSLOG_LIST_QUERY' as const;
export const SYSLOG_UNENCRYPTED_ALERT_QUERY = 'SYSLOG_UNENCRYPTED_QUERY' as const;
export const SOURCE_IP_QUERY = 'SOURCE_IP_QUERY' as const;
export const SESSION_EXTEND_QUERY = 'SESSION_EXTEND_QUERY' as const;
export const GET_TOTP_STATE_QUERY = 'GET_TOTP_STATE_QUERY' as const;
export const ROOT_CA_STATUS_QUERY = 'ROOT_CA_STATUS_QUERY' as const;
export const GET_IS_TOKEN_VALID_QUERY = 'GET_IS_TOKEN_VALID_QUERY' as const;
export const GET_ALL_USERS_QUERY = 'GET_ALL_USERS_QUERY' as const;
export const GET_SELF_QUERY = 'GET_SELF_QUERY' as const;
export const GET_SINGLE_USER_QUERY = 'GET_SINGLE_USER_QUERY' as const;

// User Management
export const DEACTIVATE_USER = 'deactivateUser' as const;
export const ACTIVATE_USER = 'activateUser' as const;
export const RESEND_EMAIL = 'resendEmail' as const;
export const TOKEN_INVALID = 'TOKEN_INVALID' as const;

// Global Alert keys
export const GLOBAL_ALERT_KEYS = {
  SYSLOG_UNENCRYPTED: 'syslog_unencrypted',
  DEPLOYMENT_FAILED: 'deployment_failed'
} as const;

// misc
export const START = 'START' as const;
export const STOP = 'STOP' as const;
export const SUCCESS = 'SUCCESS' as const;

export const MAX_FILE_SIZE = 12288 as const;
