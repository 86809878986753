import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Portal,
  TextField,
  Typography
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import { useMoreInfoMutation } from '../api/Registration/RegistrationMutations';
import StaticPageLayoutContainer from './StaticPageLayoutContainer';
import DisplayedIPList from './IPAccess/DisplayedIpList';
import {
  PASSWORD_10K_MOST_COMMON,
  MOREINFO_COUNTRY_LIST
} from '../util/loginConstants';
import MoreInfoCancelDialog from './Dialogs/MoreInfoCancelDialog';
import AddIPForm from './IPAccess/AddIPForm';
import { selectPendingIps } from '../redux/slices/sourceIpSlice';
import { useLoggedInUserQuery } from '../api/reactQuery/queries';
import { MoreInfoFormType } from '../types/formTypes';
import EXTERNAL_LINKS from '../util/externalLinks';

const disabledInput = {
  '& .MuiInputBase-root.Mui-disabled': {
    color: '#66bb6a'
  },
  '& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: '#66bb6a',
    borderWidth: 2
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: '#66bb6a'
  }
};

const MoreInfo = () => {
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const region = loggedInUserData?.user?.region?.toLowerCase() || '[region]';
  const { mutate: submitMoreInfoMutate } = useMoreInfoMutation();
  const pendingIPs = useSelector(selectPendingIps);
  const [cancelButtonDialogOpen, setCancelButtonDialogOpen] = useState(false);
  // for AddIP Form
  // portal used for form within a form (add IP)
  const portalRef = useRef(null);

  const yupErrorText = (comparison: string, number: number, field: string) => `Enter at ${comparison} ${number} characters for ${field}`;
  const domainHelperText = 'This is your EJBCA Saas domain. It will be used for load balanced VIPs and CRL CDP URLs.';

  const RegistrationFormSchema = yup.object().shape({
    mcaDomainName: yup
      .string()
      .min(1, yupErrorText('least', 1, 'Domain Name'))
      .max(63, yupErrorText('most', 63, 'Domain Name'))
      .matches(
        /^[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
        'Invalid domain name. Allowed (0-9, A-Z, a-z, -).  Subdomains are not permitted.'
      )
      .required('Required')
      .notOneOf(
        [
          'default',
          'ingress-nginx',
          'kube-node-lease',
          'kube-public',
          'kube-system'
        ],
        'Domain name not available'
      )
      .test('domainExists', 'Domain name not available', (value) => axios
        .get('/api/getDomainExists', { params: { name: value } })
        .then((resp) => resp.data.exists === false)
        // swallow exception
        // session timeout will be caught on submit
        .catch(() => true)),
    mcaCommonName: yup
      .string()
      .max(198, yupErrorText('most', 198, 'Common Name'))
      .required('Required')
      .matches(
        /^[0-9A-Za-z ,\-_().]*$/,
        'Invalid characters. Allowed: (0-9, A-Z , a-z, -, _, (, ), comma, space)'
      ),
    mcaOrganization: yup
      .string()
      .max(198, yupErrorText('most', 198, 'Organization'))
      .matches(
        /^[0-9A-Za-z ,\-_().]*$/,
        'Invalid characters. Allowed: (0-9, A-Z , a-z, -, _, (, }, comma, space)'
      ),
    mcaCountry: yup
      .string()
      .test('oneOfCountryCode', 'Invalid country code', (value) => MOREINFO_COUNTRY_LIST.some((entry) => entry.key === value)),
    mcaSuperAdminCode: yup
      .string()
      .min(8, yupErrorText('least', 8, 'Enrollment Code'))
      .max(65, yupErrorText('most', 65, 'Enrollment Code'))
      .notOneOf(PASSWORD_10K_MOST_COMMON, 'Enter a less common enrollment code')
      .required('Required!'),
    confirmMcaSuperAdminCode: yup
      .string()
      .min(8, yupErrorText('least', 8, 'Enrollment Code'))
      .max(65, yupErrorText('most', 65, 'Enrollment Code'))
      .oneOf([yup.ref('mcaSuperAdminCode'), null], 'Enrollment code must match')
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError,
    setValue,
    watch
  } = useForm<MoreInfoFormType>({
    defaultValues: {
      mcaCountry: '',
      mcaCommonName: 'ManagementCA'
    },
    resolver: yupResolver(RegistrationFormSchema),
    mode: 'onChange'
  });

  const [watchOrganization, watchCommonName, watchCountry] = watch(['mcaOrganization', 'mcaCommonName', 'mcaCountry']);

  const handleCancelButtonOpen = () => {
    setCancelButtonDialogOpen(true);
  };

  const handleCancelButtonClose = () => {
    setCancelButtonDialogOpen(false);
  };

  useEffect(() => {
    const fullDnArray = [];
    if (watchCommonName) {
      fullDnArray.push(`CN=${watchCommonName}`);
    }
    if (watchOrganization) {
      fullDnArray.push(`O=${watchOrganization}`);
    }
    if (watchCountry && watchCountry !== 'None') {
      fullDnArray.push(`C=${watchCountry}`);
    }
    setValue('fullDN', fullDnArray.join());
  }, [watchCommonName, watchOrganization, watchCountry, setValue]);

  const onSubmit = (data: MoreInfoFormType) => {
    // in PendingIps, the key is the original text with a - PENDING_IP,
    // when that gets submitted to the database, we want the most recent text,
    // in case this was edited.  Ex, original is text and key is 1.1.1.1, on update
    // new text is 2.2.2.2, key is still 1.1.1.1 - PENDING_IP, change key so no potential
    // duplicate keys if that ip is later edited back to 1.1.1.1 and then edited again
    const enrichedPendingIPs = pendingIPs.map((entry) => ({
      text: entry.text,
      description: entry.description,
      key: entry.text
    }));
    const enrichedData = data;
    enrichedData.mcaIpAddresses = enrichedPendingIPs;
    enrichedData.mcaDomainName = data.mcaDomainName.toLowerCase();

    const moreInfoObject = {
      enrichedData,
      setError
    };
    submitMoreInfoMutate(moreInfoObject);
  };

  return (
    <>
      <MoreInfoCancelDialog
        cancelButtonDialogOpen={cancelButtonDialogOpen}
        handleCancelButtonClose={handleCancelButtonClose}
      />
      <StaticPageLayoutContainer>
        <>
          <Typography component="h3" variant="subtitle1">
            Just a few more details before we can deploy your PKI...
          </Typography>
          <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('mcaDomainName')}
              id="mcaDomainName"
              label="Domain Name"
              variant="outlined"
              margin="normal"
              fullWidth={true}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {`.${region}.prod.saas.primekey.com`}
                  </InputAdornment>
                )
              }}
              autoFocus={true}
              error={!!errors.mcaDomainName}
              helperText={errors.mcaDomainName
                ? errors.mcaDomainName?.message : domainHelperText}
            />

            <Box mt={1.5} mb={1.5}>
              <Divider />
            </Box>
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('mcaCommonName')}
              variant="outlined"
              margin="normal"
              fullWidth={true}
              id="mcaCommonName"
              label="Common Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">CN=</InputAdornment>
                )
              }}
              autoComplete="off"
              error={!!errors.mcaCommonName}
              helperText={errors.mcaCommonName ? errors.mcaCommonName?.message : 'Common Name *Required'}
              onBlur={(event) => {
                setValue('mcaCommonName', event.target.value.trim());
              }}
            />
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('mcaOrganization')}
              id="mcaOrganization"
              label="Organization"
              variant="outlined"
              margin="normal"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">O=</InputAdornment>
                )
              }}
              autoComplete="off"
              error={!!errors.mcaOrganization}
              helperText={errors.mcaOrganization ? errors.mcaOrganization?.message : 'Organization Name *Optional'}
              onBlur={(event) => {
                setValue('mcaOrganization', event.target.value.trim());
              }}
            />

            <Controller
              name="mcaCountry"
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">C=</InputAdornment>
                      )
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    autoComplete="off"
                    fullWidth={true}
                    error={!!errors.mcaCountry}
                    helperText={errors.mcaCountry ? errors.mcaCountry?.message : 'ISO Country Code *Optional'}
                    id="country"
                    label="Country"
                    margin="normal"
                    select={true}
                    variant="outlined"
                  >
                    <MenuItem disabled={true} value="" />
                    {MOREINFO_COUNTRY_LIST.map((item) => (
                      <MenuItem key={item.key} value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
            />
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('fullDN')}
              id="fullDN"
              disabled={true}
              variant="outlined"
              margin="normal"
              fullWidth={true}
              label="Full Subject DN"
              helperText="Full Subject DN to be used when creating ManagementCA"
              sx={disabledInput}
            />

            <Box mt={1.5} mb={1.5}>
              <Divider />
            </Box>
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('mcaSuperAdminCode')}
              id="mcaSuperAdminCode"
              variant="outlined"
              margin="normal"
              fullWidth={true}
              label="SuperAdmin Enrollment Code"
              type="password"
              autoComplete="off"
              error={!!errors.mcaSuperAdminCode}
              helperText={errors.mcaSuperAdminCode ? errors.mcaSuperAdminCode.message : 'SuperAdmin Keystore enrollment code'}
            />
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('confirmMcaSuperAdminCode')}
              id="confirmMcaSuperAdminCode"
              variant="outlined"
              margin="normal"
              fullWidth={true}
              label="Confirm SuperAdmin Enrollment Code"
              type="password"
              autoComplete="off"
              error={!!errors.confirmMcaSuperAdminCode}
              helperText={
                errors.confirmMcaSuperAdminCode ? errors.confirmMcaSuperAdminCode.message : ''
              }
            />
            <Box mt={1.5} mb={1.5}>
              <Divider />
            </Box>

            <div ref={portalRef} />
            <DisplayedIPList />

            <Grid container={true} spacing={2} py={2} px={0}>
              <Grid item={true} xs={4}>
                <Button>
                  <Link
                    underline="none"
                    href={EXTERNAL_LINKS.AWS_LAUNCH_GUIDE}
                    color="primary"
                    target="_blank"
                  >
                    Help
                  </Link>
                </Button>
              </Grid>
              <Grid item={true} xs={true} display="grid" justifyContent="end">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => handleCancelButtonOpen()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item={true}>
                <Button type="submit" variant="contained" color="primary" disabled={!isValid || pendingIPs?.length < 1}>
                  Deploy my PKI
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      </StaticPageLayoutContainer>

      <Portal container={portalRef.current}>
        <AddIPForm />
      </Portal>
    </>
  );
};

export default MoreInfo;
