import React from 'react';
import {
  Button,
  Box
} from '@mui/material';
import {
  Save as SaveIcon,
} from '@mui/icons-material';

interface EditModeButtonProps {
  setIsInEditMode: (flag: boolean) => void,
  reset: (values?: Record<string, any>, options?: Record<string, boolean>) => void,
  isValid: boolean,
  isDirty: boolean
}

const EditModeButtons = (
  {
    setIsInEditMode = () => {},
    reset,
    isValid,
    isDirty
  }: EditModeButtonProps
) => {
  const cancelOrBackClick = () => {
    reset();
    setIsInEditMode(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        pb: 4,
        pt: 2
      }}
    >
      <Button
        sx={{ mr: 2 }}
        variant="outlined"
        onClick={cancelOrBackClick}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        startIcon={<SaveIcon />}
        variant="contained"
        disabled={!isValid || !isDirty}
      >
        Save
      </Button>

    </Box>
  );
};

export default EditModeButtons;
