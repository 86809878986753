import React, { useState } from 'react';
import {
  Divider,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useParams } from 'react-router-dom';

import { useGetSingleUserQuery } from '../../../../api/reactQuery/queries';
import { DEACTIVATE_USER, ACTIVATE_USER, RESEND_EMAIL } from '../../../../util/constants';
import { useChangeUserStatusMutation, useResendConfirmationEmailMutation } from '../../../../api/reactQuery/mutations';
import DeleteUserDialog from '../../../Dialogs/DeleteUserDialog';

interface UserFormProps {
  setIsInEditMode?: (flag: boolean) => void,
}

const UserDetailMenu = (
  {
    setIsInEditMode = () => {}
  }: UserFormProps
) => {
  const { id } = useParams<Record<string, string>>();
  const { data: singleUserQueryData } = useGetSingleUserQuery(id);
  const { mutate: deactivateUserMutate } = useChangeUserStatusMutation();
  const { mutate: activateUserMutate } = useChangeUserStatusMutation();
  const { mutate: resendConfirmationEmailMutate } = useResendConfirmationEmailMutation();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const openMenu = Boolean(menuAnchorEl);

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const onEditUserClick = () => {
    setIsInEditMode(true);
    handleMenuClose();
  };

  const onResendConfirmationEmailClick = () => {
    if (singleUserQueryData) {
      const resendConfirmationEmailMutateObject = {
        singleUserQueryData,
        action: RESEND_EMAIL
      };
      resendConfirmationEmailMutate(resendConfirmationEmailMutateObject);
    }
    handleMenuClose();
  };

  const onDeactivateUserClick = () => {
    if (singleUserQueryData) {
      const deactivateUserMutateObject = {
        singleUserQueryData,
        action: DEACTIVATE_USER
      };
      deactivateUserMutate(deactivateUserMutateObject);
    }
    handleMenuClose();
  };

  const onReactivateUserClick = () => {
    if (singleUserQueryData) {
      const reactivateUserMutateObject = {
        singleUserQueryData,
        action: ACTIVATE_USER
      };
      activateUserMutate(reactivateUserMutateObject);
    }
    handleMenuClose();
  };

  const displayDeleteUserDialog = () => {
    setOpenDeleteUserDialog(true);
  };

  return (
    <div>
      <DeleteUserDialog
        openDeleteUserDialog={openDeleteUserDialog}
        setOpenDeleteUserDialog={setOpenDeleteUserDialog}
      />
      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={onEditUserClick}>Edit User</MenuItem>

        {singleUserQueryData?.isActive
          ? (
            <div>
              {!singleUserQueryData.isConfirmed
                    && (
                    <MenuItem onClick={onResendConfirmationEmailClick}>
                      Resend Confirmation Email
                    </MenuItem>
                    )}
              <Divider />
              <MenuItem onClick={onDeactivateUserClick}>Deactivate User</MenuItem>
            </div>
          )
          : (
            <div>
              <MenuItem onClick={onReactivateUserClick}>Reactivate User</MenuItem>
              <Divider />
              <MenuItem onClick={displayDeleteUserDialog}>Delete User</MenuItem>
            </div>
          )}

      </Menu>
    </div>
  );
};

export default UserDetailMenu;
