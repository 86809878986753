import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';
import QRCode from 'react-qr-code';

import { useGetQRCodeMutation } from '../../../../../api/reactQuery/mutations';
import CopyToClipboard from '../../../../../util/CopyToClipboard';

interface TOTPQRProps {
  handleClose: (reason: string) => void,
  nextStep: (step: number) => void,
}

const TOTPQR = ({
  handleClose,
  nextStep
}: TOTPQRProps) => {
  const {
    mutate: qrCodeMutate,
    isSuccess: isQRCodeMutationSuccess,
    data: qrCodeMutationData
  } = useGetQRCodeMutation();

  useEffect(() => {
    qrCodeMutate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DialogContent>
        <Box component="ul" p={0} ml={3}>
          <li>Download a TOTP authenticator app.</li>
          <li>Follow the app instructions to set up an account.</li>
          <li>Scan the QR code or manually enter the Secret Key.</li>
        </Box>
      </DialogContent>
      <DialogContent>
        <Box height={200} width={400} p={1} textAlign="center">
          {isQRCodeMutationSuccess && qrCodeMutationData.data ? (
            <>
              <QRCode value={qrCodeMutationData.data.otpauth} level="L" size={120} />
              <Box sx={{
                display: 'flex',
                mt: 2,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                <Typography color="primary">{`Secret Key: ${qrCodeMutationData.data.secret}`}</Typography>
                <Box sx={{ mb: 0.5, ml: 1 }}>
                  <CopyToClipboard copyText={qrCodeMutationData.data.secret} />
                </Box>
              </Box>
            </>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose('button')} color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={() => nextStep(4)}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default TOTPQR;
