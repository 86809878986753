import axios, { AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import { LoginFormType, TOTPFormType } from '../../types/formTypes';
import { UserWithPrivilegeObject } from '../UserManagement/UserManagementRequests';

export interface LoginRequestPayload {
  data: LoginFormType | TOTPFormType;
  setError: UseFormSetError<LoginFormType> | UseFormSetError<TOTPFormType>;
  openTOTP?: boolean;
  setOpenTOTP?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface LoggedInUserData extends UserWithPrivilegeObject {
  domainName: string;
  certLimit?: number;
  optionsDefined: boolean;
  regId: number;
  region: string;
  userId: number;
}

export interface LoggedInUserResponse {
  user: LoggedInUserData;
  emailConfirmation?: {
    isEmailConfirmed: boolean;
    tokenError?: string;
  }
}

export interface TOTPEnabledResponse {
  totpEnabled: boolean;
}

export interface SessionExtendResponse {
  isLoggedIn: boolean;
}

export interface LogoutResponse {
  message: string;
}

export const getLoggedInUser = (): Promise<AxiosResponse<LoggedInUserResponse>> => axios.get('/api/session');
export const getSessionExtend = (): Promise<AxiosResponse<SessionExtendResponse>> => axios.get('/api/sessionExt');
export const submitLogin = (
  loginUserObject: LoginRequestPayload
): Promise<AxiosResponse<LoggedInUserResponse | TOTPEnabledResponse>> => axios.post('/api/post/login', loginUserObject.data);
export const deleteSession = (): Promise<AxiosResponse<LogoutResponse>> => axios.delete('/api/session');
