import React from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useLoginUserMutation } from '../../api/reactQuery/mutations';
import { selectBruteForceBackdropOpen } from '../../redux/slices/bruteForceBackdropSlice';
import { LoginFormType, TokenFormType, TOTPFormType } from '../../types/formTypes';

interface TOTPLoginDialogProps {
  loginObj: LoginFormType;
  setOpenTOTP: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean;
}

const TOTPLoginDialog = ({
  loginObj,
  setOpenTOTP,
  open
}: TOTPLoginDialogProps) => {
  const backdropOpen = useSelector(selectBruteForceBackdropOpen);
  const { mutate: loginUserMutate } = useLoginUserMutation();

  const TOTPVLoginFormSchema = yup.object().shape({
    token: yup
      .string()
      .min(6, 'Invalid Token')
      .max(6, 'Invalid Token')
      .matches(/^[0-9][0-9][0-9][0-9][0-9][0-9]$/, 'Invalid Token')
      .required('Required')
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
    setError
  } = useForm<TOTPFormType>({
    mode: 'onChange',
    resolver: yupResolver(TOTPVLoginFormSchema)
  });

  const onSubmit = (data: TokenFormType) => {
    const loginUserObject = {
      data: { ...data, ...loginObj } as TOTPFormType,
      setError
    };
    loginUserMutate(loginUserObject);
  };

  const resetAndClose = (reason: string) => {
    if (reason !== 'backdropClick') {
      reset({ token: '' });
      setOpenTOTP(false);
    }
  };

  const handleResetAndCloseClick = () => {
    resetAndClose('button');
  };

  return (
    <Dialog
      open={open}
      onClose={(_e, reason) => resetAndClose(reason)}
      aria-labelledby="alert-dialog-2fa-title"
      aria-describedby="alert-dialog-2fa-description"
    >
      <DialogTitle id="alert-dialog-2fa-title">
        Two-Factor Authentication
      </DialogTitle>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        width="100%"
      >
        <div>
          <DialogContent>
            <Typography variant="body2">
              Enter the 6-digit token displayed in your authenticator app
            </Typography>
            <Box width={400} height={100}>
              <TextField
                autoFocus={true}
                margin="normal"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('token', {
                  required: true
                })}
                label="Enter Token"
                id="token"
                size="small"
                inputProps={{ maxLength: 6 }}
                autoComplete="off"
                error={!!errors.token || !!errors.email}
                helperText={errors.token && errors.token.message}
                variant="standard"
              />
            </Box>
          </DialogContent>
        </div>

        <DialogActions>
          <Button onClick={handleResetAndCloseClick} variant="text" color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={backdropOpen || !isValid}
          >
            Verify
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default TOTPLoginDialog;
