const isAlreadyInList = (ipToTest: string, listToCheck: Array<{ text: string }>) => {
  // review this make sure all conditions met, returns true if already in list, false if not
  // check if ip or /32 has identical entry in list
  const ipIdenticalToList = listToCheck.some(
    (ipEntries) => ipEntries.text === ipToTest
  );

  const splitIp = ipToTest.split('/');

  // check if entry without /32 matches single IP in list
  const removedSlash32IpInList = listToCheck.some(
    (ipEntry) => ipEntry.text === splitIp[0]
  );

  // check if entry with /32 matches Cidr in list
  const withSlash32InList = listToCheck.some(
    (ipEntry) => ipEntry.text === `${ipToTest}/32`
  );

  return ipIdenticalToList || removedSlash32IpInList || withSlash32InList;
};

export default isAlreadyInList;
