const EXTERNAL_LINKS = {
  AWS_SIGNUP_URL: 'https://aws.amazon.com/marketplace/seller-profile?id=7edf9048-58e6-4086-9d98-b8e0c1d78fce',
  AWS_LAUNCH_GUIDE: 'https://doc.primekey.com/ejbca-saas/ejbca-saas-aws-launch-guide/deploy-ejbca-saas-aws#DeployEJBCASaaSAWS-Step5-ChooseOptionsforPKIDeploymentOptions',
  EJBCA_DOCS: 'https://doc.primekey.com/ejbca/',
  EJBCA_CONFIG_GUIDE: 'https://doc.primekey.com/ejbca-saas/ejbca-saas-configuration-guide/configure-ejbca-in-ejbca-saas',
  EJBCA_SAAS_DOCS: 'https://doc.primekey.com/ejbca-saas',
  PRIMEKEY_GTC: 'https://www.primekey.com/gtc/#ejbca-saas',
  PRIMEKEY_HOME: 'https://www.primekey.com/',
  PRIMEKEY_SUPPORT_PORTAL: 'https://support.primekey.com/',
  SUPPORT_EMAIL: 'mailto:support@c2company.com'
} as const;

export default EXTERNAL_LINKS;
