import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { useLoggedInUserQuery } from '../Session/SessionQueries';
import { basicErrorHandler } from '../reactQuery/queryErrorHandling';
import { INVALID_SESSION, ROOT_CA_STATUS_QUERY } from '../../util/constants';
import { submitRefreshRootCaStatus } from './RootCaRequests';

const useGetRootCaStatusQuery = () => {
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  return useQuery(
    ROOT_CA_STATUS_QUERY,
    () => {
      const response = submitRefreshRootCaStatus()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: Boolean(loggedInUserData?.user),
      refetchInterval: 5000,
      retry: (failureCount, error: AxiosError) => {
        if (error.response?.data.errors) {
          return error.response.data.errors.some((entry: any) => entry.type !== INVALID_SESSION);
        }
        return failureCount < 3;
      },
      onError: (error) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};

export default useGetRootCaStatusQuery;
