import axios, { AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import { LOGGING_APP_TYPES, TlsProtocols, SUCCESS } from '../../util/constants';
import { PseudoErrorResponse } from '../reactQuery/ErrorResponse';
import { SubmitLoggersFormType, CredentialDialogFormType } from '../../types/formTypes';

export type DeleteCredentialResponse = PseudoErrorResponse;

export interface CredListResponse {
  credentialName: string;
  id: number;
  protocol: TlsProtocols;
  certAttributes: any; // TODO: tighten
}
export interface SyslogListResponse {
  appType: typeof LOGGING_APP_TYPES[number] | '';
  credentialName?: string;
  ipdns: string;
  loggerPodLabel: string;
  pending?: boolean;
  pendingAction?: string;
  port: number;
  protocol: TlsProtocols;
}

export interface SyslogAlertResponse {
  isTcpEnabled: boolean;
}

interface LoggerSuccessObject {
  type: typeof SUCCESS,
  msg: 'Submitted Logger Request',
  severity: 'success'
}

interface SubmitLoggersPayload {
  resetInitialandDisplayed: () => void;
  data: SubmitLoggersFormType
}
export interface SubmitLoggersResponse {
  success: Array<LoggerSuccessObject>
}
export interface SubmitLoggerCredsPayload {
  formData: any, // TODO: tighten, this whole formData maybe better using hook built in form
  certType: TlsProtocols,
  handleCloseAddCertDialogClick: () => void,
  setError: UseFormSetError<CredentialDialogFormType>
}

export interface LoggerCredSuccessObject {
  type: typeof SUCCESS,
  msg: 'Added Logger Credential',
  severity: 'success'
}

export interface SubmitLoggerCredResponse {
  success: Array<LoggerCredSuccessObject>
}

export const deleteCredential = (
  deleteCredentialObject: CredListResponse
): Promise<AxiosResponse<DeleteCredentialResponse>> => axios.delete(`/api/loggerCreds/${deleteCredentialObject.id}`);

export const getCredList = (protocol: TlsProtocols): Promise<AxiosResponse<CredListResponse[]>> => axios.get(`/api/loggerCreds/${protocol}`);
export const getSyslogList = (): Promise<AxiosResponse<SyslogListResponse[]>> => axios.get('/api/loggers');
export const getSyslogUnencryptedAlert = (): Promise<AxiosResponse<SyslogAlertResponse>> => axios.get('/api/loggers?option=tcpEnabled');

export const submitLoggers = (submitLoggersObject: SubmitLoggersPayload): Promise<AxiosResponse<SubmitLoggersResponse>> => axios.post('/api/loggers', submitLoggersObject.data);
export const submitLoggerCreds = (submitLoggerCredsObject: SubmitLoggerCredsPayload): Promise<AxiosResponse<SubmitLoggerCredResponse>> => axios.post('/api/loggerCreds', submitLoggerCredsObject.formData);
