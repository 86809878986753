import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useGetSelfQuery } from '../../../../../api/reactQuery/queries';
import YourInfoNormalModeButtons from './yourInfoButtons/YourInfoNormalModeButtons';
import YourInfoEditModeButtons from './yourInfoButtons/YourInfoEditModeButtons';
import { useUpdateSelfMutation } from '../../../../../api/reactQuery/mutations';
import UpdateSelfEmailDialog from '../../../../Dialogs/UpdateSelfEmailDialog';
import { BasicUserType } from '../../../../../api/UserManagement/UserManagementRequests';

const YourInfoAccordion = () => {
  const theme = useTheme();
  const [openUpdateSelfEmailDialog, setOpenUpdateSelfEmailDialog] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const { data: getSelfQueryData, isSuccess: getSelfQueryIsSuccess } = useGetSelfQuery();
  const { mutate: updateSelfMutate } = useUpdateSelfMutation();

  const customDisabledStyle = {
    '& .MuiInput-input.Mui-disabled': { color: theme.palette.text.disabled, WebkitTextFillColor: theme.palette.text.primary },
    '& .MuiInput-root.Mui-disabled:before': { borderBottomStyle: 'none' },
  } as const;

  const UpdateSelfSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, 'Enter at least 2 characters for your first name')
      .max(255, 'Enter between 2 and 255 characters for your first name')
      .required('Required'),
    lastName: yup
      .string()
      .min(2, 'Enter at least 2 characters for your last name')
      .max(255, 'Enter between 2 and 255 characters for your last name')
      .required('Required'),
    email: yup
      .string()
      .required('Email is required')
      .email('Please enter a valid email'),
  });

  const {
    handleSubmit,
    formState: {
      errors,
      dirtyFields,
      isValid,
      isDirty
    },
    register,
    reset,
    setError
  } = useForm<BasicUserType>({
    defaultValues: {
      firstName: getSelfQueryData?.firstName,
      lastName: getSelfQueryData?.lastName,
      email: getSelfQueryData?.email
    },
    mode: 'onChange',
    resolver: yupResolver(UpdateSelfSchema)
  });

  const submitUpdateChanges = (data: BasicUserType) => {
    let selfDataToUpdate = {};
    const changedFields = Object.keys(dirtyFields) as Array<keyof BasicUserType>;
    changedFields.forEach((entry) => {
      selfDataToUpdate = { ...selfDataToUpdate, [entry]: data[entry] };
    });
    const updateSelfMutateObject = {
      selfDataToUpdate,
      setIsInEditMode,
      setError,
      setOpenUpdateEmailSelfDialog: setOpenUpdateSelfEmailDialog
    };
    updateSelfMutate(updateSelfMutateObject);
  };

  const checkForEmailChangeDialog = (data: BasicUserType) => {
    if (dirtyFields.email && !openUpdateSelfEmailDialog) {
      setOpenUpdateSelfEmailDialog(true);
    } else {
      submitUpdateChanges(data);
    }
  };

  // default values gets set before query gets back, once query is successful,
  // set default values to query data
  useEffect(() => {
    if (getSelfQueryIsSuccess) {
      reset({
        firstName: getSelfQueryData?.firstName,
        lastName: getSelfQueryData?.lastName,
        email: getSelfQueryData?.email
      });
    }
  }, [getSelfQueryData?.email,
    getSelfQueryData?.firstName,
    getSelfQueryData?.lastName,
    getSelfQueryIsSuccess,
    reset]);

  return (
    <>
      <UpdateSelfEmailDialog
        openUpdateSelfEmailDialog={openUpdateSelfEmailDialog}
        setOpenUpdateSelfEmailDialog={setOpenUpdateSelfEmailDialog}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            Your Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {getSelfQueryIsSuccess
            ? (
          // adding id to form, so accessible in dialog. https://github.com/react-hook-form/react-hook-form/issues/566#issuecomment-675515940
              <Box sx={{ width: '100%' }} component="form" onSubmit={handleSubmit(checkForEmailChangeDialog)} id="update-self-form">
                <Box sx={{
                  mb: 2,
                  display: 'flex',
                  columnGap: 4
                }}
                >
                  <TextField
                    fullWidth={true}
                    autoFocus={true}
                    margin="normal"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('firstName', {
                      required: true
                    })}
                    id="firstName"
                    label="First Name"
                    autoComplete="off"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    disabled={!isInEditMode}
                    sx={customDisabledStyle}
                  />
                  <TextField
                    fullWidth={true}
                    autoFocus={true}
                    margin="normal"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('lastName', {
                      required: true
                    })}
                    id="lastName"
                    label="Last Name"
                    autoComplete="off"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    disabled={!isInEditMode}
                    sx={customDisabledStyle}
                  />
                  <TextField
                    fullWidth={true}
                    autoFocus={true}
                    margin="normal"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('email', {
                      required: true
                    })}
                    id="email"
                    label="Email Address"
                    autoComplete="off"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    disabled={!isInEditMode}
                    sx={customDisabledStyle}
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                  {isInEditMode
                    ? (
                      <YourInfoEditModeButtons
                        setIsInEditMode={setIsInEditMode}
                        reset={reset}
                        isValid={isValid}
                        isDirty={isDirty}
                      />
                    )
                    : <YourInfoNormalModeButtons setIsInEditMode={setIsInEditMode} />}
                </Box>
              </Box>
            ) : <Typography>Loading...</Typography>}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default YourInfoAccordion;
