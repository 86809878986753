import React from 'react';
import {
  Box,
  Link,
  Typography,
} from '@mui/material';

import StaticPageLayoutContainer from './StaticPageLayoutContainer';
import EXTERNAL_LINKS from '../util/externalLinks';
import { useGetIsTokenValidQuery, useLoggedInUserQuery } from '../api/reactQuery/queries';
import { TOKEN_INVALID } from '../util/constants';

const TokenError = () => {
  const { error: tokenValidErrorData, isError: isTokenValidError } = useGetIsTokenValidQuery();
  const { data: loggedInUserData } = useLoggedInUserQuery();

  const originatingTokenError = () => {
    let tokenError: string = TOKEN_INVALID;
    if (loggedInUserData?.emailConfirmation?.tokenError) {
      tokenError = loggedInUserData?.emailConfirmation.tokenError;
    }
    if (isTokenValidError) {
      tokenValidErrorData.response?.data.errors.forEach((entry: any) => {
        tokenError = entry.tokenError;
      });
    }
    return tokenError;
  };
  return (
    <StaticPageLayoutContainer>
      <Box sx={{ width: '90%' }}>
        <Typography sx={{ mb: 4 }} variant="h6" gutterBottom={true} align="justify">
          {`Sorry, your email could not be confirmed. ${originatingTokenError() === TOKEN_INVALID ? 'There is a problem with the token in the request.' : 'The token in the request has expired.'}  Please contact your administrator to resend the email confirmation or contact`}
          <Link href={EXTERNAL_LINKS.SUPPORT_EMAIL} target="_blank">
            {' support'}
          </Link>
          .
        </Typography>
        <Typography variant="subtitle1" align="left" gutterBottom={true}>
          {`Error Code: 401 ${originatingTokenError() === TOKEN_INVALID ? 'Invalid' : 'Expired'} Token`}
        </Typography>
      </Box>
    </StaticPageLayoutContainer>
  );
};

export default TokenError;
