import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';

import UsersTable from '../../userManagement/UsersTable';
import { useGetSelfQuery } from '../../../../../api/reactQuery/queries';

const PriviligeAccordion = () => {
  const { data: getSelfQueryData } = useGetSelfQuery();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">
          Your Privileges
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        { getSelfQueryData
          && (
          <UsersTable
            tableData={[getSelfQueryData]}
            isOwnProfile={true}
          />
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default PriviligeAccordion;
