import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle
} from '@mui/material';

import TOTPQR from './TOTPQR';
import TOTPVerify from './TOTPVerify';
import TOTPReauth from './TOTPReauth';
import TOTPSetPhone from './TOTPSetPhone';
import {
  CHANGE_PHONE,
  DISABLE_ROOTCA_TOTP,
  DISABLE_TOTP,
  ENABLE_ROOTCA_TOTP,
  Setup2faActionsType,
  SETUP_TOTP,
  START_ROOTCA_TOTP,
  TotpVerifyModes
} from '../../../../../util/constants';
import { TOTPSetPhoneFormType } from '../../../../../types/formTypes';

interface TOTPDialogProps {
  handleClose: (reason: string) => void,
  nextStep: (step: number) => void,
  step: number,
  labelObj: TOTPSetPhoneFormType,
  setLabel: (label: TOTPSetPhoneFormType) => void,
  actionType: Setup2faActionsType,
  verifyMode: TotpVerifyModes,
  setVerifyMode: React.Dispatch<React.SetStateAction<TotpVerifyModes>>
  open: boolean
}

const TOTPDialog = ({
  handleClose,
  nextStep,
  step,
  labelObj,
  setLabel,
  actionType,
  verifyMode,
  setVerifyMode,
  open
}: TOTPDialogProps) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (actionType === SETUP_TOTP) {
      setTitle('Set Up Two-Factor Authentication');
    } else if (actionType === DISABLE_TOTP) {
      setTitle('Remove Two-Factor Authentication');
    } else if (actionType === ENABLE_ROOTCA_TOTP) {
      setTitle('Enforce 2FA for Root CA Start');
    } else if (actionType === DISABLE_ROOTCA_TOTP) {
      setTitle('Disable 2FA for Root CA Start');
    } else if (actionType === START_ROOTCA_TOTP) {
      setTitle('Start Root CA - Two-Factor Authentication');
    } else if (actionType === CHANGE_PHONE) {
      setTitle('Change Phone - Two-Factor Authentication');
    }
  }, [actionType]);

  const renderSwitch = (stepNumber: number) => {
    switch (stepNumber) {
      case 1:
        return (
          <TOTPReauth
            handleClose={handleClose}
            nextStep={nextStep}
            actionType={actionType}
          />
        );
      case 2:
        return (
          <TOTPSetPhone
            handleClose={handleClose}
            setLabel={setLabel}
            nextStep={nextStep}
          />
        );
      case 3:
        return (
          <TOTPQR
            handleClose={handleClose}
            nextStep={nextStep}
          />
        );
      case 4:
        return (
          <TOTPVerify
            handleClose={handleClose}
            nextStep={nextStep}
            verifyMode={verifyMode}
            setVerifyMode={setVerifyMode}
            labelObj={labelObj}
            actionType={actionType}
          />
        );
      default:
        // Invalid step specified
        return undefined;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-2fa-title"
        aria-describedby="alert-dialog-2fa-description"
      >
        <DialogTitle id="alert-dialog-2fa-title">{title}</DialogTitle>
        {renderSwitch(step)}
      </Dialog>
    </div>
  );
};

export default TOTPDialog;
