import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import BruteForceBackdrop from '../../../layout/BruteForceBackdrop';
import { useTOTPReauthMutation } from '../../../../../api/reactQuery/mutations';
import { selectBruteForceBackdropOpen } from '../../../../../redux/slices/bruteForceBackdropSlice';
import { TOTPReauthFormType } from '../../../../../types/formTypes';
import { Setup2faActionsType } from '../../../../../util/constants';

interface TOTPReauthProps {
  handleClose: (reason: string) => void,
  nextStep: (step: number) => void,
  actionType: Setup2faActionsType,
}

const TOTPReauth = ({
  handleClose,
  nextStep,
  actionType
}: TOTPReauthProps) => {
  const { mutate: submitReauthMutation } = useTOTPReauthMutation();
  const backdropOpen = useSelector(selectBruteForceBackdropOpen);

  const TOTPReauthSchema = yup.object().shape({
    password: yup
      .string()
      .max(128, 'Enter at most 128 characters')
      .required('Required')
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError
  } = useForm<TOTPReauthFormType>({
    mode: 'onChange',
    resolver: yupResolver(TOTPReauthSchema)
  });

  const onSubmit = (data: TOTPReauthFormType) => {
    const submitReauthObject = {
      password: data,
      actionType,
      nextStep,
      setError
    };
    submitReauthMutation(submitReauthObject);
  };

  return (
    <>
      <BruteForceBackdrop />
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        width="100%"
      >
        <div>
          <DialogContent>
            <Typography variant="body2">
              Verify that it&apos;s you to continue
            </Typography>
            <Box width={400} height={100}>
              <TextField
                autoFocus={true}
                margin="normal"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('password', {
                  required: true
                })}
                label="Password"
                id="password"
                InputProps={{
                  inputProps: {
                    'data-lpignore': 'false',
                    maxLength: 128
                  }
                }}
                fullWidth={true}
                type="password"
                autoComplete="off"
                error={!!errors.password}
                helperText={errors.password?.message}
                variant="standard"
              />
            </Box>
          </DialogContent>
        </div>

        <DialogActions>
          <Button onClick={() => handleClose('button')} variant="text" color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={backdropOpen || !isValid}
          >
            Next
          </Button>
        </DialogActions>
      </Box>
    </>
  );
};

export default TOTPReauth;
