import axios, { AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import { FeedbackFormType } from '../../types/formTypes';

interface SubmitFeedbackPayload {
  data: FeedbackFormType;
  setError: UseFormSetError<FeedbackFormType>;
  resetAndClose: () => void;
}

interface SubmitFeedbackResponse {
  Message: string;
}

const submitFeedback = (
  submitFeedbackObject: SubmitFeedbackPayload
): Promise<AxiosResponse<SubmitFeedbackResponse>> => axios.post('/api/post/sendFeedback', submitFeedbackObject.data);

export default submitFeedback;
