import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import { useLoggedInUserQuery } from '../../../../../api/reactQuery/queries';
import { useDeleteCredentialMutation } from '../../../../../api/reactQuery/mutations';
import { TlsProtocols } from '../../../../../util/constants';

interface CredAttributes {
  expiration: string,
  issuerDN: string,
  subjectDN: string
}

type CertAttributesKeys = 'caFileAttr' | 'clientCertAttr';

interface CredentialTableRowProps {
  row: {
    certAttributes: Record<CertAttributesKeys, Array<CredAttributes>>,
    credentialName: string,
    id: number,
    protocol: TlsProtocols
  }
}

const CredentialTableRow = ({
  row
}: CredentialTableRowProps) => {
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const userPrivs = loggedInUserData?.user?.privileges;
  const [tableOpen, setTableOpen] = useState(false);
  const { mutate: deleteCredentialMutate } = useDeleteCredentialMutation();
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

  const handleDeleteCredential = () => {
    setConfirmDeleteDialog(false);
    deleteCredentialMutate(row);
  };

  return (
    <>
      <Dialog open={confirmDeleteDialog}>
        <DialogTitle>Delete Entry</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this entry?
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() => setConfirmDeleteDialog(false)}
              color="primary"
            >
              No, cancel
            </Button>
            <Button color="primary" onClick={handleDeleteCredential}>
              Yes, delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <TableRow
        sx={{
          '& > *': {
            borderBottom: 'unset'
          }
        }}
      >
        <TableCell>
          <IconButton size="small" onClick={() => setTableOpen(!tableOpen)}>
            {tableOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.credentialName}</TableCell>
        <TableCell align="right">
          <IconButton
            disabled={!userPrivs?.LOGGERS_WRITE}
            onClick={() => setConfirmDeleteDialog(true)}
            size="large"
          >
            <DeleteIcon color={userPrivs?.LOGGERS_WRITE ? 'primary' : 'disabled'} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={tableOpen} timeout="auto">
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    <TableCell>Issuer</TableCell>
                    <TableCell>Valid To</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.certAttributes.caFileAttr.map(
                    (nestedAttributes) => (
                      <TableRow key={`${nestedAttributes.subjectDN}-${nestedAttributes.expiration}`}>
                        <TableCell>{nestedAttributes.subjectDN}</TableCell>
                        <TableCell>{nestedAttributes.issuerDN}</TableCell>
                        <TableCell width="10%">
                          {nestedAttributes.expiration.substring(0, 10)}
                        </TableCell>
                      </TableRow>
                    )
                  )}

                  {row.certAttributes.clientCertAttr && (
                    <>
                      {row.certAttributes.clientCertAttr.map(
                        (nestedAttributes) => (
                          <TableRow key={`${nestedAttributes.subjectDN}-${nestedAttributes.expiration}`}>
                            <TableCell>{nestedAttributes.subjectDN}</TableCell>
                            <TableCell>{nestedAttributes.issuerDN}</TableCell>
                            <TableCell width="10%">
                              {nestedAttributes.expiration.substring(0, 10)}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CredentialTableRow;
