import React, { useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Save as SaveIcon } from '@mui/icons-material';

import {
  IP_EDIT_CASE,
  PENDING_IP_SUFFIX,
  clearSourceIpState,
  setHelperTextError,
  selectPendingIps
} from '../../../redux/slices/sourceIpSlice';
import AcctDeployingBackdrop from '../layout/AcctDeployingBackdrop';
import DisplayedIpList from '../../IPAccess/DisplayedIpList';
import AddIPForm from '../../IPAccess/AddIPForm';
import { CHANGE_IN_PROGRESS } from '../../../util/constants';
import { useSubmitSourceIpMutation } from '../../../api/reactQuery/mutations';
import { useSourceIpQuery, useLoggedInUserQuery } from '../../../api/reactQuery/queries';

const SourceIP = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));
  const { mutate: submitSourceIpMutate } = useSubmitSourceIpMutation();
  const sourceIpQueryResult = useSourceIpQuery();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const pendingIps = useSelector(selectPendingIps);
  const userPrivs = loggedInUserData?.user?.privileges;

  const { handleSubmit } = useForm({});

  const resetSourceIPState = useCallback(() => {
    dispatch(clearSourceIpState());
  }, [dispatch]);

  // Clicking SaveChanges
  // take the queryResults (savedIPs), make into an object with a key being the text and the value
  // a nested object of the saved entry.  Remove the -pending from the pending list key.
  // For Add, just add another entry to the serverObject (savedIps)
  // Remove - find that key that matches the key in the pendinglist & remove it from serverObject.
  // Update - find the matching key and delete that entry from the serverObject
  // then take the pending ip that was updated and add it to the server object.
  // finally go through the object and make it an array to send to the server
  const onSaveChanges = () => {
    const serverIpArray = JSON.parse(sourceIpQueryResult?.data?.ipAddresses || '');
    const serverIpsObject = serverIpArray
      .reduce((obj: any, item: any) => ({ ...obj, [item.text]: item }), {});
    pendingIps?.forEach((entry: any) => {
      const simpleKey = entry.key?.split(PENDING_IP_SUFFIX)[0];
      const preppedEntry = {
        text: entry.text, description: entry.description, key: simpleKey
      };
      if (entry.status === IP_EDIT_CASE.ADD) {
        serverIpsObject[simpleKey] = preppedEntry;
      }
      if (entry.status === IP_EDIT_CASE.UPDATE) {
        delete serverIpsObject[simpleKey];
        const newKey = entry.text;
        preppedEntry.key = newKey;
        serverIpsObject[newKey] = preppedEntry;
      }
      if (entry.status === IP_EDIT_CASE.REMOVE) {
        delete serverIpsObject[simpleKey];
      }
    });
    const arrayToSendToServer = Object.keys(serverIpsObject).map((entry) => serverIpsObject[entry]);
    // check to make sure there are entries in the list, if not, user should be alerted.
    if (!(arrayToSendToServer.length > 0)) {
      dispatch(setHelperTextError({
        message: 'Add at least one Source IP',
        targetTextField: IP_EDIT_CASE.ADD
      }));
    } else {
      const submitSourceIpObject = {
        ipAddresses: arrayToSendToServer,
      };
      submitSourceIpMutate(submitSourceIpObject);
    }
  };

  const displayClearChangesButton = () => {
    if (pendingIps.length) {
      return (
        <>
          <Grid item={true} mr={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={resetSourceIPState}
            >
              Clear Changes
            </Button>
          </Grid>
        </>
      );
    }
    return undefined;
  };

  const displayChangeInProgress = () => {
    if (sourceIpQueryResult.data?.reason === CHANGE_IN_PROGRESS) {
      return (
        <>
          <Grid item={true} container={true} mr={2} display="flex" flex-direction="row">
            <Grid item={true}>
              <Typography variant="h5" mr={1}>
                Status:
              </Typography>
            </Grid>
            <Grid item={true} container={isMobileLayout} justifyContent="flex-end" mb={isMobileLayout ? 1 : 0}>
              <Typography variant="h5" color="warning.main">
                Change in Progress...
              </Typography>
            </Grid>

          </Grid>
        </>
      );
    }
    return undefined;
  };

  useEffect(() => {
    resetSourceIPState();
  }, [resetSourceIPState]);

  return (
    <Box>
      <Prompt
        when={Boolean(pendingIps.length)}
        message="You have unsaved changes.  Leaving now will discard these changes. "
      />
      <AcctDeployingBackdrop />
      <Typography variant="h4" component="h1" gutterBottom={true}>
        EJBCA Source IP Access
      </Typography>
      <Typography variant="subtitle1">
        Control what source network IPs can access the EJBCA SaaS PKI. IP
        addresses will be added to the inbound access policy of the EJBCA nodes
        directly. IP ranges can be added in CIDR notation. At least one IP must
        exist in the access list. For more information on CIDR notation, refer
        to the IETF.org page on RFC4632 or Wikipedia page on CIDR.
      </Typography>
      <Paper sx={{ p: 4, mt: 4 }}>
        {userPrivs?.SOURCEIP_WRITE && (
          <AddIPForm
            sourceIpQueryResult={sourceIpQueryResult}
          />
        )}
        <Grid container={true} spacing={2} mt={2}>
          {!isMobileLayout
            ? (
              <>
                <Grid item={true} sm={3}>
                  <Typography variant="h6">Source IP</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="h6">Description</Typography>
                </Grid>
              </>
            )
            : (
              <Grid item={true}>
                <Typography variant="h6">Allowed IPs</Typography>
              </Grid>
            )}
        </Grid>
        <Box component="form" onSubmit={handleSubmit(onSaveChanges)}>
          <DisplayedIpList sourceIpQueryResult={sourceIpQueryResult} />
          {userPrivs?.SOURCEIP_WRITE && (
            <Grid
              container={true}
              mt={2}
              alignItems="flex-end"
              justifyContent="flex-end"
              display="flex"
            >
              <>
                <Grid item={true}>
                  {displayChangeInProgress()}
                </Grid>
                <Grid item={true}>
                  {displayClearChangesButton()}
                </Grid>
                <Grid item={true}>
                  <Button
                    disabled={Boolean(!pendingIps.length)}
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </Grid>

              </>
            </Grid>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default SourceIP;
