import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';

import queryClient from './api/reactQuery/QueryClient';
import SessionErrorDialog from './components/Dialogs/SessionErrorDialog';
import darkTheme from './themes/darkTheme';
import lightTheme from './themes/lightTheme';
import AppRoutes from './routes/AppRoutes';
import { selectTheme } from './redux/slices/userPreferencesSlice';

const App = () => {
  const theme = useSelector(selectTheme);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <CssBaseline />
        <SessionErrorDialog />
        <AppRoutes />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
