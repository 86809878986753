import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import submitFeedback from './FeedbackRequests';
import { setSnackbar } from '../../redux/slices/globalSnackbarSlice';
import { receiveErrors } from '../reactQuery/queryErrorHandling';
import { INVALID_SESSION } from '../../util/constants';
import { ErrorResponse } from '../reactQuery/ErrorResponse';

const useFeedbackMutation = (successMessage?: string) => {
  const dispatch = useDispatch();

  return useMutation(
    submitFeedback,
    {
      onSuccess: (_response, submitFeedbackObject) => {
        submitFeedbackObject.resetAndClose();
        dispatch(setSnackbar({
          snackbarType: 'success',
          snackbarMessage: successMessage || 'Thank you for your feedback.'
        }));
      },
      onError: (error: AxiosError, submitFeedbackObject) => {
        if (error.response?.data.error) {
          receiveErrors(error.response.data, dispatch);
        } else {
          error.response?.data.errors?.forEach((entry: ErrorResponse) => {
            if (entry.type === INVALID_SESSION) {
              const errorData = {
                error: entry.type
              };
              receiveErrors(errorData, dispatch);
            } else if (entry.param === 'feedbackCategory' || entry.param === 'userFeedbackText') {
              submitFeedbackObject.setError(entry.param, { type: 'manual', message: entry.msg });
            } else {
              receiveErrors(entry, dispatch);
            }
          });
        }
      }
    }
  );
};

export default useFeedbackMutation;
