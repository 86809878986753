import React from 'react';
import {
  Box,
  Container,
  Link,
  Typography
} from '@mui/material';
import { Link as routerLink } from 'react-router-dom';

import EXTERNAL_LINKS from '../util/externalLinks';

const InsufficientPrivileges = () => (
  <Container
    maxWidth="xs"
    sx={{
      pb: '10px',
      pt: 8
    }}
  >
    <Typography variant="h5" align="center" gutterBottom={true}>
      403
    </Typography>
    <Box maxWidth="sm">
      <Typography variant="subtitle1" gutterBottom={true} align="center">
        You shall not pass.  Your account lacks the privileges required to access
        the page you were trying to reach. Click
        <Link to="/" component={routerLink}>
          {' here '}
        </Link>
        to go home. Your company&apos;s account manager may be able to help, or click
        <Link href={EXTERNAL_LINKS.SUPPORT_EMAIL} target="_blank">
          {' here '}
        </Link>
        to contact support.
        <br />
      </Typography>
    </Box>
  </Container>
);

export default InsufficientPrivileges;
