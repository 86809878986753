import ip from 'ip';
import isValidIp from './isValidIp';

const cidrValidation = (ipToTest: string) => {
  // split on '/' to compare /32 addresses with no slash single IPs and vice versa
  const splitInput = ipToTest.split('/');

  if (splitInput.length === 2) {
    if (!isValidIp(splitInput[0])) {
      return { isValid: false, cidrValMessage: '' };
    }
    const correctCidr = ip.cidr(ipToTest);
    return {
      isValid: correctCidr === splitInput[0],
      cidrValMessage: `. Did you mean ${correctCidr}/${splitInput[1]}?`
    };
  }
  return { isValid: splitInput.length === 1, cidrValMessage: '' };
};

export default cidrValidation;
