import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import { useLogoutUserMutation } from '../../api/reactQuery/mutations';

interface MoreInfoCancelDialogProps {
  cancelButtonDialogOpen: boolean,
  handleCancelButtonClose: () => void
}

const MoreInfoCancelDialog = (
  {
    cancelButtonDialogOpen,
    handleCancelButtonClose
  }: MoreInfoCancelDialogProps
) => {
  const { mutate: logoutUserMutate } = useLogoutUserMutation();

  const handleCancelClick = () => {
    logoutUserMutate();
  };

  return (
    <Dialog open={cancelButtonDialogOpen}>
      <DialogTitle>Are you sure you want to cancel?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Canceling now will not save your progress. You will be asked to
          complete this form the next time you log in.
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleCancelButtonClose} color="primary">
            No, finish form
          </Button>
          <Button onClick={handleCancelClick} color="primary">
            Yes, Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default MoreInfoCancelDialog;
