import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';

import { useLoggedInUserQuery } from '../../../../../api/reactQuery/queries';
import CredentialsTable from './CredentialsTable';
import AddCredentialsDialog from './AddCredentialDialog';
import { TLSAUTH, TLSUNAUTH, TlsProtocols } from '../../../../../util/constants';

const Credentials = () => {
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const userPrivs = loggedInUserData?.user?.privileges;
  const [openAddCredentialsDialog, setOpenAddCredentialsDialog] = useState<{
    open: boolean,
    certType: TlsProtocols
  }>({
    open: false,
    certType: TLSUNAUTH
  });

  const handleAddCredDialog = (protocol: TlsProtocols) => {
    setOpenAddCredentialsDialog((prevState) => ({
      ...prevState,
      open: true,
      certType: protocol
    }));
  };

  const tablesArray = [
    {
      title: 'CA Certificates',
      tooltipText: 'Certificates used for Unauthenticated logging targets.',
      protocol: TLSUNAUTH
    },
    {
      title: 'CA Certificates and Private Keys',
      tooltipText:
        'For Authenticated logging targets. Should contain CA File with full CA Chain, Private Key and corresponding Certificate.',
      protocol: TLSAUTH
    }
  ];

  return (
    <>
      <AddCredentialsDialog
        certType={openAddCredentialsDialog.certType}
        isOpen={openAddCredentialsDialog.open}
        setOpenAddCredentialsDialog={setOpenAddCredentialsDialog}
      />
      {tablesArray.map((tableEntry) => (
        <React.Fragment key={tableEntry.title}>
          <Grid
            container={true}
            item={true}
            justifyContent="center"
            alignItems="flex-end"
            sx={{ pt: 3 }}
            spacing={1}
          >
            <Grid item={true}>
              <Typography variant="h6">{tableEntry.title}</Typography>
            </Grid>
            <Grid item={true}>
              <Tooltip placement="top-start" title={tableEntry.tooltipText}>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Grid>
            <Grid container={true} item={true}>
              <CredentialsTable protocol={tableEntry.protocol} />
            </Grid>
          </Grid>
          {userPrivs?.LOGGERS_WRITE && (
          <Grid item={true} sx={{ display: 'grid', justifyContent: 'flex-end' }}>
            <Button
              color="primary"
              onClick={() => {
                handleAddCredDialog(tableEntry.protocol);
              }}
            >
              Add...
            </Button>
          </Grid>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Credentials;
