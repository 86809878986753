import React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  CompareArrows as CompareArrowsIcon,
  Dashboard as DashboardIcon,
  Dns as DnsIcon,
  ErrorOutline as ErrorOutlineIcon,
  Help as HelpIcon,
  LibraryBooks as LibraryBooksIcon,
  Link as LinkIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  Groups as GroupsIcon,
} from '@mui/icons-material';
import { UseQueryResult } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useLoggedInUserQuery, useMainDashboardQuery } from '../../api/reactQuery/queries';
import { useLogoutUserMutation } from '../../api/reactQuery/mutations';
import { toggleDrawer } from '../../redux/slices/navDrawerSlice';
import {
  SUPPORT_ROUTE,
  USER_MANAGEMENT_ROUTE,
  LOGGING_ROUTE,
  ROOT_CA_ROUTE,
  LINKS_ROUTE,
  SOURCE_IP_ROUTE,
  FAQ_ROUTE,
  DASHBOARD_ROUTE,
  PATHNAMES
} from '../../util/routeConstants';
import { TotalCertsResponse } from '../../api/MainDashboard/MainDashboardRequests';

interface NavListItemsProps {
  followLink(address: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
  isMobileLayout?: boolean
}

const NavListItems = (
  {
    followLink,
    isMobileLayout = false
  }: NavListItemsProps
) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const totalCertsQuery = useMainDashboardQuery('getTotalCerts') as UseQueryResult<TotalCertsResponse>;
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const certLimit = loggedInUserData?.user?.certLimit || 10000;
  const userPrivs = loggedInUserData?.user?.privileges;
  const { mutate: logoutUserMutate } = useLogoutUserMutation();

  const handleLogout = () => {
    logoutUserMutate();
  };

  const handleItemClick = (
    address: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    // if we're in the mobile drawer, close it when a link is selected
    if (isMobileLayout) {
      dispatch(toggleDrawer());
    }
    followLink(address, event);
  };

  return (
    <List>
      <ListItem disablePadding={true}>
        <ListItemButton
          component={Link}
          onClick={(
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => handleItemClick(DASHBOARD_ROUTE, e)}
          selected={pathname.split('/')[2] === PATHNAMES.DASHBOARD}
          to={DASHBOARD_ROUTE}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
          { !!totalCertsQuery.data?.total
            && totalCertsQuery.data.total > certLimit
            && (
            <ListItemIcon sx={{ justifyContent: 'center' }}>
              <ErrorOutlineIcon color="error" fontSize="small" />
            </ListItemIcon>
            )}
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding={true}>
        <ListItemButton
          component={Link}
          onClick={(
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => handleItemClick(LINKS_ROUTE, e)}
          selected={pathname.split('/')[2] === PATHNAMES.LINKS}
          to={LINKS_ROUTE}
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="EJBCA Links" />
        </ListItemButton>
      </ListItem>

      { userPrivs?.SOURCEIP_READ
      && (
      <ListItem disablePadding={true}>
        <ListItemButton
          component={Link}
          onClick={(
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => handleItemClick(SOURCE_IP_ROUTE, e)}
          selected={pathname.split('/')[2] === PATHNAMES.SOURCE_IP}
          to={SOURCE_IP_ROUTE}
        >
          <ListItemIcon>
            <CompareArrowsIcon />
          </ListItemIcon>
          <ListItemText primary="Source IPs" />
        </ListItemButton>
      </ListItem>
      )}

      { userPrivs?.ROOTCA_READ
      && (
      <ListItem disablePadding={true}>
        <ListItemButton
          component={Link}
          onClick={(
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => handleItemClick(ROOT_CA_ROUTE, e)}
          selected={pathname.split('/')[2] === PATHNAMES.ROOT_CA}
          to={ROOT_CA_ROUTE}
        >
          <ListItemIcon>
            <DnsIcon />
          </ListItemIcon>
          <ListItemText primary="Root CA" />
        </ListItemButton>
      </ListItem>
      )}
      <ListItem disablePadding={true}>
        <ListItemButton
          component={Link}
          onClick={(
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => handleItemClick(FAQ_ROUTE, e)}
          selected={pathname.split('/')[2] === PATHNAMES.COMPLIANCE}
          to={FAQ_ROUTE}
        >
          <ListItemIcon>
            <PlaylistAddCheckIcon />
          </ListItemIcon>
          <ListItemText primary="Compliance" />
        </ListItemButton>
      </ListItem>
      { userPrivs?.LOGGERS_READ
      && (
      <ListItem disablePadding={true}>
        <ListItemButton
          component={Link}
          onClick={(
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => handleItemClick(LOGGING_ROUTE, e)}
          selected={pathname.split('/')[2] === PATHNAMES.LOGGING}
          to={LOGGING_ROUTE}
        >
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText primary="Logging" />
        </ListItemButton>
      </ListItem>
      )}
      { userPrivs?.MANAGEUSERS_READ
      && (
      <ListItem disablePadding={true}>
        <ListItemButton
          component={Link}
          onClick={(
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => handleItemClick(USER_MANAGEMENT_ROUTE, e)}
          selected={pathname.split('/')[2] === PATHNAMES.USERS}
          to={USER_MANAGEMENT_ROUTE}
        >
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="User Management" />
        </ListItemButton>
      </ListItem>
      )}
      <ListItem disablePadding={true}>
        <ListItemButton
          component={Link}
          onClick={(
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => handleItemClick(SUPPORT_ROUTE, e)}
          selected={pathname.split('/')[2] === PATHNAMES.SUPPORT}
          to={SUPPORT_ROUTE}
        >
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Support" />
        </ListItemButton>
      </ListItem>
      <Divider />
      <ListItem disablePadding={true} sx={{ mt: 2 }}>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default NavListItems;
