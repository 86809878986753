import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// Limit roboto imports to font weights used by Material-UI
import '@fontsource/roboto/300.css'; // Weight 300.
import '@fontsource/roboto/400.css'; // Weight 400.
import '@fontsource/roboto/500.css'; // Weight 500.
import '@fontsource/roboto/700.css'; // Weight 700.

import App from './App';
import './index.css';
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
