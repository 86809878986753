/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import StaticPageLayoutContainer from './StaticPageLayoutContainer';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { useRegisterUserMutation } from '../api/Registration/RegistrationMutations';
import { RegistrationFormType } from '../types/formTypes';
import EXTERNAL_LINKS from '../util/externalLinks';
import {
  PASSWORD_10K_MOST_COMMON,
  COUNTRY_LIST,
} from '../util/loginConstants';
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE } from '../util/routeConstants';

const Registration = () => {
  const { mutate: submitRegisterUserMutate } = useRegisterUserMutation();
  const passwordScore = React.useRef(0);

  // eslint-disable-next-line no-return-assign
  const updatePasswordScore = (score: number) => passwordScore.current = score;

  const RegistrationFormSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, 'Enter at least 2 characters for your first name')
      .max(255, 'Enter between 2 and 255 characters for your first name')
      .required('Required'),
    lastName: yup
      .string()
      .min(2, 'Enter at least 2 characters for your last name')
      .max(255, 'Enter between 2 and 255 characters for your last name')
      .required('Required'),
    organization: yup
      .string()
      .min(2, 'Enter at least 2 characters for your organization')
      .max(255, 'Enter between 2 and 255 characters for your organization')
      .required('Required'),
    country: yup
      .string()
      .test('oneOfCountryCode', 'Invalid country code', (value) => COUNTRY_LIST.some((entry) => entry.key === value)),
    email: yup
      .string()
      .required('Email is required')
      .email('Please enter a valid email'),
    password: yup
      .string()
      .min(8, 'Enter at least 8 characters for your password')
      .max(128, 'Enter between 8 and 128 characters for your password')
      .notOneOf(PASSWORD_10K_MOST_COMMON, 'Enter a less common password')
      .required('Required!')
      .test(
        'passwordStrengthValidation',
        'Please provide a stronger password',
        (value) => (value ? passwordScore.current > 2 : true)
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    acceptterms: yup
      .string()
      .oneOf(['acceptterms'], 'Accept Terms is Required')
  });

  const {
    control,
    formState: { errors, isValid },
    getFieldState,
    handleSubmit,
    register,
    setError,
    trigger
  } = useForm<RegistrationFormType>({
    resolver: yupResolver(RegistrationFormSchema)
  });

  const passwordString = useWatch({ control, name: 'password', defaultValue: '' });
  const emailFieldState = getFieldState('email');
  const emailString = useWatch({ control, name: 'email', defaultValue: '' });
  const confirmPasswordState = getFieldState('confirmPassword');

  const checkEmail = useCallback(async (email) => {
    try {
      const res = await axios.get('/api/getEmailExists', { params: { field: email } });
      if (res.data.exists) {
        setError('email', { type: 'manual', message: 'That email is taken. Please specify another email.' });
      }
    } catch (error) {
      // session timeout will be caught on submit
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEmailcheck = useCallback(debounce((email) => {
    checkEmail(email);
  }, 500), []);

  const onSubmit = (data: RegistrationFormType) => {
    const registerUserObject = {
      data,
      setError
    };
    submitRegisterUserMutate(registerUserObject);
  };

  React.useEffect(() => {
    // if the email field has been changed, and doesn't have any other validation errors
    if (emailString.length > 4 && !emailFieldState.error) {
      debouncedEmailcheck(emailString);
    }
  }, [debouncedEmailcheck, emailFieldState.error, emailString]);

  return (
    <StaticPageLayoutContainer>
      <>
        <Typography component="h1" variant="h5">
          EJBCA SaaS Registration
        </Typography>
        <Box component="form" sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth={true}
            id="firstName"
            label="First Name"
            {...register('firstName', {
              onChange: () => trigger('firstName')
            })}
            autoComplete="given_name"
            autoFocus={true}
            error={!!errors.firstName}
            helperText={
              errors.firstName?.message
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth={true}
            id="lastName"
            label="Last Name"
            {...register('lastName', {
              onChange: () => trigger('lastName')
            })}
            autoComplete="family_name"
            error={!!errors.lastName}
            helperText={
              errors.lastName?.message
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth={true}
            id="organization"
            label="Company"
            {...register('organization', {
              onChange: () => trigger('organization')
            })}
            autoComplete="organization"
            error={!!errors.organization}
            helperText={
              errors.organization?.message
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth={true}
            id="email"
            label="Email Address"
            {...register('email', {
              onChange: () => trigger('email')
            })}
            autoComplete="email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth={true}
            {...register('password', {
              onChange: AwesomeDebouncePromise(async () => {
                await trigger('password');
                if (confirmPasswordState.isTouched) {
                  trigger('confirmPassword');
                }
              }, 200)
            })}
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            error={!!errors.password}
            helperText={
              errors.password?.message
            }
          />
          <PasswordStrengthMeter
            passwordString={passwordString}
            setPasswordScoreInParent={updatePasswordScore}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth={true}
            {...register('confirmPassword', {
              onChange: () => trigger(['password', 'confirmPassword'])
            })}
            label="Confirm Password"
            type="password"
            id="confirm_password"
            autoComplete="new-password"
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword?.message
            }
          />
          <Controller
            name="country"
            rules={{ required: 'Country is required' }}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                sx={{
                  my: 2,
                  mx: 0,
                  minWidth: 120,
                  display: 'flex'
                }}
                {...field}
                label="Country"
                onChange={(e) => {
                  field.onChange(e.target.value);
                  trigger('country');
                }}
                select={true}
                SelectProps={{
                  autoWidth: true
                }}
                helperText={errors.country?.message}
                error={!!errors.country}
              >
                {COUNTRY_LIST.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <FormControlLabel
            control={(
              <Controller
                control={control}
                defaultValue=""
                name="acceptterms"
                render={({ field }) => (
                  <Checkbox
                    id="acceptterms"
                    color="primary"
                    checked={field.value === 'acceptterms'}
                    onChange={(e) => {
                      field.onChange(e.target.checked ? 'acceptterms' : '');
                      // trigger all validations to catch any fields that weren't touched yet
                      trigger();
                    }}
                  />
                )}
              />
            )}
            label={(
              <div>
                <span>I have read and accept the </span>
                <Link
                  href={EXTERNAL_LINKS.PRIMEKEY_GTC}
                  variant="inherit"
                  target="_blank"
                  color="primary"
                  rel="noopener"
                >
                  terms of use
                </Link>
              </div>
            )}
          />
          <FormHelperText error={!!errors.acceptterms}>
            {errors.acceptterms?.message}
          </FormHelperText>

          <Button
            disabled={!isValid}
            type="submit"
            fullWidth={true}
            variant="contained"
            color="primary"
            sx={{
              my: 2, mr: 3, ml: 0
            }}
          >
            Register
          </Button>
          <Grid container={true}>
            <Grid item={true} xs={true}>
              <Link to={FORGOT_PASSWORD_ROUTE} component={RouterLink}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item={true}>
              <Link to={LOGIN_ROUTE} component={RouterLink}>
                Already have an account? Log in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </>
    </StaticPageLayoutContainer>
  );
};

export default Registration;
