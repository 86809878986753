import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ControlledDropzone from './ControlledDropzone';
import { useCredList } from '../../../../../api/reactQuery/queries';
import { useSubmitLoggerCredsMutation } from '../../../../../api/reactQuery/mutations';
import { CredentialDialogFormType } from '../../../../../types/formTypes';
import {
  MAX_FILE_SIZE,
  TLSAUTH,
  TlsProtocols,
  TLSUNAUTH
} from '../../../../../util/constants';

interface AddCredentailsDialogProps {
  certType: TlsProtocols,
  isOpen: boolean,
  setOpenAddCredentialsDialog: React.Dispatch<React.SetStateAction<{
    open: boolean;
    certType: TlsProtocols;
  }>>
}

const AddCredentialsDialog = ({
  certType,
  isOpen,
  setOpenAddCredentialsDialog
}: AddCredentailsDialogProps) => {
  const dispatch = useDispatch();
  const {
    mutate: submitLoggerCredsMutate,
    isLoading: submitLoggerCredsIsLoading
  } = useSubmitLoggerCredsMutation();
  const [
    credentialNamesForValidation,
    setCredentialNamesForValidation
  ] = useState(['']);

  const credListResults = useCredList(certType);

  useEffect(() => {
    if (credListResults?.isSuccess) {
      const mapped = credListResults?.data?.map((cred) => cred.credentialName.toLowerCase());
      setCredentialNamesForValidation(mapped);
    }
  }, [credListResults.data, credListResults.isSuccess]);

  // validation Tests
  const fileTypeValidation = (inputValue: File[]) => inputValue[0].type === 'application/x-x509-ca-cert';

  const fileSizeValidation = (inputValue: File[]) => inputValue[0].size <= MAX_FILE_SIZE;

  const fileRequiredValidation = (inputValue: File[]) => inputValue.length !== 0;

  const addCertSchema = yup.object().shape({
    credName: yup
      .string()
      .required('Please provide a name.')
      .min(5, 'Please use at least 5 characters.')
      .max(32, 'Please use at most 32 characters.')
      .matches(
        /^[0-9A-Za-z ]+$/,
        'Invalid name.  Allowed characters: 0-9, A-Z, a-z, space'
      )
      .test(
        'alreadyExists',
        'Name already exists',
        (value) => (value ? !credentialNamesForValidation.includes(value.toLowerCase()) : true)
      ),
    caFile: yup
      .mixed()
      .test(
        'requiredFile',
        'Please upload a CA File.',
        (value) => fileRequiredValidation(value)
      )
      .test(
        'fileSize',
        'CA File size is too large.',
        (value) => fileSizeValidation(value)
      )
      .test(
        'fileType',
        'Please provide a valid CA File filetype.',
        (value) => fileTypeValidation(value)
      ),
    clientCert: yup.mixed().when('$certType', {
      is: TLSAUTH,
      then: yup
        .mixed()
        .test(
          'requiredFile',
          'Please upload a Client Cert.',
          (value) => fileRequiredValidation(value)
        )
        .test(
          'fileSize',
          'Client Cert file size is too large.',
          (value) => fileSizeValidation(value)
        )
        .test(
          'fileType',
          'Please provide a valid Client Cert filetype.',
          (value) => fileTypeValidation(value)
        ),
      otherwise: yup.mixed().nullable()
    }),
    clientKey: yup.mixed().when('$certType', {
      is: TLSAUTH,
      then: yup
        .mixed()
        .test(
          'requiredFile',
          'Please upload a Private Key.',
          (value) => fileRequiredValidation(value)
        )
        .test(
          'fileSize',
          'Private Key file size is too large.',
          (value) => fileSizeValidation(value)
        )
        .test(
          'fileType',
          'Please provide a valid Private Key filetype.',
          (value) => fileTypeValidation(value)
        ),
      otherwise: yup.mixed().nullable()
    })
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    watch
  } = useForm<CredentialDialogFormType>({
    context: {
      certType
    },
    resolver: yupResolver(addCertSchema)
  });

  const watchCredName = watch('credName', '');
  const watchCaFile = watch('caFile');
  const watchClientCert = watch('clientCert');
  const watchClientKey = watch('clientKey');

  const disableAddButton = () => {
    if (certType === TLSAUTH) {
      return watchCredName?.length < 1
        || (watchCaFile && watchCaFile.length < 1)
        || (watchClientCert && watchClientCert.length < 1)
        || (watchClientKey && watchClientKey.length < 1)
        || Object.keys(errors).length > 0;
    }
    return watchCredName?.length < 1
      || watchCaFile?.length < 1
      || Object.keys(errors).length > 0;
  };

  const handleCloseAddCertDialogClick = () => {
    setOpenAddCredentialsDialog((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  useEffect(() => {
    // reset form state to avoid lingering error states from prior attempts
    reset();
  }, [certType, dispatch, isOpen, reset]);

  const onSubmit = (data: CredentialDialogFormType) => {
    const formData = new FormData();
    formData.append('credName', data.credName);
    formData.append('caFile', data.caFile[0]);

    if (certType === TLSAUTH) {
      if (data.clientCert && data.clientKey) {
        formData.append('clientCert', data.clientCert[0]);
        formData.append('clientKey', data.clientKey[0]);
        formData.append('protocol', certType);
      }
    } else {
      formData.append('protocol', certType);
    }
    const submitLoggerCredsObject = {
      formData,
      certType,
      handleCloseAddCertDialogClick,
      setError
    };

    submitLoggerCredsMutate(submitLoggerCredsObject);
  };

  return (
    <Dialog open={isOpen} fullWidth={true}>
      <DialogTitle>
        {certType === TLSUNAUTH
          ? 'Add CA Certificates'
          : 'Add CA Certificates and Private Keys'}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers={true}>
          <Grid container={true} item={true} direction="column" spacing={2}>
            <Grid item={true} alignItems="center" sx={{ mb: 4 }}>
              <TextField
                label="Name"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('credName', {
                  required: true
                })}
                fullWidth={true}
                helperText={errors.credName?.message}
                error={!!errors.credName}
                variant="standard"
              />
            </Grid>
            <Grid item={true}>
              <Typography variant="subtitle1">
                Accepted file types: .pem, .crt, and .cer
              </Typography>
            </Grid>
            {certType === TLSUNAUTH && (
              <Grid item={true}>
                <ControlledDropzone
                  control={control}
                  displayText="Drag and drop a CA File or click"
                  errors={errors}
                  name="caFile"
                />
              </Grid>
            )}
            {certType === TLSAUTH && (
              <Grid container={true} item={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <ControlledDropzone
                    control={control}
                    displayText="CA File"
                    errors={errors}
                    name="caFile"
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <ControlledDropzone
                    control={control}
                    displayText="Client Cert"
                    errors={errors}
                    name="clientCert"
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <ControlledDropzone
                    control={control}
                    displayText="Client Key"
                    errors={errors}
                    name="clientKey"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddCertDialogClick} color="primary">
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={disableAddButton()}
            type="submit"
            variant="contained"
          >
            {submitLoggerCredsIsLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <Typography sx={{ color: 'primary.contrastText' }}>Add</Typography>
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCredentialsDialog;
