import { PaletteMode } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export const userPreferencesSlice = createSlice({
  name: 'navDrawer',
  initialState: {
    // default to dark theme if no pref found in local storage
    theme: localStorage.getItem('themePref') as PaletteMode || 'dark'
  },
  reducers: {
    toggleTheme: (state) => {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      state.theme = newTheme;
    }
  }
});

export const { toggleTheme } = userPreferencesSlice.actions;

export const selectTheme = (state: RootState) => state.userPreferences.theme;

export default userPreferencesSlice.reducer;
