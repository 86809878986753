import React, { useEffect } from 'react';
import {
  Box,
  LinearProgress,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { removeAlert, setAlert } from '../../redux/slices/globalAlertSlice';
import PROGRESS_PROPERTIES from '../../util/progressProperties';
import { useGetDeployDialogFlag, useGetDeploymentStatus } from '../../api/reactQuery/queries';
import { GLOBAL_ALERT_KEYS } from '../../util/constants';

const DeploymentProgressBar = () => {
  const dispatch = useDispatch();
  const { data: deploymentStatus } = useGetDeploymentStatus();
  const { data: deployDialogFlag } = useGetDeployDialogFlag();

  // check for deployment error code and set up globalAlert if there's an error
  useEffect(() => {
    if (deploymentStatus?.status === -1) {
      dispatch(
        setAlert({
          alertType: 'error',
          alertMessage: 'Deployment has encountered an error.  Our operations team has been notified automatically, but you may also contact us via the Support tab or the Feedback form.',
          key: GLOBAL_ALERT_KEYS.DEPLOYMENT_FAILED
        })
      );
    } else {
      dispatch(removeAlert({ keyToRemove: GLOBAL_ALERT_KEYS.DEPLOYMENT_FAILED }));
    }
  }, [deploymentStatus?.status, dispatch]);

  return (
    <>
      {deployDialogFlag?.flag
      && (
        <>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={deploymentStatus?.status || 0} />
            </Box>
            <Box minWidth={20}>
              <Typography variant="body2" color="textSecondary">
                {`${Math.round(deploymentStatus?.status || 0)}%`}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1} mb={2}>
              <Typography
                align="center"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom={true}
              >
                {PROGRESS_PROPERTIES[deploymentStatus?.status as keyof typeof PROGRESS_PROPERTIES]}
              </Typography>
            </Box>
          </Box>

        </>
      )}
    </>

  );
};

export default DeploymentProgressBar;
