import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material';

import type { RootState } from '../store';

export interface SnackbarDetails {
  snackbarMessage: string;
  snackbarType: AlertColor;
}

interface GlobalSnackbarState {
  snackbarQueue: Array<{ key: number, snackbarDetails: SnackbarDetails }>
}

const initialState: GlobalSnackbarState = {
  snackbarQueue: []
};

export const globalSnackbarSlice = createSlice({
  name: 'globalSnackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<SnackbarDetails>) => {
      const newSnackbarInfo = {
        key: new Date().getTime(),
        snackbarDetails: action.payload
      };
      state.snackbarQueue.push(newSnackbarInfo);
    },
    sliceSnackbarQueue: (state) => {
      state.snackbarQueue = state.snackbarQueue.slice(1);
    }
  }
});

export const { setSnackbar, sliceSnackbarQueue } = globalSnackbarSlice.actions;

export const selectGlobalSnackbar = (state: RootState) => state.globalSnackbar;
export const selectGlobalSnackbarQueue = (state: RootState) => state.globalSnackbar.snackbarQueue;

export default globalSnackbarSlice.reducer;
