import React, { useState, useEffect } from 'react';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import { keyframes } from '@mui/material/styles';
import { UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import Title from './Title';
import RequestSupportDialog from '../../../Dialogs/RequestSupportDialog';
import { useLoggedInUserQuery, useMainDashboardQuery } from '../../../../api/reactQuery/queries';
import { TotalCertsResponse } from '../../../../api/MainDashboard/MainDashboardRequests';
import { selectTheme } from '../../../../redux/slices/userPreferencesSlice';

// AWS default limits
// const dimensionLimits = {
//   xs: 10000,
//   s: 250000,
//   m: 2500000
// };

const pulseDarkMode = keyframes`
  0% {
    background-color: #303030;
  }
  25% {
    background-color: #8b3b3b;
  }
  75% {
    background-color: #303030;
  }`;

const pulseLightMode = keyframes`
  0% {
    background-color: #F0F1F6;
  }
  25% {
    background-color: #8b3b3b;
  }
  75% {
    background-color: #F0F1F6;
  }`;

const fixedHeightPaper = {
  display: 'flex',
  flexDirection: 'column',
  height: 240,
  overflow: 'auto',
  padding: 2
} as const;

const TotalCerts = () => {
  const [total, setTotal] = useState(0);
  const theme = useSelector(selectTheme);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const totalCertsQuery = useMainDashboardQuery('getTotalCerts') as UseQueryResult<TotalCertsResponse>;

  const certLimit = loggedInUserData?.user.certLimit || 10000;

  const pulse = theme === 'light' ? pulseLightMode : pulseDarkMode;

  const showTotalCertificates = () => {
    if (total > -1) {
      return total.toLocaleString();
    }
    return '';
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    if (totalCertsQuery.isSuccess) {
      setTotal(totalCertsQuery.data.total);
    }
  }, [totalCertsQuery.isSuccess, totalCertsQuery.data?.total]);

  return (
    <Paper
      sx={{
        ...fixedHeightPaper,
        animation: total > certLimit ? `${pulse} 3s infinite ease` : undefined,
      }}
    >
      <Title>Total Certificates </Title>
      <Box mt={total > certLimit ? 6 : 8} mx="auto">
        <Typography
          color={total > certLimit ? 'error' : 'inherit'}
          component="p"
          // adjust font size smaller if number is at least 7 digits
          variant={total > 999999 ? 'h6' : 'h4'}
        >
          {showTotalCertificates()}
        </Typography>
      </Box>
      { total > certLimit
        && (
          <Tooltip title="Issuance limit exceeded, click for more details">
            <Button
              onClick={handleDialogOpen}
              size="small"
              variant="text"
              sx={{ alignItems: 'flex-start' }}
            >
              <ErrorOutlineIcon color="error" fontSize="small" />
              &nbsp;
              Exceeds Limit
            </Button>
          </Tooltip>
        )}
      <RequestSupportDialog
        handleDialogClose={handleDialogClose}
        isOpen={dialogOpen}
      />
    </Paper>
  );
};

export default TotalCerts;
