import React from 'react';
import {
  Add as AddIcon,
} from '@mui/icons-material';
import {
  Paper,
  Button,
  Typography
} from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import UsersTable from './UsersTable';
import { useSessionExtendQuery, useLoggedInUserQuery, useGetAllUsersQuery } from '../../../../api/reactQuery/queries';
import { CREATE_USER_ROUTE } from '../../../../util/routeConstants';

const AllUsers = () => {
  const history = useHistory();
  const sessionExtendQuery = useSessionExtendQuery();
  const { data: getUsersData } = useGetAllUsersQuery();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const userPrivs = loggedInUserData?.user?.privileges;

  // Perform session check before following link
  const followLink = async (link: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    await sessionExtendQuery.refetch({ throwOnError: true });
    history.push(link);
  };

  return (
    <>
      <Typography component="h1" variant="h4" gutterBottom={true}>
        User Management
      </Typography>
      <Typography variant="subtitle1">
        Here you can create new users, or search for users in your organization
        to view and modify their permissions or deactive accounts.
      </Typography>
      <br />
      <Paper sx={{ overflow: 'auto', p: 4 }}>
        {userPrivs?.MANAGEUSERS_WRITE
          && (
          <div style={{ float: 'right' }}>
            <Button
              component={RouterLink}
              onClick={(
                e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => followLink(CREATE_USER_ROUTE, e)}
              startIcon={<AddIcon />}
              to={CREATE_USER_ROUTE}
              variant="outlined"
            >
              Add User
            </Button>
          </div>
          )}
        { getUsersData
          && (
          <UsersTable
            tableData={getUsersData}
            isOwnProfile={false}
          />
          )}
      </Paper>
    </>
  );
};

export default AllUsers;
