import React from 'react';
import {
  Box,
  Typography
} from '@mui/material';

import StaticPageLayoutContainer from './StaticPageLayoutContainer';

const ThankYou = () => (
  <StaticPageLayoutContainer>
    <Box maxWidth="sm">
      <Typography variant="h5" component="h1" align="center" gutterBottom={true}>
        Thank You!
      </Typography>
      <Typography variant="subtitle1" gutterBottom={true} align="center">
        An e-mail has been sent to the address you provided.
        Click the link in the e-mail to confirm your e-mail address.
        Once confirmed, you can log in and complete the setup of your environment.
      </Typography>
    </Box>
  </StaticPageLayoutContainer>
);

export default ThankYou;
