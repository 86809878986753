import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
  useLocation
} from 'react-router-dom';
import { useQueryClient } from 'react-query';

import ResponsiveDrawer from './layout/ResponsiveDrawer';
import PortalAppBar from './layout/PortalAppBar';
import DeployedDialog from '../Dialogs/DeployedDialog';
import PortalFooter from './layout/PortalFooter';
import GlobalSnackbar from '../GlobalSnackbar';
import DeploymentProgressBar from './DeploymentProgressBar';
import GlobalAlert from './layout/GlobalAlert';
import { useSyslogUnencryptedAlert, useSessionExtendQuery, useLoggedInUserQuery } from '../../api/reactQuery/queries';
import {
  removeAlert,
  setAlert,
  selectGlobalAlertOpen
} from '../../redux/slices/globalAlertSlice';
import { selectDrawerOpen } from '../../redux/slices/navDrawerSlice';
import {
  GLOBAL_ALERT_KEYS,
  SESSION_EXTEND_QUERY,
  SYSLOG_UNENCRYPTED_ALERT_QUERY
} from '../../util/constants';
import PortalRoutes from '../../routes/PortalRoutes';

const drawerWidth = '240px';

const Portal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const drawerOpen = useSelector(selectDrawerOpen);
  const history = useHistory();
  const location = useLocation();
  const alertOpen = useSelector(selectGlobalAlertOpen);
  const syslogUnencryptedUsed = useSyslogUnencryptedAlert();
  const sessionExtendQuery = useSessionExtendQuery();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const theme = useTheme();

  useEffect(() => {
    if (loggedInUserData?.user?.privileges?.LOGGERS_READ) {
      queryClient.refetchQueries(SYSLOG_UNENCRYPTED_ALERT_QUERY);
    }
    // Only needs to run on mount to determine if unencrypted alert needs
    // to run on an interval or not at all
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!syslogUnencryptedUsed.isSuccess) {
      return;
    }
    if (syslogUnencryptedUsed.data?.isTcpEnabled) {
      dispatch(
        setAlert({
          alertType: 'warning',
          alertMessage: 'Logs are being sent to a remote syslog host unencrypted.  This is not advised and should be changed as soon as possible.',
          key: GLOBAL_ALERT_KEYS.SYSLOG_UNENCRYPTED
        })
      );
    } else {
      dispatch(removeAlert({ keyToRemove: GLOBAL_ALERT_KEYS.SYSLOG_UNENCRYPTED }));
    }
  }, [syslogUnencryptedUsed.isSuccess, syslogUnencryptedUsed.data?.isTcpEnabled, dispatch]);

  // Perform session check before following link
  const followLink = async (link: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    await sessionExtendQuery.refetch({ throwOnError: true });
    history.push(link);
  };

  useEffect(() => {
    queryClient.invalidateQueries(SESSION_EXTEND_QUERY);
  }, [queryClient, location]);

  return (
    <Box display="flex">
      <DeployedDialog />
      <GlobalSnackbar />

      <PortalAppBar followLink={followLink} />

      <ResponsiveDrawer drawerWidth={drawerWidth} followLink={followLink} />

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100vh"
        overflow="hidden"
        sx={{
          ml: { xs: 1, sm: drawerOpen ? drawerWidth : 9 },
          transition: () => theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
        }}
      >
        <Toolbar />
        <Box overflow="auto" height="100vh">
          <Container
            maxWidth="lg"
            sx={{
              py: 4,
              // 114px = footer 40px + container padding (32 top, 32 bottom) + footer padding 10px
              minHeight: 'calc(100vh - 114px)'
            }}
          >
            <DeploymentProgressBar />
            {alertOpen && <GlobalAlert />}
            <PortalRoutes />
          </Container>
          <PortalFooter />
        </Box>
      </Box>
    </Box>
  );
};

export default Portal;
