import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import {
  getLoggedInUser,
  getSessionExtend
} from './SessionRequests';
import { basicErrorHandler } from '../reactQuery/queryErrorHandling';
import { INVALID_SESSION, LOGGED_IN_USER_QUERY, SESSION_EXTEND_QUERY } from '../../util/constants';
import { setLoginAlert } from '../../redux/slices/loginAlertSlice';

export const useLoggedInUserQuery = () => {
  const dispatch = useDispatch();
  return useQuery(
    LOGGED_IN_USER_QUERY,
    () => {
      const response = getLoggedInUser()
        .then((res) => res.data);
      return response;
    },
    {
      onSuccess: (response) => {
        if (response?.emailConfirmation?.isEmailConfirmed) {
          dispatch(setLoginAlert({
            loginAlertmessage: 'Your email has been confirmed.',
            isLoginAlertOpen: true,
            loginAlertType: 'success'
          }));
        }
      }
    }
  );
};

export const useSessionExtendQuery = () => {
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const dispatch = useDispatch();
  return useQuery(
    SESSION_EXTEND_QUERY,
    () => {
      const response = getSessionExtend()
        .then((res) => res.data);
      return response;
    },
    {
      enabled: Boolean(loggedInUserData?.user),
      retry: (failureCount, error: AxiosError) => {
        if (error.response?.data.errors) {
          return error.response.data.errors.some((entry: any) => entry.type !== INVALID_SESSION);
        }
        return failureCount < 3;
      },
      onError: (error) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};
