import { createTheme } from '@mui/material/styles';

import commonThemeElements from './commonThemeElements';

const darkPalette = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#D5D7F7'
    },
    secondary: {
      main: '#390c72', // used for AppBar
      contrastText: '#EBEBF2'
    },
    info: {
      light: '#39746e',
      main: '#008D7D'
    },
    background: {
      default: '#202020',
      paper: '#303030'
    },
    text: {
      primary: '#EBEBF2'
    },
    error: {
      main: '#dc143c'
    },
    success: {
      light: '#419745',
      main: '#28b905'
    },
    warning: {
      light: '#ffbb44',
      main: '#ffa726'
    }
  }
});

const darkTheme = createTheme(darkPalette, {
  components: {
    ...commonThemeElements,
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          backgroundColor: '#505050',
          '&.dropzoneErrorStyle': {
            borderColor: '#f44336'
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderLeft: '8px solid transparent',
          paddingLeft: '16px',
          '&.Mui-selected': {
            borderLeft: '8px solid',
            borderColor: darkPalette.palette.primary.dark
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: darkPalette.palette.text.primary
        }
      }
    }
  }
});

export default darkTheme;
