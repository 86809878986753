import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
  Legend
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { Line } from 'react-chartjs-2';
import { UseQueryResult } from 'react-query';

import Title from './Title';
import { useMainDashboardQuery } from '../../../../api/reactQuery/queries';
import { BasicChartResponse } from '../../../../api/MainDashboard/MainDashboardRequests';

ChartJS.register(ArcElement, LineElement, PointElement, TimeScale, Tooltip, Legend);

interface CertChartDataShape {
  label: string,
  data: Array<{ x: string, y: number }>,
  fill: boolean,
  backgroundColor: string,
  borderColor: string
}

interface ChartInstanceShape {
  datasets: CertChartDataShape[]
}

const CertsIssued = () => {
  const theme = useTheme();
  const certsIssuedTodayQuery = useMainDashboardQuery('getCertsIssued24h') as UseQueryResult<BasicChartResponse>;
  const [chartInstance, setChartInstance] = useState<ChartInstanceShape>({
    datasets: []
  });

  const updateChartData = (data: number[], labels: string[]) => {
    const dataSet: Array<{ x: string, y: number }> = [];
    data.forEach((datum, idx) => {
      dataSet.push({ x: labels[idx], y: datum });
    });

    setChartInstance((prevState) => ({
      ...prevState,
      datasets: [
        {
          label: 'Number of Certificates Issued',
          data: dataSet,
          fill: false,
          backgroundColor: '#3cba9f',
          borderColor: '#3cba9f'
        }
      ]
    }));
  };

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time' as const,
        adapters: {
          date: {
            locale: enUS,
          },
        },
        time: {
          displayFormats: {
            hour: 'HH:mm'
          },
          tooltipFormat: 'yyyy-MM-dd HH:mm'
        },
        ticks: {
          color: theme.palette.text.secondary,
          autoSkip: true,
          maxTicksLimit: 12,
          maxRotation: 0,
          precision: 0
        }
      },
      y: {
        ticks: {
          color: theme.palette.text.secondary,
          maxTicksLimit: 8,
          beginAtZero: true,
          precision: 0
        }
      }
    },
    tension: 0.4
  };

  useEffect(() => {
    if (certsIssuedTodayQuery.isSuccess) {
      updateChartData(certsIssuedTodayQuery.data.data, certsIssuedTodayQuery.data.labels);
    }
  }, [certsIssuedTodayQuery.isSuccess,
    certsIssuedTodayQuery.data?.data,
    certsIssuedTodayQuery.data?.labels]);

  return (
    <Card sx={{ height: '100%' }}>
      <Title>Certificates Issued Today</Title>
      <Divider />
      <CardContent>
        <Box position="relative" height="160px">
          <Line data={chartInstance} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CertsIssued;
