import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography
} from '@mui/material';
import { useQueryClient } from 'react-query';

import TOTPDialog from '../twoFADialogs/TOTPDialog';
import * as Constants from '../../../../../util/constants';
import { useGetTOTPStateQuery } from '../../../../../api/reactQuery/queries';
import { TOTPSetPhoneFormType } from '../../../../../types/formTypes';

const TwoFAAccoridionContent = () => {
  const queryClient = useQueryClient();
  const {
    data: getTOTPStateQueryData,
    isSuccess: isGetTOTPStateQuerySuccess
  } = useGetTOTPStateQuery();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [labelObj, setLabelObj] = useState<TOTPSetPhoneFormType>({ label: '', phoneType: 'iphone' });
  // Current Action Type for TOTP Dialog
  const [actionType, setActionType] = useState<Constants.Setup2faActionsType>(Constants.SETUP_TOTP);
  const [
    verifyMode,
    setVerifyMode
  ] = useState<Constants.TotpVerifyModes>(Constants.NEW_TOTP);

  const formatDate = () => {
    const enabledDate = getTOTPStateQueryData?.totpEnabledDate || '';
    const parsedDate = Date.parse(enabledDate);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };

    const enabledDateFormatted = new Intl.DateTimeFormat(
      'default',
      options
    ).format(parsedDate);
    return enabledDateFormatted;
  };

  const handleClickOpen = async () => {
    await queryClient.invalidateQueries(Constants.SESSION_EXTEND_QUERY, {}, { throwOnError: true });

    if (!getTOTPStateQueryData?.totpEnabled) {
      setActionType(Constants.SETUP_TOTP);
    } else {
      setActionType(Constants.CHANGE_PHONE);
      setVerifyMode(Constants.STORED_TOTP);
    }

    setStep(1);
    setLabelObj({ label: '', phoneType: 'iphone' });
    setOpen(true);
  };

  const handleClose = (reason: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  // Open dialog to disable 2FA
  const onDisableClick = async () => {
    await queryClient.invalidateQueries(Constants.SESSION_EXTEND_QUERY, {}, { throwOnError: true });
    setActionType(Constants.DISABLE_TOTP);
    setStep(1);
    setOpen(true);
  };

  // Open dialog to switch Root CA TOTP state
  const onRootCASwitchClick = async (event: any) => {
    const newState = event.target.checked;
    event.preventDefault();

    await queryClient.invalidateQueries(Constants.SESSION_EXTEND_QUERY, {}, { throwOnError: true });

    if (newState) {
      setActionType(Constants.ENABLE_ROOTCA_TOTP);
    } else {
      setActionType(Constants.DISABLE_ROOTCA_TOTP);
    }
    setStep(1);
    setOpen(true);
  };

  const nextStep = (stepVal: number) => {
    setStep(stepVal);
  };

  useEffect(() => {
    queryClient.invalidateQueries(Constants.SESSION_EXTEND_QUERY, {}, { throwOnError: true });
  }, [step, queryClient]);

  return (
    <>
      <TOTPDialog
        open={open}
        handleClose={handleClose}
        nextStep={nextStep}
        step={step}
        labelObj={labelObj}
        setLabel={setLabelObj}
        actionType={actionType}
        verifyMode={verifyMode}
        setVerifyMode={setVerifyMode}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            Two-Factor Authentication
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container={true} direction="column">
            <Grid item={true} xs={12} mb={3}>
              <Typography variant="subtitle1">
                Use a TOTP Two-Factor authenticator app (e.g. Google
                Authenticator, LastPass Authenticator) to add a second
                verification step for login, and for starting the Root CA.
              </Typography>
            </Grid>
            {isGetTOTPStateQuerySuccess
              ? getTOTPStateQueryData.totpEnabled
                ? (
                  <>
                    <Grid container={true} spacing={5}>
                      <Grid item={true} xs={12}>
                        <Divider />
                        <Box mx={1} my={2}>
                          <Typography gutterBottom={true} variant="body1">
                            Device
                          </Typography>
                          <Grid container={true} justifyContent="space-between">
                            <Grid item={true} xs={9}>
                              <Typography color="textSecondary" variant="body2">
                                {`Authenticator App on ${getTOTPStateQueryData.totpPhoneType === 'IP'
                                  ? 'iPhone' : getTOTPStateQueryData.totpPhoneType === 'AN'
                                    ? 'Android' : 'Other Device'}`}
                              </Typography>
                              <Typography
                                color="textSecondary"
                                sx={{ mb: 3 }}
                                variant="body2"
                              >
                                {`${getTOTPStateQueryData.totpLabel} - Added: ${formatDate()}`}
                              </Typography>
                            </Grid>
                            <Grid item={true} xs={true}>
                              <Grid container={true} justifyContent="flex-end">
                                <IconButton
                                  aria-label="Disable TOTP"
                                  onClick={onDisableClick}
                                >
                                  <DeleteIcon color="primary" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                        <Box m={1}>
                          <Typography gutterBottom={true} variant="body1">
                            Options
                          </Typography>

                          <FormControlLabel
                            control={(
                              <Switch
                                checked={getTOTPStateQueryData.totpRootCAEnabled}
                                name="totpRootCASwitch"
                                color="primary"
                                onClick={onRootCASwitchClick}
                              />
                        )}
                            label={(
                              <Typography variant="body2">
                                Enforce for Root CA Start
                              </Typography>
                        )}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} display="grid" justifyContent="flex-end">
                      <Button
                        onClick={handleClickOpen}
                        color="primary"
                        variant="outlined"
                      >
                        CHANGE PHONE
                      </Button>
                    </Grid>
                  </>
                )
                : (
                  <Grid item={true} xs={12} display="grid" justifyContent="flex-end">
                    <Button
                      onClick={handleClickOpen}
                      color="primary"
                      variant="outlined"
                    >
                      SET UP
                    </Button>
                  </Grid>
                )
              : <Typography>Loading...</Typography>}

          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default TwoFAAccoridionContent;
