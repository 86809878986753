import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import CredentialTableRow from './CredentialTableRow';
import { useCredList } from '../../../../../api/reactQuery/queries';
import { TlsProtocols } from '../../../../../util/constants';

interface CredentialsTableProps {
  protocol: TlsProtocols;
}

const CredentialsTable = ({
  protocol
}: CredentialsTableProps) => {
  const { isLoading, data } = useCredList(protocol);

  return (
    <>
      {isLoading ? (
        <>
          <Grid item={true} alignItems="center">
            <Typography>Loading...</Typography>
          </Grid>
        </>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell key="certUserDefinedName">Name</TableCell>
                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((tableentry: any) => (
                <CredentialTableRow
                  key={tableentry.credentialName}
                  row={tableentry}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CredentialsTable;
