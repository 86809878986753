import React, { useRef, useState } from 'react';
import {
  AppBar,
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { ManageAccounts as ManageAccountsIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import logo from '../../../img/logo.png';
import { selectDrawerOpen, toggleDrawer } from '../../../redux/slices/navDrawerSlice';
import { selectTheme, toggleTheme } from '../../../redux/slices/userPreferencesSlice';
import { useLoggedInUserQuery } from '../../../api/reactQuery/queries';
import { useLogoutUserMutation } from '../../../api/reactQuery/mutations';
import { PROFILE_ROUTE } from '../../../util/routeConstants';

interface PortalAppBarProps {
  followLink(address: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

const PortalAppBar = (
  {
    followLink
  }: PortalAppBarProps
) => {
  const dispatch = useDispatch();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const drawerOpen = useSelector(selectDrawerOpen);
  const [openMenu, setOpenMenu] = useState(false);
  const theme = useTheme();
  const currentTheme = useSelector(selectTheme);
  const matchesSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const { data: loggedInUserData, isSuccess: loggedInUserIsSuccess } = useLoggedInUserQuery();
  const { mutate: logoutUserMutate } = useLogoutUserMutation();

  // Profile Menu Methods
  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (event: any) => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  };

  const handleLogout = () => {
    logoutUserMutate();
  };

  const handleMenuLink = (e: any, link: string) => {
    setOpenMenu(false);
    followLink(link, e);
  };

  const openCloseDrawer = () => {
    dispatch(toggleDrawer());
  };

  const changeTheme = () => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    localStorage.setItem('themePref', newTheme);
    dispatch(toggleTheme());
    setOpenMenu(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openMenu);
  React.useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  // End Profile Menu Methods

  return (
    <AppBar position="fixed" enableColorOnDark={true} color="secondary">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={openCloseDrawer}
          size="large"
          sx={{
            mr: '20px',
            transform: drawerOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: () => theme.transitions.create(['transform'])
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap={true}
          sx={{ display: 'flex', flexGrow: 1 }}
        >
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{ width: 150 }}
          />
        </Typography>
        {matchesSmall && loggedInUserIsSuccess && (
          <Typography variant="subtitle2" color="secondary.contrastText">
            {`${loggedInUserData?.user?.email} - ${loggedInUserData?.user?.domainName}`}
          </Typography>
        )}
        <IconButton
          color="inherit"
          ref={anchorRef}
          aria-controls={openMenu ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          disableFocusRipple={true}
          onClick={handleToggle}
          size="large"
        >
          <ManageAccountsIcon />
        </IconButton>
        <Popover
          open={openMenu}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          anchorEl={anchorRef.current}
        >
          <MenuList
            autoFocusItem={openMenu}
            id="menu-list-grow"
            onKeyDown={handleListKeyDown}
          >
            <MenuItem
              onClick={(e) => handleMenuLink(e, PROFILE_ROUTE)}
            >
              Profile
            </MenuItem>
            <MenuItem onClick={changeTheme}>Light/Dark</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

export default PortalAppBar;
