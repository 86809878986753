import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export interface ErrorDialogState {
  openDialog: boolean,
  errorType: string,
  errorMessage: string
}

const initialState: ErrorDialogState = {
  openDialog: false,
  errorType: '',
  errorMessage: ''
};

export const errorDialogSlice = createSlice({
  name: 'errorDialog',
  initialState,
  reducers: {
    closeErrorDialog: () => initialState,
    displayErrorDialog: (state, action: PayloadAction<ErrorDialogState>) => {
      state.openDialog = action.payload.openDialog;
      state.errorMessage = action.payload.errorMessage;
      state.errorType = action.payload.errorType;
    }
  }
});

export const {
  closeErrorDialog,
  displayErrorDialog
} = errorDialogSlice.actions;

export const selectErrorDialogOpen = (state: RootState) => state.errorDialog.openDialog;
export const selectErrorErrorMessage = (state: RootState) => state.errorDialog.errorMessage;
export const selectErrorErrorType = (state: RootState) => state.errorDialog.errorType;

export default errorDialogSlice.reducer;
