const commonThemeElements = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: '0.875rem',
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        marginBottom: '16px'
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&:-webkit-autofill': {
          webkitBoxShadow: '0 0 0px 1000px #444 inset'
        }
      }
    }
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover'
    },
    styleOverrides: {
      root: {
        overflowWrap: 'break-word'
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'unset'
      }
    }
  },
  MuiTextField: {
    defaultProps: {
      inputProps: {
        'data-lpignore': 'true'
      },
      variant: 'standard'
    }
  },
  MuiTypography: {
    defaultProps: {
      lineHeight: 1.43
    }
  }
};

export default commonThemeElements;
