import React from 'react';
import {
  ListItem,
  Grid
} from '@mui/material';
import { useSelector } from 'react-redux';

import PendingAddItem from './PendingAddItem';
import PendingEditedItem from './PendingEditedItem';
import ActiveEditItem from './ActiveEditItem';
import PendingRemoveItem from './PendingRemoveItem';
import {
  IP_EDIT_CASE,
  PendingIpShape,
  PENDING_IP_SUFFIX,
  selectIpInUpdateModeKey
} from '../../redux/slices/sourceIpSlice';
import ExistingItem from './ExistingItem';

interface IpListItemsProps {
  description: string,
  fullList: PendingIpShape[],
  propKey: string,
  status: keyof typeof IP_EDIT_CASE,
  text: string
}

const IpListItems = ({
  description,
  fullList,
  propKey,
  status,
  text
}: IpListItemsProps) => {
  const ipInUpdateModeKey = useSelector(selectIpInUpdateModeKey);
  const formatItemToDisplay = () => {
    if (status === IP_EDIT_CASE.ADD) {
      return (
        <PendingAddItem
          description={description}
          text={text}
          propKey={propKey}
          status={status}
          fullList={fullList}
        />
      );
    }
    if (fullList.find((match) => match.key?.split(PENDING_IP_SUFFIX)[0] === propKey
    && match.status === IP_EDIT_CASE.REMOVE)) {
      return (
        <PendingRemoveItem
          description={description}
          text={text}
          propKey={propKey}
        />
      );
    }
    if (propKey === ipInUpdateModeKey) {
      return (
        <ActiveEditItem
          description={description}
          text={text}
          fullList={fullList}
        />
      );
    }
    const pendingEditedEntry = fullList.find((match) => match.key?.split(PENDING_IP_SUFFIX)[0]
      === propKey && match.status === IP_EDIT_CASE.UPDATE);
    if (pendingEditedEntry) {
      return (
        <PendingEditedItem
          description={description}
          text={text}
          propKey={propKey}
          pendingText={pendingEditedEntry.text}
          pendingDescription={pendingEditedEntry.description}
          status={status}
        />
      );
    }
    return (
      <ExistingItem
        description={description}
        text={text}
        propKey={propKey}
        status={status}
      />
    );
  };
  // for any changes, and entry is added to the PendingIP list, the query list consist of the saved
  // pending has the change wtih a status, when the status is remove or update, we do not want
  // to display both entries, (one in pending and one in original), so we only display the original
  // (one without a status) and strikethrough that one.

  return (
    <>
      {!(status === IP_EDIT_CASE.REMOVE || status === IP_EDIT_CASE.UPDATE)
        && (
          <ListItem divider={true}>
            <Grid container={true} item={true}>
              {formatItemToDisplay()}
            </Grid>
          </ListItem>
        )}
    </>
  );
};

export default IpListItems;
