import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  useMediaQuery,
  useTheme,
  Button
} from '@mui/material';
import {
  EditOutlined as EditOutlinedIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon
} from '@mui/icons-material';

import {
  startUpdateIp,
  removeIp,
  IP_EDIT_CASE,
  selectIpInUpdateModeKey
} from '../../redux/slices/sourceIpSlice';
import { CHANGE_IN_PROGRESS } from '../../util/constants';
import { useSourceIpQuery, useLoggedInUserQuery } from '../../api/reactQuery/queries';

interface ExistingItemProps {
  description: string,
  propKey: string,
  status: keyof typeof IP_EDIT_CASE,
  text: string
}

const ExistingItem = ({
  description,
  propKey,
  status,
  text
}: ExistingItemProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down('sm'));
  const sourceIpQueryResult = useSourceIpQuery();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const ipInUpdateModeKey = useSelector(selectIpInUpdateModeKey);
  const userPrivs = loggedInUserData?.user?.privileges;
  const disableActionButtons = Boolean(ipInUpdateModeKey)
    || sourceIpQueryResult?.data?.reason === CHANGE_IN_PROGRESS
    || !userPrivs?.SOURCEIP_WRITE;

  const removeIPFromDisplayed = () => {
    dispatch(removeIp({ text, description, key: propKey }));
  };

  const startIpUpdate = () => {
    dispatch(startUpdateIp({
      text, description, key: propKey, status
    }));
  };

  const desktopViewButtons = () => (
    <ListItemSecondaryAction>
      <IconButton
        disabled={disableActionButtons}
        onClick={() => startIpUpdate()}
        color="primary"
        size="large"
        aria-label="Update Entry"
      >
        <EditOutlinedIcon />
      </IconButton>
      <IconButton
        disabled={disableActionButtons}
        onClick={() => removeIPFromDisplayed()}
        color="primary"
        size="large"
        aria-label="Delete Entry"
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItemSecondaryAction>
  );

  const mobileViewButtons = () => (
    <Grid container={true} sx={{ justifyContent: 'flex-end', mt: 1 }}>
      <Button
        sx={{ mr: 2 }}
        disabled={disableActionButtons}
        onClick={() => startIpUpdate()}
        startIcon={<EditOutlinedIcon />}
        variant="outlined"
        color="primary"
        aria-label="Update Entry"
      >
        Edit
      </Button>
      <Button
        disabled={disableActionButtons}
        onClick={() => removeIPFromDisplayed()}
        startIcon={<RemoveCircleOutlineIcon />}
        variant="outlined"
        color="primary"
        aria-label="Delete Entry"
      >
        Remove
      </Button>
    </Grid>
  );

  return (
    <>
      <Grid item={true} xs={12} sm={3}>
        <ListItemText primary={text} sx={{ display: 'flex', justifyContent: isMobileLayout ? 'flex-end' : 'flex-start' }} />
      </Grid>
      <Grid item={true} xs={12} sm={7}>
        <ListItemText
          primary={description}
        />
        {isMobileLayout
          ? mobileViewButtons()
          : desktopViewButtons()}
      </Grid>

    </>
  );
};

export default ExistingItem;
