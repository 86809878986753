import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface TcpLoggingAlertDialogProps {
  openTcpAlertDialog: boolean,
  setOpenTcpAlertDialog: React.Dispatch<React.SetStateAction<boolean>>,
}

const TcpLoggingAlertDialog = ({
  openTcpAlertDialog,
  setOpenTcpAlertDialog,

}: TcpLoggingAlertDialogProps) => {
  const handleCancelClick = () => {
    setOpenTcpAlertDialog(false);
  };

  return (
    <Dialog open={openTcpAlertDialog}>
      <DialogTitle>TCP Unencrypted Selected</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This option should be used only for testing connectivity. A banner
          will show at the top of all screens until this option is changed.
          Make sure no sensitive certificates are generated while this setting
          is in place. Are you sure you want to continue?
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleCancelClick} color="primary">
            No, cancel
          </Button>
          <Button color="primary" type="submit" form="add-syslog-form">
            Yes, continue
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default TcpLoggingAlertDialog;
