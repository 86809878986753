import React from 'react';
import { Box, Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import NavListItems from '../NavListItems';
import { selectDrawerOpen, toggleDrawer } from '../../../redux/slices/navDrawerSlice';

interface ResponsiveDrawerProps {
  drawerWidth: string;
  followLink(address: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

const ResponsiveDrawer = (
  {
    drawerWidth,
    followLink
  }: ResponsiveDrawerProps
) => {
  const dispatch = useDispatch();
  const drawerOpen = useSelector(selectDrawerOpen);
  const theme = useTheme();

  const handleDrawerToggle = () => {
    dispatch(toggleDrawer());
  };

  return (
    <>
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            top: '64px',
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            width: drawerOpen ? drawerWidth : '72px',
            transition: () => theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen
            })
          }
        }}
      >
        <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
          <NavListItems followLink={followLink} />
        </Box>
      </Drawer>

      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', top: '56px', width: drawerWidth }
        }}
      >
        <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
          <NavListItems followLink={followLink} isMobileLayout={true} />
        </Box>
      </Drawer>
    </>
  );
};

export default ResponsiveDrawer;
