import React, { useEffect } from 'react';
import {
  Link,
  Typography
} from '@mui/material';
import { useQueryClient } from 'react-query';

import useEjbcaUrl from '../../util/useEjbcaUrl';
import CopyToClipboard from '../../util/CopyToClipboard';
import { SESSION_EXTEND_QUERY } from '../../util/constants';

const DeployedDialogText = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(SESSION_EXTEND_QUERY);
  }, [queryClient]);

  const text1 = `Retrieve your superadmin keystore that will be used as the
  credentials to access your EJBCA administrative interface. The username
  will be "superadmin" and the password will be the password chosen during
  wizard configuration. Click the link below and accept the certificate
  warning to retrieve these credentials.`;

  const text2 = `We recommend using Firefox since it has its own key storage. Import
  the superadmin.p12 into FireFox via the menu under FireFox > Preferences
  > Privacy and Security > Certificates > View Certificates > Your
  Certificates > Import. The password to the p12 certificate file will be
  the same password used to retrieve it.`;

  const text3 = `Click administration in the Public Web Portal to start configuring
  EJBCA.`;

  return (
    <div>
      <Typography gutterBottom={true}>
        {`1. ${text1}`}
      </Typography>
      <Typography gutterBottom={true}>
        <Link
          color="primary"
          href={useEjbcaUrl(
            'https',
            'issuing',
            '/ejbca/enrol/keystore.jsp'
          )}
          target="_blank"
        >
          {useEjbcaUrl('https', 'issuing', '/ejbca/enrol/keystore.jsp')}
        </Link>
        {' '}
        <CopyToClipboard copyText={useEjbcaUrl('https', 'issuing', '/ejbca/enrol/keystore.jsp')} />
      </Typography>
      <Typography gutterBottom={true}>
        {`2. ${text2}`}
      </Typography>
      <Typography gutterBottom={true}>
        {`3. ${text3}`}
      </Typography>
      <Typography gutterBottom={true}>
        <Link
          color="primary"
          href={useEjbcaUrl('https', 'issuing', '/ejbca/adminweb')}
          target="_blank"
        >
          {useEjbcaUrl('https', 'issuing', '/ejbca/adminweb')}
        </Link>
        {' '}
        <CopyToClipboard copyText={useEjbcaUrl('https', 'issuing', '/ejbca/adminweb')} />
      </Typography>
    </div>
  );
};

export default DeployedDialogText;
