import axios, { AxiosResponse } from 'axios';

export type DashboardEndpoints = 'getCertsExp' | 'getCertsIssued24h' | 'getEEProfileStats' | 'getHealthCheck' | 'getTotalCerts';

export interface BasicChartResponse {
  data: number[];
  labels: string[];
}

export interface HealthCheckResponse {
  health: string;
}

export interface TotalCertsResponse {
  total: number;
}

export const getMainDashboardApis = (
  apiName: DashboardEndpoints
): Promise<AxiosResponse<BasicChartResponse | HealthCheckResponse | TotalCertsResponse>> => axios.get(`/api/${apiName}`);
