import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material';

import type { RootState } from '../store';

export interface LoginAlertState {
  loginAlertmessage: string;
  isLoginAlertOpen: boolean;
  loginAlertType: AlertColor;
}

const initialState: LoginAlertState = {
  loginAlertmessage: '',
  isLoginAlertOpen: false,
  loginAlertType: 'warning'
};

export const loginAlertSlice = createSlice({
  name: 'loginAlert',
  initialState,
  reducers: {
    setLoginAlert: (state, action: PayloadAction<LoginAlertState>) => {
      state.loginAlertmessage = action.payload.loginAlertmessage;
      state.isLoginAlertOpen = action.payload.isLoginAlertOpen;
      state.loginAlertType = action.payload.loginAlertType;
    },
    clearLoginAlert: () => initialState
  }
});

export const { setLoginAlert, clearLoginAlert } = loginAlertSlice.actions;

export const selectLoginAlertMessage = (state: RootState) => state.loginAlert.loginAlertmessage;
export const selectLoginAlertOpen = (state: RootState) => state.loginAlert.isLoginAlertOpen;
export const selectLoginAlertType = (state: RootState) => state.loginAlert.loginAlertType;

export default loginAlertSlice.reducer;
