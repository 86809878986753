import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AlreadyLoggedIn from '../components/AlreadyLoggedIn';
import Registration from '../components/Registration';
import Login from '../components/Login';
import Portal from '../components/portal/Portal';
import MoreInfo from '../components/MoreInfo';
import ThankYou from '../components/ThankYou';
import ForgotPassword from '../components/ForgotPassword';
import SetPassword from '../components/SetPassword';
import NotFound from '../components/NotFound';
import TokenError from '../components/TokenError';
import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';
import { useLoggedInUserQuery } from '../api/reactQuery/queries';
import {
  ALREADY_LOGGED_IN,
  PORTAL_ROUTE,
  DASHBOARD_ROUTE,
  TOKEN_ERROR_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  MORE_INFO_ROUTE,
  THANK_YOU_ROUTE,
  OLD_DASHBOARD_ROUTE
} from '../util/routeConstants';

const AppRoutes = () => {
  const { isSuccess: isUserQuerySuccessful } = useLoggedInUserQuery();

  return (
    <>
      {isUserQuerySuccessful && (
      <Switch>
        {/* Auth Routes: Registering, Logging In, etc */}
        <AuthRoute path="/" component={Registration} exact={true} />
        <AuthRoute path={LOGIN_ROUTE} component={Login} />
        <AuthRoute path={THANK_YOU_ROUTE} component={ThankYou} />

        {/* Redirects for partial paths or outdated links */}
        <Route
          path={PORTAL_ROUTE}
          exact={true}
          render={() => <Redirect to={DASHBOARD_ROUTE} />}
        />
        <Route
          path={OLD_DASHBOARD_ROUTE}
          render={() => <Redirect to={DASHBOARD_ROUTE} />}
        />

        {/* Protected Routes: Check logged in, check enrollment options, check privs, etc */}
        <ProtectedRoute path={MORE_INFO_ROUTE} component={MoreInfo} />
        <ProtectedRoute path={PORTAL_ROUTE} component={Portal} />

        {/* Basic Routes: No special checks */}
        <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
        <Route path={RESET_PASSWORD_ROUTE} component={SetPassword} />
        <Route path={TOKEN_ERROR_ROUTE} component={TokenError} />
        <Route path={ALREADY_LOGGED_IN} component={AlreadyLoggedIn} />
        <Route component={NotFound} />
      </Switch>
      )}
    </>
  );
};

export default AppRoutes;
