import React from 'react';
import { Grid, Paper } from '@mui/material';

import CertsIssued from './CertsIssued';
import SysHealth from './SysHealth';
import TotalCerts from './TotalCerts';
import CertsByProfile from './CertsByProfile';
import CertExpiry from './CertExpiry';

const paperStyle = {
  p: 2,
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column'
} as const;

const fixedHeightStyle = {
  height: 240
} as const;

const Dashboard = () => {
  const fixedHeightPaper = { ...paperStyle, ...fixedHeightStyle };

  return (
    <main>
      <Grid container={true} spacing={2}>
        {/* System Health */}
        <Grid item={true} xs={6} md={4} lg={2}>
          <Paper sx={fixedHeightPaper}>
            <SysHealth />
          </Paper>
        </Grid>
        {/* Total Certificates */}
        <Grid item={true} xs={6} md={4} lg={2}>
          <TotalCerts />
        </Grid>
        {/* Chart */}
        <Grid item={true} xs={12} md={8} lg={8}>
          <Paper sx={fixedHeightPaper}>
            <CertsIssued />
          </Paper>
        </Grid>
        {/* Certs By Profile */}
        <Grid item={true} xs={12} md={6}>
          <Paper sx={paperStyle}>
            <CertsByProfile />
          </Paper>
        </Grid>
        {/* Certs Expiry */}
        <Grid item={true} xs={12} md={6}>
          <Paper sx={paperStyle}>
            <CertExpiry />
          </Paper>
        </Grid>
      </Grid>
    </main>
  );
};

export default Dashboard;
