import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  submitMoreInfo,
  submitRegisterUser
} from './RegistrationRequests';
import { LOGGED_IN_USER_QUERY } from '../../util/constants';
import { fieldValidationErrorHandler } from '../reactQuery/queryErrorHandling';
import { THANK_YOU_ROUTE } from '../../util/routeConstants';

export const useRegisterUserMutation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return useMutation(
    submitRegisterUser,
    {
      onSuccess: () => {
        history.push(THANK_YOU_ROUTE);
      },
      onError: (error: AxiosError, registerUserObject) => {
        fieldValidationErrorHandler(error, dispatch, registerUserObject.setError);
      }
    }
  );
};

export const useMoreInfoMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    submitMoreInfo,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(LOGGED_IN_USER_QUERY);
      },
      onError: (error: AxiosError, moreInfoObject) => {
        fieldValidationErrorHandler(error, dispatch, moreInfoObject.setError);
      }
    }
  );
};
