import React, { useState } from 'react';
import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Box
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { useQueryClient, UseQueryResult } from 'react-query';

import { useRouteMatch } from 'react-router-dom';
import inputValidation from './InputValidation';
import {
  addIp,
  clearHelperTextError,
  IP_EDIT_CASE,
  selectIpHelperText,
  selectPendingIps
} from '../../redux/slices/sourceIpSlice';
import { CHANGE_IN_PROGRESS, SESSION_EXTEND_QUERY } from '../../util/constants';
import { SourceIpQueryResponse } from '../../api/SourceIp/SourceIpRequests';
import { BaseIpShape } from '../../types/formTypes';
import { MORE_INFO_ROUTE } from '../../util/routeConstants';

interface AddIpFormProps {
  sourceIpQueryResult?: UseQueryResult<SourceIpQueryResponse>
}

const AddIPForm = ({
  sourceIpQueryResult
}: AddIpFormProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const forceMobileLayout = useRouteMatch({
    path: MORE_INFO_ROUTE,
    sensitive: false
  });
  const [validationPassed, setValidationPassed] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    getValues
  } = useForm({ defaultValues: { text: '', description: '' } });

  const pendingIps = useSelector(selectPendingIps);
  let combinedIpsArray = sourceIpQueryResult?.data
    ? [...JSON.parse(sourceIpQueryResult?.data?.ipAddresses)]
    : [];
  if (pendingIps) {
    combinedIpsArray = [...combinedIpsArray, ...pendingIps];
  }
  const maxListLength = 100;

  const helperTextValidationError = useSelector(selectIpHelperText);
  const helperTextValidationErrorForAdd = helperTextValidationError.targetTextField
    === IP_EDIT_CASE.ADD;

  const validateIp = () => {
    setValidationPassed(false);
    if (helperTextValidationErrorForAdd) {
      dispatch(clearHelperTextError());
    }
    const ipValue = getValues('text');
    const ipObjectToValidate = {
      text: ipValue,
      description: ''
    };
    if (inputValidation(
      ipObjectToValidate,
      combinedIpsArray,
      maxListLength,
      IP_EDIT_CASE.ADD,
      dispatch
    )
    ) {
      setValidationPassed(true);
    }
  };

  const onAddIp = (data: BaseIpShape) => {
    dispatch(addIp(data));
    // Extend Session every time an IP is added, so that it doesn't timeout when making edits,
    queryClient.invalidateQueries(SESSION_EXTEND_QUERY);
    reset();
    setValidationPassed(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onAddIp)}>
      <FormHelperText>
        For example 8.8.8.8/32. Ports 80 and 443 will be enabled to the
        provided IP(s). If you are unsure you can add 0.0.0.0/0 to allow all
        IPs.
      </FormHelperText>
      <Grid
        container={true}
        alignItems="baseline"
        spacing={2}
      >
        <Grid item={true} xs={12} sm={forceMobileLayout ? 12 : 3}>
          <TextField
            fullWidth={true}
            label="Source IP"
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('text', {
              onChange: debounce(() => validateIp(), 300)
            })}
            error={helperTextValidationErrorForAdd}
            helperText={helperTextValidationErrorForAdd && helperTextValidationError?.message}
            variant="standard"
          />
        </Grid>
        <Grid item={true} xs={12} sm={forceMobileLayout ? 12 : 9}>
          <TextField
            fullWidth={true}
            label="Description"
            inputProps={{ maxLength: 255 }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('description')}
            variant="standard"
          />
        </Grid>
        <Grid item={true} container={true} direction="row-reverse" xs={12}>
          <Button
            sx={{ mt: 1 }}
            type="submit"
            color="primary"
            variant="contained"
            disabled={sourceIpQueryResult?.data?.reason === CHANGE_IN_PROGRESS || !validationPassed}
            startIcon={<AddIcon />}
            aria-label="Add"
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddIPForm;
