import React from 'react';

import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';

const visuallyHidden = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1
} as const;

interface EnhancedTableHeadProps {
  orderBy: string;
  sortDirection: 'asc' | 'desc';
  onRequestSort(event: any, property: string): void;
}

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const {
    onRequestSort,
    orderBy,
    sortDirection,
  } = props;

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'data',
      numeric: false,
      disablePadding: true,
      label: 'End Entity Profile'
    },
    {
      id: 'total',
      numeric: true,
      disablePadding: true,
      label: 'Total Certificates'
    },
    {
      id: 'percent', numeric: true, disablePadding: true, label: 'Percent'
    }
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? sortDirection : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sortDirection : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
