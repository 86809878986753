import React, { useEffect } from 'react';
import { Link as routerLink } from 'react-router-dom';
import {
  Backdrop,
  Grid,
  LinearProgress,
  Link,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearBruteForceBackdrop,
  setBruteForceBackdrop,
  selectBruteForceBackdropOpen,
  selectBruteForceBackdropCountdown,
  selectBruteForceBackdropFailedPwAttempt
} from '../../../redux/slices/bruteForceBackdropSlice';
import { useLogoutUserMutation } from '../../../api/reactQuery/mutations';
import EXTERNAL_LINKS from '../../../util/externalLinks';
import { FORGOT_PASSWORD_ROUTE } from '../../../util/routeConstants';

const BruteForceBackdrop = () => {
  const dispatch = useDispatch();
  const { mutate: logoutUserMutate } = useLogoutUserMutation();
  const backdropOpen = useSelector(selectBruteForceBackdropOpen);
  const backdropCountdown = useSelector(selectBruteForceBackdropCountdown);
  const isFailedPasswordAttempt = useSelector(selectBruteForceBackdropFailedPwAttempt);

  useEffect(() => {
    if (backdropCountdown === 0) {
      dispatch(clearBruteForceBackdrop());
    } else {
      const interval = setInterval(() => {
        dispatch(
          setBruteForceBackdrop({
            backdropOpen: true,
            backdropCountdown: backdropCountdown - 1,
            isFailedPasswordAttempt
          })
        );
      }, 1000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [backdropCountdown, dispatch, isFailedPasswordAttempt]);

  const clearBackdrop = () => {
    dispatch(clearBruteForceBackdrop());
    logoutUserMutate();
  };

  return (
    <Backdrop open={backdropOpen} sx={{ zIndex: 1400 }}>
      <Grid>
        <Grid item={true} alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary" component="h3">
            {`Too many attempts. Please wait ${backdropCountdown} seconds.`}
          </Typography>
        </Grid>
        <Grid item={true} alignItems="center">
          <LinearProgress sx={{ mt: 2, mb: 2 }} />
        </Grid>
        <Grid container={true} item={true} alignItems="center" justifyContent="center">
          {isFailedPasswordAttempt ? (
            <Link
              to={FORGOT_PASSWORD_ROUTE}
              component={routerLink}
              onClick={clearBackdrop}
              variant="h5"
              sx={{ alignContent: 'center' }}
            >
              Forgot password?
            </Link>
          ) : (
            <Link href={EXTERNAL_LINKS.SUPPORT_EMAIL} target="_blank" variant="h5">
              Email Support
            </Link>
          )}
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default BruteForceBackdrop;
