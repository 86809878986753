import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import { START, STOP } from '../../util/constants';

interface RootCaDialogProps {
  actionType: typeof START | typeof STOP,
  handleCloseConfirmDialog: (reason: string) => void,
  open: boolean,
  startRootCA: () => void,
  stopRootCA: () => void
}

const RootCADialog = ({
  actionType,
  handleCloseConfirmDialog,
  open,
  startRootCA,
  stopRootCA
}: RootCaDialogProps) => (
  <Dialog
    open={open}
    onClose={(_e, reason) => handleCloseConfirmDialog(reason)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {actionType === STOP ? 'Stop Root CA?' : 'Start Root CA?'}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {actionType === STOP
          ? 'Click the Confirm button below to stop the Root CA'
          : 'Click the Confirm button below to start the Root CA'}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleCloseConfirmDialog('button')} color="primary">
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={
                actionType === STOP ? stopRootCA : startRootCA
              }
        color="primary"
        autoFocus={true}
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

export default RootCADialog;
