import React, { useState } from 'react';
import {
  Button,
  Box,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as routerLink } from 'react-router-dom';

import SignupLink from './SignupLink';
import StaticPageLayoutContainer from './StaticPageLayoutContainer';
import { useForgotPasswordMutation } from '../api/reactQuery/mutations';
import { ForgotPasswordFormType } from '../types/formTypes';
import { LOGIN_ROUTE } from '../util/routeConstants';

const ForgotPassword = () => {
  const [messageFromServer, setMessageFromServer] = useState('');
  const { mutate: forgotPasswordMutate } = useForgotPasswordMutation();

  const ForgotPasswordSchema = yup.object().shape({
    forgotPasswordEmail: yup
      .string()
      .required('Email is required')
      .email('Please enter a valid email')
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError
  } = useForm<ForgotPasswordFormType>({
    resolver: yupResolver(ForgotPasswordSchema),
    mode: 'onChange'
  });

  const emailHandleChange = () => {
    setMessageFromServer('');
  };

  const onSubmit = (data: ForgotPasswordFormType) => {
    const forgotPasswordObject = {
      data,
      setError,
      setMessageFromServer
    };
    forgotPasswordMutate(forgotPasswordObject);
  };

  return (
    <StaticPageLayoutContainer>
      <>
        <Typography component="h1" variant="h5" gutterBottom={true}>
          Trouble Logging In?
        </Typography>
        <Typography variant="subtitle1" component="h2" gutterBottom={true} align="justify">
          Enter your email address below, and we will send you a link to reset
          your password if the account is valid.
        </Typography>

        <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            autoFocus={true}
            variant="outlined"
            margin="normal"
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('forgotPasswordEmail', {
              onChange: emailHandleChange,
              required: true
            })}
            fullWidth={true}
            id="forgotPasswordEmail"
            label="Email Address"
            autoComplete="email"
            error={!!errors.forgotPasswordEmail}
            helperText={errors.forgotPasswordEmail?.message}
          />
          <Typography variant="body2" color="textPrimary" component="p">
            {messageFromServer}
          </Typography>

          <Button
            type="submit"
            disabled={!isValid}
            fullWidth={true}
            variant="contained"
            color="primary"
            sx={{ mt: 3, mr: 0, mb: 2 }}
          >
            Send Log In Link
          </Button>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={LOGIN_ROUTE} component={routerLink}>
              Back to Login
            </Link>
            <SignupLink />
          </Box>
        </Box>
      </>
    </StaticPageLayoutContainer>
  );
};

export default ForgotPassword;
