import React, { useState } from 'react';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import {
  Button,
  Divider,
  Paper,
  Typography,
  Box,
  AlertColor
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import UserForm from './UserForm';
import UserDetailMenu from './UserDetailMenu';
import { useGetSingleUserQuery, useSessionExtendQuery, useLoggedInUserQuery } from '../../../../api/reactQuery/queries';
import { PrivilegesType } from '../../../../util/constants';
import { USER_MANAGEMENT_ROUTE } from '../../../../util/routeConstants';
import UserStatusTag from '../../../UserStatusTag';

const UserDetail = () => {
  const { id } = useParams<Record<string, string>>();
  const history = useHistory();
  const {
    data: singleUserQueryData,
    isSuccess: isSingleUserQuerySuccess
  } = useGetSingleUserQuery(id);
  const sessionExtendQuery = useSessionExtendQuery();
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const userPrivs = loggedInUserData?.user?.privileges;
  const [isInEditMode, setIsInEditMode] = useState(false);

  const whichPrivilegesForField = (privilegeField: string) => {
    if (isSingleUserQuerySuccess) {
      if (singleUserQueryData.privileges[`${privilegeField}_READ` as PrivilegesType]) {
        if (singleUserQueryData.privileges[`${privilegeField}_WRITE` as PrivilegesType]) {
          return 'Write';
        }
        return 'Read';
      }
      return 'None';
    }
    return '';
  };

  const defaultValues = {
    firstName: singleUserQueryData?.firstName || '',
    lastName: singleUserQueryData?.lastName || '',
    email: singleUserQueryData?.email || '',
    manageUsersPrivileges: whichPrivilegesForField('MANAGEUSERS'),
    sourceIPPrivileges: whichPrivilegesForField('SOURCEIP'),
    rootCAPrivileges: whichPrivilegesForField('ROOTCA'),
    loggersPrivileges: whichPrivilegesForField('LOGGERS')
  };

  const handleBackClick = async () => {
    await sessionExtendQuery.refetch({ throwOnError: true });
    history.push(USER_MANAGEMENT_ROUTE);
  };

  return (
    <>
      <Typography component="h1" variant="h4" gutterBottom={true}>
        User Details
      </Typography>
      <Typography variant="subtitle1">
        View the details for a user, including their app permissions.  Modify or
        Deactivate accounts.
      </Typography>
      <br />
      <Paper>
        {isSingleUserQuerySuccess ? (
          <>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              pt: 2,
              pr: 4,
              pl: 4,
              minHeight: 56
            }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5" sx={{ display: 'inline', color: singleUserQueryData.isActive ? 'text.primary' : 'text.disabled' }}>
                  {`${singleUserQueryData.lastName}, ${singleUserQueryData.firstName}`}
                </Typography>
                {!singleUserQueryData.isActive && (
                  <UserStatusTag label="Deactivated" tagSize="large" />
                )}
                {!singleUserQueryData.isConfirmed && (
                  <UserStatusTag label="Unverified" tagSize="large" color={'warning' as AlertColor} variant="outlined" />
                )}
              </Box>
              {(!isInEditMode
              && loggedInUserData?.user.email !== singleUserQueryData.email
              && userPrivs?.MANAGEUSERS_WRITE) && (
                <Box>
                  <UserDetailMenu
                    setIsInEditMode={setIsInEditMode}
                  />
                </Box>
              )}

            </Box>
            <Divider variant="middle" sx={{ mb: 2 }} />
            <UserForm
              defaultValues={defaultValues}
              isInEditMode={isInEditMode}
              setIsInEditMode={setIsInEditMode}
              isUserActive={singleUserQueryData.isActive}
            />

          </>
        ) : (
          <Typography>
            Loading...
          </Typography>
        )}
      </Paper>
      <Button sx={{ mt: 2 }} onClick={handleBackClick} variant="text" startIcon={<ArrowBackIcon />}>
        Back To Users
      </Button>
    </ >
  );
};

export default UserDetail;
