import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { BaseIpShape } from '../../types/formTypes';

export interface KeyedIpShape extends BaseIpShape {
  key: string
}
export interface PendingIpShape extends KeyedIpShape {
  status: keyof typeof IP_EDIT_CASE
}

export const IP_EDIT_CASE = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE'
} as const;

// string to append to ips to distinguish pending entries from saved entries
export const PENDING_IP_SUFFIX = '-PENDING_IP';

interface ErrorHelperTextShape {
  message: string,
  targetTextField: string
}

interface SourceIpState {
  pendingIps: PendingIpShape[],
  helperTextValidationError: ErrorHelperTextShape,
  ipInUpdateMode: PendingIpShape
}

const initialState: SourceIpState = {
  pendingIps: [],
  helperTextValidationError: { targetTextField: '', message: '' },
  ipInUpdateMode: {
    text: '', description: '', key: '', status: IP_EDIT_CASE.ADD
  }
};

export const sourceIpSlice = createSlice({
  name: 'sourceIP',
  initialState,
  reducers: {
    addIp: (state, action: PayloadAction<BaseIpShape>) => {
      state.pendingIps = [
        ...state.pendingIps,
        {
          ...action.payload,
          key: `${action.payload.text}${PENDING_IP_SUFFIX}`,
          status: IP_EDIT_CASE.ADD
        }
      ];
    },
    clearSourceIpState: () => initialState,
    clearHelperTextError: (state) => {
      state.helperTextValidationError = initialState.helperTextValidationError;
    },
    removeIp: (state, action: PayloadAction<KeyedIpShape>) => {
      state.pendingIps = [
        ...state.pendingIps,
        {
          ...action.payload,
          key: `${action.payload.text}${PENDING_IP_SUFFIX}`,
          status: IP_EDIT_CASE.REMOVE
        }
      ];
    },
    removeIpFromPending: (state, action: PayloadAction<KeyedIpShape>) => {
      state.pendingIps = [
        ...state.pendingIps.filter(
          (pendingIp) => pendingIp.key.split(PENDING_IP_SUFFIX)[0]
            !== action.payload.key.split(PENDING_IP_SUFFIX)[0]
        )
      ];
    },
    setHelperTextError: (state, action: PayloadAction<ErrorHelperTextShape>) => {
      state.helperTextValidationError = action.payload;
    },
    startUpdateIp: (state, action: PayloadAction<PendingIpShape>) => {
      state.ipInUpdateMode = action.payload;
      state.helperTextValidationError = initialState.helperTextValidationError;
    },
    stopUpdateIp: (state) => {
      state.ipInUpdateMode = initialState.ipInUpdateMode;
      state.helperTextValidationError = initialState.helperTextValidationError;
    },
    updateIp: (
      state,
      action: PayloadAction<{ existingEntry: PendingIpShape, newEntry: BaseIpShape }>,
    ) => {
      let tempKey = `${action.payload.existingEntry.key}${PENDING_IP_SUFFIX}`;
      let tempStatus: PendingIpShape['status'] = IP_EDIT_CASE.UPDATE;
      // if existingEntry is an "ADD" case, use newEntry's text as new key
      if (action.payload.existingEntry.status === IP_EDIT_CASE.ADD) {
        tempKey = `${action.payload.newEntry.text}${PENDING_IP_SUFFIX}`;
        tempStatus = action.payload.existingEntry.status;
      }
      return {
        ...state,
        pendingIps: [
          ...state.pendingIps.filter(
            (pendingIp) => pendingIp.key.split(PENDING_IP_SUFFIX)[0]
                !== action.payload.existingEntry.key.split(PENDING_IP_SUFFIX)[0]
          ),
          { ...action.payload.newEntry, key: tempKey, status: tempStatus }
        ]
      };
    }
  }
});

export const {
  addIp,
  clearSourceIpState,
  clearHelperTextError,
  removeIp,
  removeIpFromPending,
  setHelperTextError,
  startUpdateIp,
  stopUpdateIp,
  updateIp
} = sourceIpSlice.actions;

// selectors
export const selectPendingIps = (state: RootState) => state.sourceIP.pendingIps;
export const selectIpInUpdateMode = (state: RootState) => state.sourceIP.ipInUpdateMode;
export const selectIpInUpdateModeKey = (state: RootState) => state.sourceIP.ipInUpdateMode.key;
export const selectIpHelperText = (state: RootState) => state.sourceIP.helperTextValidationError;

export default sourceIpSlice.reducer;
