import { Action, Dispatch } from '@reduxjs/toolkit';
import { IP_EDIT_CASE, PendingIpShape, setHelperTextError } from '../../redux/slices/sourceIpSlice';
import { BaseIpShape } from '../../types/formTypes';
import {
  cidrValidation, isAlreadyInList, isValidIp
} from '../../validation';

const inputValidation = (
  newInput: BaseIpShape,
  listToCompare: PendingIpShape[],
  maxListLength: number,
  targetTextField: keyof typeof IP_EDIT_CASE,
  dispatch: Dispatch<Action>
) => {
  let inputValidationPassed = true;
  if (listToCompare.length > maxListLength) {
    dispatch(
      setHelperTextError({
        message: `Max ${maxListLength} IPs/CIDRs allowed. Please specify subnets rather than specific IPs`,
        targetTextField
      })
    );
    inputValidationPassed = false;
  }
  if (!isValidIp(newInput.text)) {
    dispatch(setHelperTextError({
      message: 'Invalid IP/CIDR',
      targetTextField
    }));
    inputValidationPassed = false;
  }
  const invalidCidrCheck = cidrValidation(newInput.text);
  if (!invalidCidrCheck.isValid) {
    dispatch(
      setHelperTextError({
        message: `Invalid CIDR${invalidCidrCheck.cidrValMessage}`,
        targetTextField
      })
    );
    inputValidationPassed = false;
  }
  if (isAlreadyInList(newInput.text, listToCompare)) {
    dispatch(setHelperTextError({
      message: 'Already in list.',
      targetTextField
    }));
    inputValidationPassed = false;
  }
  return inputValidationPassed;
};

export default inputValidation;
