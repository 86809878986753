import React from 'react';
import {
  Link,
  Paper,
  Toolbar
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ComplianceSectionShape } from './Compliance';

interface ComplianceHeaderProps {
  sections: ComplianceSectionShape[]
}

const Header = (
  {
    sections
  }: ComplianceHeaderProps
) => (
  <Paper>
    <Toolbar component="nav" variant="dense">
      {sections.map((section) => (
        <Link
          component={RouterLink}
          color="inherit"
          noWrap={true}
          key={section.title}
          variant="body2"
          to={section.url}
          sx={{ p: 1, flexShrink: 0 }}
        >
          {section.title}
        </Link>
      ))}
    </Toolbar>
  </Paper>
);

export default Header;
