import React from 'react';
import {
  Grid,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  useTheme,
  Button
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import { useSelector, useDispatch } from 'react-redux';

import { removeIpFromPending, selectIpInUpdateModeKey } from '../../redux/slices/sourceIpSlice';

interface PendingRemoveItemProps {
  description: string,
  propKey: string,
  text: string
}

const PendingRemoveItem = ({
  description,
  propKey,
  text
}: PendingRemoveItemProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down('sm'));
  const ipInUpdateModeKey = useSelector(selectIpInUpdateModeKey);
  const undoRemove = () => {
    dispatch(removeIpFromPending({ text, description, key: propKey }));
  };

  const mobileViewButtons = () => (
    <Grid container={true} sx={{ justifyContent: 'flex-end', mt: 1 }}>
      <Button
        disabled={Boolean(ipInUpdateModeKey)}
        onClick={() => undoRemove()}
        startIcon={<UndoIcon />}
        variant="outlined"
        color="primary"
        aria-label="Undo Remove"
      >
        Undo Remove
      </Button>
    </Grid>
  );

  const desktopViewButtons = () => (
    <ListItemSecondaryAction>
      <IconButton
        disabled={Boolean(ipInUpdateModeKey)}
        aria-label="Undo Remove"
        onClick={() => undoRemove()}
        color="primary"
        size="large"
      >
        <UndoIcon />
      </IconButton>
    </ListItemSecondaryAction>
  );
  return (
    <>
      <Grid item={true} xs={12} sm={3}>
        <ListItemText
          primary={text}
          sx={{
            textDecoration: 'line-through', color: 'gray', display: 'flex', justifyContent: isMobileLayout ? 'flex-end' : 'flex-start'
          }}
        />
      </Grid>
      <Grid item={true} xs={12} sm={7}>
        <ListItemText
          primary={description}
          sx={{
            flexWrap: 'wrap', wordWrap: 'break-word', textDecoration: 'line-through', color: 'gray'
          }}
        />
      </Grid>
      {isMobileLayout
        ? mobileViewButtons()
        : desktopViewButtons()}

    </>
  );
};

export default PendingRemoveItem;
