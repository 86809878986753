const PROGRESS_PROPERTIES = {
  0: 'Submitting provisioning request...',
  10: 'Provisioning started',
  20: 'Provisioning database (this can take up to 10 minutes)',
  30: 'Setting up database backups (this can take several minutes)',
  40: 'Database provisioning complete',
  50: 'Creating database read replica (this can take 15-20 minutes)',
  60: 'Provisioning DNS and load balancer',
  70: 'Provisioning EJBCA application',
  80: 'Creating ManagementCA',
  90: 'Getting Superadmin credentials ready with your password',
  100: 'Deployment complete'
};

export default PROGRESS_PROPERTIES;
