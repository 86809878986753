import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

interface NavDrawerState {
  drawerOpen: boolean;
}

const initialState: NavDrawerState = {
  drawerOpen: !window.matchMedia('(max-width: 768px)').matches
};

export const navDrawerSlice = createSlice({
  name: 'navDrawer',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen;
    }
  }
});

export const { toggleDrawer } = navDrawerSlice.actions;

export const selectDrawerOpen = (state: RootState) => state.navDrawer.drawerOpen;

export default navDrawerSlice.reducer;
