// pathnames
export const PATHNAMES = {
  COMPLIANCE: 'compliance',
  DASHBOARD: 'dashboard',
  LINKS: 'ejbca',
  LOGGING: 'logging',
  PROFILE: 'profile',
  ROOT_CA: 'rootCa',
  SOURCE_IP: 'sourceIp',
  SUPPORT: 'support',
  USERS: 'users'
} as const;

// Portal routes
export const PORTAL_ROUTE = '/portal' as const;
export const DASHBOARD_ROUTE = `${PORTAL_ROUTE}/${PATHNAMES.DASHBOARD}` as const;
export const LINKS_ROUTE = `${PORTAL_ROUTE}/${PATHNAMES.LINKS}` as const;
export const SOURCE_IP_ROUTE = `${PORTAL_ROUTE}/${PATHNAMES.SOURCE_IP}` as const;
export const ROOT_CA_ROUTE = `${PORTAL_ROUTE}/${PATHNAMES.ROOT_CA}` as const;
export const LOGGING_ROUTE = `${PORTAL_ROUTE}/${PATHNAMES.LOGGING}` as const;
export const SUPPORT_ROUTE = `${PORTAL_ROUTE}/${PATHNAMES.SUPPORT}` as const;
export const PROFILE_ROUTE = `${PORTAL_ROUTE}/${PATHNAMES.PROFILE}` as const;
// Compliance
export const COMPLIANCE_ROUTE = `${PORTAL_ROUTE}/${PATHNAMES.COMPLIANCE}` as const;
export const FAQ_ROUTE = `${COMPLIANCE_ROUTE}/faq` as const;
export const CP_ROUTE = `${COMPLIANCE_ROUTE}/cp` as const;
export const CPS_ROUTE = `${COMPLIANCE_ROUTE}/cps` as const;
// User Management
export const USER_MANAGEMENT_ROUTE = `${PORTAL_ROUTE}/${PATHNAMES.USERS}` as const;
export const CREATE_USER_ROUTE = `${USER_MANAGEMENT_ROUTE}/create` as const;

// Error routes
export const TOKEN_ERROR_ROUTE = '/tokenError' as const;

// Password routes
export const FORGOT_PASSWORD_ROUTE = '/forgotPassword' as const;
export const RESET_PASSWORD_ROUTE = '/reset/:token' as const;

// Other routes
export const LOGIN_ROUTE = '/login' as const;
export const MORE_INFO_ROUTE = '/moreInfo' as const;
export const THANK_YOU_ROUTE = '/thankYou' as const;
export const ALREADY_LOGGED_IN = '/alreadyLoggedIn' as const;

// Old dashboard routes
export const OLD_DASHBOARD_ROUTE = '/dashboard' as const;
