import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SyslogListResponse } from '../../../../../api/Logging/LoggingRequests';
import { TlsProtocols } from '../../../../../util/constants';
import { TableHeadersObject } from './SyslogTableContent';

interface ChangesPreviewProps {
  openPreviewChangesDialog: boolean,
  setOpenPreviewChangesDialog: React.Dispatch<React.SetStateAction<boolean>>,
  loggerRemove: SyslogListResponse[],
  loggerAdd: SyslogListResponse[],
  tableHeaders: TableHeadersObject[],
  protocolToText: (protocol: TlsProtocols) => string,
  setLoggerAdd: React.Dispatch<React.SetStateAction<SyslogListResponse[]>>,
  setLoggerRemove: React.Dispatch<React.SetStateAction<SyslogListResponse[]>>,
  setDisplayedSyslog: React.Dispatch<React.SetStateAction<SyslogListResponse[]>>,
  initialDisplayed: SyslogListResponse[],
  submitDisplayedSyslog: () => void
}

const ChangesPreview = ({
  openPreviewChangesDialog,
  setOpenPreviewChangesDialog,
  loggerRemove,
  loggerAdd,
  tableHeaders,
  protocolToText,
  setLoggerAdd,
  setLoggerRemove,
  setDisplayedSyslog,
  initialDisplayed,
  submitDisplayedSyslog
}: ChangesPreviewProps) => {
  const closeChangesDialog = () => {
    setOpenPreviewChangesDialog(false);
  };
  const clearChangesClick = () => {
    setLoggerAdd([]);
    setLoggerRemove([]);
    setDisplayedSyslog(initialDisplayed);
    closeChangesDialog();
  };
  const submitAndClose = () => {
    submitDisplayedSyslog();
    closeChangesDialog();
  };
  return (
    <Dialog maxWidth="lg" open={openPreviewChangesDialog}>
      <DialogTitle>
        <Grid container={true} justifyContent="space-between" alignItems="center">
          <Grid item={true}>
            <Typography variant="h5">Preview Changes</Typography>
          </Grid>
          <Grid item={true}>
            <IconButton onClick={closeChangesDialog} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container={true} spacing={2} direction="column">
          <Grid item={true}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ borderColor: 'divider' }}>Action</TableCell>
                  {tableHeaders.map((headerEntry) => (
                    <TableCell sx={{ borderColor: 'divider' }} key={headerEntry.key}>
                      {headerEntry.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loggerAdd && loggerAdd.map((entry) => (
                  <TableRow key={`${entry.ipdns}-${entry.loggerPodLabel}`}>
                    <TableCell sx={{ borderColor: 'divider' }}>Add</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{entry.appType}</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{entry.ipdns}</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{entry.loggerPodLabel}</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{protocolToText(entry.protocol)}</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>
                      {entry.credentialName ? entry.credentialName : 'N/A'}
                    </TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{entry.port}</TableCell>
                  </TableRow>
                ))}
                {loggerRemove && loggerRemove.map((entry) => (
                  <TableRow key={`${entry.ipdns}-${entry.loggerPodLabel}`}>
                    <TableCell sx={{ borderColor: 'divider' }}>Remove</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{entry.appType}</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{entry.ipdns}</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{entry.loggerPodLabel}</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{protocolToText(entry.protocol)}</TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>
                      {entry.credentialName ? entry.credentialName : 'N/A'}
                    </TableCell>
                    <TableCell sx={{ borderColor: 'divider' }}>{entry.port}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={clearChangesClick}>Clear Changes</Button>
        <Button onClick={submitAndClose}>Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangesPreview;
