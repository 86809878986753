import React from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';

import Compliance, { ComplianceSectionShape } from './Compliance';
import faq from './faq.md';
import cp from './cp.md';
import cps from './cps.md';
import Header from './Header';
import { CPS_ROUTE, CP_ROUTE, FAQ_ROUTE } from '../../../../util/routeConstants';

const sections: ComplianceSectionShape[] = [
  {
    title: 'FAQ',
    url: FAQ_ROUTE,
    pageTitle: 'Frequently Asked Questions',
    posts: faq
  },
  {
    title: 'CP',
    url: CP_ROUTE,
    pageTitle: 'Certificate Policy',
    posts: cp
  },
  {
    title: 'CPS',
    url: CPS_ROUTE,
    pageTitle: 'Certificate Practice Statement',
    posts: cps
  }
];

const ComplianceLayout = () => {
  const { id } = useParams<Record<string, string>>();

  return (
    <main>
      <Grid container={true} direction="column" spacing={5}>
        <Grid item={true}>
          <Header sections={sections} />
        </Grid>
        <Grid item={true}>
          <Compliance sections={sections} mdid={id} />
        </Grid>
      </Grid>
    </main>
  );
};

export default ComplianceLayout;
