import React from 'react';
import {
  Box,
  Divider,
  Typography
} from '@mui/material';

import Markdown from './Markdown';
import CertificatePracticeStatement from './Cps';
import CertificatePolicy from './Cp';

export interface ComplianceSectionShape {
  title: string,
  url: string,
  pageTitle: string,
  posts: string
}

interface ComplianceProps {
  mdid: string,
  sections: Array<ComplianceSectionShape>
}

const Compliance = (
  {
    sections,
    mdid
  }: ComplianceProps
) => {
  const showTitle = (pageid: string) => {
    if (pageid === 'faq') {
      return sections[0].pageTitle;
    } if (pageid === 'cp') {
      return sections[1].pageTitle;
    }
    return sections[2].pageTitle;
  };
  const showPost = (pageid: string) => {
    if (pageid === 'faq') {
      return sections[0].posts;
    } if (pageid === 'cp') {
      return sections[1].posts;
    }
    return sections[2].posts;
  };
  return (
    <>
      <Typography variant="h6" gutterBottom={true}>
        {showTitle(mdid)}
      </Typography>
      <Divider />
      <Box sx={{ typography: 'body2', px: 3, py: 0 }}>
        <Markdown>
          {showPost(mdid)}
        </Markdown>
      </Box>
      {mdid === 'cps' && <CertificatePracticeStatement />}
      {mdid === 'cp' && <CertificatePolicy />}
      <Divider />
    </>
  );
};

export default Compliance;
