import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Box
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'lodash/debounce';
import * as yup from 'yup';

import { useCredList } from '../../../../../api/reactQuery/queries';
import {
  TCP,
  TLSAUTH,
  TLSUNAUTH,
  LOGGING_APP_TYPES
} from '../../../../../util/constants';
import { SyslogFormType } from '../../../../../types/formTypes';
import { CredListResponse, SyslogListResponse } from '../../../../../api/Logging/LoggingRequests';
import TcpLoggingAlertDialog from '../../../../Dialogs/TcpLoggingAlertDialog';

interface AddSyslogFormProps {
  displayedSyslog: SyslogListResponse[],
  setDisplayedSyslog: React.Dispatch<React.SetStateAction<SyslogListResponse[]>>
}

const AddSyslogConfigForm = ({
  displayedSyslog, setDisplayedSyslog
}: AddSyslogFormProps) => {
  const [openTcpAlertDialog, setOpenTcpAlertDialog] = useState(false);
  const [selectDropDownCredentials, setSelectDropDownCredentials] = useState<CredListResponse[]>(
    []
  );

  const protocol = [
    { label: 'TCP - Unencrypted', value: TCP },
    { label: 'TLS - Unauthenticated', value: TLSUNAUTH },
    { label: 'TLS - Authenticated', value: TLSAUTH }
  ];

  const syslogSchema = yup.object().shape({
    appType: yup
      .string()
      .oneOf([...LOGGING_APP_TYPES], 'Please select a valid Application.')
      .required('Please select a valid Application'),
    ipdns: yup
      .string()
      .test(
        'validIPAddress',
        'Please provide a valid FQDN or IP Address.',
        (value: any) => {
          const re = /^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])|(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]))$/;
          return re.test(value);
        }
      )
      .test('alreadyExists', 'Entry already exists', (value: any) => {
        const mappedEntries = displayedSyslog.map((entry) => entry.ipdns.toLowerCase());
        return !mappedEntries.includes(value.toLowerCase());
      }),
    loggerPodLabel: yup
      .string()
      .required('Please provide a valid name.')
      .min(5, 'Enter at least 5 characters.')
      .max(63, 'Enter at most 63 characters.')
      .test('alreadyExists', 'Name already exists', (value: any) => {
        const mappedEntries = displayedSyslog.map((entry) => entry.loggerPodLabel.toLowerCase());
        return !mappedEntries.includes(value.toLowerCase());
      })

      .matches(
        /^[0-9A-Za-z ]+$/,
        'Invalid name.  Allowed characters: 0-9, A-Z, a-z, space'
      ),
    port: yup.number().positive().lessThan(65535),
    protocol: yup.string().oneOf(
      protocol.map((entry) => entry.value),
      'Please select a valid Protocol.'
    ),
    credentialName: yup.mixed().when('protocol', {
      is: (value: any) => value === TLSUNAUTH || value === TLSAUTH,
      then: yup
        .string()
        .required(
          'Required when using TLS, add Credentials on Credential Tab first.'
        ),
      otherwise: yup.string().optional()
    })
  });

  const {
    control,
    formState: { errors, isSubmitSuccessful, isValid },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    setError,
    trigger
  } = useForm<SyslogFormType>({
    defaultValues: {
      appType: undefined,
      credentialName: undefined,
      ipdns: '',
      loggerPodLabel: undefined,
      port: undefined,
      protocol: '',
      credId: null
    },
    resolver: yupResolver(syslogSchema)
  });

  const watchProtocolResult = watch('protocol');

  const credListQuery = useCredList(watchProtocolResult);

  const onSubmit = (data: SyslogFormType) => {
    const enrichedData = data;
    enrichedData.loggerPodLabel = enrichedData.loggerPodLabel
      .toLowerCase()
      .replace(/ /g, '-');
    if (enrichedData.protocol === TCP && !openTcpAlertDialog) {
      setOpenTcpAlertDialog(true);
      // This error never gets displayed, just used to keep the isSubmitSuccessful false
      return setError('submitError', {
        type: 'openTcpAlert'
      });
    }
    if (enrichedData.protocol === TCP && openTcpAlertDialog) {
      setOpenTcpAlertDialog(false);
      enrichedData.credId = null;
    } else {
      const findEntry = credListQuery.data?.find(
        (entry) => entry.credentialName === data.credentialName
      );
      enrichedData.credId = findEntry?.id;
    }
    setDisplayedSyslog([...displayedSyslog, enrichedData]);
    return true;
  };

  useEffect(() => {
    if (credListQuery?.isSuccess && credListQuery.data) {
      setSelectDropDownCredentials(credListQuery.data);
    }
  }, [credListQuery?.isSuccess, credListQuery?.data]);

  // reset needs to be outside of handleSubmit per useForm reset documentation
  useEffect(() => {
    if (isSubmitSuccessful && !openTcpAlertDialog) {
      reset();
    }
  }, [isSubmitSuccessful, openTcpAlertDialog, reset]);

  const credDropdownChoices = (selectDropDownCredentials.length > 0)
    ? selectDropDownCredentials.map((name) => (
      <MenuItem key={name.credentialName} value={name.credentialName}>
        {name.credentialName}
      </MenuItem>
    ))
    : (
      <MenuItem dense={true} disabled={true} key="option-placeholder">
        No credentials found
      </MenuItem>
    );

  return (
    <>
      <TcpLoggingAlertDialog
        openTcpAlertDialog={openTcpAlertDialog}
        setOpenTcpAlertDialog={setOpenTcpAlertDialog}
      />
      <Box component="form" onSubmit={handleSubmit(onSubmit)} id="add-syslog-form">
        <Grid container={true} item={true} spacing={2}>
          <Grid item={true} xs={2}>
            <Controller
              defaultValue=""
              rules={{ required: true }}
              control={control}
              name="appType"
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger('appType');
                  }}
                  error={!!errors.appType}
                  helperText={errors.appType?.message}
                  id="appType"
                  label="Application"
                  select={true}
                  fullWidth={true}
                  variant="standard"
                >
                  {LOGGING_APP_TYPES.map((application) => (
                    <MenuItem key={application} value={application}>
                      {application}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item={true} xs={2}>
            <TextField
              label="IP Address or FQDN"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('ipdns', {
                onChange: debounce(() => trigger('ipdns'), 300),
                required: true
              })}
              fullWidth={true}
              helperText={errors.ipdns?.message}
              error={!!errors.ipdns}
              variant="standard"
            />
          </Grid>
          <Grid item={true} xs={3}>
            <TextField
              id="loggerPodLabel"
              label="Name"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('loggerPodLabel', {
                onChange: () => trigger('loggerPodLabel'),
                required: true
              })}
              fullWidth={true}
              helperText={errors.loggerPodLabel?.message}
              error={!!errors.loggerPodLabel}
              variant="standard"
            />
          </Grid>
          <Grid item={true} xs={2}>
            <Controller
              rules={{ required: true }}
              control={control}
              name="protocol"
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  error={!!errors.protocol}
                  helperText={errors.protocol?.message}
                  id="protocol"
                  label="Protocol"
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('credentialName', '');
                    trigger('protocol');
                  }}
                  select={true}
                  fullWidth={true}
                  variant="standard"
                >
                  {protocol.map((protocols) => (
                    <MenuItem key={protocols.value} value={protocols.value}>
                      {protocols.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item={true} xs={2}>
            <Controller
              defaultValue=""
              control={control}
              name="credentialName"
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  disabled={
                    watchProtocolResult === TCP
                    || watchProtocolResult === undefined
                    || watchProtocolResult === ''
                  }
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger('credentialName');
                  }}
                  error={!!errors.credentialName}
                  helperText={errors.credentialName?.message}
                  id="credentialName"
                  label="Credentials"
                  select={true}
                  fullWidth={true}
                  variant="standard"
                >
                  {credDropdownChoices}
                </TextField>
              )}
            />
          </Grid>
          <Grid item={true} xs={1}>
            <TextField
              placeholder={watchProtocolResult === TCP || watchProtocolResult === undefined
                ? '514'
                : '6514'}
              label="Port"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('port', {
                onChange: () => trigger('port'),
                required: true
              })}
              fullWidth={true}
              helperText={errors.port && 'Please provide a port between 0 and 65535'}
              error={!!errors.port}
              variant="standard"
            />
          </Grid>

          <Grid item={true} xs={true} display="grid" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              disabled={!isValid}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

export default AddSyslogConfigForm;
