import React, { useEffect, useRef } from 'react';
import {
  Button,
  Box,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams, Link as routerLink } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { PASSWORD_10K_MOST_COMMON } from '../util/loginConstants';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import SignupLink from './SignupLink';
import StaticPageLayoutContainer from './StaticPageLayoutContainer';
import { useResetPasswordMutation } from '../api/reactQuery/mutations';
import { useGetIsTokenValidQuery } from '../api/reactQuery/queries';
import { GET_IS_TOKEN_VALID_QUERY } from '../util/constants';
import { LOGIN_ROUTE } from '../util/routeConstants';
import { SetPasswordFormType } from '../types/formTypes';

const SetPassword = () => {
  const { token } = useParams<Record<string, string>>();
  const queryClient = useQueryClient();
  const { mutate: resetPasswordMutate } = useResetPasswordMutation();
  const { isSuccess: isTokenValidQuerySuccess } = useGetIsTokenValidQuery();

  const passwordScore = useRef(0);

  // eslint-disable-next-line no-return-assign
  const updatePasswordScore = (score: number) => passwordScore.current = score;

  const ResetPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, 'Enter at least 8 characters for your password')
      .max(128, 'Enter between 8 and 128 characters for your password')
      .notOneOf(PASSWORD_10K_MOST_COMMON, 'Enter a less common password')
      .required('Required!')
      .test(
        'passwordStrengthValidation',
        'Please provide a stronger password',
        (value) => (value ? passwordScore.current > 2 : true)
      ),

    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  const {
    control,
    formState: { errors },
    getFieldState,
    handleSubmit,
    register,
    setError,
    trigger
  } = useForm<SetPasswordFormType>({
    resolver: yupResolver(ResetPasswordSchema)
  });

  const passwordString = useWatch({ control, name: 'password', defaultValue: '' });
  const confirmPasswordState = getFieldState('confirmPassword');

  const onSubmit = (data: SetPasswordFormType) => {
    // useMutation will not take more than one param, must take an object.
    const mutationObject = {
      data,
      token,
      setError
    };
    resetPasswordMutate(mutationObject);
  };

  useEffect(() => {
    queryClient.refetchQueries(GET_IS_TOKEN_VALID_QUERY);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticPageLayoutContainer>
      {isTokenValidQuerySuccess
        ? (
          <>
            <Typography component="h1" variant="h5" gutterBottom={true}>
              Set Your Password
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
              Enter your new password below.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Any active sessions in other windows will be logged out.
            </Typography>
            <Box component="form" sx={{ width: '100%', mt: 1 }} onSubmit={handleSubmit(onSubmit)}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth={true}
                label="Password"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('password', {
                  onChange: AwesomeDebouncePromise(async () => {
                    await trigger('password');
                    if (confirmPasswordState.isTouched) {
                      trigger('confirmPassword');
                    }
                  }, 200)
                })}
                type="password"
                id="password"
                autoComplete="new-password"
                error={!!errors.password}
                helperText={errors.password?.message}
              />
              <PasswordStrengthMeter
                passwordString={passwordString}
                setPasswordScoreInParent={updatePasswordScore}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth={true}
                label="Confirm Password"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('confirmPassword', {
                  onChange: () => trigger(['password', 'confirmPassword'])
                })}
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                sx={{ mt: 3, mr: 0, mb: 2 }}
              >
                Save Password
              </Button>

              <Grid container={true}>
                <Grid item={true} xs={true}>
                  <Link to={LOGIN_ROUTE} component={routerLink}>
                    Back to Login
                  </Link>
                </Grid>
                <Grid item={true}>
                  <SignupLink />
                </Grid>
              </Grid>
            </Box>

          </>
        )
        : <Typography>Loading...</Typography>}
    </StaticPageLayoutContainer>
  );
};

export default SetPassword;
