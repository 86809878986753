import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

import RootCADialog from '../../Dialogs/RootCADialog';
import AcctDeployingBackdrop from '../layout/AcctDeployingBackdrop';
import TOTPDialog from './profile/twoFADialogs/TOTPDialog';
import { setSnackbar } from '../../../redux/slices/globalSnackbarSlice';
import * as Constants from '../../../util/constants';
import useEjbcaUrl from '../../../util/useEjbcaUrl';
import { useGetRootCaStatusQuery, useLoggedInUserQuery } from '../../../api/reactQuery/queries';
import { useStartRootCaMutation, useStopRootCaMutation } from '../../../api/reactQuery/mutations';
import { START, STOP } from '../../../util/constants';
import { TOTPSetPhoneFormType } from '../../../types/formTypes';

const ROOTCA_STOPPED = 0;
const ROOTCA_STARTING = 10;
const ROOTCA_RUNNING = 20;
const ROOTCA_STOPPING = 30;

const RootCA = () => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState<typeof START | typeof STOP>(START);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  // TOTP
  const [openTOTP, setOpenTOTP] = useState(false);
  const [stepTOTP, setStepTOTP] = useState(1);
  const [labelObjTOTP, setLabelObjTOTP] = useState<TOTPSetPhoneFormType>({ label: '', phoneType: 'iphone' });
  // Current Action Type for TOTP Dialog
  const [
    actionTypeTOTP,
    setActionTypeTOTP
  ] = useState<Constants.Setup2faActionsType>(Constants.SETUP_TOTP);
  const [
    verifyModeTOTP,
    setVerifyModeTOTP
  ] = useState<Constants.TotpVerifyModes>(Constants.NEW_TOTP);
  const ejbcaUrl = useEjbcaUrl('https', 'root', '/ejbca/adminweb');
  const { mutate: startRootCaMutate } = useStartRootCaMutation();
  const { mutate: stopRootCaMutate } = useStopRootCaMutation();
  const theme = useTheme();
  const getRootCaStatus = useGetRootCaStatusQuery();
  const { data: loggedInUserData } = useLoggedInUserQuery();

  const userPrivs = loggedInUserData?.user?.privileges;

  const nextStepTOTP = (stepVal: number) => {
    setStepTOTP(stepVal);
  };

  const handleClickOpen = (action: typeof actionType) => {
    switch (action) {
      case STOP:
        setActionType(STOP);
        break;
      case START:
        setActionType(START);
        break;
      default:
    }
    setIsConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = (reason: string) => {
    if (reason !== 'backdropClick') {
      setIsConfirmDialogOpen(false);
    }
  };

  const handleCloseTOTP = (reason: string) => {
    if (reason !== 'backdropClick') {
      setOpenTOTP(false);
    }
  };

  const startStopRootCaSuccess = (startOrShutdown: 'startup' | 'shutdown') => {
    getRootCaStatus.refetch();
    dispatch(setSnackbar({
      snackbarType: 'success',
      snackbarMessage: `Root CA ${startOrShutdown} initiated.`
    }));
  };

  const startRootCa = async () => {
    const startRootCaObject = {
      setActionTypeTOTP,
      setVerifyModeTOTP,
      setStepTOTP,
      setLabelObjTOTP,
      setOpenTOTP,
      startStopRootCaSuccess
    };
    startRootCaMutate(startRootCaObject);
    setIsConfirmDialogOpen(false);
  };

  const stopRootCa = () => {
    const stopRootCaObject = {
      startStopRootCaSuccess
    };
    stopRootCaMutate(stopRootCaObject);
    setIsConfirmDialogOpen(false);
  };

  const getStatusLabel = () => {
    switch (getRootCaStatus.data?.status) {
      case ROOTCA_STOPPED:
        return 'Stopped';
      case ROOTCA_STARTING:
        return 'Starting...';
      case ROOTCA_RUNNING:
        return 'Running';
      case ROOTCA_STOPPING:
        return 'Stopping...';
      default:
        return 'Checking...';
    }
  };

  const colorText = () => {
    switch (getRootCaStatus.data?.status) {
      case ROOTCA_STOPPED: // white/neutral
        return theme.palette.text.secondary;
      case ROOTCA_STARTING: // yellow/orange
        return theme.palette.warning.light;
      case ROOTCA_RUNNING: // green
        return theme.palette.success.main;
      case ROOTCA_STOPPING: // yellow/orange
        return theme.palette.warning.light;
      default:
        return theme.palette.text.primary;
    }
  };

  const disabledButton = (buttonType: 'START' | 'STOP') => {
    if (getRootCaStatus.data?.status === ROOTCA_STOPPED) {
      if (buttonType === STOP) {
        return true;
      } if (buttonType === START) {
        return false;
      }
    }
    if (getRootCaStatus.data?.status === ROOTCA_RUNNING) {
      if (buttonType === START) {
        return true;
      } if (buttonType === STOP) {
        return false;
      }
    }
    return true;
  };

  const showAutoShutdownDate = () => {
    if (getRootCaStatus.data?.autoShutdownDate) {
      let autoShutdownFormatted = '';
      const parsedDate = Date.parse(getRootCaStatus.data.autoShutdownDate);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      };

      autoShutdownFormatted = new Intl.DateTimeFormat(
        'default',
        options
      ).format(parsedDate);

      if (getRootCaStatus.data?.status === ROOTCA_RUNNING) {
        return (
          <div>
            <Box
              display="flex"
              flexDirection="row"
              alignContent="flex-start"
            >
              <Typography variant="h5" color="inherit">
                {`Auto Shutdown Planned: ${autoShutdownFormatted}`}
              </Typography>
            </Box>
            <Box m={1}>
              <Typography>
                <Link color="primary" href={ejbcaUrl} target="_blank">
                  {ejbcaUrl}
                </Link>
              </Typography>
            </Box>
          </div>
        );
      }
    }
    return undefined;
  };

  return (
    <>
      <AcctDeployingBackdrop />
      <RootCADialog
        open={isConfirmDialogOpen}
        handleCloseConfirmDialog={handleCloseConfirmDialog}
        startRootCA={startRootCa}
        stopRootCA={stopRootCa}
        actionType={actionType}
      />
      <TOTPDialog
        open={openTOTP}
        handleClose={handleCloseTOTP}
        nextStep={nextStepTOTP}
        step={stepTOTP}
        labelObj={labelObjTOTP}
        setLabel={setLabelObjTOTP}
        actionType={actionTypeTOTP}
        verifyMode={verifyModeTOTP}
        setVerifyMode={setVerifyModeTOTP}
      />

      <main>
        <Typography
          component="h1"
          variant="h4"
          gutterBottom={true}
        >
          EJBCA Root CA
        </Typography>
        <Typography
          variant="subtitle1"
        >
          The EJBCA Root Node can be started for a 24 hour period. At
          the end of this duration, the node will be automatically shut
          down.
          <br />
          To start the Root Node, click the button below. Please give
          the node approximately 5 minutes to start.
          <br />
          The same credentials used to access the Issuing CA cluster
          will be used to access the Root CA AdminWeb interface.
        </Typography>
        <Paper sx={{
          mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4
        }}
        >
          <Box display="flex" flexDirection="row" alignContent="flex-start" width="100%">
            <Typography variant="h5" gutterBottom={true}>
              {'Status: '}
              <Box component="span" sx={{ color: colorText() }}>
                {getStatusLabel()}
              </Box>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" width="100%">
            {showAutoShutdownDate()}
          </Box>
          {userPrivs?.ROOTCA_WRITE && (
          <Grid container={true} spacing={2} justifyContent="flex-end">
            <Grid item={true}>
              <Button
                variant="outlined"
                color="primary"
                disabled={disabledButton(START)}
                onClick={() => handleClickOpen(START)}
              >
                Start Root CA
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                variant="outlined"
                color="primary"
                disabled={disabledButton(STOP)}
                onClick={() => handleClickOpen(STOP)}
              >
                Stop Root CA
              </Button>
            </Grid>
          </Grid>
          )}
        </Paper>
      </main>
    </>
  );
};

export default RootCA;
