import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { setSnackbar } from '../../redux/slices/globalSnackbarSlice';
import { deleteCredential, submitLoggerCreds, submitLoggers } from './LoggingRequests';
import { errorsSnackbarHandler, receiveErrors } from '../reactQuery/queryErrorHandling';
import { ErrorResponse } from '../reactQuery/ErrorResponse';
import { SYSLOG_LIST_QUERY, SYSLOG_UNENCRYPTED_ALERT_QUERY } from '../../util/constants';

export const useSubmitLoggerCredsMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    submitLoggerCreds,
    {
      onSuccess: (response, submitLoggerCredsObject) => {
        queryClient.invalidateQueries(submitLoggerCredsObject.certType);
        submitLoggerCredsObject.handleCloseAddCertDialogClick();
        response.data.success.forEach((entry: any) => {
          dispatch(
            setSnackbar({
              snackbarType: entry.severity,
              snackbarMessage: entry.msg
            })
          );
        });
      },
      onError: (error: AxiosError, submitLoggerCredsObject) => {
        error.response?.data.errors?.forEach((entry: ErrorResponse) => {
          if (entry.type) {
            const errorData = {
              error: entry.type
            };
            receiveErrors(errorData, dispatch);
          } else if (
            entry.param === 'credName'
            || entry.param === 'caFile'
            || entry.param === 'clientCert'
            || entry.param === 'clientKey'
          ) {
            submitLoggerCredsObject.setError(entry.param, { type: 'manual', message: entry.msg });
          } else {
            receiveErrors(entry, dispatch);
          }
        });
      }
    }
  );
};

export const useDeleteCredentialMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    deleteCredential,
    {
      onSuccess: (response, deleteCredentialObject) => {
        queryClient.invalidateQueries(`${deleteCredentialObject.protocol}`);
        response.data.success.forEach((entry: ErrorResponse) => {
          dispatch(
            setSnackbar({
              snackbarType: entry.severity ? entry.severity : 'error',
              snackbarMessage: entry.msg
            })
          );
        });
      },
      onError: (error: AxiosError) => {
        errorsSnackbarHandler(error, dispatch);
      }
    }
  );
};

export const useSubmitLoggersMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    submitLoggers,
    {
      onSuccess: (response, submitLoggersObject) => {
        queryClient.invalidateQueries(SYSLOG_LIST_QUERY);
        submitLoggersObject.resetInitialandDisplayed();
        queryClient.invalidateQueries(SYSLOG_UNENCRYPTED_ALERT_QUERY);
        response.data.success.forEach((entry: ErrorResponse) => {
          dispatch(
            setSnackbar({
              snackbarType: entry.severity ? entry.severity : 'error',
              snackbarMessage: entry.msg
            })
          );
        });
      },
      onError: (error: AxiosError) => {
        errorsSnackbarHandler(error, dispatch);
      }
    }
  );
};
