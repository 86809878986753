import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  SnackbarDetails,
  selectGlobalSnackbarQueue,
  sliceSnackbarQueue
} from '../redux/slices/globalSnackbarSlice';

const GlobalSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarQueue = useSelector(selectGlobalSnackbarQueue);

  const [
    displayedSnackbar,
    setdisplayedSnackbar
  ] = useState<{ key: number, snackbarDetails: SnackbarDetails }>();

  useEffect(() => {
    setdisplayedSnackbar(snackbarQueue[0]);
  }, [snackbarQueue]);

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (snackbarQueue.length) {
      dispatch(sliceSnackbarQueue());
    }
  };

  return (
    <div>
      <Snackbar
        key={displayedSnackbar ? displayedSnackbar.key : undefined}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!displayedSnackbar?.snackbarDetails?.snackbarMessage}
        autoHideDuration={6000}
        onClose={(_e, r) => handleClose(r)}
        disableWindowBlurListener={true}
      >
        <Alert
          elevation={6}
          onClose={() => handleClose()}
          severity={displayedSnackbar?.snackbarDetails?.snackbarType}
          variant="filled"
        >
          {displayedSnackbar?.snackbarDetails?.snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GlobalSnackbar;
