import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { submitStartRootCa, submitStopRootCa } from './RootCaRequests';
import { basicErrorHandler } from '../reactQuery/queryErrorHandling';
import {
  ROOTCA_TOTP_ENABLED,
  START_ROOTCA_TOTP,
  STORED_TOTP,
  ROOTCA_STARTUP_INITIATED,
  Setup2faActionsType,
  TotpVerifyModes
} from '../../util/constants';
import { TOTPSetPhoneFormType } from '../../types/formTypes';

interface RootCaSuccessTypes {
  startStopRootCaSuccess: (startOrShutdown: 'startup' | 'shutdown') => void
}

interface RootCaObjects extends RootCaSuccessTypes {
  setActionTypeTOTP: React.Dispatch<React.SetStateAction<Setup2faActionsType>>;
  setVerifyModeTOTP: React.Dispatch<React.SetStateAction<TotpVerifyModes>>;
  setStepTOTP: React.Dispatch<React.SetStateAction<number>>;
  setLabelObjTOTP: React.Dispatch<React.SetStateAction<TOTPSetPhoneFormType>>;
  setOpenTOTP: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useStartRootCaMutation = () => {
  const dispatch = useDispatch();

  return useMutation(
    submitStartRootCa,
    {
      onSuccess: (response, startRootCaObject: RootCaObjects) => {
        if (response.data.reason === ROOTCA_TOTP_ENABLED) {
          startRootCaObject.setActionTypeTOTP(START_ROOTCA_TOTP);
          startRootCaObject.setVerifyModeTOTP(STORED_TOTP);

          startRootCaObject.setStepTOTP(1);
          startRootCaObject.setLabelObjTOTP({ label: '', phoneType: 'iphone' });
          startRootCaObject.setOpenTOTP(true);
        }
        if (response.data.initAction === ROOTCA_STARTUP_INITIATED) {
          startRootCaObject.startStopRootCaSuccess('startup');
        }
      },
      onError: (error) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};

export const useStopRootCaMutation = () => {
  const dispatch = useDispatch();

  return useMutation(
    submitStopRootCa,
    {
      onSuccess: (_response, stopRootCaObject: RootCaSuccessTypes) => {
        stopRootCaObject.startStopRootCaSuccess('shutdown');
      },
      onError: (error) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};
