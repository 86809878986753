import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import {
  submitRequestForQrCode,
  submitTOTPReauth,
  submitVerifyTOTP,
  submitVerifyTOTPAction
} from './TwoFARequests';
import {
  DISABLE_TOTP,
  CHANGE_PHONE,
  ENABLE_ROOTCA_TOTP,
  DISABLE_ROOTCA_TOTP,
  START_ROOTCA_TOTP,
  REMOVED_TOTP,
  ENABLED_ROOTCA_TOTP,
  DISABLED_ROOTCA_TOTP,
  ROOTCA_STARTUP_INITIATED,
  TOKEN_MATCH_SUCCESS,
  NEW_TOTP,
  GET_TOTP_STATE_QUERY,
  ROOT_CA_STATUS_QUERY
} from '../../util/constants';
import { fieldValidationErrorHandler, basicErrorHandler } from '../reactQuery/queryErrorHandling';
import { setSnackbar } from '../../redux/slices/globalSnackbarSlice';

export const useTOTPReauthMutation = () => {
  const dispatch = useDispatch();

  return useMutation(
    submitTOTPReauth,
    {
      onSuccess: (_response, submitReauthObject) => {
        if (
          submitReauthObject.actionType === DISABLE_TOTP
          || submitReauthObject.actionType === CHANGE_PHONE
          || submitReauthObject.actionType === ENABLE_ROOTCA_TOTP
          || submitReauthObject.actionType === DISABLE_ROOTCA_TOTP
          || submitReauthObject.actionType === START_ROOTCA_TOTP
        ) {
          submitReauthObject.nextStep(4);
        } else {
          submitReauthObject.nextStep(2);
        }
      },
      onError: (error: AxiosError, submitReauthObject) => {
        fieldValidationErrorHandler(error, dispatch, submitReauthObject.setError);
      }
    }
  );
};

export const useVerifyTOTPMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    submitVerifyTOTP,
    {
      onSuccess: () => {
        queryClient.refetchQueries([GET_TOTP_STATE_QUERY], { exact: true });
      },
      onError: (error: AxiosError, verifyTOTPObject) => {
        fieldValidationErrorHandler(error, dispatch, verifyTOTPObject.setError);
      }
    }
  );
};

export const useVerifyTOTPActionMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    submitVerifyTOTPAction,
    {
      onSuccess: (response, verifyTOTPActionObject) => {
        if ((response.data.Message === REMOVED_TOTP
          && verifyTOTPActionObject.combinedFields.actionType === DISABLE_TOTP)
          || (response.data.Message === DISABLED_ROOTCA_TOTP
            && verifyTOTPActionObject.combinedFields.actionType === DISABLE_ROOTCA_TOTP)
          || (response.data.Message === ENABLED_ROOTCA_TOTP
            && verifyTOTPActionObject.combinedFields.actionType === ENABLE_ROOTCA_TOTP)) {
          queryClient.refetchQueries([GET_TOTP_STATE_QUERY], { exact: true });
          verifyTOTPActionObject.handleClose('success');
        } else if (response.data.initAction === ROOTCA_STARTUP_INITIATED
            && verifyTOTPActionObject.combinedFields.actionType === START_ROOTCA_TOTP) {
          queryClient.refetchQueries([ROOT_CA_STATUS_QUERY], { exact: true });
          dispatch(setSnackbar({
            snackbarType: 'success',
            snackbarMessage: 'Root CA startup initiated.'
          }));
          verifyTOTPActionObject.handleClose('success');
        } else if (response.data.Message === TOKEN_MATCH_SUCCESS
            && verifyTOTPActionObject.combinedFields.actionType === CHANGE_PHONE) {
          verifyTOTPActionObject.setVerifyMode(NEW_TOTP);
          verifyTOTPActionObject.nextStep(2);
        }
      },
      onError: (error: AxiosError, verifyTOTPActionObject) => {
        fieldValidationErrorHandler(error, dispatch, verifyTOTPActionObject.setError);
      }
    }
  );
};

export const useGetQRCodeMutation = () => {
  const dispatch = useDispatch();
  return useMutation(
    submitRequestForQrCode,
    {
      onError: (error: AxiosError) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};
