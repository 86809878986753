import axios, { AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import { UserFormType } from '../../types/formTypes';
import {
  ACTIVATE_USER,
  DEACTIVATE_USER,
  PrivilegesType,
  RESEND_EMAIL
} from '../../util/constants';
import { PseudoErrorResponse } from '../reactQuery/ErrorResponse';

export interface BasicUserType {
  firstName: string;
  lastName: string;
  email: string;
}

interface UserWithPrivilegeArray extends BasicUserType {
  privileges: string[];
}
export interface UserWithPrivilegeObject extends BasicUserType {
  privileges: {
    MANAGEUSERS_WRITE: boolean;
    MANAGEUSERS_READ: boolean;
    SOURCEIP_WRITE: boolean;
    SOURCEIP_READ: boolean;
    ROOTCA_WRITE: boolean;
    ROOTCA_READ: boolean;
    LOGGERS_WRITE: boolean;
    LOGGERS_READ: boolean;
  };
}

export interface AdditionalTableData {
  id: number;
  isActive: boolean;
  isConfirmed: boolean;
}

export type UserTableData = UserWithPrivilegeObject & AdditionalTableData;

export type UserResponse = UserWithPrivilegeObject & AdditionalTableData & {
  isPrimaryContact: boolean;
  isTotpEnabled: boolean;
};

export interface AddUserMutateObject {
  userData: UserWithPrivilegeArray;
  setError: UseFormSetError<UserFormType>
}

export interface PatchSelfObject {
  selfDataToUpdate: Partial<BasicUserType>;
  setError: UseFormSetError<BasicUserType>;
  setIsInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUpdateEmailSelfDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface PatchUserObject {
  id: string;
  userDataToUpdate: Partial<UserWithPrivilegeObject>;
  setError: UseFormSetError<UserFormType>;
  setIsInEditMode: (flag: boolean) => void;
  dirtyFields: any;
  userData: Partial<UserResponse>;
}

export interface StatusChangeObject {
  singleUserQueryData: UserResponse;
  action: typeof ACTIVATE_USER | typeof DEACTIVATE_USER | typeof RESEND_EMAIL
}
interface PrivilegeCategoriesResponse {
  name: PrivilegesType;
  description: string;
  sortOrder: number;
}
interface IsTokenValidResponse {
  isTokenValid: boolean;
}

// User Management
export const getAllUsers = (): Promise<AxiosResponse<UserResponse[]>> => axios.get('/api/users');

export const getSingleUser = (id: string): Promise<AxiosResponse<UserResponse>> => axios.get(`/api/users/${id}`);

export const getPrivilegeCategories = (): Promise<AxiosResponse<PrivilegeCategoriesResponse[]>> => axios.get('/api/privileges');

export const getIsTokenValid = (
  token: string
): Promise<AxiosResponse<IsTokenValidResponse>> => axios.get(`/api/token/${token}?option=validate`);

export const submitAddUser = (
  addUserMutateObject: AddUserMutateObject
): Promise<AxiosResponse<PseudoErrorResponse>> => axios.post('/api/users', addUserMutateObject.userData);

export const deleteUser = (
  deleteUserMutateObject: UserResponse
): Promise<AxiosResponse<PseudoErrorResponse>> => axios.delete(`/api/users/${deleteUserMutateObject.id}`);

export const updateUserDetails = (
  updateUserObject: PatchUserObject
): Promise<AxiosResponse<PseudoErrorResponse>> => axios.patch(`/api/users/${updateUserObject.id}`, updateUserObject.userDataToUpdate);

export const changeUserStatus = (
  userObject: StatusChangeObject
): Promise<AxiosResponse<PseudoErrorResponse>> => axios.put(`/api/users/${userObject.singleUserQueryData.id}/status`, userObject);

export const resendConfirmationEmail = (
  confirmationEmailObject: StatusChangeObject
): Promise<AxiosResponse<PseudoErrorResponse>> => axios.post(`/api/users/${confirmationEmailObject.singleUserQueryData.id}/confirmation`, confirmationEmailObject);

// Self Management
export const getSelf = (): Promise<AxiosResponse<UserResponse>> => axios.get('/api/users/me');

export const updateSelfDetails = (
  updateSelfMutateObject: PatchSelfObject
): Promise<AxiosResponse<PseudoErrorResponse>> => axios.patch('/api/users/me', updateSelfMutateObject.selfDataToUpdate);
