import React from 'react';
import {
  Paper,
  Typography

} from '@mui/material';
import UserForm from './UserForm';

const CreateUser = () => {
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    manageUsersPrivileges: '',
    sourceIPPrivileges: '',
    rootCAPrivileges: '',
    loggersPrivileges: ''
  };

  return (
    <>
      <Typography component="h1" variant="h4" gutterBottom={true}>
        Create New User
      </Typography>
      <Typography variant="subtitle1">
        Enter the required data to create a new User account.  An email containing a
        magic link will be sent to the user to complete the account set-up process.
      </Typography>
      <br />
      <Paper>
        <UserForm
          defaultValues={defaultValues}
        />
      </Paper>
    </ >
  );
};

export default CreateUser;
