import React from 'react';
import {
  CheckBox as CheckBoxIcon,
  Visibility as VisibilityIcon,
  HorizontalRule as HorizontalRuleIcon,
} from '@mui/icons-material';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  AlertColor
} from '@mui/material';

import { Link as RouterLink, useHistory } from 'react-router-dom';

import { useSessionExtendQuery } from '../../../../api/reactQuery/queries';
import { USER_MANAGEMENT_ROUTE } from '../../../../util/routeConstants';
import UserStatusTag from '../../../UserStatusTag';
import { UserTableData } from '../../../../api/UserManagement/UserManagementRequests';

interface UsersTableProps {
  isOwnProfile: boolean;
  tableData: Array<UserTableData>;
}

const UsersTable = (
  {
    isOwnProfile,
    tableData
  }: UsersTableProps
) => {
  const history = useHistory();
  const sessionExtendQuery = useSessionExtendQuery();

  const PermissionNames = [
    'Manage Users',
    'Source IPs',
    'Root CA',
    'Loggers'
  ];

  // Perform session check before following link
  const followLink = async (link: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    await sessionExtendQuery.refetch({ throwOnError: true });
    history.push(link);
  };

  const formatPrivilegeIcons = (user: UserTableData) => {
    if (user) {
      const whichColorIcon = () => {
        if (user.isActive) {
          return 'text.primary';
        }
        return 'text.disabled';
      };
      return (
        <>
          <TableCell align="center" sx={{ color: whichColorIcon() }}>
            {user.privileges.MANAGEUSERS_WRITE
              ? <CheckBoxIcon /> : user.privileges.MANAGEUSERS_READ
                ? <VisibilityIcon /> : <HorizontalRuleIcon />}
          </TableCell>
          <TableCell align="center" sx={{ color: whichColorIcon() }}>
            {user.privileges.SOURCEIP_WRITE
              ? <CheckBoxIcon /> : user.privileges.SOURCEIP_READ
                ? <VisibilityIcon /> : <HorizontalRuleIcon />}
          </TableCell>
          <TableCell align="center" sx={{ color: whichColorIcon() }}>
            {user.privileges.ROOTCA_WRITE
              ? <CheckBoxIcon /> : user.privileges.ROOTCA_READ
                ? <VisibilityIcon /> : <HorizontalRuleIcon />}
          </TableCell>
          <TableCell align="center" sx={{ color: whichColorIcon() }}>
            {user.privileges.LOGGERS_WRITE
              ? <CheckBoxIcon /> : user.privileges.LOGGERS_READ
                ? <VisibilityIcon /> : <HorizontalRuleIcon />}
          </TableCell>
        </>
      );
    }
    return null;
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          {PermissionNames.map((permName) => (
            <TableCell key={permName} align="center">
              {permName}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isOwnProfile
          ? (
            <TableRow hover={true}>
              <TableCell>
                {`${tableData[0]?.lastName}, ${tableData[0]?.firstName}`}
              </TableCell>
              {formatPrivilegeIcons(tableData[0])}
            </TableRow>
          )
          : (
            <>
              {tableData?.map((person: UserTableData) => (
                <TableRow hover={true} key={person.id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => followLink(`${USER_MANAGEMENT_ROUTE}/${person.id}`, e)}
                        to={`${USER_MANAGEMENT_ROUTE}/${person.id}`}
                        variant="body1"
                      >
                        {`${person.lastName}, ${person.firstName}`}

                      </Link>
                      {!person.isActive && (
                        <UserStatusTag label="Deactivated" />
                      )}
                      {!person.isConfirmed && (
                      <UserStatusTag label="Unverified" color={'warning' as AlertColor} variant="outlined" />
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ color: !person.isActive ? 'text.disabled' : 'text.primary' }}>
                        {person.email}
                      </Typography>
                    </Box>
                  </TableCell>
                  {formatPrivilegeIcons(person)}
                </TableRow>
              ))}
            </>
          )}
      </TableBody>
    </Table>
  );
};

export default UsersTable;
