import { useLoggedInUserQuery } from '../api/reactQuery/queries';

const useEjbcaUrl = (protocol: string, prefix: string, suffix: string) => {
  const { data: loggedInUserData } = useLoggedInUserQuery();

  const strEnv = process.env.REACT_APP_EJBCA_HOST_ENV === 'development' ? 'dev' : 'prod';
  return (
    `${protocol
    }://${
      prefix
    }.${
      loggedInUserData?.user?.domainName
    }.${
      loggedInUserData?.user?.region
    }.${
      strEnv
    }.saas.primekey.com${
      suffix}`
  );
};

export default useEjbcaUrl;
