import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps
} from 'react-router-dom';
import { useLoggedInUserQuery, useGetIsTokenValidQuery } from '../api/reactQuery/queries';

import InsufficentPrivileges from '../components/InsufficientPrivileges';
import { PrivilegesType } from '../util/constants';
import {
  LOGIN_ROUTE,
  MORE_INFO_ROUTE,
  PORTAL_ROUTE,
  TOKEN_ERROR_ROUTE
} from '../util/routeConstants';

interface ProtectedRouteProps extends RouteComponentProps {
  component: any;
  path: string;
  requiredPrivileges?: PrivilegesType[];
}

const ProtectedRoute = (
  {
    component: Component,
    path,
    requiredPrivileges = []
  }: ProtectedRouteProps
) => {
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const { isError: isTokenValidError } = useGetIsTokenValidQuery();
  const isUserLoggedIn = Boolean(loggedInUserData?.user?.email);
  const areOptionsDefined = loggedInUserData?.user?.optionsDefined;
  const userPrivs = loggedInUserData?.user?.privileges;

  return (
    <Route
      path={path}
      render={() => ((isUserLoggedIn && areOptionsDefined && path === MORE_INFO_ROUTE)
        ? <Redirect to={PORTAL_ROUTE} />
        : (isUserLoggedIn && !areOptionsDefined && path === PORTAL_ROUTE)
          ? <Redirect to={MORE_INFO_ROUTE} />
          : !isUserLoggedIn
            ? (isTokenValidError || Boolean(loggedInUserData?.emailConfirmation?.tokenError))
              ? <Redirect to={TOKEN_ERROR_ROUTE} />
              : <Redirect to={LOGIN_ROUTE} />
            : userPrivs && requiredPrivileges.every((priv) => userPrivs[priv])
              ? <Component />
              : <InsufficentPrivileges />
      )}
    />
  );
};

export default ProtectedRoute;
