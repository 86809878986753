import { UserFormType } from '../../../../types/formTypes';

// privileges from form need to be reformated to match what gets sent to backend. Form data is
// loggers: read (or write, none) backend expects just an array of Privileges, ex: LOGGERS_READ
export const preppedPrivileges = (data: UserFormType) => {
  const privilegeArray = [`MANAGEUSERS_${data.manageUsersPrivileges}`, `SOURCEIP_${data.sourceIPPrivileges}`, `ROOTCA_${data.rootCAPrivileges}`, `LOGGERS_${data.loggersPrivileges}`];
  // do not need to send any of the none entries, remove from the array
  const filterOutNone = privilegeArray.filter((entry) => !entry.includes('_None'));
  filterOutNone.forEach((entry) => {
    if (entry.includes('_Write')) {
      const privilegeName = entry.split('_Write')[0];
      filterOutNone.push(`${privilegeName}_Read`);
    }
  });
  return filterOutNone.map((entry) => entry.toUpperCase());
};

// if privileges were updated, they need to also be formatted to be displayed correctly
export const reformatPrivilegesForViewing = (data: UserFormType) => {
  const formDataPrivilegesArray = preppedPrivileges(data);
  let reformatedPrivilegeObject = {
    MANAGEUSERS_WRITE: false,
    MANAGEUSERS_READ: false,
    SOURCEIP_READ: false,
    SOURCEIP_WRITE: false,
    LOGGERS_READ: false,
    LOGGERS_WRITE: false,
    ROOTCA_READ: false,
    ROOTCA_WRITE: false
  };
  // for Privileges that are write, it must also have read privileges,
  // change privilege to ui readable object. ex: Privilege: true
  formDataPrivilegesArray.forEach((entry: string) => {
    if (entry.includes('_WRITE')) {
      const readEntry = entry.replace('_WRITE', '_READ');
      reformatedPrivilegeObject = { ...reformatedPrivilegeObject, [readEntry]: true };
    }
    reformatedPrivilegeObject = { ...reformatedPrivilegeObject, [entry]: true };
  });
  return reformatedPrivilegeObject;
};
