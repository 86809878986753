import React from 'react';
import {
  Box,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  useTheme,
  Button
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSelector, useDispatch } from 'react-redux';

import {
  startUpdateIp,
  removeIpFromPending,
  selectIpInUpdateModeKey,
  IP_EDIT_CASE
} from '../../redux/slices/sourceIpSlice';

interface PendingEditedItemProps {
  description: string,
  pendingDescription: string,
  pendingText: string,
  propKey: string,
  status: keyof typeof IP_EDIT_CASE,
  text: string
}

const PendingEditedItem = ({
  description,
  pendingDescription,
  pendingText,
  propKey,
  status,
  text
}: PendingEditedItemProps) => {
  const dispatch = useDispatch();
  const ipInUpdateModeKey = useSelector(selectIpInUpdateModeKey);
  const theme = useTheme();

  const isMobileLayout = useMediaQuery(theme.breakpoints.down('sm'));

  const startIpUpdate = () => {
    dispatch(startUpdateIp({
      text, description, key: propKey, status
    }));
  };

  const cancelEdit = () => {
    dispatch(removeIpFromPending({ text, description, key: propKey }));
  };

  const textChangesDisplay = (oldText: string, newText: string) => {
    if (oldText === newText) {
      return (
        <div>
          <span>
            {oldText}
          </span>
        </div>
      );
    }
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" color="green">
          {newText}
        </Box>
        <Box display="flex" color="gray" pr="10px" sx={{ textDecoration: 'line-through' }}>
          {oldText}
        </Box>
      </Box>
    );
  };

  const desktopViewButtons = () => (
    <ListItemSecondaryAction>
      <IconButton
        disabled={Boolean(ipInUpdateModeKey)}
        onClick={() => startIpUpdate()}
        color="primary"
        size="large"
        aria-label="Edit Entry"
      >
        <EditOutlinedIcon />
      </IconButton>
      <IconButton
        disabled={Boolean(ipInUpdateModeKey)}
        aria-label="Undo Edit"
        onClick={() => cancelEdit()}
        color="primary"
        size="large"
      >
        <UndoIcon />
      </IconButton>
    </ListItemSecondaryAction>
  );

  const mobileViewButtons = () => (
    <Grid container={true} sx={{ justifyContent: 'flex-end', mt: 1 }}>
      <Button
        sx={{ mr: 2 }}
        disabled={Boolean(ipInUpdateModeKey)}
        onClick={() => startIpUpdate()}
        startIcon={<EditOutlinedIcon />}
        variant="outlined"
        color="primary"
        aria-label="Edit Entry"
      >
        Edit
      </Button>
      <Button
        disabled={Boolean(ipInUpdateModeKey)}
        onClick={() => cancelEdit()}
        startIcon={<UndoIcon />}
        variant="outlined"
        color="primary"
        aria-label="Undo Edit"
      >
        Undo Edit
      </Button>
    </Grid>
  );

  return (
    <>
      <Grid item={true} xs={12} sm={3}>
        <ListItemText
          sx={{ display: 'flex', justifyContent: isMobileLayout ? 'flex-end' : 'flex-start' }}
          primary={textChangesDisplay(text, pendingText)}
        />
      </Grid>
      <Grid item={true} xs={12} sm={7}>
        <ListItemText
          primary={textChangesDisplay(description, pendingDescription)}
          sx={{ flexWrap: 'wrap', wordWrap: 'break-word' }}
        />
      </Grid>
      {isMobileLayout
        ? mobileViewButtons()
        : desktopViewButtons()}
    </>
  );
};

export default PendingEditedItem;
