import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

import DeployedDialogText from '../portal/DeployedDialogText';
import { useSetDeployDialogFlagMutation } from '../../api/reactQuery/mutations';
import { useGetDeploymentStatus, useGetDeployDialogFlag } from '../../api/reactQuery/queries';

const DeployedDialog = () => {
  const { data: deploymentStatus } = useGetDeploymentStatus();
  const { data: deployDialogFlag } = useGetDeployDialogFlag();
  const { mutate: setDeployDialogFlagMutation } = useSetDeployDialogFlagMutation();

  const handleDialogClose = (reason: string) => {
    if (reason !== 'backdropClick') {
      setDeployDialogFlagMutation({ value: false });
    }
  };

  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        onClose={(_e, reason) => handleDialogClose(reason)}
        aria-labelledby="customized-dialog-title"
        open={(deployDialogFlag?.flag && deploymentStatus?.status === 100) || false}
        maxWidth="md"
      >
        <DialogTitle
          id="customized-dialog-title"
          sx={{ m: 0, p: 2 }}
        >
          Retrieve Superadmin Credentials
        </DialogTitle>
        <DialogContent dividers={true} sx={{ p: 2 }}>
          <DeployedDialogText />
        </DialogContent>
        <DialogActions sx={{ m: 0, p: 1 }}>
          <Button
            onClick={() => handleDialogClose('button')}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeployedDialog;
