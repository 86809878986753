import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { useLoggedInUserQuery } from '../Session/SessionQueries';
import { basicErrorHandler } from '../reactQuery/queryErrorHandling';
import { INVALID_SESSION } from '../../util/constants';
import { DashboardEndpoints, getMainDashboardApis } from './MainDashboardRequests';

const useMainDashboardQuery = (queryName: DashboardEndpoints) => {
  const { data: loggedInUserData } = useLoggedInUserQuery();
  const dispatch = useDispatch();
  return useQuery(
    `${queryName}`,
    () => {
      const response = getMainDashboardApis(queryName)
        .then((res) => res.data);
      return response;
    },
    {
      enabled: Boolean(loggedInUserData?.user),
      refetchInterval: 6000,
      retry: (failureCount, error: AxiosError) => {
        if (error.response?.data.errors) {
          return error.response.data.errors.some((entry: any) => entry.type !== INVALID_SESSION);
        }
        return failureCount < 3;
      },
      onError: (error) => {
        basicErrorHandler(error, dispatch);
      }
    }
  );
};

export default useMainDashboardQuery;
