import React, { useEffect } from 'react';
import {
  Button,
  Box,
  Grid,
  Link,
  TextField,
  Typography,
  Alert
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Link as routerLink } from 'react-router-dom';

import TOTPLoginDialog from './Dialogs/TOTPLoginDialog';
import BruteForceBackdrop from './portal/layout/BruteForceBackdrop';
import SignupLink from './SignupLink';
import StaticPageLayoutContainer from './StaticPageLayoutContainer';
import { useLoginUserMutation } from '../api/reactQuery/mutations';
import {
  selectLoginAlertMessage,
  selectLoginAlertOpen,
  selectLoginAlertType,
  clearLoginAlert
} from '../redux/slices/loginAlertSlice';
import { selectBruteForceBackdropOpen } from '../redux/slices/bruteForceBackdropSlice';
import { LoginFormType } from '../types/formTypes';
import { FORGOT_PASSWORD_ROUTE } from '../util/routeConstants';

const Login = () => {
  const dispatch = useDispatch();
  const displayLoginAlert = useSelector(selectLoginAlertOpen);
  const loginAlertMessage = useSelector(selectLoginAlertMessage);
  const loginAlertType = useSelector(selectLoginAlertType);
  const backdropOpen = useSelector(selectBruteForceBackdropOpen);
  const [openTOTP, setOpenTOTP] = React.useState(false);
  const [loginObj, setLoginObj] = React.useState({
    email: '',
    password: ''
  });
  const { mutate: loginUserMutate } = useLoginUserMutation();

  const LoginFormSchema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .email('Please enter a valid email'),
    password: yup
      .string()
      .max(128, 'Enter at most 128 characters for your password')
      .required('Required')
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    reset,
    setError
  } = useForm<LoginFormType>({
    defaultValues: { email: '', password: '' },
    mode: 'onChange',
    resolver: yupResolver(LoginFormSchema)
  });

  const onSubmit = (data: LoginFormType) => {
    // Set login info in state, passed to TOTP dialog for submission with token.
    setLoginObj(data);
    const loginUserObject = {
      data,
      openTOTP,
      setOpenTOTP,
      setError
    };
    loginUserMutate(loginUserObject);
  };

  useEffect(() => () => {
    dispatch(clearLoginAlert());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <>
      <BruteForceBackdrop />
      <TOTPLoginDialog
        open={openTOTP}
        setOpenTOTP={setOpenTOTP}
        loginObj={loginObj}
      />
      <StaticPageLayoutContainer>
        <>
          {displayLoginAlert
            && (
            <Alert severity={loginAlertType} variant="filled" sx={{ width: '100%', mb: 2, justifyContent: 'center' }}>
              {loginAlertMessage}
            </Alert>
            )}
          <Typography component="h1" variant="h5" gutterBottom={true}>
            Login to EJBCA SaaS
          </Typography>
          <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              autoFocus={true}
              variant="outlined"
              margin="normal"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('email', {
                required: true
              })}
              fullWidth={true}
              id="email"
              InputProps={{
                inputProps: {
                  'data-lpignore': 'false'
                }
              }}
              label="Email Address"
              autoComplete="email"
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth={true}
              label="Password"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('password', {
                required: true
              })}
              type="password"
              id="password"
              InputProps={{
                inputProps: {
                  'data-lpignore': 'false'
                }
              }}
              autoComplete="off"
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
            />
            <Button
              disabled={backdropOpen || !isValid}
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
              sx={{ mt: 3, mr: 0, mb: 2 }}
            >
              Log In
            </Button>
            <Grid container={true}>
              <Grid item={true} xs={true}>
                <Link to={FORGOT_PASSWORD_ROUTE} component={routerLink}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item={true}>
                <SignupLink />
              </Grid>
            </Grid>
          </Box>
        </>
      </StaticPageLayoutContainer>
    </>
  );
};

export default Login;
