import React from 'react';
import {
  Grid,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  useTheme,
  Button
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSelector, useDispatch } from 'react-redux';

import { useRouteMatch } from 'react-router-dom';
import {
  startUpdateIp,
  removeIpFromPending,
  IP_EDIT_CASE, PendingIpShape,
  selectIpInUpdateMode
} from '../../redux/slices/sourceIpSlice';
import ActiveEditItem from './ActiveEditItem';
import { MORE_INFO_ROUTE } from '../../util/routeConstants';

interface PendingAddItemProps {
  description: string,
  fullList: PendingIpShape[],
  propKey: string,
  status: keyof typeof IP_EDIT_CASE,
  text: string
}

const PendingAddItem = ({
  description,
  fullList,
  propKey,
  status,
  text
}: PendingAddItemProps) => {
  const dispatch = useDispatch();
  const ipInUpdateMode = useSelector(selectIpInUpdateMode);
  const theme = useTheme();
  const forceMobileLayout = useRouteMatch({
    path: MORE_INFO_ROUTE,
    sensitive: false
  });
  const isMobileLayout = useMediaQuery(theme.breakpoints.down('sm')) || forceMobileLayout;
  const removeIpFromDisplayed = () => {
    dispatch(removeIpFromPending({ text, description, key: propKey }));
  };

  const handleEditClick = () => {
    dispatch(startUpdateIp({
      text, description, key: propKey, status
    }));
  };

  if (ipInUpdateMode.key === propKey) {
    return (
      <ActiveEditItem
        text={text}
        description={description}
        isPendingAdd={true}
        fullList={fullList}
      />
    );
  }

  const desktopViewButtons = () => (
    <ListItemSecondaryAction>
      <IconButton
        disabled={Boolean(ipInUpdateMode.key)}
        color="primary"
        aria-label="Edit Entry"
        onClick={() => handleEditClick()}
        size="large"
      >
        <EditOutlinedIcon />
      </IconButton>
      <IconButton
        disabled={Boolean(ipInUpdateMode.key)}
        color="primary"
        aria-label="Delete Entry"
        onClick={() => removeIpFromDisplayed()}
        size="large"
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItemSecondaryAction>
  );

  const mobileViewButtons = () => (
    <Grid container={true} sx={{ justifyContent: 'flex-end', mt: 1 }}>
      <Button
        sx={{ mr: 2 }}
        disabled={Boolean(ipInUpdateMode.key)}
        onClick={() => handleEditClick()}
        startIcon={<EditOutlinedIcon />}
        variant="outlined"
        color="primary"
        aria-label="Edit Entry"
      >
        Edit
      </Button>
      <Button
        disabled={Boolean(ipInUpdateMode.key)}
        onClick={() => removeIpFromDisplayed()}
        startIcon={<RemoveCircleOutlineIcon />}
        variant="outlined"
        color="primary"
        aria-label="Delete Entry"
      >
        Remove
      </Button>
    </Grid>
  );
  return (
    <>
      <Grid item={true} xs={12} sm={forceMobileLayout ? 12 : 3}>
        <ListItemText primary={text} sx={{ color: 'green', display: 'flex', justifyContent: isMobileLayout ? 'flex-end' : 'flex-start' }} />
      </Grid>
      <Grid item={true} xs={12} sm={forceMobileLayout ? 12 : 7}>
        <ListItemText
          primary={description}
          sx={{ color: 'green', flexWrap: 'wrap', wordWrap: 'break-word' }}
        />
      </Grid>
      {isMobileLayout
        ? mobileViewButtons()
        : desktopViewButtons()}
    </>
  );
};

export default PendingAddItem;
