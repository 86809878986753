import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { useHistory } from 'react-router-dom';

import { submitForgotPassword, submitResetPassword } from './PasswordRequests';
import { fieldValidationErrorHandler } from '../reactQuery/queryErrorHandling';
import { GET_IS_TOKEN_VALID_QUERY } from '../../util/constants';
import { setLoginAlert } from '../../redux/slices/loginAlertSlice';
import { LOGIN_ROUTE } from '../../util/routeConstants';

export const useForgotPasswordMutation = () => {
  const dispatch = useDispatch();

  return useMutation(
    submitForgotPassword,
    {
      onSuccess: (response, forgotPasswordObject) => {
        forgotPasswordObject.setMessageFromServer(response.data.Message);
      },
      onError: (error: AxiosError, forgotPasswordObject) => {
        fieldValidationErrorHandler(error, dispatch, forgotPasswordObject.setError);
      }
    }
  );
};

export const useResetPasswordMutation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(
    submitResetPassword,
    {
      onSuccess: () => {
        dispatch(setLoginAlert({
          loginAlertmessage: 'Your password has been set.',
          isLoginAlertOpen: true,
          loginAlertType: 'success'
        }));
        history.push(LOGIN_ROUTE);
      },
      onError: (error: AxiosError, resetPasswordObject) => {
        if (error.response?.data.errors) {
          error.response.data.errors.forEach((entry: any) => {
            if (entry.type === 'TOKEN') {
              queryClient.refetchQueries(GET_IS_TOKEN_VALID_QUERY);
            }
          });
        }
        fieldValidationErrorHandler(error, dispatch, resetPasswordObject.setError);
      }
    }
  );
};
