// Heavily inspired by https://blog.logrocket.com/implementing-copy-to-clipboard-in-react-with-clipboard-api/
import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import { FileCopyOutlined } from '@mui/icons-material';

interface CopyToClipboardProps {
  copyText: string
}

const CopyToClipboard = (
  {
    copyText
  }: CopyToClipboardProps
) => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      const copied = await navigator.clipboard.writeText(text);
      return copied;
    }
    // fallback for IE
    return document.execCommand('copy', true, text);
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        // hide the success message after a short time
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error from CopyToClipboard: ', err);
      });
  };

  return (
    <>
      <IconButton
        onClick={handleCopyClick}
        size="small"
      >
        <FileCopyOutlined fontSize="small" />
      </IconButton>
      {isCopied
        && <Typography component="span" variant="body1" sx={{ color: 'success.main', display: 'inline', ml: '4px' }}> Copied! </Typography>}
    </>
  );
};

export default CopyToClipboard;
