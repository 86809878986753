import React from 'react';
import {
  Button,
  Box
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useSessionExtendQuery } from '../../../../../api/reactQuery/queries';
import { USER_MANAGEMENT_ROUTE } from '../../../../../util/routeConstants';

interface CreateUserButtonProps {
  isValid: boolean;
}

const CreateUserButtons = (
  {
    isValid
  }: CreateUserButtonProps
) => {
  const history = useHistory();
  const sessionExtendQuery = useSessionExtendQuery();

  const onCancelClick = async () => {
    await sessionExtendQuery.refetch({ throwOnError: true });
    history.push(USER_MANAGEMENT_ROUTE);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        pb: 4,
        pt: 2
      }}
    >
      <Button
        sx={{ mr: 2 }}
        variant="outlined"
        onClick={onCancelClick}
      >
        Cancel
      </Button>

      <Button
        variant="contained"
        type="submit"
        disabled={!isValid}
      >
        Add User
      </Button>

    </Box>
  );
};

export default CreateUserButtons;
