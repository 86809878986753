import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  TextField,
  Typography,
  MenuItem,
  Grid,
  Tooltip
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { useGetPrivilegeCategoriesQuery } from '../../../../api/reactQuery/queries';
import { CREATE_USER_ROUTE } from '../../../../util/routeConstants';
import { UserFormType } from '../../../../types/formTypes';

const customDisabledStyle = {
  '& .MuiInput-root.Mui-disabled:before': { borderBottomStyle: 'none' },
  '& .MuiInput-input': { paddingBottom: 0 },
  '& .MuiSelect-icon.Mui-disabled': { display: 'none' },
  '& .MuiInput-input.Mui-disabled': {
    WebkitTextFillColor: 'text.primary'
  },
  textAlign: 'right'
} as const;

const customInactiveUserStyle = {
  '& .MuiInput-root.Mui-disabled:before': { borderBottomStyle: 'none' },
  '& .MuiInput-input': { paddingBottom: 0 },
  '& .MuiSelect-icon.Mui-disabled': { display: 'none' },
  textAlign: 'right'
} as const;

const customCreateUserStyle = {
  '& .MuiInput-root': { color: 'text.primary' },
  '& .MuiInputLabel-root': { color: 'text.primary' },
  textAlign: 'right'
} as const;

const tooltipStyle = {
  mb: 1, mr: 1, mt: 6, color: 'text.primary'
} as const;

interface PrivilegeFormProps {
  control: Control<UserFormType>,
  errors: FieldErrors,
  isInEditMode?: boolean,
  isUserActive?: boolean
}

interface FoundCategoryEntry {
  name: string;
  description: string
}

const PrivilegeForm = (
  {
    control,
    errors,
    isInEditMode = false,
    isUserActive = false
  }: PrivilegeFormProps
) => {
  const { pathname } = useLocation();
  const { data: privilegeCategoryData } = useGetPrivilegeCategoriesQuery();
  const privilegeOptions = ['None', 'Read', 'Write'];
  const originatingFromCreate = pathname === CREATE_USER_ROUTE;

  const inactiveUserTooltipStyle = {
    mb: 1, mr: 1, mt: 6, color: 'text.disabled'
  } as const;

  const tooltipText = (category: string, suffix: string) => {
    const foundEntry = privilegeCategoryData?.find((entry: FoundCategoryEntry) => entry.name === `${category}_${suffix}`);
    return foundEntry?.description;
  };

  return (
    <>
      <Grid container={true} item={true} direction="row" alignItems="flex-end" wrap="nowrap" justifyContent="flex-start">
        <Grid container={true} item={true} alignItems="flex-end" wrap="nowrap" sx={{ minWidth: 210, height: 72 }}>
          <Tooltip
            title={(
              <>
                <div>{`Read: ${tooltipText('MANAGEUSERS', 'READ')}`}</div>
                <div>{`Write: ${tooltipText('MANAGEUSERS', 'WRITE')}`}</div>
              </>
            )}
            sx={isUserActive || originatingFromCreate ? tooltipStyle : inactiveUserTooltipStyle}
          >
            <InfoOutlined fontSize="small" />
          </Tooltip>
          <Typography sx={{ mb: '6px', mt: 6, color: isUserActive || originatingFromCreate ? 'text.primary' : 'text.disabled' }}>
            Manage Users Privileges:
          </Typography>
        </Grid>
        <Grid item={true} md={12} sm={12}>
          <Controller
            control={control}
            name="manageUsersPrivileges"
            render={({ field }) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                fullWidth={true}
                select={true}
                margin="normal"
                disabled={!isInEditMode && !originatingFromCreate}
                error={!!errors.manageUsersPrivileges}
                helperText={errors.manageUsersPrivileges?.message}
                sx={isUserActive ? customDisabledStyle : originatingFromCreate
                  ? customCreateUserStyle : customInactiveUserStyle}
              >
                {privilegeOptions.map((privilege) => (
                  <MenuItem key={privilege} value={privilege} sx={{ justifyContent: 'flex-end' }}>
                    {privilege}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
      <Grid container={true} item={true} direction="row" alignItems="flex-end" wrap="nowrap" justifyContent="flex-start">
        <Grid item={true} container={true} alignItems="flex-end" wrap="nowrap" sx={{ minWidth: 210, height: 72 }}>
          <Tooltip
            title={(
              <>
                <div>{`Read: ${tooltipText('SOURCEIP', 'READ')}`}</div>
                <div>{`Write: ${tooltipText('SOURCEIP', 'WRITE')}`}</div>
              </>
            )}
            sx={isUserActive || originatingFromCreate ? tooltipStyle : inactiveUserTooltipStyle}
          >
            <InfoOutlined fontSize="small" />
          </Tooltip>
          <Typography sx={{ mb: '6px', mt: 6, color: isUserActive || originatingFromCreate ? 'text.primary' : 'text.disabled' }}>
            Source IP Privileges:
          </Typography>
        </Grid>
        <Grid item={true} md={12} sm={12}>
          <Controller
            control={control}
            name="sourceIPPrivileges"
            render={({ field }) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                fullWidth={true}
                select={true}
                margin="normal"
                disabled={!isInEditMode && !originatingFromCreate}
                error={!!errors.sourceIPPrivileges}
                helperText={errors.sourceIPPrivileges?.message}
                sx={isUserActive ? customDisabledStyle : originatingFromCreate
                  ? customCreateUserStyle : customInactiveUserStyle}
              >
                {privilegeOptions.map((privilege) => (
                  <MenuItem key={privilege} value={privilege} sx={{ justifyContent: 'flex-end' }}>
                    {privilege}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>

      <Grid container={true} item={true} direction="row" alignItems="flex-end" wrap="nowrap" justifyContent="flex-start">
        <Grid item={true} container={true} alignItems="flex-end" wrap="nowrap" sx={{ minWidth: 210, height: 72 }}>
          <Tooltip
            title={(
              <>
                <div>{`Read: ${tooltipText('ROOTCA', 'READ')}`}</div>
                <div>{`Write: ${tooltipText('ROOTCA', 'WRITE')}`}</div>
              </>
          )}
            sx={isUserActive || originatingFromCreate ? tooltipStyle : inactiveUserTooltipStyle}
          >
            <InfoOutlined fontSize="small" />
          </Tooltip>
          <Typography sx={{ mb: '6px', mt: 6, color: isUserActive || originatingFromCreate ? 'text.primary' : 'text.disabled' }}>
            Root CA Privileges:
          </Typography>
        </Grid>
        <Grid item={true} md={12} sm={12}>
          <Controller
            control={control}
            name="rootCAPrivileges"
            render={({ field }) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                fullWidth={true}
                select={true}
                margin="normal"
                disabled={!isInEditMode && !originatingFromCreate}
                error={!!errors.rootCAPrivileges}
                helperText={errors.rootCAPrivileges?.message}
                sx={isUserActive ? customDisabledStyle : originatingFromCreate
                  ? customCreateUserStyle : customInactiveUserStyle}
              >
                {privilegeOptions.map((privilege) => (
                  <MenuItem key={privilege} value={privilege} sx={{ justifyContent: 'flex-end' }}>
                    {privilege}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
      <Grid container={true} item={true} direction="row" alignItems="flex-end" wrap="nowrap" justifyContent="flex-start">
        <Grid item={true} container={true} alignItems="flex-end" wrap="nowrap" sx={{ minWidth: 210, height: 72 }}>
          <Tooltip
            title={(
              <>
                <div>{`Read: ${tooltipText('LOGGERS', 'READ')}`}</div>
                <div>{`Write: ${tooltipText('LOGGERS', 'WRITE')}`}</div>
              </>
          )}
            sx={isUserActive || originatingFromCreate ? tooltipStyle : inactiveUserTooltipStyle}
          >
            <InfoOutlined fontSize="small" />
          </Tooltip>
          <Typography sx={{ mb: '6px', mt: 6, color: isUserActive || originatingFromCreate ? 'text.primary' : 'text.disabled' }}>
            Logger Privileges:
          </Typography>
        </Grid>
        <Grid item={true} md={12} sm={12}>
          <Controller
            control={control}
            name="loggersPrivileges"
            render={({ field }) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                fullWidth={true}
                select={true}
                margin="normal"
                disabled={!isInEditMode && !originatingFromCreate}
                error={!!errors.loggersPrivileges}
                helperText={errors.loggersPrivileges?.message}
                sx={isUserActive ? customDisabledStyle : originatingFromCreate
                  ? customCreateUserStyle : customInactiveUserStyle}
              >
                {privilegeOptions.map((privilege) => (
                  <MenuItem key={privilege} value={privilege} sx={{ justifyContent: 'flex-end' }}>
                    {privilege}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PrivilegeForm;
